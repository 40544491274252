import { ElementRef } from '@angular/core';
import { AvProducerService } from '../../services/av-producer/av-producer.service';
import { UserService } from '../../services/user/user.service';
import { Subscription, timer } from 'rxjs';


export class ToolboxChatClass {
  protected msgText: string = '';
  protected scrollBottom: boolean = true;
  protected _chatContainerDiv: ElementRef<HTMLDivElement> | undefined;

  protected msgSubscription: Subscription | undefined;

  constructor(protected avProd: AvProducerService,
              protected userService: UserService){
    
  }

  protected init(chatDiv: ElementRef<HTMLDivElement> | undefined): void {
    this._chatContainerDiv = chatDiv;
    if (this._chatContainerDiv !== undefined){
      this._chatContainerDiv.nativeElement.onscroll = (ev => this.onScrollMoved(ev));
    }
    if (this.msgSubscription !== undefined){
      this.msgSubscription.unsubscribe();
    }
    this.msgSubscription = this.avProd.onNewMessagingChatStatus$.subscribe(id => this.updateChatStatus(id));
    this.updateChatStatus(1);
  }

  protected destroy(): void {
    if (this.msgSubscription !== undefined){
      this.msgSubscription.unsubscribe();
    }
  }

  protected onScrollMoved(ev: Event){
    console.log('[ToolboxChatClass] Div Scroll ' + this._chatContainerDiv?.nativeElement.scrollTop + '/' + this._chatContainerDiv?.nativeElement.scrollHeight);
    if (this._chatContainerDiv !== undefined){
      if (this._chatContainerDiv?.nativeElement.scrollTop !== (this._chatContainerDiv?.nativeElement.scrollHeight - this._chatContainerDiv?.nativeElement.offsetHeight)){
        this.scrollBottom = false;
      }
      else {
        this.scrollBottom = true;
      }
    }
  }

  protected updateChatStatus(id: number){
    // To do: Find a bette way to keep scroll down
    timer(200).subscribe(() => this.scrollToBottom());
  }

  protected onMessageChange() {
  }

  protected onClickRemove(id: number){
    this.avProd.azzCmdMessagingRemove(1, id);
  }

  protected onClickSend() {
    if ((this.userService.user !== undefined)&&
        (this.userService.user.id !== undefined)&&
        (this.userService.user.name !== undefined)){
      this.avProd.azzCmdMessagingSend(1, this.userService.user.id, this.userService.user.name, this.msgText);
      this.msgText = '';
    }
  }

  protected scrollToBottom(){
    if (this._chatContainerDiv !== undefined){
      if (this.scrollBottom === true){
        this._chatContainerDiv.nativeElement.scrollTop = this._chatContainerDiv?.nativeElement.scrollHeight;
      }
    }
  }
}
