<div class="dropdown">

  <button class="btn dropdown-toggle"
          [ngClass]="color"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false">
    {{ curLang }}
  </button>
  <ul class="dropdown-menu">
    <li *ngFor="let language of languages">
      <span *ngIf="curLang === language.iso">
          {{ language.name }}
          <i class="bi bi-check2 ms-auto"></i>
      </span>
      <button *ngIf="curLang !== language.iso"
              class="dropdown-item d-flex align-items-center"
              (click)="changeLanguage(language.iso)"
              aria-pressed="false">{{ language.name }}</button>
    </li>
  </ul>

</div>
