<div class="text-center w-100">
  <div class="form-floating my-3">
    <input type="tel"
           maxlength="6"
           minlength="6"
           class="form-control"
           id="code"
           name="code"
           inputmode="number"
           pattern="[0-9]{6}"
           (input)="onCodeChange($event)"
           placeholder="{{ 'users.enterCode' | translate }}"
           required>
    <label for="code">{{ "users.enterCode" | translate }}</label>
  </div>
</div>
