import { Subscription } from 'rxjs';

import { AlertStatus, ToastPlacement, ToastStatus } from '../../enums/common.enum';
import { ModalDialogId } from '../../enums/utils.enum';
import { IAlertData, IToastConfig } from '../../interfaces/utils/utils.interface';
import {
  IEventAsset,
  IEventUserLastChanges,
} from '../../interfaces/events/event.interface';
import { AvProdInputType, AvProdInputTypeNumber } from '../../const/av-producer.const';
import { EventsService } from '../../services/events/events.service';
import { ModalManagerService } from '../../services/modal/modal-manager.service';
import { UserService } from '../../services/user/user.service';


export class ResourcesClass {
  protected assets: IEventAsset[] = [];
  protected assetsFiltered: IEventAsset[] = [];
  protected assetsReceived: boolean = false;
  protected tabSelected: number = 0;
  protected filterImage: boolean = true;
  protected filterImageOverlay: boolean = true;
  protected filterVideo: boolean = true;
  protected filterSound: boolean = true;
  protected filterDoc: boolean = true;
  protected allFilters: boolean = true;

  private _orgId: number | undefined;
  private _userId: number | undefined;

  protected userChangesSubscription: Subscription | undefined;
  protected userOrgIdSubscription: Subscription | undefined;

  constructor(protected events: EventsService,
              protected userService: UserService,
              protected modalService: ModalManagerService) { }

  protected init(): void {
    this.updateAssetList();
    this.userChangesSubscription = this.events.userChanges$.subscribe((data: IEventUserLastChanges) =>
      this.checkUserChanges(data)
    );
    this.userOrgIdSubscription?.unsubscribe();
    this.userOrgIdSubscription = this.userService.orgId$.subscribe(() => this.updateOrganizationSelected());
    this.events.addPollingClient('StoragePageClass');
  }

  protected destroy(): void {
    this.userOrgIdSubscription?.unsubscribe();
    this.userChangesSubscription?.unsubscribe();
    this.events.removePollingClient('StoragePageClass');
  }

  protected displayAlert(data: IAlertData): void {
    // Must override
  }

  protected displayToast(config: IToastConfig): void {
    // Must override
  }

  protected checkUserChanges(changes: IEventUserLastChanges): void {
    if (changes.storage === true) {
      this.updateAssetList();
    }
  }

  protected updateOrganizationSelected(){
    if ((this._orgId !== this.userService.orgId)||
        (this._userId !== this.userService.user.id)){
      this.updateAssetList();
    }
  }

  protected updateAssetList(): void {
    console.log('[StoragePageClass] updateAssetList');
    this._orgId = this.userService.orgId;
    this._userId = this.userService.user.id;
    this.events.getUserAssetList()
      .subscribe((answer: IEventAsset[] | null) => {
        if (answer !== null) {
          this._orgId = this.userService.orgId;
          this._userId = this.userService.user.id;
          this.assets = answer;
          this.updateAssetFilter();
          this.assetsReceived = true;
        }
      });
  }

  protected selectAllFilter(): void {
    this.allFilters = !this.allFilters;
    this.filterImage = this.allFilters;
    this.filterImageOverlay = this.allFilters;
    this.filterVideo = this.allFilters;
    this.filterSound = this.allFilters;
    this.filterDoc = this.allFilters;

    this.updateAssetFilter();
  }

  protected updateAssetFilter(): void {
    this.assetsFiltered = this.assets.filter( (element: IEventAsset) =>
        (this.filterImage && element.type === AvProdInputType.imageTile) ||
        (this.filterImageOverlay && element.type === AvProdInputType.imageOverlay) ||
        (this.filterSound && element.type === AvProdInputType.audioClip) ||
        (this.filterVideo && element.type === AvProdInputType.videoAudioClip) ||
        (this.filterDoc && element.type === AvProdInputType.document));
    this.allFilters = this.filterImage && this.filterImageOverlay && this.filterVideo && this.filterSound && this.filterDoc;
    console.log('[StoragePageClass] updateAssetFilter - Active Filters: ' +
        this.filterImage +
        this.filterImageOverlay +
        this.filterSound +
        this.filterVideo +
        this.filterDoc
    );
    console.log('[StoragePageClass] updateAssetFilter - Return Asset list: ' + JSON.stringify(this.assetsFiltered));
  }

  protected onUpload(): void {
    console.log('[ResourcesClass] onUpload');
    this.modalService.show({
        id: ModalDialogId.managerResourceUpload,
        title: 'inputAdd.resourceUpload',
      },true);
  }

  protected onAssetSettings(id: number): void {
    console.log('[ResourcesClass] onAssetSettings: ' + id);
    this.modalService.show({
        id: ModalDialogId.assetEdit,
        subId: id,
        title: 'manager.storage.assetSettings',
      },true );
  }

  protected onDeleteAsset(id: number): void {
    this.displayAlert({
      closeButton: true,
      show: true,
      status: AlertStatus.question,
      title: 'general.confirmation',
      text: 'storage.assetDeleteConfirmation',
      buttons: [
        {
          text: 'general.cancel',
          color: 'primary',
          fill: 'outline',
          closeButton: true,
        },
        {
          text: 'general.confirm',
          color: 'danger',
          closeButton: true,
          handler: (): void => {
            this.events
              .deleteUserAsset(id)
              .pipe()
              .subscribe((answer: boolean) => {
                if (answer) {
                  this.updateAssetList();
                } else {
                  this.displayToast({
                    options: {
                      placement: ToastPlacement.topCenter,
                    },
                    data: {
                      status: ToastStatus.error,
                      text: 'storage.errorDelete',
                      closeButtonBody: true,
                    },
                  });
                }
              });
          },
        },
      ],
    });
  }
}
