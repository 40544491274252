import { IUserOrganizationMember, OrganizationStatus } from '@azz-life-streamer/shared';

export class OrganizationMemberItemClass {

  protected _member: IUserOrganizationMember | undefined;
  protected _status: string | undefined;

  constructor () {}

  protected setMember(member: IUserOrganizationMember | undefined): void {
    this._member = member;
  }

  protected setStatusString(): void {
    if (this._member !== undefined && this._member.status !== undefined) {
      let status: string = this._member.status;
      switch (status) {
        case OrganizationStatus.active:
          this._status = 'organizations.memberStatus.active';
          break;
        case OrganizationStatus.declined:
          this._status = 'organizations.memberStatus.declined';
          break;
        case OrganizationStatus.invited:
          this._status = 'organizations.memberStatus.invited';
          break;
        default:
          this._status = '';
          break;
      }
    }
  }

}
