import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { CommonModule, DOCUMENT } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { AlertStatus, IAlertData, AlertService, IAlertDataButton } from '@azz-life-streamer/shared';
import { FormInputCodeComponent } from '../form-input-code/form-input-code.component';


@Component({
  selector: 'web-alert',
  standalone: true,
  imports: [CommonModule, TranslateModule, FormsModule, FormInputCodeComponent],
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertComponent implements OnInit {
  @ViewChild('mainAlertContainer', { read: ElementRef })
  mainContainer!: ElementRef<HTMLDivElement>;

  @Output() closeAlert: EventEmitter<boolean> = new EventEmitter<boolean>();

  protected componentInputs: {
    code?: number;
  } = {
    code: undefined,
  };

  protected _alertData: IAlertData | undefined = undefined;

  constructor(@Inject(DOCUMENT) private document: Document,
              private alertService: AlertService,
              private changeDetectorRef: ChangeDetectorRef) { }

  @Input() set alertData(alertData: IAlertData) {
    if (!alertData.icon || alertData.icon === '') {
      switch (alertData.status) {
        case AlertStatus.error:
          alertData.icon = 'azz-ico-hand-right';
          break;
        case AlertStatus.success:
          alertData.icon = 'bi bi-check-circle-fill';
          break;
        case AlertStatus.warning:
          alertData.icon = 'bi bi-exclamation-triangle';
          break;
        case AlertStatus.question:
          alertData.icon = 'bi bi-question-circle-fill';
          break;
      }
    }

    if (!alertData.title || alertData.title === '') {
      switch (alertData.status) {
        case AlertStatus.error:
          alertData.title = 'general.error';
          break;
        case AlertStatus.warning:
          alertData.title = 'general.warning';
          break;
      }
    }

    if (!alertData.iconColor || alertData.iconColor === '') {
      switch (alertData.status) {
        case AlertStatus.error:
          alertData.iconColor = 'danger';
          break;
        case AlertStatus.success:
          alertData.iconColor = 'light';
          break;
        case AlertStatus.warning:
          alertData.iconColor = 'warning';
          break;
        case AlertStatus.question:
          alertData.iconColor = 'warning';
          break;
      }
    }
    this._alertData = alertData;
  }

  get alertData(): IAlertData {
    return this._alertData ?? this.resetAlertData();
  }

  ngOnInit(): void {
    this.getAlert();
  }

  protected close(): void {
    this._alertData = this.resetAlertData();
  }

  protected clickButton(button: IAlertDataButton): void {
    if (button.handler) {
      if (button.onClickLoading) {
        button.disabled = true;
      }
      if (button.onDismiss) {
        if (button.onDismiss === 'code') {
          button.handler(this.componentInputs.code);
        }
      } else {
        button.handler();
      }
    }
    if (button.closeButton) {
      this.close();
    }
  }

  protected isDisabled(button: IAlertDataButton): boolean {
    if (button.onClickLoading) {
      if (
        this.alertData.component === 'code' &&
        this.componentInputs.code === undefined
      ) {
        return true;
      }
    }
    return false;
  }

  private getAlert(): void {
    this.alertService.alertEvents$.subscribe((data: IAlertData) => {
      this.alertData = data;
      if (data.show) {
        if (this.document.body.style.overflow !== 'hidden') {
          this.document.body.style.setProperty('overflow', 'hidden');
        }
        this.mainContainer.nativeElement.style.top = window.scrollY + 'px';
      }
      this.changeDetectorRef.detectChanges();
    });
  }

  private resetAlertData(): IAlertData {
    this.componentInputs = { code: undefined };
    return {
      show: false,
      status: AlertStatus.success,
      closeButton: true,
      text: undefined,
      title: undefined,
      buttons: undefined,
      component: undefined,
      componentInputs: undefined,
    };
  }
}
