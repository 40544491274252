import { inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import {
  AvProducerService,
  EVENTS,
  EventsService,
  IAlertData,
  IAvProdOutputSettings,
  IAvProdOutputSettingsDestination,
  IEventShareInfoSection,
  IToastConfig,
  ModalDialogId,
  ModalManagerService,
} from '@azz-life-streamer/shared';
import { ShareInfoService } from '../services/share-info/share-info.service';


export class OutputConfirmationClass {

  //protected destinations: IEventDestination[] = [];
  protected destinations: IAvProdOutputSettingsDestination[] = [];
  protected _viewerLink: string = '';

  private shareSrv: ShareInfoService = inject(ShareInfoService);

  constructor(protected avProd: AvProducerService,
              protected events: EventsService,
              protected modalService: ModalManagerService,
              protected translate: TranslateService) { }

  protected init(): void {
    this.updateDestinationList();
    this.updateLink('watch');
  }
  protected destroy(): void {
  }

  protected displayAlert(data: IAlertData): void {
    // must be overridden
  }

  protected displayToast(config: IToastConfig): void {
    // must be overridden
  }

  protected closeModal(): void {
    // must be overridden
  }

  protected onClickCancel(): void{
    this.closeModal();
  }

  protected updateLink(section: string): void{
    if (this.events.currentEvent !== undefined){
      const SHARE_DATA: IEventShareInfoSection = this.shareSrv.getSharingData(this.events?.currentEvent, section);
      this._viewerLink = SHARE_DATA.url;
    }
  }

  protected updateDestinationList(): void {
    // this.events.getUserDestinationList()
    // .pipe()
    // .subscribe(answer => {
    //   if (answer !== null){
    //     const DESTS: IEventDestination[] = [];
    //     if (this.avProd.outputSettings.destinations !== undefined){
    //       const OUT_DESTS: IAvProdOutputSettingsDestination[] = this.avProd.outputSettings.destinations;
    //       for (let i=0; i<OUT_DESTS.length; i++){
    //         if (OUT_DESTS[i].active){
    //           const DEST: IEventDestination | undefined = answer.find(element => element.id === OUT_DESTS[i].id);
    //           if (DEST !== undefined){
    //             DESTS.push(DEST);
    //           }
    //         }
    //       }
    //     }
    //     this.destinations = DESTS;
    //   }
    //   console.log('[OutputConfirmationClass] ' + JSON.stringify(this.destinations));
    // });

    // USe event destinations instead of user cloud destinations
    if (this.avProd.outputSettings.destinations !== undefined) {
      this.destinations = this.avProd.outputSettings.destinations
        .filter((element: IAvProdOutputSettingsDestination) => element.active);
    }
    else{
      this.destinations = [];
    }
  }

  protected onConfig(): void {
    this.modalService.show({id: ModalDialogId.producerSettings, title: 'producer.producerSettings', subId: 6}, true);
  }
  protected onConfirm(): void {
    if ((this.avProd.outputSettings.onAir === 0) &&
        (this.events.currentEvent?.status === EVENTS.status.running)) {

      // Do not ask again
      // this.displayAlert({
      //   show: true,
      //   status: AlertStatus.question,
      //   title: 'general.confirmation',
      //   text: 'producer.goLiveConfirmation',
      //   closeButton: true,
      //   buttons: [{
      //     text: 'general.cancel',
      //     color: 'primary',
      //     fill: 'outline',
      //     closeButton: true
      //   },
      //     {
      //       text: 'general.confirm',
      //       color: 'primary',
      //       closeButton: true,
      //       handler: (): void => {
      //         const SETTINGS: IAvProdOutputSettings = {};
      //         SETTINGS.onAir = 1;
      //         this.avProd.azzChangeOutputSettings(1, SETTINGS);
      //         this.modalService.closeCurrent();
      //       }
      //     }]
      // });
      const SETTINGS: IAvProdOutputSettings = {};
      SETTINGS.onAir = 1;
      this.avProd.azzChangeOutputSettings(1, SETTINGS);
      this.modalService.closeCurrent();
    }
  }

  protected onClickCopy(section: string): void {
    if (this.events.currentEvent !== undefined) {
      const SHARE_DATA: IEventShareInfoSection = this.shareSrv.getSharingData(this.events?.currentEvent, section);
      let url: string = SHARE_DATA.url;
      this.copyToClipboard(url);
    }
  }

  protected copyToClipboard(text: string): void {
    // must be overridden
  }

  protected onClickShare(section: string): void {
    if (this.events.currentEvent !== undefined) {
      const SHARE_DATA: IEventShareInfoSection = this.shareSrv.getSharingData(this.events.currentEvent, section);
      this.shareSrv.shareToken(SHARE_DATA);
    }
  }

}
