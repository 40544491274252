import { Subscription } from 'rxjs';
import { AlertStatus } from '../../enums/common.enum';
import { IAlertData, IToastConfig } from '../../interfaces/utils/utils.interface';
import { AlertService } from '../../services/alert/alert.service';
import { AudioService } from '../../services/audio/audio.service';
import { AvProducerService } from '../../services/av-producer/av-producer.service';
import { EventsService } from '../../services/events/events.service';


export class ToolbarHeaderPublishClass {

  protected audioSubscription: Subscription | undefined;
  protected audioIcon: string = 'bi-volume-mute-fill';

  constructor(protected avProd: AvProducerService,
              protected events: EventsService,
              protected audioService: AudioService){
      // Do nothing
  }


  init(): void {
    if (this.audioSubscription !== undefined){
      this.audioSubscription.unsubscribe();
    }
    this.audioSubscription = this.audioService.enabledChange$.subscribe(() => this.updateAudioInfo());
  }

  close(): void {
    if (this.audioSubscription !== undefined){
      this.audioSubscription.unsubscribe();
    }
  }

  protected displayToast(config: IToastConfig){
    // to be overriden
  }
  protected displayAlert(data: IAlertData){
    // to be overriden
  }
  protected exitPage(){
    // to be overriden
  }

  protected onClickExit(){
    this.displayAlert({
      show: true,
      status: AlertStatus.question,
      title: 'general.confirmation',
      text: 'publisher.exitPublisherConfirmation',
      closeButton: true,
      buttons: [{
        text: 'general.cancel',
        color: 'outline-primary',
        closeButton: true,
        handler() {},
      },
      {
        text: 'general.confirm',
        color: 'primary',
        closeButton: true,
        handler: (): void => {
          this.exitPage();
        }
      }]
    });
  }

  protected updateAudioInfo(){
    if (this.audioService.getEnabled() === true){
      this.audioIcon = 'bi-volume-up';
    }
    else {
      this.audioIcon = 'bi-volume-mute';
    }
  }

  protected onClickAudioToggle(){
    let enabled: boolean = !this.audioService.getEnabled();
    this.audioService.changeEnabled(enabled);
    this.updateAudioInfo();
  }
}
