import { inject } from '@angular/core';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { of, Subscription, switchMap } from 'rxjs';

import { ENV_COMMON } from '@azz-life-streamer/environments';
import { AlertStatus, ToastPlacement, ToastStatus } from '../../enums/common.enum';
import { IAlertDataButton, IToastConfig } from '../../interfaces/utils/utils.interface';
import { IEventUserLastChanges } from '../../interfaces/events/event.interface';
import { IDeleteUser, IUserSubscriptionConfig } from '../../interfaces/user/user.interface';
import { EventsService } from '../../services/events/events.service';
import { UserService } from '../../services/user/user.service';
import { AlertService } from '../../services/alert/alert.service';
import { OAuthService } from '../../services/oauth/oauth.service';


export class ManagerAccountClass {
  protected isBusy: boolean = false;
  protected isProduction: boolean = false;
  protected productKey: string | undefined;
  protected productCustomer: string = '';
  protected productLevel: string = '';
  protected productBilling: string = '';
  protected isProductPlanAvailable: boolean = false;
  protected subscriptionMessage: string | undefined;
  protected datePipe: DatePipe = new DatePipe('en-US');

  protected userChangesSubscription: Subscription | undefined;

  protected oAuthService: OAuthService = inject(OAuthService);

  private eventsService: EventsService = inject(EventsService);
  private alertService: AlertService = inject(AlertService);
  private validDeleteCode: boolean = false;
  private readonly deleteUser: IDeleteUser;

  constructor(protected userService: UserService,
              protected translate: TranslateService) {
    if (ENV_COMMON.production) {
      this.isProduction = true;
    }
    this.deleteUser = { code: undefined, email: this.userService.user.email };
  }

  protected init(){
    this.userChangesSubscription?.unsubscribe();
    this.userChangesSubscription = this.eventsService.userChanges$.subscribe(data => this.checkUserChanges(data));
    this.eventsService.addPollingClient('ManagerAccountClass');
    this.updateCurrentPlan();
    this.updateSubscriptionMessage();
  }

  protected close(){
    this.userChangesSubscription?.unsubscribe();
    this.eventsService.removePollingClient('ManagerAccountClass');
  }

  protected displayToast(config: IToastConfig): void {
    // Must override
  }

  protected navigateToLogin(): void {
    // Must override
  }

  protected checkUserChanges(changes: IEventUserLastChanges){
    if (changes.account === true){
      this.userService.getUserData().subscribe(() => {
        this.updateCurrentPlan();
        this.updateSubscriptionMessage();
      });
    }
  }

  protected logout(): void {
    this.showConfirm('logout');
  }

  protected deleteAccount(): void {
    this.showConfirm('delete');
  }

  protected gotoSelectSubscription(): void {
    // Must override
  }

  protected gotoStripeCustomerPortal(): void {
    this.isBusy = true;
    try {
      // Use orgId = 0 because here we want to display user's plan
      this.eventsService.getStripeCustomerPortal(0).subscribe((answer) => {
        this.isBusy = false;
        console.log('[PayCheckout] Return CheckSession: ' + JSON.stringify(answer));
        let err: boolean = true;
        if (answer !== null && answer !== undefined){
          if (answer.status_code === 200 && answer.data.url !== undefined) {
            err = false;
            window.open(answer.data.url, '_self');
          }
        }
        if (err) {
          this.showStripeError();
        }
      });
    } catch (error) {
      this.isBusy = false;
      this.showStripeError();
    }
  }

  /**
   * Display Stripe error warning to user
   *
   * @private
   */
  protected showStripeError(): void {
    this.alertService.showAlert({
      show: true,
      status: AlertStatus.error,
      text: 'users.stripeError',
      closeButton: true,
      buttons: [
        {
          text: 'general.close',
          color: 'primary',
          closeButton: true,
        },
      ],
    });
  }

  /**
   * After calling the api to send a deletion code to the user,
   * calls the function that displays the prompt to enter that code
   *
   * @private
   */
  protected sendDeletionCode(): void {
    this.userService
      .sendDeletionAccountCode(this.deleteUser)
      .subscribe((resp: boolean) => {
        if (resp) {
          this.showDeleteAccountPrompt();
        } else {
          this.alertService.showAlert({
            show: true,
            status: AlertStatus.error,
            text: this.translate.instant('general.error'),
            closeButton: true,
            buttons: [
              {
                text: 'general.close',
                color: 'primary',
                closeButton: true,
              },
            ],
          });
        }
      });
  }

  /**
   * Show a confirmation alert for both deletion and logout
   *
   * @param mode
   * @private
   */
  private showConfirm(mode: string): void {
    const BUTTONS: IAlertDataButton[] = [];
    BUTTONS.push({
      text: 'general.cancel',
      color: 'primary',
      closeButton: true,
    });
    BUTTONS.push({
      text: mode === 'delete' ? 'general.continue' : 'general.confirm',
      color: 'danger',
      fill: 'outline',
      onClickLoading: mode === 'delete',
      handler: () => {
        if (mode === 'delete') {
          this.sendDeletionCode();
        } else if (mode === 'logout') {
          this.alertService.close();
          this.oAuthService.logout();
          this.navigateToLogin();
        }
      },
    });

    this.alertService.showAlert({
      show: true,
      status: mode === 'delete' ? AlertStatus.error : AlertStatus.question,
      title: this.translate.instant(
        mode === 'delete' ? 'users.deleteAccount' : 'general.confirm'
      ),
      text: this.translate.instant(
        mode === 'delete' ? 'users.confirmDeleteAccount' : 'users.confirmLogout'
      ),
      buttons: BUTTONS,
      closeButton: true,
    });
  }

  /**
   * displays the alert component with a prompt to enter previously sent code to user
   *
   * @private
   */
  private showDeleteAccountPrompt(text?: string): void {
    const BUTTONS: IAlertDataButton[] = [];
    BUTTONS.push({
      text: 'general.cancel',
      color: 'primary',
      closeButton: true,
    });
    BUTTONS.push({
      text: 'general.send',
      color: 'danger',
      fill: 'outline',
      onClickLoading: true,
      onDismiss: 'code',
      handler: (code: number) => {
        this.deleteUser.code = code;
        this.userService
          .checkDeletionAccountCode(this.deleteUser)
          .pipe(
            switchMap((result: boolean) => {
              if (result) {
                this.validDeleteCode = true;
                return this.userService.deleteAccount();
              } else {
                return of(false);
              }
            })
          )
          .subscribe({
            next: this.handleDeleteAccountConfirmResponse.bind(this),
            error: this.handleDeleteAccountConfirmErrorResponse.bind(this),
          });
      },
    });

    this.alertService.showAlert({
      show: true,
      status: AlertStatus.error,
      title: this.translate.instant('users.deleteAccount'),
      text: text
        ? text
        : this.translate.instant('users.confirmDeleteAccountCode'),
      component: 'code',
      componentInputs: { model: this.deleteUser },
      buttons: BUTTONS,
      closeButton: true,
    });
  }


  private handleDeleteAccountConfirmResponse(result: boolean): void {
    if (result) {
      this.alertService.close();
      this.navigateToLogin();
    } else {
      if (this.validDeleteCode) {
        this.handleDeleteAccountConfirmErrorResponse();
      } else {
        this.showDeleteAccountPrompt('users.deleteAccountErrorCode');
      }
    }
  }

  private handleDeleteAccountConfirmErrorResponse(): void {
    this.alertService.close();
    this.displayToast({
      options: {
        autohide: true,
        placement: ToastPlacement.bottomCenter,
      },
      data: {
        status: ToastStatus.error,
        text: !this.validDeleteCode
          ? 'users.errorCodeInfo'
          : 'users.deleteAccountError',
        alignText: 'text-center',
      },
    });
  }

  private updateCurrentPlan(): void {
    this.productKey = this.userService.user.productKey;

    if (this.productKey !== undefined) {
      this.isProductPlanAvailable = true;
      if (this.productKey[0] === 'i') {
        this.productCustomer = '';
      } else if (this.productKey[0] === 'o') {
        this.productCustomer = 'manager.customerTypeNameOrganization';
      }

      if (this.productKey[2] === 'm') {
        this.productBilling = 'manager.billingModeNameMonthly';
      } else if (this.productKey[2] === 'y') {
        this.productBilling = 'manager.billingModeNameYearly';
      }

      if (this.productKey[1] === 'b') {
        this.productLevel = 'manager.subscriptionLevelNameBronze';
      } else if (this.productKey[1] === 's') {
        this.productLevel = 'manager.subscriptionLevelNameSilver';
      } else if (this.productKey[1] === 'g') {
        this.productLevel = 'manager.subscriptionLevelNameGold';
      } else {
        this.isProductPlanAvailable = false;
        this.productLevel = 'manager.subscriptionLevelNameFree';
        this.productBilling = '';
      }
    } else {
      this.productLevel = 'manager.subscriptionLevelNameFree';
      this.productBilling = '';
      this.productCustomer = 'manager.customerTypeNameIndividual';
      this.isProductPlanAvailable = false;
    }
  }

  private updateSubscriptionMessage(): void {
    if (this.userService.user?.subscription !== undefined){
      const SUB : IUserSubscriptionConfig = this.userService.user?.subscription;
      console.log(SUB);

      if(SUB.trialEnd !== undefined && SUB.trialEnd !== null){
        this.subscriptionMessage = this.translate.instant('manager.account.subscriptionMessage.freeTrialEnds') + this.datePipe.transform(SUB.trialEnd * 1000, 'dd/MM/yyyy');
      }
      else if (SUB.cancelAt !== undefined && SUB.cancelAt !== null){
        this.subscriptionMessage = this.translate.instant('manager.account.subscriptionMessage.cancelAt') + this.datePipe.transform(SUB.cancelAt * 1000, 'dd/MM/yyyy');
      }
      else if (SUB.currentPeriodEnd !== undefined && SUB.currentPeriodEnd !== null){
        this.subscriptionMessage = this.translate.instant('manager.account.subscriptionMessage.nextPayment') + this.datePipe.transform(SUB.currentPeriodEnd * 1000, 'dd/MM/yyyy');
      }
    }
  }
}
