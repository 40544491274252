export interface IVideoConstraints {
  deviceId?: any;
  groupId?: string;
  aspectRatio?: number;
  frameRate?: number;
  height?: { exact: number };
  width?: { exact: number };
  resizeMode?: string; // none | crop-and-scale
}

export interface IAudioConstraints {
  deviceId?: any;
  groupId?: string;
  autoGainControl?: boolean;
  channelCount?: number;
  echoCancellation?: boolean;
  latency?: number;
  noiseSuppression?: boolean;
  sampleRate?: number;
  sampleSize?: number;
  volume?: number;
}

export interface IMediaConstraints {
  audio: IAudioConstraints | false;
  video: IVideoConstraints | false;
}
