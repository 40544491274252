<nav [class]="'navbar navbar-expand-lg fixed-top bg-gradient ' + topBar.mode"
     id="homeContentNavBar"
     [ngClass]="{
        'scrolling': scrolling && (topBar.scrollValue > 0),
        'scrolled': scrolled && (topBar.scrollValue > 0),
        'collapse-show': !collapsed,
        'border-bottom-shadow': topBar.borderBottom
      }">
  <div [ngClass]="topBar.fluid? 'container-fluid' : 'container' ">
    <a class="navbar-brand"
      [routerLink]="[urlService.curLangUrl]"
      id="topbar-home-link"
      webTooltip data-bs-placement="bottom" data-bs-trigger="hover"
      [title]="'general.backToHome' | translate">
      <img [src]="APP.logoPath + ( topBar.mode === 'primary' ? 'azzulei-landscape-white.svg'
     : 'azzulei-landscape-' + themeService.selectedTheme + '.svg')"
           class="d-none d-xl-block"
           alt="Azzulei"
           title="">
      <img [src]="APP.logoPath + ( topBar.mode === 'primary' ? 'azzulei-only-logo-white.svg'
     : 'azzulei-only-logo-' + themeService.selectedTheme + '.svg')"
           class="d-block d-xl-none"
           alt="Azzulei"
           title="">
    </a>

    <div class="d-flex">

      <div class="d-flex d-lg-none align-items-center gap-3">
        <ng-container [ngTemplateOutlet]="menuTools"></ng-container>
      </div>

      <!--data-bs-toggle="collapse"
      data-bs-target="#navbarCollapse" -->
      <button class="navbar-toggler"
              (click)="toggleMenu()"
              type="button"
              aria-controls="navbarCollapse"
              aria-expanded="false"
              aria-label="Toggle navigation">
        <i class="bi bi-list"></i>
      </button>

    </div>

    <div class="navbar-collapse" [ngClass]="this.collapsed? 'collapse' : 'show'" id="navbarCollapse" (click)="toggleMenu()">
        <ul class="navbar-nav">
          <li class="nav-item">
            <a class="nav-link"
               [class.active]="(activeFragment | async) === APP.fragments.home"
               [routerLink]="[urlService.curLangUrl]">{{ "home.menu.home" | translate }}</a>
          </li>
          <li class="nav-item">
            <a *ngIf="this.topBar.mode === 'primary'" class="nav-link"
               [class.active]="(activeFragment | async) === APP.fragments.useCases"
               [routerLink]="[urlService.curLangUrl]"
               [fragment]="APP.fragments.useCases">{{ "home.menu.useCases" | translate }}</a>

            <a *ngIf="this.topBar.mode !== 'primary'" class="nav-link"
                routerLinkActive="active"
               [routerLink]="[urlService.curLangUrl, COMMON.urls.useCases]">{{ "home.menu.useCases" | translate }}</a>
          </li>
          <li class="nav-item">
            <a *ngIf="this.topBar.mode === 'primary'" class="nav-link"
               [class.active]="(activeFragment | async) === APP.fragments.howItWorks"
               [routerLink]="[urlService.curLangUrl]"
               [fragment]="APP.fragments.howItWorks">{{ "home.menu.howItWorks" | translate }}</a>

            <a *ngIf="this.topBar.mode !== 'primary'" class="nav-link"
                routerLinkActive="active"
               [routerLink]="[urlService.curLangUrl, COMMON.urls.howItWorks]">{{ "home.menu.howItWorks" | translate }}</a>
          </li>
          <li class="nav-item">
            <a *ngIf="this.topBar.mode === 'primary'" class="nav-link"
               [class.active]="(activeFragment | async) === APP.fragments.features"
               [routerLink]="[urlService.curLangUrl]"
               [fragment]="APP.fragments.features">{{ "home.menu.features" | translate }}</a>

            <a *ngIf="this.topBar.mode !== 'primary'" class="nav-link"
                routerLinkActive="active"
               [routerLink]="[urlService.curLangUrl, COMMON.urls.features]">{{ "home.menu.features" | translate }}</a>
          </li>
          <li class="nav-item">
            <a *ngIf="this.topBar.mode === 'primary'" class="nav-link"
               [class.active]="(activeFragment | async) === APP.fragments.prices"
               [routerLink]="[urlService.curLangUrl]"
               [fragment]="APP.fragments.prices">{{ "home.menu.prices" | translate }}</a>

            <a *ngIf="this.topBar.mode !== 'primary'" class="nav-link"
               routerLinkActive="active"
              [routerLink]="[urlService.curLangUrl, COMMON.urls.prices]">{{ "home.menu.prices" | translate }}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link"
               routerLinkActive="active"
               [routerLink]="[urlService.curLangUrl, COMMON.urls.blog]">{{ "home.menu.blog" | translate }}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link"
               routerLinkActive="active"
               [routerLink]="[urlService.curLangUrl, COMMON.urls.contact]">{{ "home.menu.contact" | translate }}</a>
          </li>
        </ul>
      </div>

    <div class="d-none d-lg-flex align-items-center gap-3">
      <ng-container [ngTemplateOutlet]="menuTools"></ng-container>
    </div>

  </div>
</nav>

<ng-template #menuTools>

  <ng-container *ngIf="!userService.anonymousUser; else userTools">
    <a class="manager" [routerLink]="COMMON.urls.manager">
      <span class="d-none d-md-inline-block">{{ "manager.link" | translate }}</span>
      <i class="azz-ico-home"></i>
    </a>

    <a class="user-logged"
       data-bs-toggle="offcanvas"
       [href]="APP.fragments.userPanel"
       role="button"
       aria-controls="userPanel"
       aria-label="User Panel">
      <i class="bi bi-person-circle"></i>
    </a>
  </ng-container>

  <ng-template #userTools>
    <a class="login"
       (click)="modalUserRequested.emit('login')">
      <span class="d-none d-md-inline-block">{{ "users.login" | translate }}</span>
      <i class="bi bi-box-arrow-in-right"></i>
    </a>

    <button class="btn btn-primary btn-signup"
            (click)="modalUserRequested.emit('signUp')">
      {{"users.signUp" | translate}}
    </button>

    <web-change-language></web-change-language>
  </ng-template>

</ng-template>
