import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { ModalDialogId } from '../../enums/utils.enum';
import { IModalDialogData } from '../../interfaces/utils/utils.interface';


@Injectable({
  providedIn: 'root'
})
export class ModalManagerService {

  modalManagerEvent$: Observable<IModalDialogData>;
  private _modalEvent: Subject<IModalDialogData> = new Subject<IModalDialogData>();
  private _modalEvents: IModalDialogData[] = [];

  constructor() {
    this.modalManagerEvent$ = this._modalEvent.asObservable();
  }

  protected emitNewData(): void {
    if (this._modalEvents.length > 0) {
      this._modalEvent.next(this._modalEvents[this._modalEvents.length - 1]);
    } else {
      this._modalEvent.next({
        id: ModalDialogId.none,
        subId: undefined,
        title: ''
      })
    }
  }

  public show(data: IModalDialogData, keepHistory: boolean): void {
    if (!keepHistory) {
      this._modalEvents = [];
    }
    this._modalEvents.push(data);
    this.emitNewData();
  }

  public closeCurrent(): void {
    this._modalEvents.pop();
    this.emitNewData();
  }

  public closeAll(): void {
    this._modalEvents = [];
    this.emitNewData();
  }

  public getModalCount(): number {
    return this._modalEvents.length;
  }
}
