import { Component } from '@angular/core';

import { UserService } from '@azz-life-streamer/shared';
import { LayoutService } from '../core/services/layout/layout.service';
import { UrlService } from '../core/services/url/url.service';


@Component({
  selector: 'web-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent {

  constructor(protected userService: UserService,
              protected urlService: UrlService,
              protected layoutService: LayoutService) {
    this.layoutService.showTopBar = true;
    this.layoutService.showFooter = true;
  }

  protected modalRequested(page: string): void {
    this.layoutService.showUserModal(page);
  }

}
