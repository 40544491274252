import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { ApiUtilsService, DeviceService, ErrorSeverity, IError, LanguageService, UserService } from '@azz-life-streamer/shared';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {
  error$: Observable<IError>;
  private _error: Subject<IError> = new Subject<IError>();

  constructor(private apiUtilsService: ApiUtilsService,
              private userService: UserService,
              private languageService: LanguageService,
              private deviceService: DeviceService) {
    this.error$ = this._error.asObservable();
  }

  handle(error: IError): void {
    const COMMENTS: string[] = [];
    COMMENTS.push('[Error Occurs]');
    if (error.severity) {
      COMMENTS.push('Severity: ' + ErrorSeverity[error.severity]);
    }

    if (error.code) {
      COMMENTS.push((error.code) ? 'Code: ' + error.code : '');
    }

    if (error.location) {
      COMMENTS.push((error.location) ? 'Location: ' + error.location : '');
    }

    if (error.text) {
      COMMENTS.push((error.text) ? 'Message: ' + error.text : '');
    }

    if (this.userService.user) {
      COMMENTS.push('User: ' + JSON.stringify(this.userService.user));
    } else {
      COMMENTS.push('Anonymous user');
    }

    if (this.deviceService.device) {
      COMMENTS.push('Device: ' + JSON.stringify(this.deviceService.device));
    } else {
      COMMENTS.push('Undetected device');
    }

    if (this.languageService.deviceLang) {
      COMMENTS.push('Language: ' + JSON.stringify(this.languageService.deviceLang));
    } else {
      COMMENTS.push('Undetected language');
    }
    console.warn('SENDING EMAIL!!! ' + COMMENTS.join('\n') );
    /*this.apiUtilsService.sendSupportEmail({
      name: 'Error handler',
      email: 'team@azzulei.com',
      subject: 'Error detected',
      comments: COMMENTS.join('<br>'),
      version: this.deviceService?.device?.appVersion ?? '',
    }).pipe(first()).subscribe();


    this._error.next(error);*/
  }

}
