import { Subscription, interval } from 'rxjs';

import { AlertStatus, ToastPlacement, ToastStatus } from '../../enums/common.enum';
import { ModalDialogId } from '../../enums/utils.enum';
import { IEvent } from '../../interfaces/events/event.interface';
import { IAlertData, IToastConfig } from '../../interfaces/utils/utils.interface';
import { EVENTS } from '../../const/events.const';
import { AvProducerService } from '../../services/av-producer/av-producer.service';
import { EventsService } from '../../services/events/events.service';
import { ModalManagerService } from '../../services/modal/modal-manager.service';
import { IAvProdComposerSettings } from '../../interfaces/av-producer/composer-settings.interface';

export class ToolbarHeaderClass {

  protected _event: IEvent | undefined;
  protected onlineTimeInfo: string = '';
  protected setupTimeLeft: string = '';
  protected setupTimeLeftWarning: boolean = false;
  protected noActivityWarning: boolean = false;
  protected destinationIcons: string[] = [];
  protected subscriptionOutputSettings: Subscription | undefined;
  protected subscriptionTimer: Subscription | undefined;
  protected _displayElements: boolean[] = []; //title, status, timer, destinations, button

  protected setEvent(event: IEvent | undefined): void {
    this._event = event;
  }

  protected emitExitPage(param: boolean): void {
    // to be overridden
  }
  protected emitSetSingleCamLayout(param: boolean): void {
    // to be overridden
  }

  constructor(protected avProd: AvProducerService,
              protected events: EventsService,
              protected modalService: ModalManagerService) {
  }

  protected init(): void {
    this.updateDestinations();
    this.subscriptionTimer = interval(1000).subscribe(() => this.onUpdateTimer());
    this.subscriptionOutputSettings = this.avProd.onNewOutputSettings$.subscribe(() => this.updateDestinations());
    //this.setDisplayElements([true, true, true, true, true]);
  }
  protected close(): void{
    this.resetAlert('producer.finishLiveNoActivityConfirmation');
    if (this.subscriptionTimer !== undefined){
      this.subscriptionTimer.unsubscribe();
    }
    if (this.subscriptionOutputSettings !== undefined){
      this.subscriptionOutputSettings.unsubscribe();
    }
  }

  protected onUpdateTimer(): void {
    const NOW: number = Math.round((new Date()).getTime() / 1000);
    // Retrieve event info from avProducer WS instead of API
    if (this.avProd.commsStatus.ok) {
      if ((this.avProd.serverStatus.event?.streamStarted === 0) || (this.avProd.serverStatus.event?.streamStarted === null)) {
        this.onlineTimeInfo = '00:00:00';
        if (this.noActivityWarning) {
          this.noActivityWarning = false;
          this.resetAlert('producer.finishLiveNoActivityConfirmation');
        }

        if ((this.avProd.serverSettings !== undefined)&&(this.avProd.serverSettings?.event.maxSetup > 0)){
          const SETUP_TIME_LEFT: Date = new Date((this.avProd.serverSettings?.event.maxSetup - (NOW - this.avProd.serverSettings?.event.start))*1000);
          if (SETUP_TIME_LEFT.getTime() > 0){
            this.setupTimeLeft = SETUP_TIME_LEFT.getUTCHours().toString().padStart(2, '0') + ':' +
                    SETUP_TIME_LEFT.getUTCMinutes().toString().padStart(2, '0') + ':' +
                    SETUP_TIME_LEFT.getUTCSeconds().toString().padStart(2, '0');
          }
          else{
            this.setupTimeLeftWarning = true;
            this.setupTimeLeft = '00:00:00';
          }
        }
        else{
          this.setupTimeLeft = '--:--:--';
        }
      }
      else {
        this.setupTimeLeft = '';
        const TIME_ON: Date = new Date((NOW - this.avProd.serverStatus.event?.streamStarted) * 1000);
        this.onlineTimeInfo = TIME_ON.getUTCHours().toString().padStart(2, '0') + ':' +
                        TIME_ON.getUTCMinutes().toString().padStart(2, '0') + ':' +
                        TIME_ON.getUTCSeconds().toString().padStart(2, '0');

        // Check no activity warning
        if (this.avProd.serverStatus.lastActivityWarning) {
          if (!this.noActivityWarning) {
            this.noActivityWarning = true;
            this.displayAlert({
              show: true,
              status: AlertStatus.question,
              title: 'general.confirmation',
              text: 'producer.finishLiveNoActivityConfirmation',
              closeButton: true,
              buttons: [{
                text: 'producer.resumeProduction',
                color: 'primary',
                fill: 'outline',
                closeButton: true,
                handler: (): void => {
                  const SETTINGS: IAvProdComposerSettings = {
                    videoTransitionDirection: this.avProd.composerSettings.videoTransitionDirection
                  }
                  // Send any composer setting to reset activity in avProducer
                  this.avProd.azzChangeComposerSettings(SETTINGS);
                }
              },
              {
                text: 'producer.finish',
                color: 'primary',
                closeButton: true,
                handler: (): void => {
                  this.onFinishLive();
                }
              }]
            });
          }
        }
        else{
          if (this.noActivityWarning) {
            this.noActivityWarning = false;
            this.resetAlert('producer.finishLiveNoActivityConfirmation');
          }
        }
      }
    }
  }

  protected updateDestinations(): void {
    const TMP_DESTINATION_ICONS: string[] = [];
    if (this.avProd.outputSettings.destinations !== undefined) {
      for (let i: number = 0; i < this.avProd.outputSettings.destinations.length; i++) {
        if (this.avProd.outputSettings.destinations[i].active) {
          // Todo: Update list of destination icons
        }
      }
      this.destinationIcons = TMP_DESTINATION_ICONS;
    }
  }

  protected displayToast(config: IToastConfig): void {
    // to be overridden
  }
  protected displayAlert(data: IAlertData): void {
    // to be overridden
  }
  protected resetAlert(msg: string): void {
    // to be overridden
  }

  protected onConfigDestinations(): void {
    this.modalService.show({id: ModalDialogId.producerSettings, title: 'producer.producerSettings', subId: 6}, false);
  }

  protected onSetLayoutSingleCam(): void {
    this.emitSetSingleCamLayout(true);
  }

  protected onExit(): void {
    this.resetAlert('producer.finishLiveNoActivityConfirmation');
    this.emitExitPage(true);
  }

  protected onGoLive(): void {
    //TO BE OVERRIDE
  }

  protected onFinishLive(): void {
    if ((this.avProd.outputSettings.onAir === 1) &&
      (this._event !== undefined) &&
      (this._event.status === EVENTS.status.running) &&
      (this._event.producerToken !== undefined)) {

      this.displayAlert({
        show: true,
        status: AlertStatus.question,
        title: 'general.confirmation',
        text: 'producer.finishLiveConfirmation',
        closeButton: true,
        buttons: [{
            text: 'general.cancel',
            color: 'primary',
            fill: 'outline',
            closeButton: true
          },
          {
            text: 'general.confirm',
            color: 'primary',
            closeButton: true,
            handler: (): void => {
              this.finishEvent();
            }
          }]
      });
    }
  }

  protected finishEvent(){
    if (this._event?.id !== undefined) {
      this.events.finishEvent(this._event.id, false, this._event.producerToken)
        .subscribe((eventFinished: IEvent) => {
          if (eventFinished) {
            // To do: Display success message
            this._event = eventFinished;
            this.displayToast({
              options: {
                autohide: true,
                placement: ToastPlacement.bottomRight
              },
              data: {
                closeButtonHeader: true,
                status: ToastStatus.success,
                title: 'general.done',
                text: 'eventStatus.finishEventSuccess',
                alignText: 'text-center',
                buttons: []
              },
            });
            // Exit producer page
            this.emitExitPage(true);
          } else {
            this.displayToast({
              options: {
                autohide: true,
                placement: ToastPlacement.bottomRight
              },
              data: {
                closeButtonHeader: true,
                status: ToastStatus.error,
                title: 'general.error',
                text: 'eventStatus.finishEventError',
                alignText: 'text-center',
                buttons: []
              },
            });
          }
        });
    }
  }

  protected onConfig(): void {
    this.modalService.show({id: ModalDialogId.producerSettings, title: 'producer.producerSettings'}, false);
  }

  protected setDisplayElements(display: boolean[]){
    for (let i: number = 0; i < display.length; i++){
      this._displayElements[i] = display[i];
    }
  }
}
