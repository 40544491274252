export interface IAvProdVideoTile {
    id: number;
    name: string;
    x: number;
    y: number;
    width: number;
    height: number;
    borderWidth: number;
    borderColor: string;
}

export interface IAvProdVideoLayout {
    id: number;
    name: string;
    tiles: IAvProdVideoTile[];
}

export interface IAvProdLayoutMngrSettings {
    videoLayouts: IAvProdVideoLayout[];
    favorites: number[];
}