// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { ENV_COMMON } from '../common/environment';

export const ENV_MOBILE = {
  production: false,
  appId: 'com.azzulei.tv',
  appName: 'AzzuleiTVDev',
  webDir: '../../dist/apps/mobile/development',
  uxCamKey: 'zhe6jiojyha4hhp',

  oAuthGoogle: {
    authorizationBaseUrl: ENV_COMMON.oAuthGoogle.authorizationBaseUrl,
    accessTokenEndpoint: ENV_COMMON.oAuthGoogle.accessTokenEndpoint,
    scope: ENV_COMMON.oAuthGoogle.scope,
    resourceUrl: ENV_COMMON.oAuthGoogle.resourceUrl,
    web: {
      appId: '492270698470-dnuhqj9d1lbs1tisirdlniuh81f99i82.apps.googleusercontent.com',
      responseType: ENV_COMMON.oAuthGoogle.web.responseType,
      accessTokenEndpoint: ENV_COMMON.oAuthGoogle.web.accessTokenEndpoint,
      redirectUrl: 'http://localhost:8100',
      windowOptions: ENV_COMMON.oAuthGoogle.web.windowOptions
    },
    android: {
      appId: '492270698470-accom7ma27e2d6fucnse0cst9oo1ln63.apps.googleusercontent.com',
      //appId: '492270698470-fj1ofb9qmannojrqcgivthrpo41doucj.apps.googleusercontent.com',
      responseType: 'code', // if you configured an android app in google dev console the value must be 'code'
      redirectUrl: 'com.azzulei.tv:/' // package name from google dev console
    },
    ios: {
      appId: '492270698470-oars8ig19310te095muq947pvrm9ti71.apps.googleusercontent.com',
      responseType: 'code', // if you configured a ios app in google dev console the value must be 'code'
      redirectUrl: 'com.azzulei.tv:/' // Bundle ID from google dev console
    }
  },
  oAuthMicrosoft: {
    appId: '9022e3b3-cd2a-4419-9172-25204976f536',
    authorizationBaseUrl: ENV_COMMON.oAuthMicrosoft.authority,
    scope: ENV_COMMON.oAuthMicrosoft.scopes,
    accessTokenEndpoint: ENV_COMMON.oAuthMicrosoft.accessTokenEndPoint,
    resourceUrl: ENV_COMMON.oAuthMicrosoft.graphUrl,
    responseType: 'code',
    pkceEnabled: true,
    logsEnabled: true,
    web: {
      redirectUrl: `http://localhost:8100`,
      windowOptions: ENV_COMMON.oAuthMicrosoft.windowOptions,
    },
    android: {
      redirectUrl: ENV_COMMON.oAuthMicrosoft.redirectUrl+ENV_COMMON.oAuthMicrosoft.hash,
    },
    ios: {
      pkceEnabled: true, // workaround for bug #111
      redirectUrl: `msauth.${ENV_COMMON.bundleId}://auth`,
    },
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
