
export enum AzzCustomerType {
  individual = 1,
  organization = 2
}

export enum AzzBillingMode {
  monthly = 1,
  yearly = 2
}

export enum AzzSubscriptionLevel {
  bronze = 1,
  silver = 2,
  gold = 3
}
