import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, DOCUMENT } from '@angular/common';
import { Subscription } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';

import {
  AvProdSettingsType,
  AV_PROD_OPTIONS_COLOR,
  IAvSettingSelectOption,
  IAvSettingsItemConfig,
  SharedModule
} from '@azz-life-streamer/shared';
import { TooltipDirective } from '../../core/directives/tooltip/tooltip.directive';


@Component({
  selector: 'web-settings-item',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, SharedModule, TranslateModule, TooltipDirective],
  templateUrl: './settings-item.component.html',
  styleUrls: ['./settings-item.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class SettingsItemComponent implements OnInit, OnDestroy {

  //protected value: number | string | undefined = undefined;
  protected formControl: AbstractControl = new FormControl();
  protected colorText: string = '';
  protected colorCustom: string = '#000000';
  protected _isDisabled: boolean | undefined;

  protected colorValueSubscription: Subscription = new Subscription();

  protected valueOption: IAvSettingSelectOption = {
    label: '',
    labelTranslate: '',
    valueNumber: 0,
    valueStr: ''
  };

  protected configInt: IAvSettingsItemConfig = {
    id: '',
    type: AvProdSettingsType.none,
    title: '',
    name: '',
    min: 0,
    max: 0,
    step: 1,
    options: [],
    placeholder: '',
    value: ''
  }

  @Input() set isDisabled(value: boolean){
    this._isDisabled = value;
    this.checkInputDisabled();
  }

  @Input() formGroup: FormGroup = new FormGroup([]);

  @Input() set config(configIn: IAvSettingsItemConfig) {
    this.configInt = configIn;
    if ((this.configInt.options.length === 0) &&
      (this.configInt.type === AvProdSettingsType.selectComboNumber) &&
      (this.configInt.step !== 0)) {
      for (let i: number = 0; this.configInt.min + (this.configInt.step * i) <= this.configInt.max; i++) {
        const VALUE: number = this.configInt.min + (this.configInt.step * i);
        if (VALUE <= this.configInt.max){
          const OPTION: IAvSettingSelectOption = {
            label: VALUE.toString(),
            labelTranslate: VALUE.toString(),
            valueNumber: VALUE,
            valueStr: VALUE.toString()
          }
          this.configInt.options.push(OPTION);
        }
      }
    }

    if (this.colorValueSubscription !== undefined) this.colorValueSubscription.unsubscribe();
    if (this.configInt.type === AvProdSettingsType.color) {
      this.configInt.options = AV_PROD_OPTIONS_COLOR;
      this.colorValueSubscription = this.formControl.valueChanges.subscribe(event => this.onColorSetValue(event));
    }
  }

  @Output() settingChange = new EventEmitter<IAvSettingsItemConfig>();
  @Output() settingTouched = new EventEmitter<IAvSettingsItemConfig>();
  @Output() settingFocus = new EventEmitter<IAvSettingsItemConfig>();

  constructor(@Inject(DOCUMENT) private dom: Document) {
    // do nothing
  }

  ngOnInit(): void {
    const MY_FORM_CONTROL: AbstractControl | null = this.formGroup.controls[this.configInt.id];
    if (MY_FORM_CONTROL !== null) {
      this.formControl = MY_FORM_CONTROL;
      if (this.colorValueSubscription !== undefined) this.colorValueSubscription.unsubscribe();
      if (this.configInt.type === AvProdSettingsType.color) {
        this.onColorSetValue(this.formControl.value);
        if (this.colorValueSubscription !== undefined) this.colorValueSubscription.unsubscribe();
        this.colorValueSubscription = this.formControl.valueChanges.subscribe(event => this.onColorSetValue(event));
      }
    }
    this.checkInputDisabled();
  }

  ngOnDestroy(): void {
    if (this.colorValueSubscription !== undefined) this.colorValueSubscription.unsubscribe();
  }

  /**
   * Function called when the user changes the value
   */
  protected onControlChange() {
    //console.log('[SettingsItemComponent] onControlChange');
    this.settingChange.emit(this.configInt);
  }

  /**
   * Function called when the user touches the control
   */
  protected onControlTouched() {
    //console.log('[SettingsItemComponent] onControlTouched');
    this.settingTouched.emit(this.configInt);
  }

  /**
   * Function called when the control gets focus
   */
  protected onControlFocus() {
    this.settingFocus.emit(this.configInt);
  }

  protected onColorSetValue(event: any) {
    console.log('[SettingsItemComponent] onColorSetValue ' + JSON.stringify(event));
    if ((typeof (event) === 'string') && event.includes('#')) {
      this.colorText = 'custom';
      this.colorCustom = event;
    } else {
      this.colorText = event;
      this.colorCustom = this.colorText2Code(event);
    }
  }

  protected colorText2Code(color: string): string {
    let ret: string = '#ffffff';
    switch (color){
      case 'white':
        ret = '#ffffff';
        break;
      case 'black':
        ret = '#000000';
        break;
      case 'red':
        ret = '#ff0000';
        break;
      case 'blue':
        ret = '#1d71b8';
        break;
      case 'darkblue':
        ret = '#172039';
        break;
      case 'lightblue':
        ret = '#009fe3';
        break;
      case 'yellow':
        ret = '#ffdc00';
        break;
      case 'green':
        ret = '#00dc1e';
        break;
      case 'orange':
        ret = '#f3920b';
        break;
      case 'gray':
        ret = '#c0c0c0';
        break;
      case 'darkgray':
        ret = '#646464';
        break;
    }
    return ret;
  }

  protected onColorChange() {
    console.log('[SettingsItemComponent] onColorChange');
    if (this.colorText === 'custom') {
      this.colorText = 'custom';
      this.formControl.setValue(this.colorCustom);
    } else {
      this.formControl.setValue(this.colorText);
    }
    this.formControl.markAsDirty();
    this.onControlChange();
  }

  protected onColorChangeCustom() {
    console.log('[SettingsItemComponent] onColorChangeCustom');
    this.colorText = 'custom';
    this.formControl.setValue(this.colorCustom);
    this.formControl.markAsDirty();
    this.onControlChange();
  }

  protected onSliderIncrease() {
    if ((this.configInt.type === 'numberSlider') &&
      (this.configInt.step !== 0)) {
      if (this.formControl.value + this.configInt.step > this.configInt.max) {
        this.formControl.setValue(this.configInt.max);
      } else {
        // Avoid more than 4 decimal digits
        let newValue: number = this.formControl.value + this.configInt.step;
        newValue = (Math.round(newValue * 10000)) / 10000;
        this.formControl.setValue(newValue);
      }
      this.formControl.markAsDirty();
      this.onControlChange();
    }
  }

  protected onSliderDecrease() {
    if ((this.configInt.type === 'numberSlider') &&
      (this.configInt.step !== 0)) {
      if (this.formControl.value - this.configInt.step < this.configInt.min) {
        this.formControl.setValue(this.configInt.min);
      } else {
        // Avoid more than 4 decimal digits
        let newValue: number = this.formControl.value - this.configInt.step;
        newValue = (Math.round(newValue * 10000)) / 10000;
        this.formControl.setValue(newValue);
      }
      this.formControl.markAsDirty();
      this.onControlChange();
    }
  }

  protected onInputScroll(elementId: string){
    let el: HTMLElement | null = this.dom.getElementById(elementId);
    el?.scrollIntoView({behavior: 'smooth'});
  }

  protected checkInputDisabled(){
    if(this._isDisabled === true){
      this.formGroup.controls[this.configInt.id]?.disable();
    }
    else if(this._isDisabled === false){
      this.formGroup.controls[this.configInt.id]?.enable();      
    }
  }
}
