export interface IAvProdOutputSettingsVideo {
    resolution?: string;
    bitRate?: number;
    fps?: number;
}

export interface IAvProdOutputSettingsAudio {
    bitRate?: number;
    sampleRate?: number;
}

export interface IAvProdOutputSettingsJpeg {
    frameDecimation?: number;
    quality?: number;
    scale?: number;
}

export interface IAvProdOutputSettingsDestination {
    active: boolean;
    id: number;
    name: string;
    platform: string;
    avatarUrl?: string;
}

export interface IAvProdOutputSettings {
    onAir?: number;
    video?: IAvProdOutputSettingsVideo;
    audio?: IAvProdOutputSettingsAudio;
    jpeg?: IAvProdOutputSettingsJpeg;
    destinations?: IAvProdOutputSettingsDestination[];
}
