import { FormControl, FormGroup } from '@angular/forms';
import { timer } from 'rxjs';
import { AvProdSettingsType } from '../const/av-producer.const';
import { ToastPlacement, ToastStatus } from '../enums/common.enum';
import { IAvSettingsItemConfig } from '../interfaces/av-producer/event-av-producer.interface';
import { IEventDestination } from '../interfaces/events/event.interface';
import { IAlertData, IToastConfig } from '../interfaces/utils/utils.interface';
import { EventsService } from '../services/events/events.service';

export class DestinationSettingsClass {
  protected useSaveButton: boolean = false;
  protected destSettingsForm: FormGroup = new FormGroup([]);
  protected isBusy: boolean = false;
  protected isDirty: boolean = false;
  protected destination: IEventDestination | undefined;

  protected _mode: 'create' | 'edit' = 'create';
  protected _platform: string = '';
  protected _id: number = -1;

//  protected customRtmpName: string = '';
//  protected customRtmpUrl: string = '';

  protected items: IAvSettingsItemConfig[] = [
    {
      id: 'destName',
      type: AvProdSettingsType.text,
      name: 'destinations.name',
      min: 0,
      max: 0,
      step: 1,
      options: [],
      placeholder: '',
      value: ''
    },
    {
      id: 'destActive',
      type: AvProdSettingsType.switchBoolean,
      name: 'destinations.active',
      min: 0,
      max: 0,
      step: 1,
      options: [],
      placeholder: '',
      info: 'destinations.infoFieldActive',
      value: ''
    },
    {
      id: 'destCustomRtmpUrlBase',
      type: AvProdSettingsType.text,
      name: 'destinations.customRtmpUrlBase',
      min: 0,
      max: 200,
      step: 1,
      options: [],
      placeholder: 'rtmp:// or rtmps://',
      info: 'destinations.infoFieldRtmpUrlBase',
      value: ''
    },
    {
      id: 'destCustomRtmpUrlKey',
      type: AvProdSettingsType.text,
      name: 'destinations.customRtmpUrlKey',
      min: 0,
      max: 400,
      step: 1,
      options: [],
      placeholder: '',
      info: 'destinations.infoFieldRtmpUrlKey',
      value: ''
    },
    {
      id: 'destCustomSrtUrlBase',
      type: AvProdSettingsType.text,
      name: 'destinations.customSrtUrlBase',
      min: 0,
      max: 0,
      step: 1,
      options: [],
      placeholder: '',
      info: 'destinations.infoFieldSrtUrlBase',
      value: ''
    },
    {
      id: 'destCustomPort',
      type: AvProdSettingsType.numberText,
      name: 'destinations.customPort',
      min: 1024,
      max: 65535,
      step: 1,
      options: [],
      placeholder: '',
      info: 'destinations.infoFieldCustomPort',
      value: ''
    },
    {
      id: 'destCustomSrtUrlKey',
      type: AvProdSettingsType.text,
      name: 'destinations.customSrtUrlKey',
      min: 0,
      max: 0,
      step: 1,
      options: [],
      placeholder: '',
      info: 'destinations.infoFieldSrtUrlKey',
      value: ''
    },
    {
      id: 'destAuth',
      type: AvProdSettingsType.switchBoolean,
      name: 'destinations.useAuthentication',
      min: 0,
      max: 0,
      step: 1,
      options: [],
      placeholder: '',
      visible: false
    },
    {
      id: 'destAuthUser',
      type: AvProdSettingsType.text,
      name: 'destinations.authUser',
      min: 0,
      max: 0,
      step: 1,
      options: [],
      placeholder: '',
      value: '',
      visible: false
    },
    {
      id: 'destAuthPassword',
      type: AvProdSettingsType.password,
      name: 'destinations.authPassword',
      min: 0,
      max: 0,
      step: 1,
      options: [],
      placeholder: '',
      value: '',
      visible: false
    },
    {
      id: 'destPrivate',
      type: AvProdSettingsType.switchBoolean,
      name: 'destinations.private',
      min: 0,
      max: 0,
      step: 1,
      options: [],
      placeholder: '',
      value: false,
      visible: false
    }
  ];

  constructor(protected events: EventsService){
    this.items.forEach((element: IAvSettingsItemConfig) => {
      this.destSettingsForm.addControl(element.id, new FormControl());
    });
    this.destSettingsForm.get('destAuth')?.setValue(false);
    this.destSettingsForm.get('destActive')?.setValue(false);
  }

  protected init(): void {
    this.updateItemListVisibility();
    this.updateDestinationInfo();
  }

  protected displayAlert(data: IAlertData){
    // Must override
  }
  protected displayToast(config: IToastConfig){
    // Must override
  }
  protected exit(){
    // Must override
  }

  protected setPlatform(value: string){
    this._platform = value;
  }
  protected setId(value: number){
    this._id = value;
    this.updateDestinationInfo();
  }
  protected setMode(value: 'create' | 'edit'){
    this._mode = value;
  }

  protected onSettingChanged(event: IAvSettingsItemConfig): void {
    this.updateItemListVisibility();
    this.refreshDirtyFlags();
    if ((this.useSaveButton === false)&&(this._mode === 'edit')){
      this.onSave();
    }
  }

  protected onSettingTouched(event: IAvSettingsItemConfig): void {
    console.log('[DestinationsSettingsClass] onSettingTouched');
    if (event.id === 'destCustomPort'){
      if ((this.destSettingsForm.get('destCustomPort')?.value !== null)&&
          (this.destSettingsForm.get('destCustomPort')?.value > 65535)){
        this.destSettingsForm.get('destCustomPort')?.setValue(65535)
      }
    }
    this.updateItemListVisibility();
    this.refreshDirtyFlags();
  }

  protected refreshDirtyFlags(){
    this.isDirty = this.destSettingsForm.dirty;
  }

  protected updateItemListVisibility(){
    //console.log('[DestinationsSettingsClass] updateItemListVisibility');
    this.updateItemVisibility('destName', false);
    this.updateItemVisibility('destActive', true);
    this.updateItemVisibility('destCustomRtmpUrlBase', false);
    this.updateItemVisibility('destCustomRtmpUrlKey', false);
    this.updateItemVisibility('destCustomSrtUrlBase', false);
    this.updateItemVisibility('destCustomSrtUrlKey', false);
    this.updateItemVisibility('destCustomPort', false);
    this.updateItemVisibility('destAuth', false);
    this.updateItemVisibility('destAuthUser', false);
    this.updateItemVisibility('destAuthPassword', false);
    this.updateItemVisibility('destPrivate', false);

    if (this._platform === 'rtmp'){
      this.updateItemVisibility('destName', true);
      this.updateItemVisibility('destCustomRtmpUrlBase', true);
      this.updateItemVisibility('destCustomRtmpUrlKey', true);
      //this.updateItemVisibility('destAuth', true);
    }
    else if (this._platform === 'srt'){
      this.updateItemVisibility('destName', true);
      this.updateItemVisibility('destCustomSrtUrlBase', true);
      this.updateItemVisibility('destCustomSrtUrlKey', true);
      this.updateItemVisibility('destCustomPort', true);
      //this.updateItemVisibility('destAuth', true);
    }

    // if (this.destSettingsForm.get('destAuth')?.value === true){
    //   this.updateItemVisibility('destAuthUser', true);
    //   this.updateItemVisibility('destAuthPassword', true);
    // }
    // else{
    //   this.updateItemVisibility('destAuthUser', false);
    //   this.updateItemVisibility('destAuthPassword', false);
    // }
  }

  protected updateItemVisibility(id: string, visible: boolean): void {
    const ITEM: IAvSettingsItemConfig | undefined = this.items.find((element: IAvSettingsItemConfig) => (element.id === id));
    if (ITEM !== undefined) {
      ITEM.visible = visible;
    }
  }

  protected updateDestinationInfo(){
    if ((this._id !== -1)&&(this._mode === 'edit')){
      this.events.getUserDestinationInfo(this._id)
      .pipe()
      .subscribe(answer => {
        if (answer !== null){
          this.destination = answer;
          if (this._mode === 'edit'){
            this._platform = this.destination.platform.toLowerCase();
            this.destSettingsForm.get('destName')?.setValue(this.destination.name);
            this.destSettingsForm.get('destActive')?.setValue(this.destination.active);
            if (this._platform === 'rtmp'){
              this.destSettingsForm.get('destCustomRtmpUrlBase')?.setValue(this.destination.settings.url);
              this.destSettingsForm.get('destCustomRtmpUrlKey')?.setValue(this.destination.settings.key);
              //this.destSettingsForm.get('destAuth')?.setValue(this.destination.settings.auth);
              //this.destSettingsForm.get('destAuthUser')?.setValue(this.destination.settings.authUser);
              //this.destSettingsForm.get('destAuthPassword')?.setValue(this.destination.settings.authPassword);
            }
            else if (this._platform === 'srt'){
              this.destSettingsForm.get('destCustomSrtUrlBase')?.setValue(this.destination.settings.destination);
              this.destSettingsForm.get('destCustomSrtUrlKey')?.setValue(this.destination.settings.key);
              this.destSettingsForm.get('destCustomPort')?.setValue(this.destination.settings.port);
              //this.destSettingsForm.get('destAuth')?.setValue(this.destination.settings.auth);
              //this.destSettingsForm.get('destAuthUser')?.setValue(this.destination.settings.authUser);
              //this.destSettingsForm.get('destAuthPassword')?.setValue(this.destination.settings.authPassword);
            }
            this.updateItemListVisibility();
          }
        }
        console.log('[DestinationSettingsClass] ' + JSON.stringify(answer));
      });
    }
  }

  protected onSave(){
    if (this._mode === 'edit'){
      let name: string | undefined = this.destSettingsForm.get('destName')?.value;
      let active: boolean | undefined = this.destSettingsForm.get('destActive')?.value;
      let settings: any;
      let err: string = this.checkFieldsReady();

      if (err !== ''){
        this.displayError(err);
      }
      else{
        if (this._platform === 'rtmp'){
          settings = {
            url: this.destSettingsForm.get('destCustomRtmpUrlBase')?.value,
            key: this.destSettingsForm.get('destCustomRtmpUrlKey')?.value,
            //auth: this.destSettingsForm.get('destAuth')?.value
          }
          //if (settings?.auth === true){
          //  settings.authUser = this.destSettingsForm.get('destAuthUser')?.value;
          //  settings.authPassword = this.destSettingsForm.get('destAuthPassword')?.value;
          //}
        }
        else if (this._platform === 'srt'){
          settings = {
            destination: this.destSettingsForm.get('destCustomSrtUrlBase')?.value,
            port: this.destSettingsForm.get('destCustomPort')?.value,
            key: this.destSettingsForm.get('destCustomSrtUrlKey')?.value,
            //auth: this.destSettingsForm.get('destAuth')?.value
          }
          //if (settings?.auth === true){
          //  settings.authUser = this.destSettingsForm.get('destAuthUser')?.value;
          //  settings.authPassword = this.destSettingsForm.get('destAuthPassword')?.value;
          //}
        }
        this.isBusy = true;
        try {
          this.events.updateUserDestination(this._id, name, active, settings)
            .pipe()
            .subscribe(answer => {
              console.log('[DestinationSettingsClass] Edit: ' + JSON.stringify(answer));
              if ((answer !== null)&&(answer.status_code === 200)){
                console.log('[DestinationSettingsClass] Edit OK');
                if (this.useSaveButton === true){
                  this.exit();
                }
              }
              else{
                this.displayError('destinations.errorSavingSettings');
              }
              this.isBusy = false;
              timer(1000).subscribe(() => this.events.forcePollingChanges());
            });
        }
        catch(error){
          this.displayError('destinations.errorSavingSettings');
          this.isBusy = false;
        }
      }
    }
  }

  protected onAdd(){
    if (this._mode === 'create'){
      let name: string | undefined = this.destSettingsForm.get('destName')?.value;
      let active: boolean | undefined = this.destSettingsForm.get('destActive')?.value;
      let settings: any;
      let err: string = this.checkFieldsReady();

      if (err !== ''){
        this.displayError(err);
      }
      else{
        if (this._platform === 'rtmp'){
          settings = {
            url: this.destSettingsForm.get('destCustomRtmpUrlBase')?.value,
            key: this.destSettingsForm.get('destCustomRtmpUrlKey')?.value,
            //auth: this.destSettingsForm.get('destAuth')?.value
          }
          //if (settings?.auth === true){
          //  settings.authUser = this.destSettingsForm.get('destAuthUser')?.value;
          //  settings.authPassword = this.destSettingsForm.get('destAuthPassword')?.value;
          //}
        }
        else if (this._platform === 'srt'){
          settings = {
            destination: this.destSettingsForm.get('destCustomSrtUrlBase')?.value,
            key: this.destSettingsForm.get('destCustomSrtUrlKey')?.value,
            port: this.destSettingsForm.get('destCustomPort')?.value,
            //auth: this.destSettingsForm.get('destAuth')?.value
          }
          //if (settings?.auth === true){
          //  settings.authUser = this.destSettingsForm.get('destAuthUser')?.value;
          //  settings.authPassword = this.destSettingsForm.get('destAuthPassword')?.value;
          //}
        }
        this.isBusy = true;
        try {
          this.events.createUserDestination(this._platform, name, active, settings)
            .pipe()
            .subscribe(answer => {
              console.log('[DestinationSettingsClass] Add: ' + JSON.stringify(answer));
              if ((answer !== null)&&(answer.status_code === 200)){
                if ((this._platform === 'twitch')||
                    (this._platform === 'youtube')){
                  if (answer.data.url !== undefined){
                    window.open(answer.data.url, '_self');
                  }
                }
                this.exit();
              }
              else {
                this.displayError('destinations.errorAdding');
              }
              this.isBusy = false;
              timer(1000).subscribe(() => this.events.forcePollingChanges());
            });
        }
        catch(error){
          this.displayError('destinations.errorAdding');
          this.isBusy = false;
        }
      }
    }
  }

  protected displayError(error: string){
    this.displayToast({
      options: {
        placement: ToastPlacement.middleCenter,
        autohide: true
      },
      data: {
        status: ToastStatus.error,
        text: error,
        closeButtonBody: true,
      },
    });
  }

  protected checkFieldsReady(): string{
    let ret: string = '';
    /*
    for (let i: number=0; i<this.items.length; i++){
      if (this.items[i].visible === true){
        if ((this.items[i].type === AvProdSettingsType.text)||
            (this.items[i].type === AvProdSettingsType.textArea)||
            (this.items[i].type === AvProdSettingsType.password)){
          let value: any = this.destSettingsForm.get(this.items[i].id)?.value;
          if ((typeof(value) !== 'string')||(value === '')){
            ret = false;
            break;
          }
        }
      }
    }*/

    if (this._platform === 'rtmp'){
      let value: any;
      value = this.destSettingsForm.get('destCustomRtmpUrlBase')?.value;
      if ((typeof(value) !== 'string')||(value === '')){
        ret = 'destinations.errorAddingFields';
        return ret;
      }
      else{
        if ((value.substring(0, 7) !== 'rtmp://') &&
            (value.substring(0, 8) !== 'rtmps://'))
        {
          ret = 'destinations.errorCustomRtmpAddress';
          return ret;
        }
      }
      value = this.destSettingsForm.get('destCustomRtmpUrlKey')?.value;
      if ((typeof(value) !== 'string')||(value === '')){
        ret = 'destinations.errorAddingFields';
        return ret;
      }
    }
    else if (this._platform === 'srt'){
      let value: any;
      value = this.destSettingsForm.get('destCustomSrtUrlBase')?.value;
      if ((typeof(value) !== 'string')||(value === '')){
        ret = 'destinations.errorAddingFields';
        return ret;
      }

      // Key is optional
      //value = this.destSettingsForm.get('destCustomSrtUrlKey')?.value;
      value = this.destSettingsForm.get('destCustomPort')?.value;
      if (typeof(value) !== 'number'){
        ret = 'destinations.errorAddingFields';
        return ret;
      }
      else{
        if (this.destSettingsForm.get('destCustomPort')?.invalid)
        {
          ret = 'destinations.errorCustomSrtPort';
          return ret;
        }
      }
    }

    return ret;
  }
}
