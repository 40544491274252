import { AvProdInputSourcePushProtocol } from '../../const/av-producer.const';

export interface IAvProdInputSettingsVideo {
    frameCropStart: number;
    frameCropEnd: number;
}

export interface IAvProdInputSettingsAudio {
    volume?: number;
    mode?: number;
}

export interface IAvProdInputSettings {
    favorite?: boolean;
    playingMode?: number;
    playingSpeed?: number;
    name?: string;
    liveSourceType?: number;
    liveSourcePullURL?: string;
    liveSourcePushURL?: string;
    liveSourcePushProtocol?: AvProdInputSourcePushProtocol;
    tileFillMode?: number;
    tileFillColor?: string;
    video?: IAvProdInputSettingsVideo;
    audio?: IAvProdInputSettingsAudio;
    availableForOverlay?: boolean;
    availableForLayout?: boolean;
    overlayName?: boolean;
    overlayTitle?: boolean;
}
