<div class="main-container"
     #mainAlertContainer
     [ngStyle]="{'visibility' : alertData.show ? 'visible' : 'hidden'}">

  <div class="alert-container" [ngClass]="{'zoomIn': alertData.show}">

    <div class="alert-header">
      <button *ngIf="alertData?.closeButton === true"
              type="button"
              class="btn-close"
              (click)="close()"></button>
    </div>

    <div class="alert-body">

      <span [class]="alertData.icon + ' ' + alertData.iconColor + ' ' + alertData.status"></span>
      <div class="text-center w-100">
        <h2 *ngIf="alertData.title && alertData.title !== ''">{{ alertData.title | translate }}</h2>
        <br *ngIf="alertData.title && alertData.title === ''">
        <p *ngIf="alertData.text && alertData.text !== ''">{{ alertData.text | translate }}</p>
        <br *ngIf="alertData.text && alertData.text === ''">
      </div>

      <ng-container *ngIf="alertData.component !== undefined">
        <web-form-input-code *ngIf="alertData.component==='code'"
                             (codeChanged)="componentInputs.code=$event"></web-form-input-code>
      </ng-container>

      <div class="w-100 d-flex g-1 justify-content-evenly mt-4 gap-2" *ngIf="alertData.buttons">
        <button type="button"
                *ngFor="let button of alertData.buttons!"
                class="btn"
                [disabled]="isDisabled(button)"
                [class.btn-link]="(button.fill === 'clear')"
                [ngClass]=" [
                 (button.fill === 'outline') ? ('btn-' + button.fill +'-' + button.color) : '',
                 (button.fill === 'solid') ?  'btn-' + button.color : '',
                 (!button.fill) ?  'btn-' + button.color : '',
                 ]"
                (click)="clickButton(button)">
          <span class="spinner-border spinner-border-sm"
               [ngClass]="{'d-none': !button.onClickLoading || button.onClickLoading && !button.disabled}"
               role="button"
               aria-hidden="true"></span>
          <span [ngClass]="{'d-none': button.onClickLoading && button.disabled}">{{ button.text | translate }}</span>

        </button>
      </div>
    </div>
  </div>
</div>
