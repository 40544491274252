<div class="modal fade" id="usersModal" #modal tabindex="-1" aria-labelledby="UsersModalLabel" aria-hidden="true">

  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
       [ngClass]="{'modal-dialog-scrollable': (page === 'privacy' || page === 'terms')}">
    <div class="modal-content">
      <div class="left-container">
        <img src="/assets/shared/img/logo/azzulei-landscape-dark.png" alt="Azzulei" title="Azzulei">
        <img src="/assets/shared/img/azzulei-desktop-xl.png" class="desktop" alt="Multi device access" title="Multi device access">
      </div>

      <div class="form-container">

        <div class="d-flex justify-content-end">

          <button type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  (click)="closeRequested()"
                  *ngIf="(page!=='privacy' && page!=='terms')"
                  #modalClose></button>

          <button type="button"
                  class="btn-close btn-danger"
                  *ngIf="(page==='privacy' || page==='terms')"
                  (click)="changeModalContent('signUp')"></button>
        </div>

        <ng-container [ngSwitch]="true">

          <web-login *ngSwitchCase="page==='login'"></web-login>

          <web-sign-up *ngSwitchCase="page==='signUp'"></web-sign-up>

          <web-forgot-password *ngSwitchCase="page==='forgotPassword'"></web-forgot-password>

          <web-activate-account *ngSwitchCase="page==='activateAccount'"></web-activate-account>

          <web-legal *ngSwitchCase="page==='privacy' || page==='terms'" [page]="page"></web-legal>

        </ng-container>

        <div class="sso-container">
          <hr>
          <button class="btn btn-light"
                  (click)="gSsoSignIn()">

            <span *ngIf="checkingLoginGo"
                  class="spinner-border spinner-border-sm"
                  role="button"
                  aria-hidden="true"></span>
            <ng-container *ngIf="!checkingLoginGo">
              <object data="/assets/shared/icons/google-coloured.svg" width="24" height="24"> </object>
              {{ "users.googleLogin" | translate }}
            </ng-container>
          </button>

          <br>

          <button
            class="btn btn-light"
            (click)="mSsoSignIn()">
            <span *ngIf="checkingLoginMs"
                  class="spinner-border spinner-border-sm"
                  role="button"
                  aria-hidden="true"></span>
            <ng-container *ngIf="!checkingLoginMs">
              <object data="/assets/shared/icons/microsoft-coloured.svg" width="24" height="24"> </object>
              {{ "users.microsoftLogin" | translate }}
            </ng-container>
          </button>

        </div>

      </div>
    </div>
  </div>

</div>
