import { Subscription, timer } from 'rxjs';

import { AlertStatus, ToastPlacement, ToastStatus } from '../../enums/common.enum';
import { IAlertData, IToastConfig } from '../../interfaces/utils/utils.interface';
import { IAvProdInput } from '../../interfaces/av-producer/event-av-producer.interface';
import { IAvProdTimeTag } from '../../interfaces/av-producer/composer-settings.interface';
import { AvProdDeviceType, AvProdInputPlayingState, AvProdInputTypeNumber } from '../../const/av-producer.const';
import { AvProducerService } from '../../services/av-producer/av-producer.service';
import { ModalDialogId } from '../../enums/utils.enum';
import { ModalManagerService } from '../../services/modal/modal-manager.service';
import { IEvent } from '../../interfaces/events/event.interface';
import { EVENTS } from '../../const/events.const';
import { EventsService } from '../../services/events/events.service';

export class ToolboxGeneralClass{

    protected listHighlightInputs: IAvProdInput[] = [];
    protected listLiveActiveInputs: IAvProdInput[] = [];
    protected listHighlightTags: IAvProdTimeTag[] = [];
    protected highlightBusy: boolean = false;

    protected _event: IEvent | undefined;
  
    protected tagIdEditionActive: number | undefined;
    protected tagNameEdition: string = '';
  
    protected inputListSubscription: Subscription | undefined;
    protected inputStatusSubscription: Subscription | undefined;
    protected composerSettingsSubscription: Subscription | undefined;
    protected highlightBusySubscription: Subscription | undefined;

    constructor(protected avProd: AvProducerService, 
                protected events: EventsService, 
                protected modalService: ModalManagerService) {
        // do nothing
      } 
      
      protected displayAlert(data: IAlertData): void {
        // must be overridden
      }
      protected displayToast(config: IToastConfig): void {
        // must be overridden
      }
      protected emitSetSingleCamLayout(param: boolean){
        // must be overridden
      }

      protected onSetSingleCamLayout(param: boolean){
        this.emitSetSingleCamLayout(param);
      }
}