import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { filter, first, switchMap, tap } from 'rxjs';

import {
  AuthService, COMMON, DeviceService, UserService
} from '@azz-life-streamer/shared';
import { APP, VERSION_CODE, VERSION_NAME } from './core/const/app.const';
import { UrlService } from './core/services/url/url.service';


@Component({
  selector: 'web-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

  public title: string = 'azzulei-tv';

  constructor(private deviceService: DeviceService,
              private authService: AuthService,
              private userService: UserService,
              private router: Router,
              private location: Location,
              private urlService: UrlService,
              /*@Inject(DOCUMENT) private document: Document*/) {
     //console.log('[APP component] constructor');
  }

  ngOnInit(): void {
    //console.log('[APP component] ngOnInit device: ', this.deviceService.device, this.router.url)

      this.deviceService
        .initDevice(APP.key, 'App web', VERSION_NAME, VERSION_CODE)
        .pipe(
          first(),
          filter(() => !this.userService.user),
          switchMap(() => this.authService.autoAuthAvailable()),

          tap((authResult: boolean) => {

            if (!authResult) {
              this.userService.initUserAnonymous();
            }
          }),
          filter((authResult: boolean) => authResult),
          switchMap(() => this.userService.initUser()),
          tap((loginResult: boolean) => {
            let curLang : string | undefined = undefined;
            if(this.router.url.length === 3) {
              curLang = this.urlService.getLanguageFromUrl(this.router.url);
            }
            if (loginResult && (this.location.path() === '') || curLang ) {
              this.router.navigateByUrl(COMMON.urls.manager)
                .catch(console.error);
            }
          })
        )
        .subscribe();
    }
}
