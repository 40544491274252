import { Component, ElementRef, Input, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import {
  COMMON, OAuthService, ToastPlacement, ToastStatus, UserService
} from '@azz-life-streamer/shared';
import { VERSION_NAME } from '../../../core/const/app.const';
import { ToastService } from '../../../core/services/toast/toast.service';
import { UrlService } from '../../../core/services/url/url.service';


@Component({
  selector: 'web-users-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss'],
})
export class PanelComponent implements AfterViewInit, OnDestroy {
  @ViewChild('panel') panel!: ElementRef;
  @ViewChild('panelClose') panelCloseButton!: ElementRef;

  @Input() image: string | undefined;

  protected editingProfile: boolean = false;
  protected readonly COMMON: any = COMMON;
  protected readonly VERSION_NAME: string = VERSION_NAME;

  private closingSession: boolean = false;

  constructor(protected userService: UserService,
              protected urlService: UrlService,
              private OAuthService: OAuthService,
              private toastService: ToastService,
              private router: Router) {}

  ngAfterViewInit(): void {
    this.panel.nativeElement.addEventListener('hidden.bs.offcanvas', () => {
      this.toastService.hideToast();
    });
  }

  ngOnDestroy(): void {
    this.panel.nativeElement.removeAllListeners();
  }

  protected goToAccount(): void {
    this.panelCloseButton.nativeElement.click();
    this.router.navigateByUrl(COMMON.urls.managerAccount).catch(console.error);
  }

  protected editProfile(): void {
    this.editingProfile = true;
  }

  protected onClickLogout(): void {
    if (!this.closingSession) {
      this.closingSession = true;
      this.showConfirm('logout');
    }
  }

  protected deleteAccount(): void {
    this.showConfirm('deleteAccount');
  }

  private showConfirm(mode: string): void {
    this.toastService.showToast({
      options: {
        autohide: false,
        placement: ToastPlacement.bottomRight,
      },
      data: {
        status: ToastStatus.info,
        text:
          mode === 'logout'
            ? 'users.confirmLogout'
            : 'users.confirmDeleteAccount',
        alignText: 'text-center',
        buttons: [
          {
            text: 'general.cancel',
            color: 'primary',
            closeButton: true,
            handler: (): void => {
              this.closingSession = false;
            }
          },
          {
            text: 'general.confirm',
            color: 'danger',
            closeButton: true,
            handler: (): void => {
              this.closingSession = false;
              if (mode === 'logout') {
                this.OAuthService.logout();
                this.router
                  .navigateByUrl(COMMON.urls.home)
                  .catch(console.error);
              }
              if (mode === 'deleteAccount') {
                this.userService.deleteAccount().subscribe({
                  next: this.handleDeleteAccountConfirmResponse.bind(this),
                  error:
                    this.handleDeleteAccountConfirmErrorResponse.bind(this),
                });
              }
            },
          },
        ],
      },
    });
  }

  private handleDeleteAccountConfirmResponse(result: boolean): void {
    if (result) {
      console.log('account deleted');
    } else {
      console.log('unable to delete account');
    }
  }

  private handleDeleteAccountConfirmErrorResponse(): void {
    console.log('unable to delete account');
  }
}
