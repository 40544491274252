import { ElementRef, Injectable } from '@angular/core';
import { interval, BehaviorSubject, timer, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import {
  IMediaDevice,
  IMediaInputDevices,
  IMediaOutputDevices
} from '../../interfaces/publish-stream/devices.interface';
import { IPublishStreamSlot, IPublisherSettings, IVideoResolution } from '../../interfaces/publish-stream/settings.interface';
import { IAvProdInputSettings } from '../../interfaces/av-producer/input-settings.interface';
import { IAvMsgAscii, IAvProdInput } from '../../interfaces/av-producer/event-av-producer.interface';
import { IAvProdInterfaceStatus } from '../../interfaces/av-producer/interface-status.interface';
import {
  AvProdDeviceType,
  AvProdInputPlayingState,
  AvProdInputTypeNumber,
  AvProdWebRtcConnectionType
} from '../../const/av-producer.const';
import {
  AvProdPublishCommsStatus,
  AvProdPublishSourceType,
  PUBLISH_STREAM_DEFAULT_RESOLUTION,
  PUBLISH_STREAM_DEFAULT_SETTINGS,
  PUBLISH_STREAM_MAX_SLOTS,
  PUBLISH_STREAM_RESOLUTIONS
} from '../../const/publish-stream';
import { COMMON } from '../../const/common.const';
import { UserService } from '../user/user.service';
import { AvProducerService } from '../av-producer/av-producer.service';
import { DeviceService } from '../device/device.service';


@Injectable({
  providedIn: 'root'
})
export class PublishStreamService {

  protected requestStreamIdSubscription: Subscription | undefined;
  protected avProdInterfaceSubscription: Subscription | undefined;
  protected checkStreamIdsSubscription: Subscription | undefined;
  protected publishCommandAnsSubscription: Subscription | undefined;
  protected avProdCommsStatusSubscription: Subscription | undefined;

  protected userMediaInitialized: boolean = false;
  public userMediaAvailable: boolean = false;
  public userMediaEnabled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public mediaInputDevices: BehaviorSubject<IMediaInputDevices> = new BehaviorSubject<IMediaInputDevices>({
    audioInputs: [],
    videoInputs: []
  });
  public mediaOutputDevices: BehaviorSubject<IMediaOutputDevices> = new BehaviorSubject<IMediaOutputDevices>({
    audioOutputs: []
  });

  public slotChangeSource: BehaviorSubject<string> = new BehaviorSubject('');
  public streamSlots: IPublishStreamSlot[] = [];
  public avServerLiveStreamsTotal: number = 0;
  public avServerLiveStreamsFree: number = 0;

  public userSettings: IPublisherSettings | undefined;
  protected userSettingsEvent: string = '';
  protected userSettingsSameEvent: boolean = false;
  protected eventToken: string = '';

  private defaultVideoInputDeviceId: string | undefined = undefined;
  private defaultAudioInputDeviceId: string | undefined = undefined;
  private defaultAudioOutputDeviceId: string | undefined = undefined;
  public defaultVideoInput: IMediaDevice | undefined = undefined;
  public defaultAudioInput: IMediaDevice | undefined = undefined;
  public defaultAudioOutput: IMediaDevice | undefined = undefined;

  constructor(private avProd: AvProducerService,
              private userService: UserService,
              private deviceService: DeviceService,
              private translate: TranslateService) {
      // Do nothing
  }

  public async initializeUserMedia(): Promise<void> {
    if (this.userMediaInitialized == false){
      if (this.getHasUserMedia()) {
        this.userMediaAvailable = true;
        const CONSTRAINTS = {
          audio: {deviceId: undefined},
          video: (this.deviceService.device?.platform === 'web') ? {deviceId: undefined} : {facingMode: 'environment'}
        };
        // Asking user for permissions and getting mediaDevices
        let myStream: MediaStream | undefined;
        navigator.mediaDevices.getUserMedia(CONSTRAINTS)
          .then((stream: MediaStream) => {
            myStream = stream;
            this.defaultVideoInputDeviceId = myStream.getVideoTracks()[0].getSettings().deviceId;
            this.defaultAudioInputDeviceId = myStream.getAudioTracks()[0].getSettings().deviceId;
            console.log('[PublishStreamService] initializeUserMedia Media enabled - default devices Id: '
              + this.defaultVideoInputDeviceId + ' ' + this.defaultAudioInputDeviceId);
          })
          .then(() => this.refreshMediaDevices())
          .then(() => {
            if (myStream !== undefined) {
              // close stream to release default devices
              this.closeStream(myStream);
              myStream = undefined;
            }
            this.userMediaInitialized = true;
            this.userMediaEnabled.next(true);
          })
          .catch(() => {
            this.userMediaInitialized = true;
            this.userMediaEnabled.next(false);
            console.log('[PublishStreamService] initializeUserMedia Media ERROR getUserMedia');
          });

      } else {
        this.userMediaAvailable = false;
        this.userMediaInitialized = true;
        console.log('[PublishStreamService] initializeUserMedia Media ERROR getHasUerMedia');
      }
      if(this.defaultVideoInput === undefined) {
        this.defaultVideoInput = this.getVideoDeviceById('default');
      }
      if(this.defaultAudioInput === undefined) {
        this.defaultAudioInput = this.getAudioInputDeviceById('default');
      }
    }
  }

  /**
   * Function to initialize the service
   */
  public async init(eventTokenViewer: string | undefined): Promise<void> {
    console.log('[PublishStreamService] Init');
    if (this.userMediaInitialized === false){
      await this.initializeUserMedia();
    }

    await this.refreshMediaDevices()
      .catch(console.error);

    if (this.requestStreamIdSubscription !== undefined) this.requestStreamIdSubscription.unsubscribe();
    this.requestStreamIdSubscription = this.avProd.onRequestMediaStreamIdResponse$.subscribe(msg => this.receiveStreamId(msg));
    if (this.avProdInterfaceSubscription !== undefined) this.avProdInterfaceSubscription.unsubscribe();
    this.avProdInterfaceSubscription = this.avProd.onNewInterfaceStatus$.subscribe(msg => this.receiveInterfaceStatus(msg));
    if (this.checkStreamIdsSubscription !== undefined) this.checkStreamIdsSubscription.unsubscribe();
    this.checkStreamIdsSubscription = timer(2000).subscribe(() => this.checkSlotStreamIds());
    this.publishCommandAnsSubscription?.unsubscribe();
    this.publishCommandAnsSubscription = this.avProd.onInterfaceCommandResponse$.subscribe(msg => this.receiveInterfaceCommandResponse(msg));
    this.avProdCommsStatusSubscription?.unsubscribe();
    this.avProdCommsStatusSubscription = this.avProd.onCommsStatusChange$.subscribe(() => this.checkAvProdCommsStatus());

    if ((eventTokenViewer !== undefined)&&(eventTokenViewer !== '')){
      this.eventToken = eventTokenViewer;
    }
    this.userSettings = this.getSettingsFromLocalStorage();
    this.checkUserVideoDevice();
    this.checkUserAudioInputDevice();
    this.checkUserAudioOutputDevice();
  }

  public destroy(): void {
    console.log('[PublishStreamService] Destroy');
    if (this.requestStreamIdSubscription !== undefined) this.requestStreamIdSubscription.unsubscribe();
    if (this.avProdInterfaceSubscription !== undefined) this.avProdInterfaceSubscription.unsubscribe();
    this.checkStreamIdsSubscription?.unsubscribe();
    this.publishCommandAnsSubscription?.unsubscribe();
  }

  /**
   * Function returns whether the platform supports user media devices or not
   *
   * @returns boolean
   */
  protected getHasUserMedia(): boolean {
    return !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia);
  }

  /**
   * Function returns the list of available Media input devices
   *
   * @returns
   */
  protected async getDevices(): Promise<{ inputs: IMediaInputDevices, outputs: IMediaOutputDevices }> {
    return navigator.mediaDevices.enumerateDevices()
      .then((devices: MediaDeviceInfo[]) => this.formatDevices(devices))
      .catch(err => err);
  }

  /**
   * Function returns the video capabilities of a specific Media input devices
   *
   * @returns any
   */
  public getDeviceVideoCapabilities(deviceId: string | undefined): any {
    let ret: any;
    if (deviceId !== undefined) {
      const DEVICE: IMediaDevice | undefined = this.getVideoDeviceById(deviceId);
      if (DEVICE !== undefined) {
        ret = DEVICE.videoCapabilities;
      }
    }
    return ret;
  }

  /**
   * Function returns the list stream slot ids by source type (device or screen)
   *
   * @returns string[]
   */
  public getSlotIdsBySourceType(sourceType: AvProdPublishSourceType): string[] {
    const IDS: string[] = [];
    for (let i: number = 0; i < this.streamSlots.length; i++) {
      if (this.streamSlots[i].sourceType === sourceType) {
        IDS.push(this.streamSlots[i].id);
      }
    }
    return IDS;
  }

  /**
   * Function converts list of media devices into enriched format
   *
   * @param devices Raw list of media devices
   * @returns IMediaInputDevices
   */
  protected formatDevices(devices: MediaDeviceInfo[]): { inputs: IMediaInputDevices, outputs: IMediaOutputDevices } {
    const VIDEO_DEVICES: IMediaDevice[] = [];
    const AUDIO_IN_DEVICES: IMediaDevice[] = [];
    const AUDIO_OUT_DEVICES: IMediaDevice[] = [];
    for (let i = 0; i !== devices.length; ++i) {

      const DEVICE_INFO: MediaDeviceInfo = devices[i];
      const label: string = '';

      const EXISTS: IMediaDevice | undefined = this.getVideoDeviceById(DEVICE_INFO.deviceId);

      const CUR_DEVICE: IMediaDevice = {
        label,
        deviceId: DEVICE_INFO.deviceId,
        id: i,
        type: DEVICE_INFO.kind,
        videoCapabilities: EXISTS?.videoCapabilities
      };
      if (DEVICE_INFO.kind === 'videoinput') {
        CUR_DEVICE.label = DEVICE_INFO.label;
        VIDEO_DEVICES.push(CUR_DEVICE);
      } else if (DEVICE_INFO.kind === 'audioinput') {
        CUR_DEVICE.label = DEVICE_INFO.label;
        AUDIO_IN_DEVICES.push(CUR_DEVICE);
      } else if (DEVICE_INFO.kind === 'audiooutput') {
        CUR_DEVICE.label = DEVICE_INFO.label;
        AUDIO_OUT_DEVICES.push(CUR_DEVICE);
      }
    }
    return {
      inputs: {
        audioInputs: AUDIO_IN_DEVICES,
        videoInputs: VIDEO_DEVICES,
      },
      outputs: {
        audioOutputs: AUDIO_OUT_DEVICES
      }
    };
  }

  /**
   * Function to refresh the list of available devices
   *
   */
  public async refreshMediaDevices(): Promise<void> {
    if (this.userMediaAvailable && this.userMediaEnabled) {
      const DEVICES: { inputs: IMediaInputDevices, outputs: IMediaOutputDevices } = await this.getDevices();

      this.mediaInputDevices.next(DEVICES.inputs);
      this.mediaOutputDevices.next(DEVICES.outputs);

      if(this.defaultVideoInputDeviceId && !this.defaultVideoInput) {
        this.defaultVideoInput = this.getVideoDeviceById(this.defaultVideoInputDeviceId);
      }

      if(this.defaultAudioInputDeviceId && !this.defaultAudioInput) {
        this.defaultAudioInput = this.getAudioInputDeviceById(this.defaultAudioInputDeviceId);
      }

      if(this.defaultAudioOutputDeviceId && !this.defaultAudioOutput) {
        this.defaultAudioOutput = this.getAudioOutputDeviceById(this.defaultAudioOutputDeviceId);
      }
      //console.log('[PublishStreamService] refreshMediaDevices Inputs: ' + JSON.stringify(this.mediaInputDevices));
      //console.log('[PublishStreamService] refreshMediaDevices Default: ' + JSON.stringify(this.defaultVideoInput));
    }
  }

  protected receiveInterfaceCommandResponse(msg: IAvMsgAscii) {
    if (msg.item === 'interface/1/commands'){
      if (msg.dataTx.command === 'PublishStart'){
        console.log('[PublishStreamService] receiveInterfaceCommandResponse ' + JSON.stringify(msg));
        if ((msg.data.slotIndex !== undefined)&&(this.streamSlots[msg.data.slotIndex - 1] !== undefined)){
          if (msg.retCode === 0){
            if (msg.data.streamId !== this.streamSlots[msg.data.slotIndex - 1].streamId){
              this.stopMediaPublish(msg.data.slotIndex - 1);
              console.error('[PublishStreamService] receiveInterfaceCommandResponse PublishStart ERROR 1 ' + JSON.stringify(msg));
            }
            else{
              this.streamSlots[msg.data.slotIndex - 1].busy = true;
              this.streamSlots[msg.data.slotIndex - 1].lastPublicationTimestamp = (new Date()).getTime();
              this.startMediaPublish(msg.data.slotIndex - 1).then(() => {
                this.streamSlots[msg.data.slotIndex - 1].busy = false;
              });
            }
          }
          else{
            this.stopMediaPublish(msg.data.slotIndex - 1);
            console.error('[PublishStreamService] receiveInterfaceCommandResponse PublishStart ERROR 2 ' + JSON.stringify(msg));
          }
        }
      }
    }
  }

  protected receiveInterfaceStatus(msg: IAvProdInterfaceStatus): void {
    this.avServerLiveStreamsTotal = this.avProd.interfaceStatus.liveStreams.length;
    let freeStreams: number = 0;
    for (let i: number = 0; i < this.avProd.interfaceStatus.liveStreams.length; i++) {
      if ((this.avProd.interfaceStatus.liveStreams[i].inUse === false) &&
        (this.avProd.interfaceStatus.liveStreams[i].sourceType === 1)) {
        freeStreams++;
      }
    }
    this.avServerLiveStreamsFree = freeStreams;
    console.log('[PublishStreamService] receiveInterfaceStatus Total:' + this.avServerLiveStreamsFree + '/' + this.avServerLiveStreamsTotal);
  }

  protected receiveStreamId(msg: IAvMsgAscii): void {
    let ok: boolean = false;
    if ((msg.item === 'interface/1/commands') && (msg.dataTx.command === 'RequestMediaStreamId')) {
      if ((msg.dataTx.params.slotId !== undefined)) {
        const SLOT: number = this.getSlotIndex(msg.dataTx.params.slotId);
        if ((msg.retCode === 0) && (msg.data.streamId !== undefined) &&
          (this.streamSlots[SLOT] !== undefined) && (this.streamSlots[SLOT].streamId === -1)) {
          this.streamSlots[SLOT].streamId = msg.data.streamId;
          this.streamSlots[SLOT].streamIdPrev = msg.data.streamId;
          ok = true;
        } else {
          this.stopMediaPublish(SLOT);
          console.log('[PublishStreamService] receiveStreamId ERROR Disable Slot');
        }
      }
    }
    if (!ok) {
      console.log('[PublishStreamService] receiveStreamId ERROR *** ' + JSON.stringify(msg));
    } else {
      console.log('[PublishStreamService] receiveStreamId OK ' + JSON.stringify(msg));
    }
  }

  protected checkAvProdCommsStatus(){
    console.log('[PublishStreamService] checkAvProdCommsStatus');
    if (this.avProd.commsStatus.ok !== true){
      console.log('[PublishStreamService] checkAvProdCommsStatus NOT OK');
      for (let i:number=0; i<this.streamSlots.length; i++){
        if (this.streamSlots[i].active === true){
          if (this.streamSlots[i].streamId !== -1){
            this.stopMediaPublish(i);
          }
        }
      }
    }
  }

  protected checkSlotStreamIds(){
    // Check inactive slots
    for (let i:number=0; i<this.streamSlots.length; i++){
      if (this.streamSlots[i].active !== true){
        if (this.streamSlots[i].streamId !== -1){
          this.avProd.azzCmdInterfacePublishEnd(this.streamSlots[i].streamId, i+1);
          this.streamSlots[i].streamId = -1;
        }
      }
    }
    // Check avProducer server feedback
    if (this.avProd.clientStatus.publish !== undefined){
      for (let i:number=0; i<this.avProd.clientStatus.publish.length; i++){
        if (this.avProd.clientStatus.publish[i].streamId !== -1){
          if (this.streamSlots[i].active !== true){
            this.avProd.azzCmdInterfacePublishEnd(this.avProd.clientStatus.publish[i].streamId, i+1);
          }
          else{
            if (this.avProd.clientStatus.publish[i].streamId !== this.streamSlots[i].streamId){
              console.log('[PublishSrteamService] checkSlotStreamIds - StreamIds not matching ' + this.avProd.clientStatus.publish[i].streamId + '/' + this.streamSlots[i].streamId);
              if (this.streamSlots[i].streamId !== -1){
                this.stopMediaPublish(i);
              }
            }
          }
        }
      }
    }
  }

  /**
   * Function to enable a new publishing stream slot
   *
   * @param sourceType Specify source type
   */
  public enableSlotAvailable(sourceType: AvProdPublishSourceType): string {
    for (let i: number = 0; i < this.streamSlots.length; i++) {
      if ((this.streamSlots[i].sourceType === sourceType) &&
        (this.streamSlots[i].active === false)) {
        this.setSlotActive(this.streamSlots[i].id, true);
        return this.streamSlots[i].id;
      }
    }
    return '';
  }

  /**
   * Function to enable/disable a specific publish stream slot
   *
   * @param id Stream slot identifier
   * @param active Flag to enable/disable this slot
   */
  public setSlotActive(id: string, active: boolean): void {
    const SLOT: number = this.getSlotIndex(id);
    if (this.streamSlots[SLOT] !== undefined) {
      if (this.streamSlots[SLOT].active !== active) {
        if (active === false) {
          this.resetSlot(this.streamSlots[SLOT].id);
        }
        this.streamSlots[SLOT].active = active;
        this.slotChangeSource.next(id);
      }
    }
  }

  /**
   * Function to start a publish stream slot
   *
   * @param id Stream slot identifier
   * @param videoElement Associated HTML Video element
   * @param canvasElement Associated HTML Canvas element
   */
  public startSlot(id: string, videoElement: ElementRef<HTMLVideoElement>, canvasElement?: ElementRef<HTMLCanvasElement> | undefined) {
    const SLOT: number = this.getSlotIndex(id);
    if ((this.streamSlots[SLOT] !== undefined) &&
      (this.streamSlots[SLOT].active === true)) {
      this.streamSlots[SLOT].videoElement = videoElement;
      this.streamSlots[SLOT].canvasOrigElement = canvasElement;
      if (!this.streamSlots[SLOT].initialized) {
        const DEVICES: IMediaInputDevices = this.mediaInputDevices.getValue();
        // Video
        if (this.streamSlots[SLOT].settings.videoDevice === undefined) {
          if(this.defaultVideoInput) {
            this.streamSlots[SLOT].settings.videoDevice = this.defaultVideoInput;
          } else {
            this.streamSlots[SLOT].settings.videoDevice = this.getVideoDeviceById('default') ?? DEVICES.videoInputs[0];
          }
          this.streamSlots[SLOT].settings.videoDevice = this.defaultVideoInput ?? DEVICES.videoInputs[0];
          console.log('[PublishStreamService] startSlot ' + SLOT + ' Video: ' + JSON.stringify(this.streamSlots[SLOT].settings.videoDevice));
        }

        // Audio
        if (this.streamSlots[SLOT].settings.audioInputDevice === undefined) {
          if(this.defaultAudioInput) {
            this.streamSlots[SLOT].settings.audioInputDevice = this.defaultAudioInput;
          } else {
            this.streamSlots[SLOT].settings.audioInputDevice = this.getAudioInputDeviceById('default') ?? DEVICES.audioInputs[0];
          }
          console.log('[PublishStreamService] startSlot ' + SLOT + ' Audio: ' + JSON.stringify(this.streamSlots[SLOT].settings.audioInputDevice));
        }

        this.streamSlots[SLOT].initialized = true;
      }
      // Always make sure to open media when starting slot
      //if (!this.streamSlots[SLOT].mediaOpen) {
        this.openMedia(SLOT)
          .catch(console.error);
      //}
      this.slotChangeSource.next(id);
    }
  }

  /**
   * Function to stop a publish stream slot
   *
   * @param id Stream slot identifier
   */
  public stopSlot(id: string): void {
    const SLOT: number = this.getSlotIndex(id);
    console.log('[PublishStreamService] stopSlot ' + SLOT + ' Video: ' + JSON.stringify(this.streamSlots[SLOT]?.settings.videoDevice));
    if ((this.streamSlots[SLOT] !== undefined)&&(this.streamSlots[SLOT].active === true)) {
      this.closeMedia(SLOT);
      this.slotChangeSource.next(id);
    }
  }

  public getSlotIndex(id: string): number {
    let index: number = -1;
    for (let i: number = 0; i < this.streamSlots.length; i++) {
      if (this.streamSlots[i].id === id) {
        index = i;
        break;
      }
    }
    return index;
  }

  public setDeviceVideoCapabilities(deviceId: string | undefined, capabilities: any): void {
    console.log('[PublishStreamService] setDeviceVideoCapabilities Video capabilities: ' + JSON.stringify(capabilities));
    if (deviceId !== undefined) {
      const DEVICE: IMediaDevice | undefined = this.getVideoDeviceById(deviceId);
      if (DEVICE !== undefined) {
        DEVICE.videoCapabilities = capabilities;
      }
    }
  }

  /**
   * Function to open media stream
   *
   * @param slot Stream slot index
   * @returns Promise<boolean>
   */
  protected async openMedia(slot: number): Promise<boolean> {
    let l_Ret: boolean = true;
    if (this.streamSlots[slot] !== undefined) {
      if (this.streamSlots[slot].streamId === -1) {
        //console.log('[PublishStreamService] openMedia - before Request Stream: interfaceStatus: ' + JSON.stringify(this.avProd.interfaceStatus));
        //console.log('[PublishStreamService] openMedia - before Request Stream: clientStatus: ' + JSON.stringify(this.avProd.clientStatus));
        this.avProd.azzCmdInterfaceRequestMediaStreamId(slot + 1, this.streamSlots[slot].id, this.streamSlots[slot].streamIdPrev);
      }
      // First close and open video stream
      if (this.streamSlots[slot].sourceType !== AvProdPublishSourceType.device){
        this.removeStreamTracks(this.streamSlots[slot].publishStream, 'video');
        this.closeStream(this.streamSlots[slot].videoStream);
        this.clearCanvas(slot);
        this.streamSlots[slot].videoStream = undefined;
      }
      if ((this.streamSlots[slot].settings.videoDevice !== undefined) &&
        (this.streamSlots[slot].videoElement !== undefined) &&
        (this.streamSlots[slot].settings.videoEnabled)) {
        let l_Tries: number = 4;  // Try up to 4 times to open. Need to add this because Settings component may still have the device open.
        l_Ret = false;
        while((l_Ret === false)&&(l_Tries > 0)){
          l_Tries--;
          console.log('[PublishStreamService] openMedia Tries:' + l_Tries);
          try {
            if (this.streamSlots[slot].sourceType === AvProdPublishSourceType.device) {

              const TRACK_IMAGE_CONSTRAINTS: any = {
                zoom: this.streamSlots[slot].settings.videoZoom
              };
              const TRACK_VIDEO_CONSTRAINTS: any = {
                width: {ideal: this.streamSlots[slot].settings.videoResolution.width},
                height: {ideal: this.streamSlots[slot].settings.videoResolution.height},
                frameRate: {ideal: 25},
              };
              const VIDEO_CONSTRAINTS: any = {
                width: {ideal: this.streamSlots[slot].settings.videoResolution.width},
                height: {ideal: this.streamSlots[slot].settings.videoResolution.height},
                frameRate: {ideal: 25},
                zoom: this.streamSlots[slot].settings.videoZoom
              };
              if (this.streamSlots[slot].settings.facingMode !== undefined) {
                VIDEO_CONSTRAINTS.facingMode = this.streamSlots[slot].settings.facingMode;
                TRACK_VIDEO_CONSTRAINTS.facingMode = VIDEO_CONSTRAINTS.facingMode;
              } else {
                VIDEO_CONSTRAINTS.deviceId = {exact: this.streamSlots[slot].settings.videoDevice?.deviceId};
                TRACK_VIDEO_CONSTRAINTS.deviceId = VIDEO_CONSTRAINTS.deviceId;
              }

              const CONSTRAINTS: any = {
                audio: false,
                video: VIDEO_CONSTRAINTS
              }
              const VIDEO_TRACK: MediaStreamTrack | undefined = this.streamSlots[slot].videoStream?.getVideoTracks()[0];
              if ((VIDEO_TRACK !== undefined)&&(VIDEO_TRACK.getSettings().deviceId === this.streamSlots[slot].settings.videoDevice?.deviceId)){
                console.log('[PublishStreamService] openMedia TRACK setConstraints: ' + JSON.stringify(TRACK_VIDEO_CONSTRAINTS));
                console.log('[PublishStreamService] openMedia TRACK settings: ' + JSON.stringify(VIDEO_TRACK.getSettings()));
                console.log('[PublishStreamService] openMedia TRACK constraints: ' + JSON.stringify(VIDEO_TRACK.getConstraints()));
                await VIDEO_TRACK.applyConstraints(TRACK_VIDEO_CONSTRAINTS).catch(err => console.error(err));
                await VIDEO_TRACK.applyConstraints(TRACK_IMAGE_CONSTRAINTS).catch(err => console.error(err));
                console.log('[PublishStreamService] openMedia TRACK settings 2: ' + JSON.stringify(VIDEO_TRACK.getSettings()));
                console.log('[PublishStreamService] openMedia TRACK constraints 2: ' + JSON.stringify(VIDEO_TRACK.getConstraints()));
              }
              else{
                this.removeStreamTracks(this.streamSlots[slot].publishStream, 'video');
                this.closeStream(this.streamSlots[slot].videoStream);
                this.clearCanvas(slot);
                this.streamSlots[slot].videoStream = undefined;
                console.log('[PublishStreamService] : openMedia Video ' + JSON.stringify(CONSTRAINTS));
                this.streamSlots[slot].videoStream = await navigator.mediaDevices.getUserMedia(CONSTRAINTS);
              }

            } else if (this.streamSlots[slot].sourceType === AvProdPublishSourceType.screen) {
              const CONSTRAINTS: any = {
                audio: {
                  echoCancellation: false,
                  autoGainControl: false,
                  noiseSuppression: false,
                },
                video: true
              }
              this.streamSlots[slot].videoStream = await navigator.mediaDevices.getDisplayMedia(CONSTRAINTS);
              console.log('[PublishStreamService] : openMedia Screen ' + JSON.stringify(CONSTRAINTS));
              console.log('[PublishStreamService] : openMedia Screen videoStream ' + JSON.stringify(this.streamSlots[slot].videoStream));
              // Temporary: Disable audio for screen broadcast
              this.streamSlots[slot].settings.audioEnabled = true;
            }

            console.log('[PublishStreamService] openMedia ' + this.streamSlots[slot].videoStream);
            if (this.streamSlots[slot].videoStream !== undefined) {
              this.streamSlots[slot].videoStream!.getVideoTracks()[0].onended = (event) => this.handleVideoStreamTrackEnded(slot, event);
              const TMP_STREAM: MediaStream = this.streamSlots[slot].videoStream!;   // Joaquin: Repasar para intentar quitar el !
              this.streamSlots[slot].videoElement!.nativeElement.srcObject = TMP_STREAM;   // Joaquin: Repasar para intentar quitar el !
              const TRACKS: MediaStreamTrack[] = TMP_STREAM.getVideoTracks();
              if ((TRACKS.length > 0) && (TRACKS[0].kind === 'video')) {
                this.streamSlots[slot].settingsVideoActual = TRACKS[0].getSettings();

                if ((this.streamSlots[slot].settingsVideoActual?.height !== undefined) &&
                    (this.streamSlots[slot].settingsVideoActual?.height !== this.streamSlots[slot].settings.videoResolution.height)){

                  const NEW_RES: IVideoResolution | undefined = PUBLISH_STREAM_RESOLUTIONS.find(
                    (element: IVideoResolution) => element.height === this.streamSlots[slot].settingsVideoActual?.height);
                  if (NEW_RES !== undefined) {
                    this.streamSlots[slot].settings.videoResolution = NEW_RES;
                  }
                }

                if (this.streamSlots[slot].settings.videoUseCanvas) {
                  if ((this.streamSlots[slot].settingsVideoActual?.width !== undefined) && (this.streamSlots[slot].settingsVideoActual?.height !== undefined)) {
                    if (this.streamSlots[slot].canvasOrigElement !== undefined) {
                      this.streamSlots[slot].canvasOrigElement!.nativeElement.width = this.streamSlots[slot].settings.videoResolution.width;   // Joaquin: Repasar para intentar quitar el !
                      this.streamSlots[slot].canvasOrigElement!.nativeElement.height = this.streamSlots[slot].settings.videoResolution.height;   // Joaquin: Repasar para intentar quitar el !
                      l_Ret = true;
                    }
                  }
                } else {
                  l_Ret = true;
                }
                console.log('[PublishStreamService] (openMedia) Video Stream Settings: ' + JSON.stringify(this.streamSlots[slot].settingsVideoActual));
                this.setDeviceVideoCapabilities(this.streamSlots[slot].settings.videoDevice?.deviceId, TRACKS[0].getCapabilities());
              } else {
                this.streamSlots[slot].videoCapabilities = undefined;
              }
            }
          } catch (error) {
            console.log('[PublishStreamService] : openMedia Video Error ' + error);
            l_Ret = false;
          }
        }
      }
      else{
        this.removeStreamTracks(this.streamSlots[slot].publishStream, 'video');
        this.closeStream(this.streamSlots[slot].videoStream);
        this.streamSlots[slot].videoStream = undefined;
      }

      if (l_Ret) {
        // Open audio stream
        if (this.streamSlots[slot].sourceType === AvProdPublishSourceType.screen) {
          this.closeStream(this.streamSlots[slot].audioStream);
          if (this.streamSlots[slot].videoStream !== undefined) {
            const TMP_STREAM: MediaStream = this.streamSlots[slot].videoStream!;   // Joaquin: Repasar para intentar quitar el !
            if (TMP_STREAM.getAudioTracks().length > 0) {
              this.streamSlots[slot].audioStream = TMP_STREAM.clone();
              this.removeStreamTracks(this.streamSlots[slot].audioStream, 'video');
              this.removeStreamTracks(this.streamSlots[slot].videoStream, 'audio');
            }
            else {
              this.streamSlots[slot].settings.audioEnabled = false;
            }
            const TRACKS: MediaStreamTrack[] = TMP_STREAM.getVideoTracks();
          }
          else {
            this.removeStreamTracks(this.streamSlots[slot].publishStream, 'audio');
            this.streamSlots[slot].settings.audioEnabled = false;
          }
        }
        else {
          if (!this.streamSlots[slot].settings.audioEnabled) {
            this.removeStreamTracks(this.streamSlots[slot].publishStream, 'audio');
            this.closeStream(this.streamSlots[slot].audioStream);
          } else if (this.streamSlots[slot].settings.audioInputDevice !== undefined) {
            l_Ret = false;
            try {
              const CONSTRAINTS: any = {
                video: false,
                audio: {
                  deviceId: this.streamSlots[slot].settings.audioInputDevice?.deviceId,
                  echoCancellation: this.streamSlots[slot].settings.audioEchoCancellation,
                  autoGainControl: this.streamSlots[slot].settings.audioAutoGainControl,
                  noiseSuppression: this.streamSlots[slot].settings.audioNoiseSuppression,
                  volume: 1.0
                  /* Other parameters: latency, channelCount, volume, autoGainControl */
                }
              }
              console.log('[PublishStreamService] (openMedia) Audio Stream New Settings: ' + JSON.stringify(CONSTRAINTS.audio));
              if ((this.streamSlots[slot].audioStream?.getAudioTracks()[0] !== undefined)&&
                  (this.streamSlots[slot].audioStream?.getAudioTracks()[0].getSettings().deviceId === CONSTRAINTS.deviceId)){
                this.streamSlots[slot].audioStream?.getAudioTracks()[0].applyConstraints(CONSTRAINTS.audio);
                console.log('[PublishStreamService] (openMedia) Audio Stream Apply Settings');
              }
              else{
                this.removeStreamTracks(this.streamSlots[slot].publishStream, 'audio');
                this.closeStream(this.streamSlots[slot].audioStream);
                this.streamSlots[slot].audioStream = await navigator.mediaDevices.getUserMedia(CONSTRAINTS);
                console.log('[PublishStreamService] (openMedia) Audio Stream Open Device');
              }
              if (this.streamSlots[slot].audioStream !== undefined) {
                if (this.streamSlots[slot].audioStream!.getAudioTracks().length > 0) {   // Joaquin: Ojo, intentar poder quitar el !
                  this.streamSlots[slot].audioStream!.getAudioTracks()[0].enabled = !this.streamSlots[slot].muted;
                  this.streamSlots[slot].settingsAudioActual = this.streamSlots[slot].audioStream!.getAudioTracks()[0].getSettings();
                  console.log('[PublishStreamService] (openMedia) Audio Stream Settings: ' + JSON.stringify(this.streamSlots[slot].settingsAudioActual));
                  // Update actual settings
                  if (this.streamSlots[slot].settingsAudioActual?.autoGainControl === true){
                    this.streamSlots[slot].settings.audioAutoGainControl = true;
                  }
                  else if (this.streamSlots[slot].settingsAudioActual?.autoGainControl === false){
                    this.streamSlots[slot].settings.audioAutoGainControl = false;
                  }
                  if (this.streamSlots[slot].settingsAudioActual?.noiseSuppression === true){
                    this.streamSlots[slot].settings.audioNoiseSuppression = true;
                  }
                  else if (this.streamSlots[slot].settingsAudioActual?.noiseSuppression === false){
                    this.streamSlots[slot].settings.audioNoiseSuppression = false;
                  }
                  if (this.streamSlots[slot].settingsAudioActual?.echoCancellation === true){
                    this.streamSlots[slot].settings.audioEchoCancellation = true;
                  }
                  else if (this.streamSlots[slot].settingsAudioActual?.echoCancellation === false){
                    this.streamSlots[slot].settings.audioEchoCancellation = false;
                  }
                }
                this.streamSlots[slot].audioStream!.getTracks().forEach(function (track) {
                  const SETTINGS: MediaTrackSettings = track.getSettings();
                  console.log('[PublishStreamService] (openMedia) Audio Stream Settings: ' + SETTINGS.deviceId + ' ' + track.kind + ' ' + JSON.stringify(SETTINGS));
                })
                l_Ret = true;
              }
            } catch (error) {
              console.log('[PublishStreamService] : openMedia Audio Error ' + error);
              l_Ret = false;
            }
          }
        }
      }

      if (l_Ret) {
        this.streamSlots[slot].canvasRefreshSubscription?.unsubscribe();
        if (this.streamSlots[slot].settings.videoUseCanvas) {
          this.streamSlots[slot].canvasRefreshSubscription = interval(40).subscribe(() => this.tickCanvasFrameUpdate(slot));
        }
        this.streamSlots[slot].mediaOpen = true;
        this.streamSlots[slot].publishing = false;
        this.streamSlots[slot].publishingCommanded = true;
        if (this.streamSlots[slot].publishCheckSubscription !== undefined) this.streamSlots[slot].publishCheckSubscription!.unsubscribe();
        this.streamSlots[slot].publishCheckSubscription = timer(100, 1000).subscribe(() => {
          this.tickPublishCheck(slot)
        });
      } else {
        this.closeMedia(slot);
        if (this.streamSlots[slot].sourceType === AvProdPublishSourceType.screen) {
          this.setSlotActive(this.streamSlots[slot].id, false);
        }
      }

    }
    return l_Ret;
  }

  /**
   * Function to close media stream
   *
   * @param slot Stream slot index
   */
  protected closeMedia(slot: number): void {
    if (this.streamSlots[slot] !== undefined) {
      if (this.streamSlots[slot].canvasRefreshSubscription !== undefined) {
        this.streamSlots[slot].canvasRefreshSubscription!.unsubscribe();   // Joaquin: Repasar para intentar quitar el !
      }
      console.log('[PublishStreamService] : closeMedia');
      //this.streamSlots[slot].publishingCommanded = false;
      //if (this.streamSlots[slot].publishCheckSubscription !== undefined) this.streamSlots[slot].publishCheckSubscription!.unsubscribe();
      //this.stopMediaPublish(slot);
      this.closeStream(this.streamSlots[slot].videoStream);
      this.closeStream(this.streamSlots[slot].audioStream);
      this.closeStream(this.streamSlots[slot].publishStream);
      //this.closeStream(this.streamSlots[slot].videoCanvasStream);
      this.streamSlots[slot].videoStream = undefined;
      this.streamSlots[slot].audioStream = undefined;
      this.streamSlots[slot].publishStream = undefined;
      //this.streamSlots[slot].videoCanvasStream = undefined;
      this.streamSlots[slot].mediaOpen = false;
    }
  }

  /**
   * Function to close a specific media stream
   *
   * @param stream Media stream object to be stopped
   * @returns boolean
   */
  protected closeStream(stream: MediaStream | undefined): boolean {
    let l_Ret: boolean = false;
    if (stream !== undefined) {
      try {
        let list: MediaStreamTrack[] = stream.getTracks();
        for (let i: number = 0; i<list.length; i++){
          list[i].stop();
          stream.removeTrack(list[i]);
        }
        l_Ret = true;
      } catch (e) {
        l_Ret = false;
      }
    }
    return l_Ret;
  }

  /**
   * Function to close a specific media stream
   *
   * @param stream Media stream object to be stopped
   * @param kind Track kind to be removed (video or audio)
   * @returns boolean
   */
  protected removeStreamTracks(stream: MediaStream | undefined, kind: string): boolean {
    let l_Ret: boolean = false;
    if (stream !== undefined) {
      try {
        let list: MediaStreamTrack[] = stream.getTracks();
        for (let i: number = 0; i<list.length; i++){
          if (list[i].kind === kind){
            list[i].stop();
            stream.removeTrack(list[i]);
          }
        }
        l_Ret = true;
      } catch (e) {
        l_Ret = false;
      }
    }
    return l_Ret;
  }

  /**
   * Function to start media stream transmission
   * @param slot Media stream slot index
   * @returns Promise<boolean>
   */
  protected async startMediaPublish(slot: number): Promise<boolean> {
    if ((this.streamSlots[slot] !== undefined) &&
        (this.streamSlots[slot].active === true)) {

      // Stop publication if still on
      if (this.streamSlots[slot].publishing) {
        this.stopMediaPublish(slot);
        try {
          this.streamSlots[slot].publishStream?.getTracks().forEach((track: MediaStreamTrack) => {
            this.streamSlots[slot].publishStream?.removeTrack(track);
          });
        } catch (e) {
          console.log('[PublishStreamService] startMediaPublish. Error closing publishStream');
        }
        this.streamSlots[slot].publishStream = undefined;
      }

      // Open media device if it is closed
      if (!this.streamSlots[slot].mediaOpen) {
        await this.openMedia(slot);
      }

      // If media device is open, start publication
      //if (this.streamSlots[slot].mediaOpen && !this.streamSlots[slot].publishing) {
      if (this.streamSlots[slot].mediaOpen) {

        // Clear Publish stream
        this.closeStream(this.streamSlots[slot].videoCanvasStream);
        this.streamSlots[slot].videoCanvasStream = undefined;
        this.streamSlots[slot].publishStream = new MediaStream();

        // Prepare video stream
        if ((this.streamSlots[slot].settings.videoDevice !== undefined) && this.streamSlots[slot].settings.videoEnabled) {
          try {
            if (this.streamSlots[slot].settings.videoUseCanvas) {
              if (this.streamSlots[slot].canvasOrigElement !== undefined) {
                if ((this.streamSlots[slot].canvasOrigElement!.nativeElement.width !== this.streamSlots[slot].settings.videoResolution.width) ||
                  (this.streamSlots[slot].canvasOrigElement!.nativeElement.height !== this.streamSlots[slot].settings.videoResolution.height)) {
                  this.streamSlots[slot].canvasOrigElement!.nativeElement.width = this.streamSlots[slot].settings.videoResolution.width;   // Joaquin: Repasar para intentar quitar el !
                  this.streamSlots[slot].canvasOrigElement!.nativeElement.height = this.streamSlots[slot].settings.videoResolution.height;   // Joaquin: Repasar para intentar quitar el !
                  this.streamSlots[slot].videoCanvasStream = this.streamSlots[slot].canvasOrigElement?.nativeElement.captureStream(25);
                }
              }
              if (this.streamSlots[slot].videoCanvasStream === undefined) {
                this.streamSlots[slot].videoCanvasStream = this.streamSlots[slot].canvasOrigElement?.nativeElement.captureStream(25);
              }
              if ((this.streamSlots[slot].videoCanvasStream !== undefined) &&
                (this.streamSlots[slot].publishStream !== undefined)) {
                this.streamSlots[slot].videoCanvasStream!.getTracks().forEach(track => this.streamSlots[slot].publishStream!.addTrack(track));   // Joaquin: Repasar para intentar quitar el !
                console.log('[PublishStreamService] StartMediaPublish Video publish tracks: ' + JSON.stringify(this.streamSlots[slot].videoCanvasStream!.getTracks()));
              } else {
                console.log('[PublishStreamService] StartMediaPublish Video publish error');
              }
            } else {
              // Use camera video stream directly
              if ((this.streamSlots[slot].videoStream !== undefined) &&
                (this.streamSlots[slot].publishStream !== undefined)) {
                this.streamSlots[slot].videoStream!.getTracks().forEach(track => this.streamSlots[slot].publishStream!.addTrack(track));   // Joaquin: Repasar para intentar quitar el !
              } else {
                console.log('[PublishStreamService] StartMediaPublish Video publish error');
              }

            }

          } catch (e) {
            console.error('[PublishStreamService] StartMediaPublish Video publish error: ' + e);
          }
        }

        // Prepare audio stream
        if ((this.streamSlots[slot].settings.audioInputDevice !== undefined) && this.streamSlots[slot].settings.audioEnabled) {
          if (this.streamSlots[slot].audioStream !== undefined) {
            this.streamSlots[slot].audioStream!.getTracks().forEach(track => this.streamSlots[slot].publishStream!.addTrack(track));   // Joaquin: Repasar para intentar quitar el !
          }
        }

        if (this.streamSlots[slot].publishStream !== undefined) {
          if (this.streamSlots[slot].publishStream!.getTracks().length > 0) {   // Joaquin: Repasar para intentar quitar el !
            this.avProd.webRtcConnectionPing(AvProdWebRtcConnectionType.broadcaster, AvProdDeviceType.input, this.streamSlots[slot].streamId - 1, this.streamSlots[slot].publishStream, false)
            this.streamSlots[slot].publishing = true;
          }
        }

        if (this.streamSlots[slot].publishing) {
          if (this.streamSlots[slot].streamId !== -1) {
            const NEW_NAME: IAvProdInputSettings = {
              name: this.streamSlots[slot].settings.streamName
            };
            this.avProd.azzChangeInputSettings(this.streamSlots[slot].streamId - 1, NEW_NAME);
            // Change message order now. PublishStart cmd is now the message to start the process
            //this.avProd.azzCmdInterfacePublishStart(this.streamSlots[slot].streamId, 2, slot + 1);
          }
          //this.statDataReconnections++;
        }
        //this.updatePublishClientSettings(slot);
      }
    }
    return this.streamSlots[slot].publishing;
  }

  /**
   * Function to stop media stream transmission
   * @param slot Media stream slot index
   * @returns Promise<boolean>
   */
  protected stopMediaPublish(slot: number): void {
    console.log('[PublishStreamService] stopMediaPublish');
    if (this.streamSlots[slot] !== undefined) {
      if ((this.streamSlots[slot].streamId !== -1) && this.streamSlots[slot].publishing) {
        console.log('[PublishStreamService] Release MediaStreamId ' + this.streamSlots[slot].streamId + ' Slot:' + (slot + 1));
        this.avProd.azzCmdInterfacePublishEnd(this.streamSlots[slot].streamId, slot + 1);
        this.avProd.webRtcConnectionDelete(AvProdWebRtcConnectionType.broadcaster, AvProdDeviceType.input, this.streamSlots[slot].streamId - 1)
          .catch(console.error);
      }
      this.streamSlots[slot].streamId = -1;
      this.streamSlots[slot].publishing = false;
      this.streamSlots[slot].lastPublicationTimestamp = (new Date()).getTime();
    }
  }

  protected clearCanvas(slot: number): void {
    if (this.streamSlots[slot].settings.videoUseCanvas &&
      (this.streamSlots[slot].canvasOrigElement !== undefined) &&
      (this.streamSlots[slot].videoElement !== undefined)) {    // Joaquin: Revisar para intentar quitar los ! en todo el bloque
      const CONTEXT: CanvasRenderingContext2D | null = this.streamSlots[slot].canvasOrigElement!.nativeElement.getContext('2d');
      if (CONTEXT !== null) {
        CONTEXT.fillRect(
          0,
          0,
          this.streamSlots[slot].canvasOrigElement!.nativeElement.width,
          this.streamSlots[slot].canvasOrigElement!.nativeElement.height
        );
      }
    }
  }

  /**
   * Function called when videoStream track is removed
   *
   * @param slot Media stream slot index
   */
  protected handleVideoStreamTrackEnded(slot: number, event: any){
    console.log('[PublishStreamService] handleVideoStreamTrackEnded **** ' + JSON.stringify(event));
    if (event.type === 'ended'){
      if (this.streamSlots[slot] !== undefined) {
        if (this.streamSlots[slot].sourceType === AvProdPublishSourceType.screen) {
          console.log('[PublishStreamService] handleVideoStreamTrackEnded SCREEN SHARE STOP');
          this.setSlotActive(this.streamSlots[slot].id, false);
        }
      }
    }
  }

  /**
   * Function called periodically to update canvas image
   *
   * @param slot Media stream slot index
   */
  protected tickCanvasFrameUpdate(slot: number): void {
    if (this.streamSlots[slot].settings.videoUseCanvas &&
      (this.streamSlots[slot].canvasOrigElement !== undefined) &&
      (this.streamSlots[slot].videoElement !== undefined)) {    // Joaquin: Revisar para intentar quitar los ! en todo el bloque
      const CONTEXT: CanvasRenderingContext2D | null = this.streamSlots[slot].canvasOrigElement!.nativeElement.getContext('2d');
      if (CONTEXT !== null) {
        const TIME_NOW: Date = new Date();
        const AR_ORIG: number = this.streamSlots[slot].videoElement!.nativeElement.videoWidth / this.streamSlots[slot].videoElement!.nativeElement.videoHeight;
        const AR_OUT: number = this.streamSlots[slot].canvasOrigElement!.nativeElement.width / this.streamSlots[slot].canvasOrigElement!.nativeElement.height;
        let origWidth: number;
        let origHeight: number;
        if (AR_ORIG >= AR_OUT) {
          origHeight = this.streamSlots[slot].videoElement!.nativeElement.videoHeight / this.streamSlots[slot].camZoom;
          origWidth = origHeight * AR_OUT;
        } else {
          origWidth = this.streamSlots[slot].videoElement!.nativeElement.videoWidth / this.streamSlots[slot].camZoom;
          origHeight = origWidth / AR_OUT;
        }
        const CENTER_OFFSET_X: number = (1.0 - (origWidth / this.streamSlots[slot].videoElement!.nativeElement.videoWidth)) / 2;
        const CENTER_OFFSET_Y: number = (1.0 - (origHeight / this.streamSlots[slot].videoElement!.nativeElement.videoHeight)) / 2;
        const ORIG_X: number = this.streamSlots[slot].videoElement!.nativeElement.videoWidth * (CENTER_OFFSET_X + this.streamSlots[slot].camZoomOffsetX);
        const ORIG_Y: number = this.streamSlots[slot].videoElement!.nativeElement.videoHeight * (CENTER_OFFSET_Y + this.streamSlots[slot].camZoomOffsetY);

        CONTEXT.font = '50px sans-serif';
        CONTEXT.drawImage(
          this.streamSlots[slot].videoElement!.nativeElement,
          ORIG_X,
          ORIG_Y,
          origWidth,
          origHeight,
          0,
          0,
          this.streamSlots[slot].canvasOrigElement!.nativeElement.width,
          this.streamSlots[slot].canvasOrigElement!.nativeElement.height);

        const TIME: string = this.getTime(TIME_NOW);

        CONTEXT.fillStyle = 'black';
        CONTEXT.fillText(TIME, 10, 50);
        CONTEXT.fillStyle = 'white';
        CONTEXT.fillText(TIME, 12, 52);

      }
    }
  }

  private getTime(d: Date): string {
    return this.pad(d.getDate(), 2) + '/' + this.pad(d.getMonth() + 1, 2) + '/' + this.pad(d.getFullYear() - 2000, 2) + ' ' + this.pad(d.getHours(), 2) + ':' + this.pad(d.getMinutes(), 2) + ':' + this.pad(d.getSeconds(), 2) + '.' + this.pad(d.getMilliseconds(), 3);
  }

  private pad(num: number, size: number): string {
    let str: string = num.toString();
    while (str.length < size) str = '0' + str;
    return str;
  }

  /**
   * Function to modify zoom settings
   *
   * @param slot Media stream slot index
   * @param incZoom Zoom increment
   * @param incX X Offset increment
   * @param incY Y Offset increment
   */
  protected canvasZoomIncrements(slot: number, incZoom: number, incX: number, incY: number): void {
    if (this.streamSlots[slot] !== undefined) {
      this.streamSlots[slot].camZoom += incZoom;
      this.streamSlots[slot].camZoomOffsetX += incX;
      this.streamSlots[slot].camZoomOffsetY += incY;

      if (this.streamSlots[slot].camZoom < 1.0) this.streamSlots[slot].camZoom = 1.0;
      else if (this.streamSlots[slot].camZoom > 4.0) this.streamSlots[slot].camZoom = 4.0;

      const MAX_OFFSET: number = (1.0 - (1.0 / this.streamSlots[slot].camZoom)) / 2;

      if (this.streamSlots[slot].camZoomOffsetX < -MAX_OFFSET) this.streamSlots[slot].camZoomOffsetX = -MAX_OFFSET;
      else if (this.streamSlots[slot].camZoomOffsetX > MAX_OFFSET) this.streamSlots[slot].camZoomOffsetX = MAX_OFFSET;

      if (this.streamSlots[slot].camZoomOffsetY < -MAX_OFFSET) this.streamSlots[slot].camZoomOffsetY = -MAX_OFFSET;
      else if (this.streamSlots[slot].camZoomOffsetY > MAX_OFFSET) this.streamSlots[slot].camZoomOffsetY = MAX_OFFSET;
    }
  }

  /**
   * Function called periodically to check publish command and status
   *
   * @param slot Media stream slot index
   */
  protected tickPublishCheck(slot: number): void {
    let status: AvProdPublishCommsStatus | undefined;
    if (this.streamSlots[slot] !== undefined) {
      if (this.streamSlots[slot].active === false) {
        if (this.streamSlots[slot].publishCheckSubscription !== undefined) this.streamSlots[slot].publishCheckSubscription!.unsubscribe();
        if (this.streamSlots[slot].publishing === true) {
          this.stopMediaPublish(slot);
        }
        this.closeMedia(slot);
      } else {
        if (this.streamSlots[slot].publishingCommanded) {
          status = AvProdPublishCommsStatus.connecting;
          if (!this.avProd.commsStatus.ok) {
            status = AvProdPublishCommsStatus.error;
            if (this.streamSlots[slot].publishing) {
              this.stopMediaPublish(slot);
            }
          } else if (!this.streamSlots[slot].publishing) {
            if (this.streamSlots[slot].streamId == -1) {
              // Request media stream Id from avProducer server
              //console.log('[PublishStreamService] tickPublishCheck - before Request Stream: interfaceStatus: ' + JSON.stringify(this.avProd.interfaceStatus));
              //console.log('[PublishStreamService] tickPublishCheck - before Request Stream: clientStatus: ' + JSON.stringify(this.avProd.clientStatus));
              this.avProd.azzCmdInterfaceRequestMediaStreamId(slot + 1, this.streamSlots[slot].id, this.streamSlots[slot].streamIdPrev);
            } else if ((new Date()).getTime() - this.streamSlots[slot].lastPublicationTimestamp > 4000) {
              // Wait 4 seconds after any previous publication try
              this.avProd.azzCmdInterfacePublishStart(this.streamSlots[slot].streamId, 2, slot + 1);
              // this.streamSlots[slot].busy = true;
              // this.streamSlots[slot].lastPublicationTimestamp = (new Date()).getTime();
              // this.startMediaPublish(slot).then(() => {
              //   this.streamSlots[slot].busy = false;
              // });
            }
          } else if (this.streamSlots[slot].publishing) {
            if (this.streamSlots[slot].streamId !== -1) {
              this.avProd.webRtcConnectionPing(AvProdWebRtcConnectionType.broadcaster, AvProdDeviceType.input, this.streamSlots[slot].streamId - 1, this.streamSlots[slot].publishStream, false)
              const INPUT: IAvProdInput | undefined = this.avProd.inputs.find(element => (element.info.inputTypeNumber === AvProdInputTypeNumber.videoAudioStream) && (element.info.id === this.streamSlots[slot].streamId - 1));
              if (INPUT?.status?.playingState === AvProdInputPlayingState.playing) {
                status = AvProdPublishCommsStatus.ok;
              } else if ((new Date()).getTime() - this.streamSlots[slot].lastPublicationTimestamp > 8000) {
                // Reset publication and try again
                this.stopMediaPublish(slot);
                status = AvProdPublishCommsStatus.error;
              }
            } else {
              this.stopMediaPublish(slot);
            }
          }
        } else {
          if (this.streamSlots[slot].publishCheckSubscription !== undefined) this.streamSlots[slot].publishCheckSubscription!.unsubscribe();
          this.stopMediaPublish(slot);
        }
      }
      if (this.streamSlots[slot].commsStatus !== status) {
        this.streamSlots[slot].commsStatus = status;
        this.slotChangeSource.next(this.streamSlots[slot].id);
      }
      //console.log('[PublishStreamService] tickPublishCheck: ' + this.streamSlots[slot].id + '(' + this.streamSlots[slot].streamId + ') ' + this.streamSlots[slot].commsStatus);
    }
  }

  protected resetSlot(id: string): void {
    const SLOT: number = this.getSlotIndex(id);
    if (this.streamSlots[SLOT] !== undefined) {
      let defaultName: string = PUBLISH_STREAM_DEFAULT_SETTINGS.streamName;
      if (this.streamSlots[SLOT].sourceType === AvProdPublishSourceType.screen) {
        defaultName = this.translate.instant('general.screenShare');
      } else {
        if (this.userSettingsSameEvent && this.userSettings?.streamName !== undefined){
          defaultName = this.userSettings?.streamName;
        }
        else if (this.userService.user.name !== undefined) {
          defaultName = this.userService.user.name;
        }
      }
      console.log('PublishStreamService resetSlot: ', id);
      this.stopMediaPublish(SLOT);
      this.streamSlots[SLOT].publishCheckSubscription?.unsubscribe();
      this.streamSlots[SLOT].canvasRefreshSubscription?.unsubscribe();
      this.closeMedia(SLOT);

      this.streamSlots[SLOT].active = false;
      this.streamSlots[SLOT].streamId = -1;
      this.streamSlots[SLOT].streamIdPrev = -1;
      this.streamSlots[SLOT].busy = false;
      this.streamSlots[SLOT].initialized = false;
      this.streamSlots[SLOT].settings = {
        allowRemote: this.userSettings?.allowRemote ?? PUBLISH_STREAM_DEFAULT_SETTINGS.allowRemote,
        streamName: defaultName,
        audioEnabled: this.userSettings?.audioEnabled ?? PUBLISH_STREAM_DEFAULT_SETTINGS.audioEnabled,
        audioInputDevice: this.userSettings?.audioInputDevice ?? PUBLISH_STREAM_DEFAULT_SETTINGS.audioInputDevice,
        audioOutputDevice: this.userSettings?.audioOutputDevice ?? PUBLISH_STREAM_DEFAULT_SETTINGS.audioOutputDevice,
        audioBitRate: this.userSettings?.audioBitRate ?? PUBLISH_STREAM_DEFAULT_SETTINGS.audioBitRate,
        audioEchoCancellation: this.userSettings?.audioEchoCancellation ?? PUBLISH_STREAM_DEFAULT_SETTINGS.audioEchoCancellation,
        audioNoiseSuppression: this.userSettings?.audioNoiseSuppression ?? PUBLISH_STREAM_DEFAULT_SETTINGS.audioNoiseSuppression,
        audioAutoGainControl: this.userSettings?.audioAutoGainControl ?? PUBLISH_STREAM_DEFAULT_SETTINGS.audioAutoGainControl,
        videoEnabled: this.userSettings?.videoEnabled ?? PUBLISH_STREAM_DEFAULT_SETTINGS.videoEnabled,
        videoDevice: this.userSettings?.videoDevice ?? PUBLISH_STREAM_DEFAULT_SETTINGS.videoDevice,
        videoBitRate: this.userSettings?.videoBitRate ?? PUBLISH_STREAM_DEFAULT_SETTINGS.videoBitRate,
        videoResolution: this.userSettings?.videoResolution ?? PUBLISH_STREAM_DEFAULT_RESOLUTION,
        videoMirror: this.userSettings?.videoMirror ?? PUBLISH_STREAM_DEFAULT_SETTINGS.videoMirror,
        videoUseCanvas: this.userSettings?.videoUseCanvas ?? PUBLISH_STREAM_DEFAULT_SETTINGS.videoUseCanvas,
        videoZoom: (this.userSettingsSameEvent && this.userSettings?.videoZoom !== undefined)? this.userSettings?.videoZoom : PUBLISH_STREAM_DEFAULT_SETTINGS.videoZoom,
        savingModeSeconds: this.userSettings?.savingModeSeconds ?? PUBLISH_STREAM_DEFAULT_SETTINGS.savingModeSeconds,
        facingMode: PUBLISH_STREAM_DEFAULT_SETTINGS.facingMode,
      };
      this.streamSlots[SLOT].mediaOpen = false;
      this.streamSlots[SLOT].publishing = false;
      this.streamSlots[SLOT].publishingCommanded = false;
      this.streamSlots[SLOT].lastPublicationTimestamp = 0;
      this.streamSlots[SLOT].publishCheckSubscription = undefined;
      this.streamSlots[SLOT].videoStream = undefined;
      this.streamSlots[SLOT].videoElement = undefined;
      this.streamSlots[SLOT].canvasOrigElement = undefined;
      this.streamSlots[SLOT].canvasRefreshSubscription = undefined;
      this.streamSlots[SLOT].videoCanvasStream = undefined;
      this.streamSlots[SLOT].audioStream = undefined;
      this.streamSlots[SLOT].publishStream = undefined;
      this.streamSlots[SLOT].muted = false;
      this.streamSlots[SLOT].camZoomOffsetX = 0;
      this.streamSlots[SLOT].camZoomOffsetY = 0;
      this.streamSlots[SLOT].camZoom = 1.0;
    }
  }

  /**
   * Function to create a new Media stream slot
   * @param id Stream slot identifier
   * @param sourceType
   *
   * @returns Media stream slot index or -1 if error
   */
  public createStreamSlot(id: string, sourceType: AvProdPublishSourceType): number {
    // Delete before, in case it already exists
    this.deleteStreamSlot(id);
    let ret: number = -1;
    if (this.streamSlots.length < PUBLISH_STREAM_MAX_SLOTS) {
      let defaultName: string = PUBLISH_STREAM_DEFAULT_SETTINGS.streamName;
      if (sourceType === AvProdPublishSourceType.screen) {
        defaultName = this.translate.instant('general.screenShare');
      } else {
        if (this.userSettingsSameEvent && this.userSettings?.streamName !== undefined){
          defaultName = this.userSettings?.streamName;
        }
        else if (this.userService.user.name !== undefined) {
          defaultName = this.userService.user.name;
        }
      }
      const NEW_SLOT: IPublishStreamSlot = {
        id: id,
        sourceType: sourceType,
        active: false,
        streamId: -1,
        streamIdPrev: -1,
        busy: false,
        initialized: false,
        settings: {
          allowRemote: this.userSettings?.allowRemote ?? PUBLISH_STREAM_DEFAULT_SETTINGS.allowRemote,
          streamName: defaultName,
          audioEnabled: this.userSettings?.audioEnabled ?? PUBLISH_STREAM_DEFAULT_SETTINGS.audioEnabled,
          audioInputDevice: this.userSettings?.audioInputDevice ?? PUBLISH_STREAM_DEFAULT_SETTINGS.audioInputDevice,
          audioOutputDevice: this.userSettings?.audioOutputDevice ?? PUBLISH_STREAM_DEFAULT_SETTINGS.audioOutputDevice,
          audioBitRate: this.userSettings?.audioBitRate ?? PUBLISH_STREAM_DEFAULT_SETTINGS.audioBitRate,
          audioEchoCancellation: this.userSettings?.audioEchoCancellation ?? PUBLISH_STREAM_DEFAULT_SETTINGS.audioEchoCancellation,
          audioNoiseSuppression: this.userSettings?.audioNoiseSuppression ?? PUBLISH_STREAM_DEFAULT_SETTINGS.audioNoiseSuppression,
          audioAutoGainControl: this.userSettings?.audioAutoGainControl ?? PUBLISH_STREAM_DEFAULT_SETTINGS.audioAutoGainControl,
          videoEnabled: this.userSettings?.videoEnabled ?? PUBLISH_STREAM_DEFAULT_SETTINGS.videoEnabled,
          videoDevice: this.userSettings?.videoDevice ?? PUBLISH_STREAM_DEFAULT_SETTINGS.videoDevice,
          videoBitRate: this.userSettings?.videoBitRate ?? PUBLISH_STREAM_DEFAULT_SETTINGS.videoBitRate,
          videoResolution: this.userSettings?.videoResolution ?? PUBLISH_STREAM_DEFAULT_RESOLUTION,
          videoMirror: this.userSettings?.videoMirror ?? PUBLISH_STREAM_DEFAULT_SETTINGS.videoMirror,
          videoUseCanvas: this.userSettings?.videoUseCanvas ?? PUBLISH_STREAM_DEFAULT_SETTINGS.videoUseCanvas,
          videoZoom: (this.userSettingsSameEvent && this.userSettings?.videoZoom !== undefined)? this.userSettings?.videoZoom : PUBLISH_STREAM_DEFAULT_SETTINGS.videoZoom,
          savingModeSeconds: this.userSettings?.savingModeSeconds ?? PUBLISH_STREAM_DEFAULT_SETTINGS.savingModeSeconds,
          facingMode: PUBLISH_STREAM_DEFAULT_SETTINGS.facingMode,
        },
        mediaOpen: false,
        publishing: false,
        publishingCommanded: false,
        lastPublicationTimestamp: 0,
        publishCheckSubscription: undefined,
        videoStream: undefined,
        videoElement: undefined,
        canvasOrigElement: undefined,
        canvasRefreshSubscription: undefined,
        videoCanvasStream: undefined,
        audioStream: undefined,
        publishStream: undefined,
        muted: false,
        camZoomOffsetX: 0,
        camZoomOffsetY: 0,
        camZoom: 1.0,
      };

      //console.log('[PublishStreamService] createStreamSlot User Device: ' + JSON.stringify(this.userSettings?.videoDevice));
      //console.log('[PublishStreamService] createStreamSlot NEW Device: ' + JSON.stringify(NEW_SLOT.settings?.videoDevice));
      this.streamSlots.push(NEW_SLOT);
      if ((!this.userSettings)&&(sourceType !== AvProdPublishSourceType.screen)) {
        this.userSettings = NEW_SLOT.settings;
        this.setSettingsInLocalStorage(NEW_SLOT.settings);
      }
      // Improve identification adding some identifier field
      ret = this.streamSlots.length - 1;
      this.slotChangeSource.next(id);
    }

    return ret;
  }

  /**
   * Function to delete a media stream slot
   * @param id Media stream slot identifier
   * @returns
   */
  public deleteStreamSlot(id: string): void {
    const SLOT: number = this.getSlotIndex(id);
    if (this.streamSlots[SLOT] !== undefined) {
      console.log('PublishStreamService deleteStreamSlot: ', id);
      this.stopMediaPublish(SLOT);
      if (this.streamSlots[SLOT].publishCheckSubscription !== undefined) this.streamSlots[SLOT].publishCheckSubscription!.unsubscribe();
      if (this.streamSlots[SLOT].canvasRefreshSubscription !== undefined) this.streamSlots[SLOT].canvasRefreshSubscription!.unsubscribe();
      this.closeMedia(SLOT);

      this.streamSlots.splice(SLOT, 1);
      this.slotChangeSource.next(id);
    }
  }

  /**
   * Function to request media stream slot settings
   * @param id Media stream slot identifier
   * @returns
   */
  public getStreamSlotSettings(id: string): IPublisherSettings | null {
    const SLOT: number = this.getSlotIndex(id);
    if (this.streamSlots[SLOT] !== undefined) {
      return this.streamSlots[SLOT].settings;
    } else {
      return null;
    }
  }

  /**
   * Function to request media stream slot status
   * @param id Media stream slot identifier
   * @returns
   */
  public getStreamSlotStatus(id: string): AvProdPublishCommsStatus | undefined {
    const SLOT: number = this.getSlotIndex(id);
    if (this.streamSlots[SLOT] !== undefined) {
      return this.streamSlots[SLOT].commsStatus;
    } else {
      return undefined;
    }
  }

  /**
   * Function to request media stream slot status
   * @returns
   */
  public getStreamSlotsVideoActive(): number[] {
    let ret: number[] = [];
    for (let i: number = 0; i < this.streamSlots.length; i++) {
      if (this.streamSlots[i].publishStream !== undefined) {
        if (this.streamSlots[i].publishStream?.getVideoTracks()[0].muted === false){
          ret.push(i);
        }
      }
    }
    return ret;
  }

  /**
   * Function to request media stream slot stream id
   * @param id Media stream slot identifier
   * @returns
   */
  public getStreamSlotStreamId(id: string): number | undefined {
    const SLOT: number = this.getSlotIndex(id);
    if (this.streamSlots[SLOT] !== undefined) {
      return this.streamSlots[SLOT].streamId;
    } else {
      return undefined;
    }
  }

  /**
   * Function to apply media stream slot settings
   * @param id Media stream slot identifier
   * @returns
   */
  public applyStreamSlotSettings(id: string): void {
    const SLOT: number = this.getSlotIndex(id);
    if (this.streamSlots[SLOT] !== undefined) {
      if (this.streamSlots[SLOT].sourceType === AvProdPublishSourceType.device) {
        const OPEN: boolean = this.streamSlots[SLOT].mediaOpen;
        if (OPEN) {
          this.closeMedia(SLOT);
        }
        if (OPEN || this.streamSlots[SLOT].active === true) {
          this.openMedia(SLOT)
            .catch(console.error);
        }
      }
      if (this.streamSlots[SLOT].streamId !== -1) {
        const NEW_NAME: IAvProdInputSettings = {
          name: this.streamSlots[SLOT].settings.streamName
        };
        this.avProd.azzChangeInputSettings(this.streamSlots[SLOT].streamId - 1, NEW_NAME);
      }
    }
  }

  /**
   * Function to change media stream slot settings
   * @param id Media stream slot identifier
   * @param settings
   * @returns
   */
  public changeStreamSlotSettings(id: string, settings: IPublisherSettings): void {
    const SLOT: number = this.getSlotIndex(id);
    console.log('[PublishStreamService] changeStreamSlotSettings: ', SLOT, settings);

    if (this.streamSlots[SLOT] !== undefined) {
      const OPEN: boolean = this.streamSlots[SLOT].mediaOpen;
      // if ((this.streamSlots[SLOT].sourceType === AvProdPublishSourceType.device) && (OPEN)) {
      //   this.closeMedia(SLOT);
      // }
      this.streamSlots[SLOT].settings = settings;
      if ((this.streamSlots[SLOT].sourceType === AvProdPublishSourceType.device) &&
          (OPEN || this.streamSlots[SLOT].active === true)) {
        this.openMedia(SLOT)
          .catch(console.error);
      }
      if (this.streamSlots[SLOT].streamId !== -1) {
        const NEW_NAME: IAvProdInputSettings = {
          name: this.streamSlots[SLOT].settings.streamName
        };
        this.avProd.azzChangeInputSettings(this.streamSlots[SLOT].streamId - 1, NEW_NAME);
      }
      this.slotChangeSource.next(id);
      this.userSettings = settings;
      if (this.streamSlots[SLOT].sourceType !== AvProdPublishSourceType.screen){
        this.setSettingsInLocalStorage(settings);
      }
    }
  }

  public changeStreamSlotAudioMuted(id: string, muted: boolean): void {
    const SLOT: number = this.getSlotIndex(id);
    if (this.streamSlots[SLOT] !== undefined) {
      this.streamSlots[SLOT].muted = muted;
      if (this.streamSlots[SLOT].audioStream !== undefined) {
        if (this.streamSlots[SLOT].audioStream!.getAudioTracks().length > 0) {   // Joaquin: Ojo, intentar poder quitar el !
          this.streamSlots[SLOT].audioStream!.getAudioTracks()[0].enabled = !this.streamSlots[SLOT].muted;
        }
      }
      this.slotChangeSource.next(id);
    }
  }

  private getSettingsFromLocalStorage(): IPublisherSettings | undefined {
    const EVENT: string | null = localStorage.getItem(COMMON.storageKeys.publishSettingsEvent);
    if(EVENT !== null ) {
      const CURRENT_EVENT: string = this.avProd.getEventTokenViewer();
      if (CURRENT_EVENT !== ''){
        this.eventToken = CURRENT_EVENT;
      }
      this.userSettingsEvent = EVENT;
      if ((this.userSettingsEvent !== '')&&(this.userSettingsEvent === this.eventToken)){
        this.userSettingsSameEvent = true;
      }
      else{
        this.userSettingsSameEvent = false;
      }
      console.log('[PublishStreamService] getSettingsFromLocalStorage 1: event ' + this.userSettingsEvent + ' ' + this.userSettingsSameEvent);
    }
    console.log('[PublishStreamService] getSettingsFromLocalStorage 2: event ' + this.userSettingsEvent + ' ' + this.userSettingsSameEvent);
    const SETTINGS: string | null = localStorage.getItem(COMMON.storageKeys.publishSettings);
    if(SETTINGS !== null ) {
      return JSON.parse(SETTINGS);
    }
    return undefined;
  }

  private setSettingsInLocalStorage(settings: IPublisherSettings): void {
    localStorage.setItem(COMMON.storageKeys.publishSettings, JSON.stringify(settings));
    const CURRENT_EVENT: string = this.avProd.getEventTokenViewer();
    if (CURRENT_EVENT !== ''){
      this.eventToken = CURRENT_EVENT;
    }
    localStorage.setItem(COMMON.storageKeys.publishSettingsEvent, this.eventToken);
    this.userSettingsEvent = this.eventToken;
    this.userSettingsSameEvent = true;
    console.log('[PublishStreamService] setSettingsInLocalStorage: event ' + this.userSettingsEvent + ' ' + this.userSettingsSameEvent);
  }

  private checkUserVideoDevice(): void {
    if(this.userSettings?.videoDevice) {
      let tmpVideoDevice: IMediaDevice | undefined = this.getVideoDeviceById(this.userSettings?.videoDevice.deviceId);
      if(!tmpVideoDevice) {
        tmpVideoDevice = this.getVideoDeviceByLabel(this.userSettings?.videoDevice.label);
      }
      this.userSettings.videoDevice = tmpVideoDevice ?? this.defaultVideoInput;
      this.setSettingsInLocalStorage(this.userSettings);
    }
  }

  private getVideoDeviceById(deviceId: string): IMediaDevice | undefined {
    return this.mediaInputDevices.getValue().videoInputs.find((device: IMediaDevice) =>
      device.deviceId === deviceId);
  }

  private getVideoDeviceByLabel(label: string): IMediaDevice | undefined {
    return this.mediaInputDevices.getValue().videoInputs.find((device: IMediaDevice) =>
      device.label === label);
  }

  private checkUserAudioInputDevice(): void {
    if(this.userSettings?.audioInputDevice) {
      let tmpAudioDevice: IMediaDevice | undefined = this.getAudioInputDeviceById(this.userSettings?.audioInputDevice.deviceId);
      if(!tmpAudioDevice) {
        tmpAudioDevice = this.getAudioInputDeviceByLabel(this.userSettings?.audioInputDevice.label);
      }
      this.userSettings.audioInputDevice = tmpAudioDevice ?? this.defaultAudioInput;
      this.setSettingsInLocalStorage(this.userSettings);
    }
  }

  private getAudioInputDeviceById(deviceId: string): IMediaDevice | undefined {
    return this.mediaInputDevices.getValue().audioInputs.find((device: IMediaDevice) =>
      device.deviceId === deviceId);
  }

  private getAudioInputDeviceByLabel(label: string): IMediaDevice | undefined {
    return this.mediaInputDevices.getValue().audioInputs.find((device: IMediaDevice) =>
      device.label === label);
  }

  private checkUserAudioOutputDevice(): void {
    if(this.userSettings?.audioOutputDevice) {
      let tmpAudioDevice: IMediaDevice | undefined = this.getAudioOutputDeviceById(this.userSettings?.audioOutputDevice.deviceId);
      if(!tmpAudioDevice) {
        tmpAudioDevice = this.getAudioOutputDeviceByLabel(this.userSettings?.audioOutputDevice.label);
      }
      this.userSettings.audioOutputDevice = tmpAudioDevice;
      this.setSettingsInLocalStorage(this.userSettings);
    }
  }

  private getAudioOutputDeviceById(deviceId: string): IMediaDevice | undefined {
    return this.mediaOutputDevices.getValue().audioOutputs.find((device: IMediaDevice) =>
      device.deviceId === deviceId);
  }

  private getAudioOutputDeviceByLabel(label: string): IMediaDevice | undefined {
    return this.mediaOutputDevices.getValue().audioOutputs.find((device: IMediaDevice) =>
      device.label === label);
  }
}
