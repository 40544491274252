<div aria-live="polite" aria-atomic="true">
  <ng-container *ngFor="let placement of Object.keys(toastPlacement)">
    <div [class]="'toast-container position-fixed d-flex flex-column gap-2 p-3 ' + getCssPlacement(placement)">
     <ng-container *ngFor="let toastConfig of getCurrentToasts(placement); index as i">

        <web-toast [config]="toastConfig"
                   (closeToast)="close(i, placement)"></web-toast>
      </ng-container>
    </div>
  </ng-container>
</div>
