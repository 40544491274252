import localeEs from '@angular/common/locales/es';
import { NgModule, LOCALE_ID } from '@angular/core';
import {
  LocationStrategy,
  PathLocationStrategy,
  registerLocaleData,
} from '@angular/common';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
  TranslateStore,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';
import { PixelModule } from 'ngx-pixels';

import { ENV_COMMON, ENV_WEB } from '@azz-life-streamer/environments';
import {
  InterceptorsModule,
  LanguageService,
  OAuthService,
  SharedModule,
  ThemeService,
} from '@azz-life-streamer/shared';
import { APP_ROUTES } from './app.routes';
import { AppComponent } from './app.component';
import { CookieConsentComponent } from './shared/cookie-consent/cookie-consent.component';

registerLocaleData(localeEs);
export const createTranslateLoader = (http: HttpClient): TranslateHttpLoader =>
  new TranslateHttpLoader(http, './assets/shared/i18n/', '.json');

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    RouterModule.forRoot(APP_ROUTES, {
      initialNavigation: 'enabledBlocking',
      anchorScrolling: 'enabled',
      scrollOffset: [0, 0],
      bindToComponentInputs: true,
      scrollPositionRestoration: 'enabled',
    }),
    NgxGoogleAnalyticsModule.forRoot(ENV_WEB.googleTagManagerId),
    PixelModule.forRoot({ enabled: true, fbPixelId: ENV_WEB.facebookPixelId }),
    CookieConsentComponent,
    InterceptorsModule,
    SharedModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    FormsModule
  ],
  providers: [
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    HttpClient,
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: LOCALE_ID,
      useValue: 'en-US',
      useFactory: (translate: TranslateService) => translate.currentLang,
      deps: [TranslateService],
    },
    {
      provide: 'source',
      useValue: 'web',
      deps: [ThemeService, OAuthService],
    },
    TranslateStore,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private languageService: LanguageService) {
    if (ENV_COMMON.production) {
      console.log = () => {};
    }
    this.languageService.initLanguages()
      .then(() => this.languageService.initTranslations())
      .catch(console.error);
  }
}
