import { AvProdInputTypeNumber } from '../../const/av-producer.const';

export interface IAvProdVideoFfmpeg {
    ready: number;
    ts: number;
    drop: number;
    dup: number;
    fr: number;
    frame: number;
    bitrate: number;
    progress: string;
}

export interface IAvProdVideoLivePublish {
    receiving: boolean;
    drop: number;
    dup: number;
    ffmpeg: IAvProdVideoFfmpeg;
}

export interface IAvProdInputStatusVideo {
    resolution: string;
    frameRate: number;
    bitRate: number;
    frameCount: number;
    frameCurrent: number;
    livePublish: IAvProdVideoLivePublish;
}

export interface IAvProdAudioFfmpeg {
    ready: number;
    ts: number;
    bitrate: number;
    progress: string;
}

export interface IAvProdAudioLivePublish {
    receiving: boolean;
    drop: number;
    dup: number;
    ffmpeg: IAvProdAudioFfmpeg;
}

export interface IAvProdInputStatusAudio {
    available: boolean;
    livePublish: IAvProdAudioLivePublish;
}

export interface IAvProdInputStatus {
    id: number;
    name: string;
    url: string;
    type: string;
    typeNumber: AvProdInputTypeNumber;
    playingMode: number;
    playingState: number;
    playingCount?: number;
    video: IAvProdInputStatusVideo;
    audio: IAvProdInputStatusAudio;
}
