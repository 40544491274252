
export interface IPlayerData {
    id: number;
    name: string;
    token: string;
    start: Date;
    protected: boolean;
    password: string;
    status: string;
}

export interface IPlayerHlsClientInfo {
    valid: boolean;
    url: string;
    clientId: string | null;
    segmentFirst: number;
    segmentDuration: number;
    start: Date;
    serverTotalTimeCurrent?: number;
}

export interface IPlayerControls {
  isPaused: boolean;
  isMuted: boolean;
  isLive: boolean;
  isFullscreen: boolean;
  overlayTimeout: number;
  volume: number;
  currentVolume: number;
  liveFinished: boolean;
}

export interface IPlayerAudioCommon {
  isMuted: boolean;
  volume: number;
  currentVolume: number;
}
