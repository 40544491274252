<div class="dropdown bd-mode-toggle ms-auto cursor-pointer">
  <button class="btn dropdown-toggle"
          [ngClass]="color"
          type="button"
          aria-expanded="false"
          data-bs-toggle="dropdown"
          aria-label="Toggle theme (auto)">
    <i class="bi" [ngClass]="(themeService.selectedTheme === 'dark')? 'bi-moon-stars' : 'bi-sun' "></i>
  </button>
  <ul class="dropdown-menu dropdown-menu-end shadow" aria-labelledby="bd-theme-text">
    <li *ngFor="let theme of themeService.themes">
      <button type="button" class="dropdown-item d-flex align-items-center"
              (click)="themeService.toggleTheme()"
              aria-pressed="false">
        <i [class]="theme.icon"></i> {{ theme.name | translate }}
        <i class="bi bi-check2 ms-auto" [ngClass]="{'d-none': themeService.selectedTheme !== theme.key }"></i>
      </button>
    </li>
  </ul>
</div>
