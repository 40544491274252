import { IPublisherSettings, IVideoResolution } from '../interfaces/publish-stream/settings.interface';

export enum AvProdPublishCommsStatus {
  connecting = 'Connecting',
  ok = 'Ok',
  error = 'Error'
}

export enum AvProdPublishSourceType {
  device = 1,
  screen = 2
}

export const PUBLISH_STREAM_MAX_SLOTS: number = 4;      // Allow now up to 4 slots

export const PUBLISH_STREAM_DEFAULT_RESOLUTION =  {
    name: '540p',
    size: '960x540',
    width: 960,
    height: 540,
    selectable: true,
};

export const PUBLISH_STREAM_RESOLUTIONS: IVideoResolution[] = [
    {
      name: '72p',
      size: '128x72',
      width: 128,
      height: 72,
      selectable: false,
    },
    {
      name: '144p',
      size: '256x144',
      width: 256,
      height: 144,
      selectable: false,
    },
    {
      name: '360p',
      size: '640x360',
      width: 640,
      height: 360,
      selectable: true,
    },
    {
      name: '540p',
      size: '960x540',
      width: 960,
      height: 540,
      selectable: true,
    },
    {
      name: '720p',
      size: '1280x720',
      width: 1280,
      height: 720,
      selectable: true,
    },
    {
      name: '1080p',
      size: '1920x1080',
      width: 1920,
      height: 1080,
      selectable: true,
    },
];


export const DEFAULT_SAVE_MODE_INTERVAL: any = {
    option: '30s',
    value: 30
};

export const SAVE_MODE_INTERVALS: any[] = [
    {
        option: '30s',
        value: 30
    },
    {
        option: '1min',
        value: 60
    },
    {
        option: '3min',
        value: 180
    },
    {
        option: '5min',
        value: 300
    },
    {
        option: 'None',
        value: 0
    }
];

export const PUBLISH_STREAM_DEFAULT_SETTINGS: IPublisherSettings = {
    allowRemote: false,
    streamName: '',
    audioEnabled: true,
    audioInputDevice: undefined,
    audioOutputDevice: undefined,
    audioBitRate: 128,
    audioEchoCancellation: true,
    audioNoiseSuppression: true,
    audioAutoGainControl: true,
    videoEnabled: true,
    videoDevice: undefined,
    videoBitRate: 1500,
    videoResolution: PUBLISH_STREAM_DEFAULT_RESOLUTION,
    videoMirror: false,
    videoUseCanvas: false,
    videoZoom: 1,
    facingMode: undefined,
    savingModeSeconds: DEFAULT_SAVE_MODE_INTERVAL.value
  }

