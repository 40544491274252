import { Subscription } from 'rxjs';
import { IAvProdInput } from '../../interfaces/av-producer/event-av-producer.interface';
import { AvProducerService } from '../../services/av-producer/av-producer.service';
import { ModalManagerService } from '../../services/modal/modal-manager.service';
import { AvProdInputTypeNumber } from '../../const/av-producer.const';
import { ModalDialogId } from '../../enums/utils.enum';



export class ToolboxLiveInputsClass {

  protected tabSelected: number = 0;
  protected addExtButtonDisabled: boolean = true;
  protected liveInputs: IAvProdInput[] = [];

  protected inputStatusSubscription: Subscription | undefined;
  protected inputSettingsSubscription: Subscription | undefined;

  constructor(protected avProd: AvProducerService,
              protected modalService: ModalManagerService) {
    // do nothing
  }

  protected init(): void {
    if (this.inputStatusSubscription !== undefined) this.inputStatusSubscription.unsubscribe();
    if (this.inputSettingsSubscription !== undefined) this.inputSettingsSubscription.unsubscribe();
    this.inputStatusSubscription = this.avProd.onNewInputStatus$.subscribe(() => this.updateLiveInputs());
    this.inputSettingsSubscription = this.avProd.onNewInputSettings$.subscribe(() => this.updateLiveInputs());
    this.updateLiveInputs();
  }

  protected destroy(): void {
    if (this.inputStatusSubscription !== undefined) this.inputStatusSubscription.unsubscribe();
    if (this.inputSettingsSubscription !== undefined) this.inputSettingsSubscription.unsubscribe();
  }

  protected updateLiveInputs(): void {
    console.log('[ToolboxLiveInputsClass] updateLiveInputs');
    this.liveInputs = this.avProd.inputs.filter(element =>
      (element.info.inputTypeNumber === AvProdInputTypeNumber.videoAudioStream));
  }

  protected onClickInputConfig(id: number): void {
    this.modalService.show({id: ModalDialogId.producerInputSettings, subId: id, title: 'producer.inputSettings'}, false);
  }

}
