export const MAX_SECONDS_INSTANCE_BUILD: number = 300; // 5 minutes

export const DEFAULT_POSTER: string = 'assets/img/events/azzulei.png';

export const EVENTS = {
  status: {
    archived : 'archived',
    archiving : 'archiving',
    creating: 'creating',
    deleted: 'deleted',
    deleting: 'deleting',
    destroyed: 'destroyed',
    destroying: 'destroying',
    error : 'error',
    expired: 'expired',
    finished : 'finished',
    finishing : 'finishing',
    installing : 'installing',
    pending: 'pending',
    running: 'running',
    scheduled : 'scheduled',
  },

  monthlyFrequency: [
    '1-5',
    '6-15',
    '16-30',
    'moreThan30'
  ],

  averageDuration: [
    '1hourOrLess',
    '1h-3h',
    '3h-6h',
    'moreThan6h'
  ],

  averageAudience: [
    '1-20',
    '21-50',
    '51-100',
    '101-250',
    '251-500',
    'moreThan500'
  ],
};


