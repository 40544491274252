import { Inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

import { ENV_COMMON } from '@azz-life-streamer/environments';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { APP } from '../../const/app.const';


@Injectable({
  providedIn: 'root',
})
export class MetaCheckerService {
  protected readonly ENV_COMMON: any = ENV_COMMON;

  protected readonly APP: any = APP;

  constructor(@Inject(DOCUMENT) private dom: Document, 
              private meta: Meta, 
              private title: Title, 
              private translate: TranslateService) {}

  public noIndexDevelop(): void {
    if (this.meta.getTag('name=robots') === null && !ENV_COMMON.production) {
      this.meta.addTag({ name: 'robots', content: 'noindex, nofollow' });
    }
  }

  public addNoIndex(): void {
    if (this.meta.getTag('name=robots') === null && ENV_COMMON.production) {
      this.meta.addTag({ name: 'robots', content: 'noindex, nofollow' });
    }
  }

  public removeNoIndex(): void {
    if (this.meta.getTag('name=robots') !== null && ENV_COMMON.production) {
      this.meta.removeTag('name=robots');
    }
  }

  public updateMetaTags(value: string): void {
    let canonical: string = '';
    let location: string = 'home';

    if (value !== null){
      location = value;
    }

    this.title.setTitle(this.translate.instant((APP.metaInfo as any)[location].title));
    this.meta.updateTag({name: 'description', content: this.translate.instant((APP.metaInfo as any)[location].description)});
    this.meta.updateTag({property: 'og:title', content: this.translate.instant((APP.metaInfo as any)[location].title)});
    this.meta.updateTag({property: 'og:description', content: this.translate.instant((APP.metaInfo as any)[location].description)});
    canonical = this.translate.instant((APP.metaInfo as any)[location].canonical);

    const head = this.dom.getElementsByTagName('head')[0];
    let element: HTMLLinkElement | null = this.dom.querySelector(`link[rel='canonical']`) || null
    if (element === null) {
      element= this.dom.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel','canonical');
    element.setAttribute('href',canonical)
  }

}