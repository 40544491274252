import { Component, Input, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import {
  COMMON,
  ILanguage,
  LanguageService,
  UserService,
} from '@azz-life-streamer/shared';
import { Subscription } from 'rxjs';

@Component({
  selector: 'web-change-language',
  standalone: true,
  imports: [CommonModule, FormsModule, TranslateModule],
  templateUrl: './change-language.component.html',
  styleUrls: ['./change-language.component.scss'],
})
export class ChangeLanguageComponent implements OnDestroy {

  @Input() panel: boolean = false;

  protected languages: ILanguage[] = [];
  protected curLang: string;

  private curLangSubscription: Subscription | undefined = undefined;
  private _color: string = '';

  constructor(protected languageService: LanguageService,
              private userService: UserService) {
    this.languages = this.languageService.getLanguages();

    this.curLang = this.languageService.getPriorityLanguage().iso ?? COMMON.defaultLanguage.iso;
    this.curLangSubscription = this.languageService.curLanguage$.subscribe({
      next: (lang: ILanguage) => {
        this.curLang = lang.iso;
      },
    });
  }

  @Input() set color(value: string) {
    this.setColor(value);
  }

  get color(): string {
    return this._color;
  }

  ngOnDestroy(): void {
    this.curLangSubscription?.unsubscribe();
  }

  public changeLanguage(langSelected: string): void {
    if (langSelected !== undefined) {
      this.languageService.changeLanguage(langSelected)
        .then((result: boolean) => {
          if (result) {
            if (!this.userService.anonymousUser) {
              this.userService.updateLanguage(langSelected);
            }
          }
        })
        .catch(console.error);
    }
  }

  protected setColor(color: string): void {
    if (color !== this._color) this._color = color;
  }
}
