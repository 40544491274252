import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import { UserService } from '@azz-life-streamer/shared';
import { ITopBar } from '../../interfaces/web.interface';
import { UrlService } from '../url/url.service';


@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  private topBar: ITopBar = {
    mode: 'primary',
    fluid: false,
    borderBottom: false,
    scrollValue: 860
  }
  private pageInUsersModal: string = '';

  public showTopBar: boolean | undefined = undefined;
  public showFooter: boolean | undefined = undefined;

  public userModal: BehaviorSubject<string> = new BehaviorSubject<string>(this.pageInUsersModal);
  public topBarUpdate: BehaviorSubject<ITopBar> = new BehaviorSubject<ITopBar>(this.topBar);

  constructor(private userService: UserService,
              private urlService: UrlService,
              private router: Router) {}


  public setTopBar(topBar: ITopBar): void {
    this.topBar = topBar;
    this.topBarUpdate.next(topBar)
  }

  public showUserModal(content: string): void {
    if(this.userService.anonymousUser) {
      this.setUsersModalContent(content);
      this.userModal.next(this.pageInUsersModal);
    }
  }

  public hideUserModal(): void {
    this.resetUsersModalContent();
    this.userModal.next(this.pageInUsersModal);
    if((this.router.url.includes('login') || this.router.url.includes('sign-up')) && this.urlService.curLangUrl) {
      this.router.navigateByUrl(this.urlService.curLangUrl)
        .catch(console.error)
    }
  }

  public setUsersModalContent(page: string): void {
    this.pageInUsersModal = page;
  }

  public resetTopBar() {
    this.showTopBar = undefined;
  }

  private resetUsersModalContent(): void {
    this.pageInUsersModal = '';
  }
}
