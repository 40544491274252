import { NgForm } from '@angular/forms';
import { inject } from '@angular/core';

import { AlertService, AlertStatus, ApiUtilsService, IContactData, UserService } from '@azz-life-streamer/shared';


export class ContactFormClass {

  private alertService: AlertService = inject(AlertService);
  private apiUtilsService: ApiUtilsService = inject(ApiUtilsService);

  protected contactData: IContactData;
  protected sendButtonDisabled: boolean = false;

  constructor(protected userService: UserService) {
    this.contactData = this.initContactData();
    if (this.userService.user) {
      this.contactData.name = this.userService.user.name ?? '';
      this.contactData.email = this.userService.user.email;
    }
  }

  protected setVersion(version: string) {
    this.contactData.version = version;
  }

  protected send(form: NgForm): void {

    if (form.valid) {
      this.sendButtonDisabled = true;
      this.apiUtilsService.sendContactForm(this.contactData)
        .subscribe({
          next: () => {
            this.alertService.showAlert({
              show: true,
              status: AlertStatus.success,
              text: 'home.contact.messageSent',
              closeButton: true,
              buttons: [{
                text: 'general.close',
                color: 'primary',
                closeButton: true,
                handler: () => {
                  this.closeConfirmHook();
                }}],
            });
            this.initContactData();
          },
          error: () => {
            this.alertService.showAlert({
              show: true,
              status: AlertStatus.error,
              text: 'home.contact.messageSentError',
              closeButton: true,
              buttons: [{
                text: 'general.close',
                color: 'primary',
                closeButton: true}],
            });
          }
        });
    }
  }

  private initContactData(): IContactData {
    return {
      name: '',
      email: '',
      subject: '',
      comments: '',
      version: ''
    };
  }

  protected closeConfirmHook(): void {
    // must be overridden
  }
}
