import { COMMON, StudioTypes } from '@azz-life-streamer/shared';

export class ProducerSelectClass {


  protected readonly StudioTypes = StudioTypes;

  private _token: string = '';

  constructor() {

  }

  set token(token: string) {
    this._token = token;
  }

  get token(): string {
    return this._token;
  }

  protected onSelect(option: number): void {
    let url: string = '';
    switch (option) {
      case StudioTypes.full:
        url = COMMON.urls.produceFull;
        break;
      case StudioTypes.score:
        url = COMMON.urls.produceScore;
        break;
      case StudioTypes.singleCam:
        url = COMMON.urls.produceSingleCam;
        break;
    }

    if (url !== '') {
      console.log('[ProducerSelectComponent] onSelect ' + url + '/' + this.token.toLowerCase());
     this.navigateToSection( [url, this.token.toLowerCase()]);
    }
  }

  protected navigateToSection(section: string[]): void {
    // must be overridden
  }
}
