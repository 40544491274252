import { IAvProdInterfaceClientDeviceStatus } from './interface-client-status.interface';

export interface IAvProdInterfaceStatusLiveStream {
    streamId: number;
    clientId: number;
    inUse: boolean;
    sourceType: number;
    deviceId: string;
}

export interface IAvProdInterfaceStatusMediaDevice {
    id: string;
    name: string;
}

export interface IAvProdInterfaceStatusVideoOptions {
    resolutions: string[];
    devices: IAvProdInterfaceStatusMediaDevice[];
    bitRates: number[];
}

export interface IAvProdInterfaceStatusVideo {
    bitRate: number;
    frameRate: number;
    resolution: string;
    deviceId: string;
    enabled: boolean;
    options: IAvProdInterfaceStatusVideoOptions;
}

export interface IAvProdInterfaceStatusAudioOptions {
    devices: IAvProdInterfaceStatusMediaDevice[];
    bitRates: number[];
}

export interface IAvProdInterfaceStatusAudio {
    bitRate: number;
    deviceId: string;
    enabled: boolean;
    options: IAvProdInterfaceStatusAudioOptions;
}

export interface IAvProdInterfaceStatusPublish {
    streamId: number;
    state: number;
    video: IAvProdInterfaceStatusVideo;
    audio: IAvProdInterfaceStatusAudio;
}

export interface IAvProdInterfaceStatusClient {
    id: number;
    user: string;
    name: string;
    ipAddress: string;
    clientType: number;
    deviceType: number;
    publish: IAvProdInterfaceStatusPublish[];
    status: IAvProdInterfaceClientDeviceStatus;
}

export interface IAvProdInterfaceStatus {
    liveStreams: IAvProdInterfaceStatusLiveStream[];
    clients: IAvProdInterfaceStatusClient[];
}
