import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Meta } from '@angular/platform-browser';

import { ENV_COMMON } from '@azz-life-streamer/environments';
import { COMMON, ILanguage, LanguageService } from '@azz-life-streamer/shared';
import { APP } from '../../const/app.const';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UrlService implements OnDestroy {
  public curLangUrl: string;

  private curLang: string;
  private curLangSubscription: Subscription | undefined = undefined;

  constructor( private languageService: LanguageService,
               private meta: Meta,
               private router: Router) {
    this.curLangUrl = this.removeCommonLanguage(
      this.languageService.getPriorityLanguage().iso ??
        COMMON.defaultLanguage.iso
    );
    this.curLang = this.languageService.getPriorityLanguage().iso ?? COMMON.defaultLanguage.iso;
    this.curLangSubscription = this.languageService.curLanguage$.subscribe({
      next: (lang: ILanguage) => {
        this.curLang = lang.iso;
        this.curLangUrl = this.removeCommonLanguage(lang.iso);
        this.checkUrlAfterLanguageChange(lang.iso);
      }
    });
  }

  ngOnDestroy(): void {
    this.curLangSubscription?.unsubscribe();
  }

  public checkUrlLangParam(lang: string): Promise<boolean> {
    if (lang !== this.curLang) {
      const NEW_URL: string = this.getLocalizeUrlByLanguage(this.curLang);
      return this.router.navigateByUrl(NEW_URL);
    }
    return new Promise<boolean>((resolve) => resolve(false));
  }

  public getLocalizeUrl(url: string): string {
    const SEPARATOR: string = url.includes('#') ? '' : '/';
    return this.curLang !== COMMON.defaultLanguage.iso
      ? this.curLang + SEPARATOR + url
      : url;
  }

  public addHrefLangTag(): void {
    COMMON.languages.forEach((lang: ILanguage) => {
      // Before adding new alternate tags removes the old ones
      let _hreflang: string;
      if (lang.iso === 'es'){
        _hreflang = lang.iso;
      } else {
        _hreflang = 'x-default';
      }
      this.meta.removeTag('hreflang='+_hreflang);
      let url: string = this.getLocalizeUrlByLanguage(lang.iso);
      if(url.charAt(0) === '/' && lang.iso === COMMON.defaultLanguage.iso) {
        //url = url.slice(1);
      }
      url = url.replace('//', '/');

      
      this.meta.addTag({
                         rel: 'alternate',
                         href: ENV_COMMON.scheme + ENV_COMMON.domain + url,
                         hreflang: _hreflang,
                       });
    });
  }

  public removeHrefLangTags(): void {
    COMMON.languages.forEach((lang: ILanguage) => {
      this.meta.removeTag('hreflang=' + lang.iso);
    });
  }

  public getLanguageFromUrl(url: string): string | undefined {
    const SLUG: string[] = url.split('/');

    if(SLUG[1].includes('#')) {
      const SUB_SLUG: string[] = SLUG[1].split('#');
      if(SUB_SLUG[0] === '') {
        return COMMON.defaultLanguage.iso;
      } else {
        const LANG: ILanguage | undefined = this.languageService.getLanguage(url.slice(1, 3));
        return LANG ? LANG.iso : undefined;
      }
    }

    if(SLUG[1] === '' || SLUG[1].length > 2) {
      return COMMON.defaultLanguage.iso;
    } else {
      const LANG: ILanguage | undefined = this.languageService.getLanguage(url.slice(1, 3))
      return (LANG)? LANG.iso : undefined;
    }
  }

  private checkUrlAfterLanguageChange(lang: string): void {
    if (!APP.skipSections.some((section: string) => this.router.url.includes(section))) {
      const NEW_URL: string = this.getLocalizeUrlByLanguage(lang);
      if (NEW_URL !== '') {
        this.router.navigateByUrl(NEW_URL)
            .catch(console.error);
      }
    }
  }

  private getLocalizeUrlByLanguage(lang: string): string {
    const URL_LANG: string | undefined = this.getLanguageFromUrl(this.router.url);
    if(URL_LANG !== undefined) {
      const CUR_URL: string = (this.router.url.length === 3) ? '' : this.router.url;
      const HAS_HASH: boolean = CUR_URL.includes('#');
      const SEPARATOR: string = HAS_HASH ? '' : CUR_URL.slice(1) === '' ? '' : '/';
      const SLICE_FROM: number = URL_LANG === COMMON.defaultLanguage.iso ? 1 : 3;
      const NEW_LANG_URL: string = lang === COMMON.defaultLanguage.iso ? '' : '/' + lang;
      if(NEW_LANG_URL + SEPARATOR + CUR_URL.slice(SLICE_FROM) === '') return '/';
      return NEW_LANG_URL + SEPARATOR + CUR_URL.slice(SLICE_FROM);
    } else {
      return '';
    }
  }



  private removeCommonLanguage(lang: string): string {
    return lang === COMMON.defaultLanguage.iso ? '' : lang;
  }
}
