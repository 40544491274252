<div class="container-fluid bg-primary">
  <div class="container d-flex flex-column gap-3 gap-md-0 gap-xl-5 flex-md-row flex-md-wrap">

    <div class="brand-container">
      <img src="/assets/shared/img/logo/azzulei-landscape-white.svg" width="140px"
           alt="Azzulei" title="Azzulei">
      <p [innerHTML]="'home.footer.title' | translate"></p>
      <div class="social-networks">
        <a aria-label="Facebook" [href]="COMMON.urlsExt.facebook" [target]="'_blank'" [rel]="'noopener noreferrer'"><i class="bi bi-facebook"></i></a>
        <a aria-label="Instagram" [href]="COMMON.urlsExt.instagram" [target]="'_blank'" [rel]="'noopener noreferrer'"><i class="bi bi-instagram"></i></a>
        <a aria-label="LinkedIn" [href]="COMMON.urlsExt.linkedin" [target]="'_blank'" [rel]="'noopener noreferrer'"><i class="bi bi-linkedin"></i></a>
        <a aria-label="X" [href]="COMMON.urlsExt.twitterX" [target]="'_blank'" [rel]="'noopener noreferrer'"><i class="bi bi-twitter-x"></i></a>
        <a aria-label="Youtube" [href]="COMMON.urlsExt.youtube" [target]="'_blank'" [rel]="'noopener noreferrer'"><i class="bi bi-youtube"></i></a>
      </div>
    </div>

    <div class="d-flex flex-row flex-wrap gap-5 gap-md-3 gap-lg-5 ms-md-5">
      <div class="column">
        <span>{{ "home.footer.product" | translate }}</span>
        <a [routerLink]="[urlService.curLangUrl]" [fragment]="'moreInfo'">{{ "home.footer.solutions" | translate }}</a>
        <a [routerLink]="[urlService.curLangUrl, COMMON.urls.features]">{{ "home.footer.features" | translate }}</a>
        <a [routerLink]="[urlService.curLangUrl, COMMON.urls.prices]">{{ "home.footer.pricing" | translate }}</a>
      </div>
      <div class="column">
        <span>{{ "home.footer.company" | translate }}</span>
        <a [routerLink]="[urlService.curLangUrl, COMMON.urls.aboutUs]">{{ "home.footer.aboutUs" | translate }}</a>
        <a [routerLink]="[urlService.curLangUrl, COMMON.urls.contact]">{{ "home.footer.contact" | translate }}</a>
        <a [routerLink]="[urlService.curLangUrl, COMMON.urls.blog]">{{ "home.footer.blog" | translate }}</a>
      </div>
      <div class="column">
        <span>{{ "home.footer.support" | translate }}</span>
        <a [routerLink]="[urlService.curLangUrl, COMMON.urls.howItWorks]">{{ "home.menu.howItWorks" | translate }}</a>
        <a [routerLink]="[urlService.curLangUrl, COMMON.urls.support, COMMON.urls.helpCenter]">{{ "home.footer.helpCenter" | translate }}</a>
        <a [routerLink]="[urlService.curLangUrl, COMMON.urls.support, COMMON.urls.developers]">{{ "home.footer.developers" | translate }}</a>
      </div>
      <div class="column">
        <span>{{ "home.footer.legalAdvice" | translate }}</span>
        <a [routerLink]="[urlService.curLangUrl, COMMON.urls.legal, COMMON.urls.terms]">{{ "home.footer.legalTerms" | translate }}</a>
        <a [routerLink]="[urlService.curLangUrl, COMMON.urls.legal, COMMON.urls.privacy]">{{ "home.footer.privacy" | translate }}</a>
        <a [routerLink]="[urlService.curLangUrl, COMMON.urls.legal, COMMON.urls.cookies]">{{ "home.footer.cookies" | translate }}</a>
      </div>
    </div>
  </div>
</div>
