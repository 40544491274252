import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

import {
  AlertStatus,
  IAlertData,
  IAlertDataButton,
  LoginClass,
  UserService,
  AlertService,
  COMMON,
} from '@azz-life-streamer/shared';
import { VERSION_CODE } from '../../../core/const/app.const';
import { LayoutService } from '../../../core/services/layout/layout.service';
import { MetaCheckerService } from '../../../core/services/meta-checker/meta-checker.service';

@Component({
  selector: 'web-login',
  templateUrl: './login.component.html',
  styleUrls: ['../styles/users-form.scss'],
})
export class LoginComponent extends LoginClass {
  @ViewChild('fLogin') fLogin!: NgForm;

  @Output() alert: EventEmitter<IAlertData> = new EventEmitter<IAlertData>();

  constructor(protected override userService: UserService,
              protected layoutService: LayoutService,
              private router: Router,
              private alertService: AlertService,
              private metaService: MetaCheckerService) {
    super(userService);
    this.metaService.removeNoIndex();
  }

  override login(): void {
    super.login(this.fLogin, VERSION_CODE);
  }

  override modalClose(): void {
    this.layoutService.hideUserModal();
    const REDIRECT: string | null = localStorage.getItem(COMMON.storageKeys.redirectTo);
    if(REDIRECT) {
      localStorage.removeItem(COMMON.storageKeys.redirectTo);
      this.router.navigateByUrl(REDIRECT)
        .catch(console.error);
    } else {
      this.router.navigateByUrl(COMMON.urls.manager)
        .catch(console.error);
    }
  }

  protected activationAccountRequested(): void {
    this.layoutService.showUserModal('activateAccount');
  }

  override showErrorMessage(): void {
    const BUTTONS: IAlertDataButton[] = [];
    let closeButton: boolean = false;
    if (this.activateAccountPend) {
      BUTTONS.push({
        text: 'users.activateAccount',
        color: 'secondary',
        handler: () => {
          this.alertService.close();
          super.sendActivationCode();
          this.activationAccountRequested();
        },
      });
    } else {
      closeButton = true;
      BUTTONS.push({
        text: 'general.close',
        color: 'primary',
        handler: () => {
          this.alertService.close();
        },
      });
    }

    this.alertService.showAlert({
      status: this.error ? AlertStatus.error : AlertStatus.success,
      show: true,
      title: this.errorTitle ?? '',
      text: this.errorMessage ?? '',
      buttons: BUTTONS,
      closeButton,
    });
  }
}
