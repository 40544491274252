export interface JsonSegmentPlayer {
  first: number;
  sequence: number;
  duration: number;
  start: number;
  id: string | null;
  now: number;
  end?: number;
  show_ad?: boolean;
}

export interface LocalStorageToken {
  id: string;
  ts: number;
}

export interface LocalStorageTokenList {
  [index: string]: LocalStorageToken
}
