import { Component } from '@angular/core';

import { COMMON } from '@azz-life-streamer/shared';
import { UrlService } from '../../../core/services/url/url.service';


@Component({
  selector: 'web-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  protected readonly COMMON: any = COMMON;

  constructor(protected urlService: UrlService) { }
}
