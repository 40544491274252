import { Injectable } from '@angular/core';
import { SignInWithApple } from '@capacitor-community/apple-sign-in';
import { Platform } from '@ionic/angular';
import { from, Observable, switchMap, tap } from 'rxjs';

import { ENV_COMMON } from '@azz-life-streamer/environments';
import { ILoginAppleUser } from '../../interfaces/user/user.interface';
import { COMMON } from '../../const/common.const';
import { UserService } from '../user/user.service';


@Injectable({
  providedIn: 'root'
})
export class AppleSsoService {


  public loginAppleUser: ILoginAppleUser = {
    identityToken: '',
    authorizationCode: '',
    name: '',
    language: '',
    user: ''
  };

  private options: any;

  constructor(private userService: UserService,
              private platform: Platform) {
    this.initializeApp();
  }

  initializeApp(): void {
    this.platform.ready().then(() => {
      // Send options in initialize just for debug in web
      // For app capacitor sends the configuration to plugin (capacitor.config.json)
     this.options = {
        clientId: ENV_COMMON.appleSsoClientId.bundle,
        redirectURI:  ENV_COMMON.web + COMMON.urls.login,
        scopes: 'email name',
        state: '12345',
        nonce: 'nonce',
      };
    })
  }

  signIn(versionCode: number): Observable<boolean> {
    return from(SignInWithApple.authorize(this.options))
      .pipe(
        tap((result: any) => {
          let response = result.response;
          if (response) {
            if ((response.givenName !== '' && response.givenName !== 'null' && response.givenName !== null) ||
              (response.familyName !== '' &&  response.familyName !== 'null' && response.familyName !== null)) {
              this.loginAppleUser.name = response.givenName + ' ' + response.familyName;
            } else {
              this.loginAppleUser.name = '-';
            }
            this.loginAppleUser.user = response.user;
            this.loginAppleUser.authorizationCode = response.authorizationCode;
            this.loginAppleUser.identityToken = response.identityToken;
            this.loginAppleUser.version = versionCode;
            this.saveAuthentication({
              identityToken: response.identityToken,
              authorizationCode: response.authorizationCode,
              user: response.user
            });
          } else {
            throw new Error('not logged');
          }
        }),
        switchMap(() => this.userService.loginApple(this.loginAppleUser))
      );
  }
  saveAuthentication(auth?: any): void {
    if (auth) {
      localStorage.setItem(COMMON.storageKeys.appleAuth, JSON.stringify(auth));
    } else {
      localStorage.removeItem(COMMON.storageKeys.appleAuth);
    }
  }

  getAuthentication(): any | undefined {
    const AUTH: string | null = localStorage.getItem(COMMON.storageKeys.appleAuth)
    if (AUTH !== null) {
      return JSON.parse(AUTH);
    }
    return undefined;
  }

}
