import { Subscription, timer } from 'rxjs';

import { AlertStatus, ToastPlacement, ToastStatus } from '../../enums/common.enum';
import { ModalDialogId } from '../../enums/utils.enum';
import { IEventAsset, IEventUserLastChanges } from '../../interfaces/events/event.interface';
import { IAlertData, IToastConfig } from '../../interfaces/utils/utils.interface';
import { AvProdInputType } from '../../const/av-producer.const';
import { EventsService } from '../../services/events/events.service';
import { ModalManagerService } from '../../services/modal/modal-manager.service';
import { UserService } from '../../services/user/user.service';

export class StoragePageClass {

//  protected assets: IEventAsset[] = [];
//  protected assetsFiltered: IEventAsset[] = [];
  protected tabSelected: number = 0;
//  protected filterImage: boolean = true;
//  protected filterImageOverlay: boolean = true;
//  protected filterVideo: boolean = true;
//  protected filterSound: boolean = true;
//  protected filterDoc: boolean = true;
//  protected allFilters: boolean = true;
  protected storageTotal: number = 0;

  private _orgId: number | undefined;
  private _userId: number | undefined;

//  protected userChangesSubscription: Subscription | undefined;
//  protected userOrgIdSubscription: Subscription | undefined;

  constructor(protected events: EventsService,
              protected userService: UserService,
              protected modalService: ModalManagerService){
    // Do nothing
  }

  protected init(): void {
    // this.updateAssetList();
    // this.userOrgIdSubscription?.unsubscribe();
    // this.userOrgIdSubscription = this.userService.orgId$.subscribe(() => this.updateOrganizationSelected());
    // this.userChangesSubscription?.unsubscribe();
    // this.userChangesSubscription = this.events.userChanges$.subscribe(data => this.checkUserChanges(data));
    // this.events.addPollingClient('StoragePageClass');
  }

  protected destroy(){
    // this.userChangesSubscription?.unsubscribe();
    // this.userOrgIdSubscription?.unsubscribe();
    // this.events.removePollingClient('StoragePageClass');
  }

  protected displayAlert(data: IAlertData){
    // Must override
  }
  protected displayToast(config: IToastConfig){
    // Must override
  }

  protected setStorage(value: number): void{
    //Must override
  }

  protected checkUserChanges(changes: IEventUserLastChanges){
    if ((changes.storage === true)||
        (changes.account === true)){
      this.updateAssetList();
    }
  }

  protected updateOrganizationSelected(){
    if ((this._orgId !== this.userService.orgId)||
        (this._userId !== this.userService.user.id)){
      this.updateAssetList();
    }
  }

  protected updateAssetList(){
    // console.log('[StoragePageClass] updateAssetList');
    // this._orgId = this.userService.orgId;
    // this._userId = this.userService.user.id;
    // this.events.getUserAssetList()
    // .pipe()
    // .subscribe(answer => {
    //   if (answer !== null){
    //     this.assets = answer;
    //     this.updateAssetFilter();
    //   }
    // });
  }

  protected selectAllFilter(){
    // this.allFilters = !this.allFilters;
    // this.filterImage = this.allFilters;
    // this.filterImageOverlay = this.allFilters;
    // this.filterVideo = this.allFilters;
    // this.filterSound = this.allFilters;
    // this.filterDoc = this.allFilters;

    // this.updateAssetFilter();
  }

  protected updateAssetFilter(){
    // this.assetsFiltered = this.assets.filter(element =>
    //     ((this.filterImage)&&(element.type === AvProdInputType.imageTile))||
    //     ((this.filterImageOverlay)&&(element.type === AvProdInputType.imageOverlay))||
    //     ((this.filterSound)&&(element.type === AvProdInputType.audioClip))||
    //     ((this.filterVideo)&&(element.type === AvProdInputType.videoAudioClip))||
    //     ((this.filterDoc)&&(element.type === AvProdInputType.document))
    // );
    // if(this.filterImage && this.filterImageOverlay && this.filterVideo && this.filterSound && this.filterDoc){
    //   this.allFilters = true;
    // }
    // else{
    //   this.allFilters = false;
    // }

    // console.log('[StoragePageClass] updateAssetFilter - Return Asset list: ' + JSON.stringify(this.assetsFiltered));
  }

  protected onUpload() {
    this.modalService.show(
      {
        id: ModalDialogId.managerResourceUpload,
        title: 'inputAdd.resourceUpload'
      },
      true
    )
  }

  protected onDeleteAsset(id: number){

    this.displayAlert({
      closeButton: true,
      show: true,
      status: AlertStatus.question,
      title: 'general.confirmation',
      text: 'storage.assetDeleteConfirmation',
      buttons: [{
          text: 'general.cancel',
          color: 'primary',
          fill: 'outline',
          closeButton: true
        },
        {
          text: 'general.confirm',
          color: 'danger',
          closeButton: true,
          handler: (): void => {
            this.events.deleteUserAsset(id)
              .pipe()
              .subscribe(answer => {
                if (answer === true){
                  this.updateAssetList();
                }
                else{
                  this.displayToast({
                    options: {
                      placement: ToastPlacement.topCenter,
                    },
                    data: {
                      status: ToastStatus.error,
                      text: 'storage.errorDelete',
                      closeButtonBody: true,
                    },
                  });
                }
                timer(1000).subscribe(() => this.events.forcePollingChanges());
            });
          }
        }]
    });
  }

  protected formatSizeKB(size: number): string {
    let scaledSize: number = size;
    let units: string[] = [' B',' KB',' MB',' GB'];
    let iterations: number = 1; // Input is KBytes

    if (scaledSize < 0){
      scaledSize = 0;
    }

    while ((scaledSize > 1024)&&(iterations < units.length-1))
    {
      scaledSize = scaledSize / 1024;
      iterations++;
    }

    return (Number(scaledSize.toFixed(1)).toString() + units[iterations]);
  }
}
