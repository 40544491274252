import { FormControl, FormGroup } from '@angular/forms';
import { AvProdSettingsType } from '../../const/av-producer.const';
import { AvProdPublishSourceType } from '../../const/publish-stream';
import { IAvSettingsItemConfig } from '../../interfaces/av-producer/event-av-producer.interface';
import { ModalManagerService } from '../../services/modal/modal-manager.service';
import { PublishStreamService } from '../../services/publish-stream/publish-stream.service';
import { IPublisherSettings } from '../../interfaces/publish-stream/settings.interface';
import { IAlertData, IToastConfig } from '../../interfaces/utils/utils.interface';
import { ToastPlacement, ToastStatus } from '../../enums/common.enum';

export class InputAddScreenClass {
    //protected useLocalScreen: boolean = false;
    protected broadcastSlotIdScreen: string = 'Screen1';
    protected broadcastSlotIndexScreen: number = -1;

    protected settingsForm: FormGroup = new FormGroup([]);

    protected itemsSettings: IAvSettingsItemConfig[] = [
        {
          id: 'name',
          type: AvProdSettingsType.text,
          name: 'inputSettings.name',
          min: 0,
          max: 0,
          step: 1,
          options: [],
          placeholder: '',
          value: ''
        }
    ];
    
    constructor(protected modalService: ModalManagerService,
                protected publishService: PublishStreamService){
        this.itemsSettings.forEach((element: IAvSettingsItemConfig) => {
            this.settingsForm.addControl(element.id, new FormControl());
        });
    }
  
    protected init(): void {
      this.broadcastSlotIndexScreen = this.publishService.getSlotIndex(this.broadcastSlotIdScreen);
      if (this.broadcastSlotIndexScreen >= 0){
        console.log('[InputAddScreenClass] init');
        //this.useLocalScreen = this.publishService.streamSlots[this.broadcastSlotIndexScreen].active;
        const SETTINGS: IPublisherSettings | null = this.publishService.getStreamSlotSettings(this.broadcastSlotIdScreen);
        if (SETTINGS !== null){
            this.settingsForm.get('name')?.setValue(SETTINGS.streamName);
        }
        if ((this.publishService.avServerLiveStreamsFree === 0)&&(this.publishService.streamSlots[this.broadcastSlotIndexScreen].active === false)){
          this.displayToast({
            options: {
              placement: ToastPlacement.middleCenter,
              autohide: true
            },
            data: {
              status: ToastStatus.error,
              text: 'inputAdd.errorLiveStreamServerLimit',
              closeButtonBody: true,
    
            },
          });
        }
      }
      //else{
      //  this.useLocalScreen = false;
      //}
    }
    protected destroy(): void {
      this.applySettings();
    }

    protected displayToast(config: IToastConfig){
      // Must override
    }
    protected displayAlert(data: IAlertData){
      // Must override
    }

    protected applySettings(){
      let settings: IPublisherSettings | null = this.publishService.getStreamSlotSettings(this.broadcastSlotIdScreen);
      console.log('[InputAddScreenClass] applySettings');
      if (settings !== null){
          if ((this.settingsForm.get('name')?.value !== null)&&
              (this.settingsForm.get('name')?.value !== '')){
              settings.streamName = this.settingsForm.get('name')?.value;
              this.publishService.changeStreamSlotSettings(this.broadcastSlotIdScreen, settings);
          }
          else{
              this.settingsForm.get('name')?.setValue(settings.streamName);
          }
      }
    }

    protected onStartSharing(): void {
      console.log('[InputAddScreenClass] onStartSharing');
      //this.useLocalScreen = true;
      this.publishService.setSlotActive(this.broadcastSlotIdScreen, true);
      this.applySettings();
      this.modalService.closeAll();
    }  

    protected onStopSharing(): void {
      console.log('[InputAddScreenClass] onStopSharing');
      //this.useLocalScreen = false;
      this.publishService.setSlotActive(this.broadcastSlotIdScreen, false);
      this.modalService.closeAll();
    }  
}
