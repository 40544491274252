import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Toast } from 'bootstrap';
import { fromEvent, take } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';

import { IToastData, IToastOptions, ToastPlacement, ToastStatus } from '@azz-life-streamer/shared';


@Component({
  selector: 'web-toast',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
})
export class ToastComponent implements AfterViewInit {

  @ViewChild('toast',  { static: true }) toastEl!: ElementRef;

  @Output() closeToast: EventEmitter<boolean> = new EventEmitter<boolean>();

  protected backgroundColor: string = 'text-bg-dark';

  private _options: IToastOptions = {
    placement: ToastPlacement.bottomRight
  }
  private _data: IToastData = {
    status: ToastStatus.success
  }
  private toast: Toast | undefined

  @Input() set config(config: { data: IToastData, options: IToastOptions }) {
    this._options = config.options;

    if(!config.data.alignText) {
      config.data.alignText = 'text-left'
    }
    switch(config.data.status) {
      case ToastStatus.error:
        this.backgroundColor = 'text-bg-danger';
        break;
      case ToastStatus.success:
        this.backgroundColor = 'text-bg-success';
        break;
      case ToastStatus.warning:
        this.backgroundColor = 'text-bg-warning';
        break;
      case ToastStatus.info:
        this.backgroundColor = 'text-bg-dark';
        break;
    }
    this._data = config.data;
  }

  protected get options(): IToastOptions {
    return this._options;
  }

  protected get data(): IToastData {
    return this._data;
  }

  ngAfterViewInit(): void {
    this.show();
  }

  private show(): void {
    this.toast = new Toast(this.toastEl.nativeElement, this.options);
    fromEvent(this.toastEl.nativeElement, 'hidden.bs.toast')
      .pipe(take(1))
      .subscribe(() => this.hide());
    this.toast?.show();
  }

  public hide(): void {
    this.toast?.dispose();
    this.closeToast.emit()
  }

}
