import { ElementRef } from '@angular/core';
import { Subscription, timer } from 'rxjs';

import { DisplayOptions } from '../enums/publish-stream.enum';
import { AlertStatus, ToastPlacement, ToastStatus } from '../enums/common.enum';
import { ModalDialogId } from '../enums/utils.enum';
import { IEvent } from '../interfaces/events/event.interface';
import { IPublisherSettings } from '../interfaces/publish-stream/settings.interface';
import { IAlertData, IModalDialogData, IToastConfig } from '../interfaces/utils/utils.interface';
import { IAvProdCommsStatus } from '../interfaces/av-producer/event-av-producer.interface';
import { IAvProdInterfaceClientDeviceStatus } from '../interfaces/av-producer/interface-client-status.interface';
import { AvProdClientType, AvProdConnectionStatus, AvProdDeviceType } from '../const/av-producer.const';
import { EVENTS } from '../const/events.const';
import { AvProdPublishCommsStatus, AvProdPublishSourceType } from '../const/publish-stream';
import { EventsService } from '../services/events/events.service';
import { UserService } from '../services/user/user.service';
import { AvProducerService } from '../services/av-producer/av-producer.service';
import { PublishStreamService } from '../services/publish-stream/publish-stream.service';
import { ModalManagerService } from '../services/modal/modal-manager.service';


export class PublisherPageClass {
  protected _inputContainer: ElementRef<HTMLDivElement> | undefined;

  protected mediaAllowed: boolean | undefined = undefined;
  protected initialized: boolean | undefined = undefined;
  protected displayOption: string = DisplayOptions.outputInput;
  protected displayOptions = DisplayOptions;
  protected initializedPublishService: boolean = false;

  protected typeOutput: AvProdDeviceType = AvProdDeviceType.output;
  protected typeInput: AvProdDeviceType = AvProdDeviceType.input;

  protected event: IEvent | undefined;
  protected userInitializedRetries: number = 0;
  protected connectionStatus: AvProdConnectionStatus = AvProdConnectionStatus.none;
  protected connectionStatusText: string = '';
  protected avProdConnectionStatus = AvProdConnectionStatus;
  protected avProdCommsOk: boolean = false;

  protected publishStatusText: string = '';
  protected publishSettings: IPublisherSettings | undefined;
  protected videoEnabled: boolean = false;
  protected clientStatusInfo: IAvProdInterfaceClientDeviceStatus | undefined;

  protected publisherSlotIdCam1: string = 'Camera1';
  protected broadcastScreenAllowed: boolean = false;

  protected avCommsStatusSubscription: Subscription | undefined;
  protected avCommsTooManyClientsSubscription: Subscription | undefined;
  protected userCheckTimerSubscription: Subscription | undefined;
  protected mediaEnabledSubscription: Subscription | undefined;
  protected streamChangeSubscription: Subscription | undefined;
  protected modalServiceSubscription: Subscription | undefined;

  protected modalDialogId = ModalDialogId;
  protected modalDataCurrent: IModalDialogData = {id: ModalDialogId.none, title: ''};

  protected batteryInfo:  IAvProdInterfaceClientDeviceStatus | undefined = undefined;

  protected validatingToken: boolean | undefined = undefined;
  private _token: string = '';
  private _tokenMode: string = 'publisher';

  constructor(protected eventsService: EventsService,
              protected userService: UserService,
              protected avProd: AvProducerService,
              protected publishService: PublishStreamService,
              protected modalService: ModalManagerService) { }

  set token(token: string) {
    this._token = token;
  }

  get token(): string {
    return this._token;
  }

  protected hideModal(): void {
    //must be overridden
  }

  protected showModal(): void {
    //must be overridden
  }

  async init(token: string, tokenMode: string): Promise<void> {
    this._tokenMode = tokenMode;
    this.token = token;
    if (this.token !== null) {
      this.validatingToken = true;
      this.checkToken();
    }

    if (this.mediaEnabledSubscription !== undefined) this.mediaEnabledSubscription.unsubscribe();

    await this.publishService.initializeUserMedia();

    if (this.publishService.userMediaEnabled.value){
      if (!this.initializedPublishService){
        if (this.event?.viewerToken !== undefined){
          this.initializedPublishService = true;
          this.publishService.init(this.event?.viewerToken)
          .then(()=> {
            this.createBroadcasters();
            this.mediaAllowed = true;
            this.initialized = false;
          })
          .catch(console.error);
        }
      }
    }
    else{
      this.mediaEnabledSubscription = this.publishService.userMediaEnabled
        .subscribe((allowed: boolean) => {

          console.log('[PublisherPageClass] init Allowed: ' + allowed);
          if (allowed) {
            if (!this.initializedPublishService) {
              if (this.event?.viewerToken !== undefined) {
                this.initializedPublishService = true;
                this.publishService.init(this.event?.viewerToken)
                .then(()=> {
                  this.createBroadcasters();
                  this.mediaAllowed = allowed;
                  this.initialized = false;
                })
                .catch(console.error);
              }
            }
          }
          else {
            if (this.mediaAllowed !== undefined) {
              this.initialized = false;
            }
            this.mediaAllowed = allowed;
          }
        });
    }

    if (this.modalServiceSubscription !== undefined) this.modalServiceSubscription.unsubscribe();
    this.modalServiceSubscription =
      this.modalService.modalManagerEvent$.subscribe((value: IModalDialogData) => {
        this.onModalNewData(value);
      });
    this.avProd.deviceStatusInfo.subscribe(
      (info: IAvProdInterfaceClientDeviceStatus) => {
        this.batteryInfo = info;
      }
    );
  }

  destroy(): void {
    if (this.modalServiceSubscription !== undefined) this.modalServiceSubscription.unsubscribe();
    if (this.userCheckTimerSubscription !== undefined) this.userCheckTimerSubscription.unsubscribe();
    if (this.avCommsStatusSubscription !== undefined) this.avCommsStatusSubscription.unsubscribe();
    if (this.avCommsTooManyClientsSubscription !== undefined) this.avCommsTooManyClientsSubscription.unsubscribe();
    if (this.mediaEnabledSubscription !== undefined) this.mediaEnabledSubscription.unsubscribe();
    this.publishService.deleteStreamSlot(this.publisherSlotIdCam1);
    this.avProd.destroy();
    this.publishService.destroy();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected displayToast(config: IToastConfig): void {
    // must be overridden
  }

  protected onChangeStreamName(name: string) {
    if (this.publishSettings !== undefined){
      this.publishSettings.streamName = name;
    }
  }
  protected onChangeMirrorImage(value: boolean) {
    if (this.publishSettings !== undefined){
      this.publishSettings.videoMirror = value;
    }
  }
  protected onChangeZoom(value: number) {
    if (this.publishSettings !== undefined){
      this.publishSettings.videoZoom = value;
    }
  }

  protected onModalNewData(data: IModalDialogData){
    this.modalDataCurrent = data;
    switch(data.id){
      case ModalDialogId.none:
        this.hideModal();
        break;
      default:
        this.showModal();
        break;
    }
  }

  protected closeModalCurrent(){
    this.modalService.closeCurrent();
  }

  protected showError(detail: string): void {
    this.displayToast({
      options: {
        placement: ToastPlacement.topCenter,
      },
      data: {
        status: ToastStatus.error,
        text: detail,
        closeButtonBody: true,
      },
    });
  }

  protected startBroadcast(): void {
    const SETTINGS: IPublisherSettings | null = this.publishService.getStreamSlotSettings(this.publisherSlotIdCam1);
    if (SETTINGS !== null) {
      this.publishService.setSlotActive(this.publisherSlotIdCam1, true);
      this.publishSettings = SETTINGS;
      this.initialized = true;
    }
  }

  protected checkToken(): void {

    let needToLogInRedirect: boolean = false;

    if (this.userService.isInitialized) {
      if (!this.userService.anonymousUser) {
        this.eventsService.getEventInfo(this._tokenMode, this.token)
          .pipe()
          .subscribe((event: IEvent | null) => {
            this.validatingToken = false;
            if (event) {
              let eventReady: boolean = false;
              this.event = event;
              //this.event.publisherToken = this.token;
              // Update active event in EventsService
              this.eventsService.currentEvent = this.event;
              console.log('[PublisherPageClass] Init Event info ' + JSON.stringify(this.event));

              // Check event status
              if ((this.event?.status === EVENTS.status.creating)||
                  (this.event?.status?.includes(EVENTS.status.installing) === true)||
                  (this.event?.status === EVENTS.status.pending)||
                  (this.event?.status === EVENTS.status.scheduled)) {
                this.connectionStatus = this.avProdConnectionStatus.eventNotReady;
                this.connectionStatusText = 'producer.msgOverEventNotReady';
              }
              else if ((this.event?.status !== undefined)&&
                        (this.event?.status !== EVENTS.status.running)) {
                this.connectionStatus = this.avProdConnectionStatus.eventFinished;
                this.connectionStatusText = 'producer.msgOverEventFinished';
              }
              else if (this.event?.status !== undefined) {
                eventReady = true;
              }

              // Add this token to previously used token list
              this.eventsService.saveToken(this.token, this._tokenMode, this.event.name);

              if (eventReady){
                this.avProd.init();
                // Initialize available client information
                this.avProd.clientInfo.token = this.token;
                if (this._tokenMode === 'producer'){
                  this.avProd.clientInfo.clientType = AvProdClientType.producer;
                }
                else{
                  this.avProd.clientInfo.clientType = AvProdClientType.publisher;
                }

                // open comms with avProducer
                this.avProd.openComms(this.event);
                this.avProd.changeAudioLevelActive(true);
                //this.avProd.addVideoActive(AV_PROD_FRAME_ID_OUTPUT_OFFSET);
                //for (let i:number = 0; i<4; i++) {
                //  this.avProd.addVideoActive(i);
                //}
                if (this.avCommsStatusSubscription !== undefined) this.avCommsStatusSubscription.unsubscribe();
                this.avCommsStatusSubscription = this.avProd.onCommsStatusChange$
                  .subscribe((data: IAvProdCommsStatus) => {
                    this.receiveCommsStatusChange(data)
                  });
                if (this.avCommsTooManyClientsSubscription !== undefined) this.avCommsTooManyClientsSubscription.unsubscribe();
                this.avCommsTooManyClientsSubscription = this.avProd.onCommsTooManyClientsChange$
                  .subscribe(data => {
                    this.receiveCommsTooManyClientsChange(data)
                  });
                if (this.streamChangeSubscription !== undefined) this.streamChangeSubscription.unsubscribe();
                this.streamChangeSubscription = this.publishService.slotChangeSource
                  .subscribe((id: string) => this.updateStreamSlot(id));

                if (this.publishService.userMediaEnabled.value) {
                  if (!this.initializedPublishService) {
                    this.initializedPublishService = true;
                    this.publishService.init(this.event?.viewerToken)
                    .then(()=> {
                      this.createBroadcasters();
                      this.mediaAllowed = true;
                      this.initialized = false;
                    })
                    .catch(console.error);
                  }
                }
              }
              else {
                timer(3000).subscribe(() => this.checkToken());
              }
          } else {
              // Display error
              this.showError('general.errorTokenNotValid');
              this.exitPage();
            }
          });
      } else {
        needToLogInRedirect = true;
      }
    } else {

      // Users service not initialized yet
      if (this.userInitializedRetries < 30) {
        // Wait for initialization
        this.userInitializedRetries++;
        console.log('[PublisherPageClass] User service not initialized. Wait and try');
        this.userCheckTimerSubscription = timer(500).subscribe(() => {
          this.checkToken();
        });

      } else {
        needToLogInRedirect = true;
      }
    }

    if (needToLogInRedirect) {
      this.showError('general.errorTokenNotValidAnonymous');
      this.exitPage();
    }
  }

  private createBroadcasters(): void {
    this.publishService.createStreamSlot(this.publisherSlotIdCam1, AvProdPublishSourceType.device);
    //this.publishService.setSlotActive(this.publisherSlotIdCam1, true);
    const SETTINGS: IPublisherSettings | null = this.publishService.getStreamSlotSettings(this.publisherSlotIdCam1);
    if (SETTINGS !== null) {
      this.publishSettings = SETTINGS;
    }
  }

  protected receiveCommsTooManyClientsChange(data: boolean): void {
    console.log('[PublisherPageClass] receiveCommsTooManyClientsChange ' + JSON.stringify(this.avProd.commsStatus));
    if (data) {
      this.connectionStatus = this.avProdConnectionStatus.tooManyClients;
      this.connectionStatusText = 'producer.msgOverTooManyClientsLiveInput';
      console.log('[PublisherPageClass] receiveCommsTooManyClientsChange Error ' + data);
    }
  }

  protected receiveCommsStatusChange(data: IAvProdCommsStatus) {
    console.log('[PublisherPageClass] receiveCommsStatusChange ' + JSON.stringify(data));
    if (this.avProdCommsOk !== this.avProd.commsStatus.ok){
      this.avProdCommsOk = this.avProd.commsStatus.ok;
      if (!this.avProdCommsOk) {
        if ((this.connectionStatus === this.avProdConnectionStatus.ok)||
            (this.connectionStatus === this.avProdConnectionStatus.none)){
          this.connectionStatus = this.avProdConnectionStatus.commsError;
          this.connectionStatusText = 'producer.msgOverCommsError';
        }
        /*
        this.displayToast({
          options: {
            autohide: true,
            placement: ToastPlacement.middleCenter
          },
          data: {
            closeButtonHeader: true,
            status: ToastStatus.error,
            title: 'general.error',
            text: 'general.errorCommsServerWs',
            alignText: 'text-center',
            buttons: []
          },
        });
        */
        this.requestEventInfo();
      }
      else {
        if (this.event?.status === EVENTS.status.running){
          if (this.connectionStatus !== this.avProdConnectionStatus.tooManyClients){
            this.connectionStatus = this.avProdConnectionStatus.ok;
            this.connectionStatusText = '';
          }
        }
      }
    }
  }

  private requestEventInfo(): void {
    // Do not request info from API. retrieve from avProducer WS status
    if (this.avProd.commsStatus.ok && (this.event !== undefined)) {
      this.event.producerToken = this.avProd.serverStatus.event.producerToken;
      this.event.publisherToken = this.avProd.serverStatus.event.publisherToken;
      this.event.viewerToken = this.avProd.serverStatus.event.viewerToken;
      this.event.status = this.avProd.serverStatus.event.status.toLowerCase();
      this.event.started = this.avProd.serverStatus.event.streamStarted;
    } else {
      this.eventsService.getEventInfo(this._tokenMode, this.token)
        .pipe()
        .subscribe((event: IEvent | null) => {
          if (event) {
            this.event = event;
            this.event.producerToken = this.token;
            // Update active event in EventsService
            this.eventsService.currentEvent = this.event;
            console.log('[PublisherPageClass] Event info ' + JSON.stringify(this.event));
            if ((event.status === EVENTS.status.finishing) ||
              (this.event.status === EVENTS.status.finished) ||
              (this.event.status === EVENTS.status.archived)) {

              // The event has finished. Show message and navigate to Home
              ///////////////

              // Exit producer page
              this.exitPage();
            }
          }
        });
    }

    // Check event status
    if ((this.event?.status === EVENTS.status.creating)||
        (this.event?.status?.includes(EVENTS.status.installing) === true)||
        (this.event?.status === EVENTS.status.pending)||
        (this.event?.status === EVENTS.status.scheduled)) {
      this.connectionStatus = this.avProdConnectionStatus.eventNotReady;
      this.connectionStatusText = 'producer.msgOverEventNotReady';
    }
    else if ((this.event?.status !== undefined) &&
              (this.event?.status !== EVENTS.status.running)) {
      this.connectionStatus = this.avProdConnectionStatus.eventFinished;
      this.connectionStatusText = 'producer.msgOverEventFinished';
    }
    else if ((this.event?.status !== undefined) &&
              this.avProdCommsOk) {
      this.connectionStatus = this.avProdConnectionStatus.ok;
      this.connectionStatusText = '';
    }
  }

  protected updateStreamSlot(slotId: string): void {
    if (slotId === this.publisherSlotIdCam1) {
      const STATUS: AvProdPublishCommsStatus | undefined = this.publishService.getStreamSlotStatus(this.publisherSlotIdCam1);
      this.notPublishingHook();
      if (STATUS === AvProdPublishCommsStatus.connecting) {
        this.publishStatusText = 'publisher.connecting';
      } else if (STATUS === AvProdPublishCommsStatus.error) {
        this.publishStatusText = 'publisher.error';
      } else {
        this.publishingHook();
        this.publishStatusText = 'publisher.connected';
      }
      console.log('[PublisherPageClass] updateStreamSlot: ' + this.publishStatusText);
    }
  }

  protected exitPage(): void {
    //must be overridden
  }

  protected displayAlert(data: IAlertData): void {
    //must be overridden
  }

  protected publishingHook(): void {
    // override id needed
  }

  protected notPublishingHook(): void {
    // override id needed
  }

  protected dragInput(mouseEvent: MouseEvent): void {
    console.log(mouseEvent);
  }

  protected onClickExit(){
    this.displayAlert({
      show: true,
      status: AlertStatus.question,
      title: 'general.confirmation',
      text: 'publisher.exitPublisherConfirmation',
      closeButton: true,
      buttons: [{
        text: 'general.cancel',
        color: 'primary',
        fill: 'outline',
        closeButton: true,
        handler() {},
      },
      {
        text: 'general.confirm',
        color: 'primary',
        closeButton: true,
        handler: (): void => {
          this.exitPage();
        }
      }]
    });
  }

}
