import { timer } from 'rxjs';

import {
  EventsService,
  IToastConfig,
  ToastPlacement,
  ToastStatus,
  UserService
} from '@azz-life-streamer/shared';


export class EventCodePromptClass {

  protected eventToken: string = '';
  protected eventTokenPrevious: string = '';

  constructor(protected userService: UserService,
              protected eventsService: EventsService) {
    // Do nothing
  }

  protected displayToast(config: IToastConfig): void {
    //must be overridden
  }

  protected async navigateToSection(section: string): Promise<boolean> {
    return false;
  }

  protected onTokenEditChange(event: any): void {
    timer(10).subscribe((): void => {
      this.eventToken = event.replace(/[^a-zA-Z-]/g, '');
      this.eventToken = this.eventToken.toLowerCase();
      if (this.eventTokenPrevious.length > this.eventToken.length) {
        if (this.eventToken === this.eventTokenPrevious.substring(0, this.eventTokenPrevious.length - 1)) {
          if (this.eventTokenPrevious[this.eventTokenPrevious.length - 1] === '-') {
            this.eventToken = this.eventToken.substring(0, this.eventToken.length - 1);
          }
        } else if (this.eventToken != '') {
          this.eventToken = this.eventTokenPrevious;
        }
      }

      //if (this.eventTokenPrevious.length < this.eventToken.length) {
      if ((this.eventToken.length === 3) || (this.eventToken.length == 7)) {
        this.eventToken += '-';
      }
      let newToken: string = '';
      for (let i: number = 0; i < this.eventToken.length; i++) {
        if (this.eventToken[i] === '-') {
          if ((newToken.length === 3) || (newToken.length === 7)) newToken += this.eventToken[i];
        } else {
          if ((newToken.length === 3) || (newToken.length === 7)) newToken += '-';
          newToken += this.eventToken[i];
        }
      }
      this.eventToken = newToken;
      //}
      this.eventTokenPrevious = this.eventToken;
    });
  }

  protected goToToken(): void {
    console.log('Event token:' + this.eventToken);

    if (this.eventToken === '') {
      this.showErrorToken();
    } else {
      this.eventsService.checkToken(this.eventToken.toLowerCase())
        .pipe()
        .subscribe(data => {
          /*console.log('Check token: ' + JSON.stringify(data));*/
          if (data) {
            this.navigateToSection(data.tokenType)
              .then(() => {
                this.eventToken = '';
                this.eventTokenPrevious = '';
              })
              .catch(console.error);
          } else {
            this.showErrorToken();
          }
        });
    }

  }

  private showErrorToken(): void {
    let msgDetail: string;
    if (this.userService.anonymousUser) {
      msgDetail = 'general.errorTokenNotValidAnonymous';
    } else {
      msgDetail = 'general.errorTokenNotValid';
    }
    console.error('[EventCodePrompt] showErrorToken: ' + msgDetail);

    this.displayToast({
      options: {
        placement: ToastPlacement.topCenter,
      },
      data: {
        status: ToastStatus.error,
        text: msgDetail,
        closeButtonBody: true,
      },
    });
  }
}
