import { Subscription } from 'rxjs';
import { AvProducerService } from '../../services/av-producer/av-producer.service';
import { FormControl, FormGroup } from '@angular/forms';
import { IAvSettingSelectOption, IAvSettingsItemConfig } from '../../interfaces/av-producer/event-av-producer.interface';
import { AvProdInputTypeNumber, AvProdSettingsType } from '../../const/av-producer.const';
import { AV_PROD_OPTIONS_BACKGROUND_TYPE, AV_PROD_OPTIONS_FONTS, AV_PROD_OPTIONS_OVERLAY_HTML_TYPE, AV_PROD_OPTIONS_OVERLAY_STYLE_MODES } from '../../const/av-producer-options';
import { IAvProdComposerSettings, IAvProdTextGroup } from '../../interfaces/av-producer/composer-settings.interface';
import { IAvProdOverlayItemSettings, IAvProdOverlaySettings } from '../../interfaces/av-producer/overlay-settings.interface';
import { ModalDialogId } from '../../enums/utils.enum';
import { ModalManagerService } from '../../services/modal/modal-manager.service';
import { IToastConfig } from '../../interfaces/utils/utils.interface';
import { ToastPlacement, ToastStatus } from '../../enums/common.enum';

export class ToolboxOverlayClass {
  protected _expanded: boolean = false;
  protected _overlayImageAvailable = false;
  protected _overlayImagesActive: boolean[] | undefined[] = [];

  protected settingsStyleForm: FormGroup = new FormGroup([]);
  protected settingsInputNamesForm: FormGroup = new FormGroup([]);
  protected settingsBannerForm: FormGroup = new FormGroup([]);
  protected settingsBannerTextsForms: FormGroup[] = [];
  protected settingsTickerForm: FormGroup = new FormGroup([]);
  protected settingsTickerTextsForms: FormGroup[] = [];
  protected settingsTimeForm: FormGroup = new FormGroup([]);
  protected settingsImageForms: FormGroup[] = [];
  protected settingsBackgroundForm: FormGroup = new FormGroup([]);
  protected settingsHtmlGraphForm: FormGroup = new FormGroup([]);

  protected isFormDirty: boolean = false;
  protected serverSettingsSubscription: Subscription | undefined;
  protected componentsSubscription: Subscription | undefined;
  protected bannerTextList: IAvProdTextGroup[] = [];
  protected bannerTextSelected: number = 0;
  protected bannerTextMax: number = 5;
  protected tickerTextList: IAvProdTextGroup[] = [];
  protected tickerTextSelected: number = 0;
  protected tickerTextMax: number = 5;
  protected applyChangesDirectly: boolean = true;

  protected itemsSettingsStyle: IAvSettingsItemConfig[] = [
    {
      id: 'mode',
      type: AvProdSettingsType.selectComboNumber,
      name: 'overlaySettings.styleMode',
      min: 0,
      max: 0,
      step: 1,
      options: AV_PROD_OPTIONS_OVERLAY_STYLE_MODES,
      placeholder: '',
      value: undefined,
      config: {
        mode: 'native'
      }
    },
    {
      id: 'colorMain',
      type: AvProdSettingsType.color,
      name: 'overlaySettings.styleColorMain',
      min: 0,
      max: 0,
      step: 1,
      options: [],
      placeholder: '',
      value: undefined,
      config: {
        mode: 'native'
      }
    },
    {
      id: 'colorSecondary',
      type: AvProdSettingsType.color,
      name: 'overlaySettings.styleColorSecondary',
      min: 0,
      max: 0,
      step: 1,
      options: [],
      placeholder: '',
      value: undefined,
      config: {
        mode: 'native'
      }
    },
    {
      id: 'fontFace',
      type: AvProdSettingsType.selectComboString,
      name: 'overlaySettings.styleFontFace',
      min: 0,
      max: 0,
      step: 1,
      options: AV_PROD_OPTIONS_FONTS,
      placeholder: '',
      value: undefined,
      config: {
        mode: 'native'
      }
    }
  ];

  protected itemsSettingsInputNames: IAvSettingsItemConfig[] = [
    {
      id: 'visible',
      type: AvProdSettingsType.switchBoolean,
      name: '',       //name: 'overlaySettings.inputNamesVisible',
      min: 0,
      max: 0,
      step: 1,
      options: [],
      placeholder: '',
      value: false
    }
  ];

  protected itemsSettingsBanner: IAvSettingsItemConfig[] = [
    {
      id: 'visible',
      type: AvProdSettingsType.switchBoolean,
      name: '',       //name: 'overlaySettings.bannerVisible',
      min: 0,
      max: 0,
      step: 1,
      options: [],
      placeholder: '',
      value: false
    }
  ];

  protected itemsSettingsBannerTextGroup: IAvSettingsItemConfig[] = [
    {
      id: 'title',
      type: AvProdSettingsType.text,
      name: 'overlaySettings.bannerTitle',
      min: 0,
      max: 0,
      step: 1,
      options: [],
      placeholder: '',
      value: ''
    }
  ];

  protected itemsSettingsTicker: IAvSettingsItemConfig[] = [
    {
      id: 'visible',
      type: AvProdSettingsType.switchBoolean,
      name: '',
      min: 0,
      max: 0,
      step: 1,
      options: [],
      placeholder: '',
      value: false
    }
  ];

  protected itemsSettingsTickerTextGroup: IAvSettingsItemConfig[] = [
    {
      id: 'text',
      type: AvProdSettingsType.text,
      name: 'overlaySettings.tickerText',
      min: 0,
      max: 0,
      step: 1,
      options: [],
      placeholder: '',
      value: ''
    }
  ];

  protected itemsSettingsTime: IAvSettingsItemConfig[] = [
    {
      id: 'visible',
      type: AvProdSettingsType.switchBoolean,
      name: '',
      min: 0,
      max: 0,
      step: 1,
      options: [],
      placeholder: '',
      value: false
    }
  ];

  protected itemsSettingsImageItem: IAvSettingsItemConfig[] = [
    {
      id: 'enable',
      type: AvProdSettingsType.switchBoolean,
      name: '',
      min: 0,
      max: 0,
      step: 1,
      options: [],
      placeholder: '',
      value: false
    },
    {
      id: 'imageInputId',
      type: AvProdSettingsType.selectComboNumber,
      name: 'overlaySettings.imageInputId',
      min: 0,
      max: 0,
      step: 1,
      options: [],
      placeholder: 'overlaySettings.selectOverlayImageEmpty',
      value: 0,
      config: {
        mode: 'native'
      }
    },
    {
      id: 'imageScale',
      type: AvProdSettingsType.numberSlider,
      name: 'overlaySettings.imageScale',
      min: 0.1,
      max: 1.0,
      step: 0.1,
      options: [],
      placeholder: '',
      value: 1.0
    },
    {
      id: 'imagePositionX',
      type: AvProdSettingsType.numberSlider,
      name: 'overlaySettings.imagePositionX',
      min: 0,
      max: 100.0,
      step: 0.1,
      options: [],
      placeholder: '',
      value: 0
    },
    {
      id: 'imagePositionY',
      type: AvProdSettingsType.numberSlider,
      name: 'overlaySettings.imagePositionY',
      min: 0,
      max: 100.0,
      step: 0.1,
      options: [],
      placeholder: '',
      value: 0
    }
  ];

  protected itemsSettingsBackground: IAvSettingsItemConfig[] = [
    {
      id: 'mode',
      type: AvProdSettingsType.selectComboNumber,
      name: 'overlaySettings.backgroundMode',
      min: 0,
      max: 0,
      step: 1,
      options: AV_PROD_OPTIONS_BACKGROUND_TYPE,
      placeholder: '',
      value: undefined,
      config: {
        mode: 'native'
      }
    },
    {
      id: 'color',
      type: AvProdSettingsType.color,
      name: 'overlaySettings.backgroundColor',
      min: 0,
      max: 0,
      step: 1,
      options: [],
      placeholder: '',
      value: undefined,
      config: {
        mode: 'native'
      }
    },
    {
      id: 'input',
      type: AvProdSettingsType.selectComboNumber,
      name: 'overlaySettings.backgroundInput',
      min: 0,
      max: 0,
      step: 1,
      options: [],
      placeholder: 'overlaySettings.selectBackgroundImage',
      value: undefined,
      config: {
        mode: 'native'
      }
    }
  ];

  protected itemsSettingsHtmlGraph: IAvSettingsItemConfig[] = [
    {
      id: 'enabled',
      type: AvProdSettingsType.switchBoolean,
      name: '',
      min: 0,
      max: 0,
      step: 1,
      options: [],
      placeholder: '',
      value: false
    },
    {
      id: 'htmlGraphicsType',
      type: AvProdSettingsType.selectComboNumber,
      name: 'overlaySettings.htmlGraphicsType',
      min: 0,
      max: 0,
      step: 1,
      options: AV_PROD_OPTIONS_OVERLAY_HTML_TYPE,
      placeholder: '',
      config: {
        mode: 'native'
      }
    },
    {
      id: 'htmlGraphicsWebUrl',
      type: AvProdSettingsType.text,
      name: 'overlaySettings.htmlGraphicsWebUrl',
      min: 0,
      max: 200,
      step: 1,
      options: [],
      placeholder: '',
      value: ''
    },
    {
      id: 'htmlGraphicsPositionX',
      type: AvProdSettingsType.numberSlider,
      name: 'overlaySettings.htmlGraphicsPositionX',
      min: 0,
      max: 100.0,
      step: 0.1,
      options: [],
      placeholder: ''
    },
    {
      id: 'htmlGraphicsPositionY',
      type: AvProdSettingsType.numberSlider,
      name: 'overlaySettings.htmlGraphicsPositionY',
      min: 0,
      max: 100.0,
      step: 0.1,
      options: [],
      placeholder: ''
    },
    {
      id: 'htmlGraphicsWidth',
      type: AvProdSettingsType.numberSlider,
      name: 'overlaySettings.htmlGraphicsWidth',
      min: 0,
      max: 100.0,
      step: 0.1,
      options: [],
      placeholder: ''
    },
    {
      id: 'htmlGraphicsHeight',
      type: AvProdSettingsType.numberSlider,
      name: 'overlaySettings.htmlGraphicsHeight',
      min: 0,
      max: 100.0,
      step: 0.1,
      options: [],
      placeholder: ''
    }
  ];

  protected itemsSettingsBackgroundFiltered: IAvSettingsItemConfig[] = [];

  constructor(protected avProd: AvProducerService,
              protected modalService: ModalManagerService) {}

  init(): void {
    this.itemsSettingsStyle.forEach(element => {
      this.settingsStyleForm.addControl(element.id, new FormControl());
    });
    this.itemsSettingsBackground.forEach(element => {
      this.settingsBackgroundForm.addControl(element.id, new FormControl());
    });
    this.itemsSettingsInputNames.forEach(element => {
      this.settingsInputNamesForm.addControl(element.id, new FormControl());
    });
    this.itemsSettingsBanner.forEach(element => {
      this.settingsBannerForm.addControl(element.id, new FormControl());
    });
    this.itemsSettingsTicker.forEach(element => {
      this.settingsTickerForm.addControl(element.id, new FormControl());
    });
    this.itemsSettingsTime.forEach(element => {
      this.settingsTimeForm.addControl(element.id, new FormControl());
    });
    this.itemsSettingsHtmlGraph.forEach(element => {
      this.settingsHtmlGraphForm.addControl(element.id, new FormControl());
    });
    // Add Banner text controls (custom)
    this.settingsBannerForm.addControl('bannerTextSelected', new FormControl());
    // Add Ticker text controls (custom)
    this.settingsTickerForm.addControl('tickerTextSelected', new FormControl());

    this.readServerSettings();
    this.serverSettingsSubscription = this.avProd.onNewComposerSettings$.subscribe( () => this.readServerSettings());
    this.componentsSubscription = this.avProd.components$.subscribe( () => this.readServerSettings());
  }

  close(): void {
    //Close function
  }
  protected displayToast(config: IToastConfig){
    // Must override
  }

  protected updateInputImageList(): IAvSettingSelectOption[] {
    const LIST: IAvSettingSelectOption[] = [];
    console.log('[ToolboxOverlayClass] updateInputImageList');
    this.avProd.inputs.forEach(element => {
      if (element.info.inputTypeNumber === AvProdInputTypeNumber.imageTile)
      {
        const OPTION: IAvSettingSelectOption = {
          valueNumber: element.info.id,
          valueStr: element.info.name,
          label: element.info.name,
          labelTranslate: element.info.name,
        }
        LIST.push(OPTION);
      }
    });
    return LIST;
  }

  protected updateInputImageOverlayList(): IAvSettingSelectOption[] {
    const LIST: IAvSettingSelectOption[] = [];
    this.avProd.inputs.forEach(element => {
      if (element.info.inputTypeNumber === AvProdInputTypeNumber.imageOverlay)
      {
        const OPTION: IAvSettingSelectOption = {
          valueNumber: element.info.id,
          valueStr: element.info.name,
          label: element.info.name,
          labelTranslate: element.info.name,
        }
        LIST.push(OPTION);
      }
    });
    if (LIST.length > 0){
      this._overlayImageAvailable = true;
    }
    else{
      this._overlayImageAvailable = false;
    }
    return LIST;
  }

  protected updateItemsBackgroundFiltered(): void {
    const NEW_LIST: IAvSettingsItemConfig[] = [];
    Object.assign(NEW_LIST, this.itemsSettingsBackground);

    const ITEM_COLOR: IAvSettingsItemConfig | undefined = NEW_LIST.find((element: IAvSettingsItemConfig) => (element.id === 'color'));
    const ITEM_INPUT: IAvSettingsItemConfig | undefined = NEW_LIST.find((element: IAvSettingsItemConfig) => (element.id === 'input'));
    if ((ITEM_COLOR !== undefined)&&(ITEM_INPUT !== undefined)) {
      const MODE: number | undefined = this.settingsBackgroundForm.get('mode')?.value;
      if (MODE === 0){
        ITEM_COLOR.visible = true;
        ITEM_INPUT.visible = false;
      }
      else if (MODE === 1){
        ITEM_COLOR.visible = false;
        ITEM_INPUT.visible = true;
      }
      else{
        ITEM_COLOR.visible = true;
        ITEM_INPUT.visible = true;
      }
    }
    this.itemsSettingsBackgroundFiltered = NEW_LIST;
  }

  /**
   * Function to update a setting in the form
   *
   * @param list
   * @param id Setting name
   * @param options Setting new options
   */
  protected settingOptionsUpdate(list: IAvSettingsItemConfig[], id: string, options: IAvSettingSelectOption[]) {
    for (let i=0; i<list.length; i++) {
      if (list[i].id === id) {
        // Setting found
        const NEW_SETTING: IAvSettingsItemConfig = {
          id: id,
          type: AvProdSettingsType.none,
          name: '',
          min: 0,
          max: 0,
          step: 1,
          options: [],
          placeholder: '',
          value: 0
        };
        Object.assign(NEW_SETTING, list[i]);

        if (options !== undefined) {
          NEW_SETTING.options = options;
        }

        list[i] = NEW_SETTING;
        break;
      }
    }
  }

  protected settingPlaceholderUpdate(list: IAvSettingsItemConfig[], id: string, placeholder: string) {
    for (let i=0; i<list.length; i++) {
      if (list[i].id === id) {
        // Setting found
        const NEW_SETTING: IAvSettingsItemConfig = {
          id: id,
          type: AvProdSettingsType.none,
          name: '',
          min: 0,
          max: 0,
          step: 1,
          options: [],
          placeholder: '',
          value: 0
        };
        Object.assign(NEW_SETTING, list[i]);

        NEW_SETTING.placeholder = placeholder;

        list[i] = NEW_SETTING;
        break;
      }
    }
  }

  protected readServerSettings() {
    //console.log('[ToolboxOverlayClass] readServerSettings');
    if (this.avProd.commsStatus.ok){
      console.log('[ToolboxOverlayClass] readServerSettings Comms OK');
      // Global settings
      this.settingsInputNamesForm.get('visible')?.setValue(this.avProd.composerSettings.overlayInputNames?.enabled);
      this.settingsBannerForm.get('visible')?.setValue(this.avProd.composerSettings.overlayBanner?.enabled);
      this.settingsTickerForm.get('visible')?.setValue(this.avProd.composerSettings.overlayTicker?.enabled);
      this.settingsTimeForm.get('visible')?.setValue(this.avProd.composerSettings.overlayTime?.enabled);
      // Style settings
      this.settingsStyleForm.get('mode')?.setValue(this.avProd.composerSettings.overlayStyle?.mode);
      this.settingsStyleForm.get('colorMain')?.setValue(this.avProd.composerSettings.overlayStyle?.colorMain);
      this.settingsStyleForm.get('colorSecondary')?.setValue(this.avProd.composerSettings.overlayStyle?.colorSecondary);
      this.settingsStyleForm.get('fontFace')?.setValue(this.avProd.composerSettings.overlayStyle?.fontFace);
      // Background settings
      this.settingsBackgroundForm.get('mode')?.setValue(this.avProd.composerSettings.videoBackground?.type);
      this.settingsBackgroundForm.get('mode')?.setValue(this.avProd.composerSettings.videoBackground?.type);
      if ((this.avProd.composerSettings.videoBackground?.inputId !== undefined)&&
          (this.avProd.composerSettings.videoBackground?.inputId > 0)){
        this.settingsBackgroundForm.get('input')?.setValue(this.avProd.composerSettings.videoBackground?.inputId);
      }
      else {
        this.settingsBackgroundForm.get('input')?.setValue(null);
      }
      if (this.avProd.composerSettings.videoBackground?.colors !== undefined) {
        this.settingsBackgroundForm.get('color')?.setValue(this.avProd.composerSettings.videoBackground?.colors[0]);
      }
      // Banner settings
      this.bannerTextList = [];
      this.settingsBannerTextsForms = [];
      if (this.avProd.composerSettings.overlayBanner?.textSelected !== undefined){
        this.bannerTextSelected = this.avProd.composerSettings.overlayBanner?.textSelected;
        this.settingsBannerForm.get('bannerTextSelected')?.setValue(this.avProd.composerSettings.overlayBanner?.textSelected);
      }
      if (this.avProd.composerSettings.overlayBanner?.texts !== undefined){
        for (let i: number = 0; i<this.avProd.composerSettings.overlayBanner?.texts?.length; i++){
          const TEXT_GROUP: FormGroup = new FormGroup([]);
          const TEXT_ITEM: IAvProdTextGroup = {
            id: this.avProd.composerSettings.overlayBanner?.texts[i].id,
            title: this.avProd.composerSettings.overlayBanner?.texts[i].title,
            text: this.avProd.composerSettings.overlayBanner?.texts[i].text,
          }
          this.bannerTextList.push(TEXT_ITEM);
          this.itemsSettingsBannerTextGroup.forEach(element => {
            TEXT_GROUP.addControl(element.id, new FormControl());
          });
          TEXT_GROUP.get('title')?.setValue(TEXT_ITEM.title);
          TEXT_GROUP.get('text')?.setValue(TEXT_ITEM.text);
          this.settingsBannerTextsForms.push(TEXT_GROUP);
        }
      }
      // Ticker settings
      this.tickerTextList = [];
      this.settingsTickerTextsForms = [];
      if (this.avProd.composerSettings.overlayTicker?.textSelected !== undefined){
        this.tickerTextSelected = this.avProd.composerSettings.overlayTicker?.textSelected;
        this.settingsTickerForm.get('tickerTextSelected')?.setValue(this.avProd.composerSettings.overlayTicker?.textSelected);
      }
      if (this.avProd.composerSettings.overlayTicker?.texts !== undefined){
        for (let i: number = 0; i<this.avProd.composerSettings.overlayTicker?.texts?.length; i++){
          const TEXT_GROUP: FormGroup = new FormGroup([]);
          const TEXT_ITEM: IAvProdTextGroup = {
            id: this.avProd.composerSettings.overlayTicker?.texts[i].id,
            text: this.avProd.composerSettings.overlayTicker?.texts[i].text,
          }
          this.tickerTextList.push(TEXT_ITEM);
          this.itemsSettingsTickerTextGroup.forEach(element => {
            TEXT_GROUP.addControl(element.id, new FormControl());
          });
          TEXT_GROUP.get('text')?.setValue(TEXT_ITEM.text);
          this.settingsTickerTextsForms.push(TEXT_GROUP);
        }
      }
      if (this.avProd.composerSettings.overlayAdvanced !== undefined){
        // HTML Graphics
        if ((this.avProd.composerSettings.overlayAdvanced.length > 0)&&
            (this.avProd.composerSettings.overlayAdvanced[0] !== undefined)) {

          this.settingsHtmlGraphForm.get('enabled')?.setValue(this.avProd.composerSettings.overlayAdvanced[0].general?.enabled);

          this.settingsHtmlGraphForm.get('htmlGraphicsPositionX')?.setValue(this.avProd.composerSettings.overlayAdvanced[0].general?.position?.x);
          this.settingsHtmlGraphForm.get('htmlGraphicsPositionY')?.setValue(this.avProd.composerSettings.overlayAdvanced[0].general?.position?.y);
          this.settingsHtmlGraphForm.get('htmlGraphicsType')?.setValue(this.avProd.composerSettings.overlayAdvanced[0].browserSettings?.providerType);
          this.settingsHtmlGraphForm.get('htmlGraphicsWebUrl')?.setValue(this.avProd.composerSettings.overlayAdvanced[0].browserSettings?.webUrl);
          this.settingsHtmlGraphForm.get('htmlGraphicsWidth')?.setValue(this.avProd.composerSettings.overlayAdvanced[0].browserSettings?.width);
          this.settingsHtmlGraphForm.get('htmlGraphicsHeight')?.setValue(this.avProd.composerSettings.overlayAdvanced[0].browserSettings?.height);

        }

        // Image settings
        if ((this.avProd.composerSettings.overlayAdvanced.length > 1)&&
            (this.avProd.composerSettings.overlayAdvanced[1] !== undefined)) {

          if ((this.avProd.composerSettings.overlayAdvanced[1].groupSettings !== undefined) &&
              (this.avProd.composerSettings.overlayAdvanced[1].groupSettings.images !== undefined)){
            for (let i: number = 0; i<this.avProd.composerSettings.overlayAdvanced[1].groupSettings.images.length; i++){
              let imgGroup: FormGroup | undefined = this.settingsImageForms[i]?? new FormGroup([]);
              if (i < this.settingsImageForms.length){
                imgGroup = this.settingsImageForms[i];
              }
              else {
                imgGroup = new FormGroup([]);
                this.itemsSettingsImageItem.forEach(element => {
                  if (imgGroup !== undefined){
                    imgGroup.addControl(element.id, new FormControl());
                  }
                });
                this.settingsImageForms.push(imgGroup);
              }
              imgGroup.get('enable')?.setValue(this.avProd.composerSettings.overlayAdvanced[1].groupSettings.images[i].general?.enabled);
              this._overlayImagesActive[i] = this.avProd.composerSettings.overlayAdvanced[1].groupSettings.images[i].general?.enabled;
              imgGroup.get('imagePositionX')?.setValue(this.avProd.composerSettings.overlayAdvanced[1].groupSettings.images[i].general?.position?.x);
              imgGroup.get('imagePositionY')?.setValue(this.avProd.composerSettings.overlayAdvanced[1].groupSettings.images[i].general?.position?.y);
              //imgGroup.get('imageInputId')?.setValue(this.avProd.composerSettings.overlayAdvanced[1].groupSettings.images[i].imageSettings?.inputId);
              if (this.avProd.composerSettings.overlayAdvanced[1].groupSettings.images[i].imageSettings?.inputId === undefined){
                imgGroup.get('imageInputId')?.setValue(null);
              }
              else if (this.avProd.composerSettings.overlayAdvanced[1].groupSettings.images[i].imageSettings?.inputId === -1){
                imgGroup.get('imageInputId')?.setValue(null);
              }
              else {
                imgGroup.get('imageInputId')?.setValue(this.avProd.composerSettings.overlayAdvanced[1].groupSettings.images[i].imageSettings?.inputId);
              }
              imgGroup.get('imageScale')?.setValue(this.avProd.composerSettings.overlayAdvanced[1].groupSettings.images[i].imageSettings?.scale);
            }
          }
        }
      }

      this.updateItemsBackgroundFiltered();

      const INPUT_IMAGE_LIST: IAvSettingSelectOption[] = this.updateInputImageList();
      this.settingOptionsUpdate(this.itemsSettingsBackground, 'input', INPUT_IMAGE_LIST);
      const INPUT_IMAGE_OVERLAY_LIST: IAvSettingSelectOption[] = this.updateInputImageOverlayList();
      this.settingOptionsUpdate(this.itemsSettingsImageItem, 'imageInputId', INPUT_IMAGE_OVERLAY_LIST);
      if (INPUT_IMAGE_OVERLAY_LIST.length > 0){
        this.settingPlaceholderUpdate(this.itemsSettingsImageItem, 'imageInputId', 'overlaySettings.selectOverlayImage');
      }
    }
  }

  protected onSettingChanged(event: any) {
    this.updateItemsBackgroundFiltered();
    this.checkFormDirty();
  }

  protected onBannerTextChanged(event: any) {
    for (let i=0; i<this.tickerTextList.length; i++){
      if (this.settingsBannerTextsForms[i] !== undefined){
        this.bannerTextList[i].text = this.settingsBannerTextsForms[i].get('text')?.value;
      }
    }
    this.checkFormDirty();
  }

  protected onBannerSelectChanged() {
    this.bannerTextSelected = this.settingsBannerForm.get('bannerTextSelected')?.value;
    console.log('[ToolboxOverlayClass] onBannerSelectChanged: ' + this.bannerTextSelected);
    this.checkFormDirty();
  }

  protected onBannerAddText() {
    let newId: number = -1;
    console.log('[ToolboxOverlayClass] onBannerAddText');
    for (let i=0; i<10; i++){
      if (this.bannerTextList.find(element => element.id === i) === undefined){
        newId = i;
        break;
      }
    }

    if (newId !== -1){
      this.bannerTextList.push({
        id: newId,
        title: 'Title ' + newId,
        text: 'Text ' + newId
      });
    }
    this.updateBannerTextForms();
    this.settingsBannerForm.get('bannerTextSelected')?.markAsDirty();
    this.checkFormDirty();
  }

  protected onBannerDeleteText(id: number | undefined) {
    if (id !== undefined){
      for (let i: number = 0; i<this.bannerTextList.length; i++){
        if (this.bannerTextList[i].id === id){
          this.bannerTextList.splice(i, 1);
          this.settingsBannerForm.get('bannerTextSelected')?.markAsDirty();
        }
      }
      if ((this.bannerTextSelected === id)&&
          (this.bannerTextList[0].id !== undefined)) {
        this.bannerTextSelected = this.bannerTextList[0].id;
        this.settingsBannerForm.get('bannerTextSelected')?.setValue(this.bannerTextSelected);
        this.settingsBannerForm.get('bannerTextSelected')?.markAsDirty();
      }
      this.updateBannerTextForms();
      this.checkFormDirty();
    }
  }

  protected onTickerTextChanged(event: any) {
    for (let i=0; i<this.tickerTextList.length; i++){
      if (this.settingsTickerTextsForms[i] !== undefined){
        this.tickerTextList[i].text = this.settingsTickerTextsForms[i].get('text')?.value;
      }
    }
    this.checkFormDirty();
  }

  protected onTickerSelectChanged() {
    this.tickerTextSelected = this.settingsTickerForm.get('tickerTextSelected')?.value;
    console.log('[ToolboxOverlayClass] onTickerSelectChanged: ' + this.tickerTextSelected);
    this.checkFormDirty();
  }

  protected onTickerAddText() {
    let NEW_ID: number = -1;
    console.log('[ToolboxOverlayClass] onTickerAddText');
    for (let i=0; i<10; i++){
      if (this.tickerTextList.find(element => element.id === i) === undefined){
        NEW_ID = i;
        break;
      }
    }

    if (NEW_ID !== -1){
      this.tickerTextList.push({
        id: NEW_ID,
        text: 'Text ' + NEW_ID
      });
    }
    this.updateTickerTextForms();
    this.settingsTickerForm.get('tickerTextSelected')?.markAsDirty();
    this.checkFormDirty();
  }

  protected onTickerDeleteText(id: number | undefined) {
    if (id !== undefined){
      for (let i: number = 0; i<this.tickerTextList.length; i++){
        if (this.tickerTextList[i].id === id){
          this.tickerTextList.splice(i, 1);
          this.settingsTickerForm.get('tickerTextSelected')?.markAsDirty();
        }
      }
      if ((this.tickerTextSelected === id)&&
          (this.tickerTextList[0].id !== undefined)) {
        this.tickerTextSelected = this.tickerTextList[0].id;
        this.settingsTickerForm.get('tickerTextSelected')?.setValue(this.tickerTextSelected);
        this.settingsTickerForm.get('tickerTextSelected')?.markAsDirty();
      }
      this.updateTickerTextForms();
      this.checkFormDirty();
    }
  }

  protected onSave() {
    const SETTINGS: IAvProdComposerSettings = {};
    // Global settings
    SETTINGS.overlayInputNames = {};
    SETTINGS.overlayInputNames.enabled = this.settingsInputNamesForm.get('visible')?.value;
    SETTINGS.overlayTime = {};
    SETTINGS.overlayTime.enabled = this.settingsTimeForm.get('visible')?.value;
    SETTINGS.overlayBanner = {};
    SETTINGS.overlayBanner.enabled = this.settingsBannerForm.get('visible')?.value;
    SETTINGS.overlayBanner.positionPreset = 2;
    SETTINGS.overlayBanner.alignment = 0;
    SETTINGS.overlayBanner.docked = true;
    SETTINGS.overlayBanner.texts = [];
    for (let i:number = 0; i<this.bannerTextList.length; i++){
      if (this.settingsBannerTextsForms[i] !== undefined){
        SETTINGS.overlayBanner.texts.push({
          id: this.bannerTextList[i].id,
          title: this.settingsBannerTextsForms[i].get('title')?.value,
          text: this.settingsBannerTextsForms[i].get('text')?.value,
        });
      }
    }
    SETTINGS.overlayBanner.textSelected = this.settingsBannerForm.get('bannerTextSelected')?.value;
    SETTINGS.overlayTicker = {};
    SETTINGS.overlayTicker.enabled = this.settingsTickerForm.get('visible')?.value;
    SETTINGS.overlayTicker.texts = [];
    for (let i:number = 0; i<this.tickerTextList.length; i++){
      if (this.settingsTickerTextsForms[i] !== undefined){
        SETTINGS.overlayTicker.texts.push({
          id: this.tickerTextList[i].id,
          text: this.settingsTickerTextsForms[i].get('text')?.value,
        });
      }
    }
    SETTINGS.overlayTicker.textSelected = this.settingsTickerForm.get('tickerTextSelected')?.value;

    // Overlay Style
    SETTINGS.overlayStyle = {};
    SETTINGS.overlayStyle.mode = this.settingsStyleForm.get('mode')?.value;
    SETTINGS.overlayStyle.colorMain = this.settingsStyleForm.get('colorMain')?.value;
    SETTINGS.overlayStyle.colorSecondary = this.settingsStyleForm.get('colorSecondary')?.value;
    SETTINGS.overlayStyle.fontFace = this.settingsStyleForm.get('fontFace')?.value;

    // Background
    SETTINGS.videoBackground = {};
    SETTINGS.videoBackground.type = this.settingsBackgroundForm.get('mode')?.value;
    if (this.avProd.composerSettings.videoBackground?.colors !== undefined) {
      SETTINGS.videoBackground.colors = [];
      const NEW_COLOR: string | undefined = this.settingsBackgroundForm.get('color')?.value;
      if (NEW_COLOR !== undefined){
        SETTINGS.videoBackground.colors.push(NEW_COLOR);
      }
    }
    if (this.settingsBackgroundForm.get('input')?.value === null){
      SETTINGS.videoBackground.inputId = -1;
    }
    else{
      SETTINGS.videoBackground.inputId = this.settingsBackgroundForm.get('input')?.value;
    }

    // Overlay Images
    if (this.avProd.composerSettings.overlayAdvanced !== undefined) {
      for (let i:number = 0; i<this.avProd.composerSettings.overlayAdvanced.length; i++){

        if ((this.avProd.composerSettings.overlayAdvanced[i].id === 2)&&
            (this.avProd.composerSettings.overlayAdvanced[i].rootType === 1)){
          // Images
          const SETTINGS_OVERLAY: IAvProdOverlaySettings = this.avProd.composerSettings.overlayAdvanced[i];

          if (SETTINGS_OVERLAY.groupSettings?.images !== undefined){

            while (SETTINGS_OVERLAY.groupSettings?.images.length < 2){
              const SETTINGS_IMAGE: IAvProdOverlayItemSettings = {
                general: {
                  enabled: false,
                  itemType: 3,
                  position: {
                    x:0,
                    y:0
                  },
                },
                imageSettings: {
                  inputId: -1,
                  scale: 1.0
                }
              }
              SETTINGS_OVERLAY.groupSettings?.images.push(SETTINGS_IMAGE);
            }
            for (let j:number = 0; j < SETTINGS_OVERLAY.groupSettings?.images.length; j++){
              if (this.settingsImageForms[j] !== undefined){
                const SETTINGS_IMAGE: IAvProdOverlayItemSettings = SETTINGS_OVERLAY.groupSettings?.images[j];
                if (SETTINGS_IMAGE.general !== undefined){
                  SETTINGS_IMAGE.general.enabled = this.settingsImageForms[j].get('enable')?.value;
                  SETTINGS_IMAGE.general.position = {};
                  SETTINGS_IMAGE.general.position.x = this.settingsImageForms[j].get('imagePositionX')?.value;
                  SETTINGS_IMAGE.general.position.y = this.settingsImageForms[j].get('imagePositionY')?.value;
                }
                if (SETTINGS_IMAGE.imageSettings !== undefined){
                  if (this.settingsImageForms[j].get('imageInputId')?.value !== null){
                    SETTINGS_IMAGE.imageSettings.inputId = this.settingsImageForms[j].get('imageInputId')?.value;
                  }
                  else{
                    SETTINGS_IMAGE.imageSettings.inputId = -1;
                  }
                  SETTINGS_IMAGE.imageSettings.scale = this.settingsImageForms[j].get('imageScale')?.value;
                }
                if ((SETTINGS_IMAGE.general?.enabled === true)&&(SETTINGS_IMAGE.imageSettings?.inputId === -1))
                {
                  let toastText: string = 'toolboxOverlay.imageOverlayNotSelected';
                  console.log('TOOLBOX OVERLAY - Overlay image available: ' + this._overlayImageAvailable);  
                  if (this._overlayImageAvailable !== true){
                    toastText = 'toolboxOverlay.imageOverlayNotAvailable';                  
                  }
                  SETTINGS_IMAGE.general.enabled = false;
                  this.displayToast({
                    options: {
                      autohide: true,
                      placement: ToastPlacement.middleCenter
                    },
                    data: {
                      closeButtonHeader: true,
                      status: ToastStatus.error,
                      title: 'general.error',
                      text: toastText,
                      alignText: 'text-center',
                      buttons: []
                    },
                  });
                }
                SETTINGS_OVERLAY.groupSettings.images[j] = SETTINGS_IMAGE;
              }
            }
          }

          const COMP_SETTINGS: IAvProdComposerSettings = {};
          COMP_SETTINGS.overlayAdvanced = [];
          COMP_SETTINGS.overlayAdvanced.push(SETTINGS_OVERLAY);
          this.avProd.azzChangeComposerSettings(COMP_SETTINGS);
          break;
        }
        else if ((this.avProd.composerSettings.overlayAdvanced[i].id === 1)&&
                 (this.avProd.composerSettings.overlayAdvanced[i].rootType === 2)&&
                 (this.settingsHtmlGraphForm.dirty === true)){
          // HTML Graphics
          const SETTINGS_OVERLAY: IAvProdOverlaySettings = {
            id: this.avProd.composerSettings.overlayAdvanced[i].id,
            general: {
              enabled: this.settingsHtmlGraphForm.get('enabled')?.value,
              position: {
                x: this.settingsHtmlGraphForm.get('htmlGraphicsPositionX')?.value,
                y: this.settingsHtmlGraphForm.get('htmlGraphicsPositionY')?.value
              }
            },
            browserSettings: {
              webUrl: this.settingsHtmlGraphForm.get('htmlGraphicsWebUrl')?.value,
              providerType: this.settingsHtmlGraphForm.get('htmlGraphicsType')?.value,
              width: this.settingsHtmlGraphForm.get('htmlGraphicsWidth')?.value,
              height: this.settingsHtmlGraphForm.get('htmlGraphicsHeight')?.value
            }
          }
          const COMP_SETTINGS: IAvProdComposerSettings = {};
          COMP_SETTINGS.overlayAdvanced = [];
          COMP_SETTINGS.overlayAdvanced.push(SETTINGS_OVERLAY);
          this.avProd.azzChangeComposerSettings(COMP_SETTINGS);
          this.settingsHtmlGraphForm.markAsPristine();
        }
      }
    }

    console.log('[ToolboxOverlayClass] onSave: ' + JSON.stringify(SETTINGS));
    this.avProd.azzChangeComposerSettings(SETTINGS);

    this.settingsBackgroundForm.markAsPristine();
    this.settingsBannerForm.markAsPristine();
    this.settingsBannerTextsForms.forEach(element => {
      element.markAsPristine();
    });
    this.settingsInputNamesForm.markAsPristine();
    this.settingsImageForms.forEach(element => {
      element.markAsPristine();
    });
    this.settingsStyleForm.markAsPristine();
    this.settingsTickerForm.markAsPristine();
    this.settingsTimeForm.markAsPristine();
    this.checkFormDirty();
  }

  protected checkFormDirty() {
    console.log('[ToolboxOverlayClass] checkFormDirty');
    this.isFormDirty =
          this.settingsBackgroundForm.dirty ||
          this.settingsInputNamesForm.dirty ||
          this.settingsBannerForm.dirty ||
          this.settingsStyleForm.dirty ||
          this.settingsTickerForm.dirty ||
          this.settingsTimeForm.dirty ||
          this.settingsHtmlGraphForm.dirty;
    this.settingsBannerTextsForms.forEach(element => {
      this.isFormDirty = this.isFormDirty || element.dirty;
    });
    this.settingsTickerTextsForms.forEach(element => {
      this.isFormDirty = this.isFormDirty || element.dirty;
    });
    this.settingsImageForms.forEach(element => {
      this.isFormDirty = this.isFormDirty || element.dirty;
    });

    if (this.applyChangesDirectly){
      if (this.isFormDirty){
        this.onSave();
      }
    }
  }

  protected updateBannerTextForms() {
    this.settingsBannerTextsForms = [];
    for (let i: number = 0; i<this.bannerTextList.length; i++){
      const TEXT_GROUP: FormGroup = new FormGroup([]);
      this.itemsSettingsBannerTextGroup.forEach(element => {
        TEXT_GROUP.addControl(element.id, new FormControl());
      });
      TEXT_GROUP.get('title')?.setValue(this.bannerTextList[i].title);
      TEXT_GROUP.get('text')?.setValue(this.bannerTextList[i].text);
      this.settingsBannerTextsForms.push(TEXT_GROUP);
    }
  }

  protected updateTickerTextForms() {
    this.settingsTickerTextsForms = [];
    for (let i: number = 0; i<this.tickerTextList.length; i++){
      const TEXT_GROUP: FormGroup = new FormGroup([]);
      this.itemsSettingsTickerTextGroup.forEach(element => {
        TEXT_GROUP.addControl(element.id, new FormControl());
      });
      TEXT_GROUP.get('text')?.setValue(this.tickerTextList[i].text);
      this.settingsTickerTextsForms.push(TEXT_GROUP);
    }
  }

  protected onClickAccordionBack(){
    console.log('[ToolboxOverlayClass] onClickAccordionBack');
  }
  protected onClickImageUpload(event: MouseEvent) {
    console.log('[ToolboxOverlayClass] onClickImageUpload');
    event.stopPropagation();
    this.modalService.show({id: ModalDialogId.producerInputAddResource, title: 'inputAdd.resourceManagement', subId: AvProdInputTypeNumber.imageOverlay}, false);
  }
  protected onClickBackgroundUpload(event: MouseEvent) {
    console.log('[ToolboxOverlayClass] onClickBackgroundUpload');
    event.stopPropagation();
    this.modalService.show({id: ModalDialogId.producerInputAddResource, title: 'inputAdd.resourceManagement', subId: AvProdInputTypeNumber.imageTile}, false);
  }
}
