export interface ILanguage {
    iso: string;
    name: string;
    code: string;
}

export interface IDeviceLanguage {
  default: ILanguage,
  preferred: ILanguage
}
