import { FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

import { ToastPlacement, ToastStatus } from '../../enums/common.enum';
import { IToastConfig } from '../../interfaces/utils/utils.interface';
import { IAvSettingsItemConfig } from '../../interfaces/av-producer/event-av-producer.interface';
import { IAvProdComposerSettings } from '../../interfaces/av-producer/composer-settings.interface';
import {
  AV_PROD_OPTIONS_TILE_TRANSITION,
  AV_PROD_OPTIONS_TILE_TRANSITION_DIRECTION,
} from '../../const/av-producer-options';
import { AvProdSettingsType } from '../../const/av-producer.const';
import { AvProducerService } from '../../services/av-producer/av-producer.service';

export class ProducerSettingsVideoClass {
  protected useSaveButton: boolean = false;
  protected settingsForm: FormGroup = new FormGroup([]);
  protected isBusy: boolean = false;
  protected isDirty: boolean = false;
  protected isResetNeeded: boolean = false;

  protected composerSettingsSubscription: Subscription = new Subscription();

  protected items: IAvSettingsItemConfig[] = [
    {
      id: 'tileTransition',
      type: AvProdSettingsType.selectComboNumber,
      name: 'producerSettings.tileTransition',
      min: 0,
      max: 0,
      step: 1,
      options: AV_PROD_OPTIONS_TILE_TRANSITION,
      placeholder: '',
      config: {
        mode: 'native'
      }
    },
    {
      id: 'tileTransitionDirection',
      type: AvProdSettingsType.selectComboNumber,
      name: 'producerSettings.tileTransitionDirection',
      min: 0,
      max: 0,
      step: 1,
      options: AV_PROD_OPTIONS_TILE_TRANSITION_DIRECTION,
      placeholder: '',
      config: {
        mode: 'native'
      }
    },
    {
      id: 'tileTransitionSpeed',
      type: AvProdSettingsType.numberSlider,
      name: 'producerSettings.tileTransitionSpeed',
      min: 1,
      max: 20,
      step: 1,
      options: [],
      placeholder: '',
    },
    {
      id: 'layoutTransition',
      type: AvProdSettingsType.switchBoolean,
      name: 'producerSettings.layoutTransition',
      min: 0,
      max: 0,
      step: 1,
      options: [],
      placeholder: '',
    },
    {
      id: 'layoutTransitionSpeed',
      type: AvProdSettingsType.numberSlider,
      name: 'producerSettings.layoutTransitionSpeed',
      min: 1,
      max: 20,
      step: 1,
      options: [],
      placeholder: '',
    },
  ];

  constructor(protected avProd: AvProducerService) {}

  protected init(): void {
    this.items.forEach((element: IAvSettingsItemConfig) => {
      this.settingsForm.addControl(element.id, new FormControl());
    });

    this.readServerSettings();
    this.composerSettingsSubscription =  this.avProd.onNewComposerSettings$
      .subscribe((settings: IAvProdComposerSettings) =>
        this.onNewServerSettings()
      );
  }

  protected destroy(): void {
      this.composerSettingsSubscription?.unsubscribe();
  }

  protected emitDirty(value: boolean): void {
    // must override
  }

  protected displayToast(config: IToastConfig): void {
    // must override
  }

  protected readServerSettings(): void {
    if (!this.isDirty) {
      this.settingsForm.get('tileTransition')?.setValue(this.avProd.composerSettings.videoTransitionMode);
      this.settingsForm.get('tileTransitionDirection')?.setValue(this.avProd.composerSettings.videoTransitionDirection);
      this.settingsForm.get('tileTransitionSpeed')?.setValue(this.avProd.composerSettings.videoTransitionSpeed, {
          emitEvent: true,
        });
      if (this.avProd.composerSettings.layoutTransitionMode === 0) {
        this.settingsForm.get('layoutTransition')?.setValue(false);
      } else {
        this.settingsForm.get('layoutTransition')?.setValue(true);
      }
      this.settingsForm.get('layoutTransitionSpeed')?.setValue(this.avProd.composerSettings.layoutTransitionSpeed);

      this.settingsForm.markAsPristine();
      this.isResetNeeded = false;
      this.isDirty = false;
      this.emitDirty(this.isDirty);
    } else {
      this.isResetNeeded = true;
    }
  }

  protected onSettingChanged(event: any): void {
    this.refreshDirtyFlags();
    if ((this.useSaveButton === false)&&(this.isDirty)){
      this.onSave();
    }
  }

  protected refreshDirtyFlags(): void {
    this.isDirty = this.settingsForm.dirty;
    if (this.isDirty) {
      this.isResetNeeded = true;
    }
    this.emitDirty(this.isDirty);
  }

  protected onNewServerSettings(): void {
    if (this.isDirty) {
      this.isResetNeeded = true;
    } else {
      this.readServerSettings();
    }
  }

  protected onSave(): void {
    this.isBusy = true;

    let settings: IAvProdComposerSettings = {};
    settings.videoTransitionMode = this.settingsForm.get('tileTransition')?.value;
    settings.videoTransitionDirection = this.settingsForm.get('tileTransitionDirection')?.value;
    settings.videoTransitionSpeed = this.settingsForm.get('tileTransitionSpeed')?.value;
    let valueLayoutTransition: boolean = this.settingsForm.get('layoutTransition')?.value;
    if (valueLayoutTransition) {
      settings.layoutTransitionMode = 1;
    } else {
      settings.layoutTransitionMode = 0;
    }
    settings.layoutTransitionSpeed = this.settingsForm.get('layoutTransitionSpeed')?.value;
    console.log('[ProducerSettingsVideoClass] onSave');
    this.settingsForm.markAsPristine();

    this.avProd.azzChangeComposerSettings(settings);

    this.displayToast({
      options: {
        autohide: true,
        placement: ToastPlacement.bottomRight,
      },
      data: {
        closeButtonHeader: true,
        status: ToastStatus.success,
        title: 'general.done',
        text: 'producer.settingsSavedSuccess',
        alignText: 'text-center',
        buttons: [],
      },
    });

    this.refreshDirtyFlags();
    this.isBusy = false;
  }

  protected onReset(): void {
    this.isDirty = false;
    this.readServerSettings();
    this.emitDirty(this.isDirty);
  }
}
