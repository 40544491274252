import { Inject, Injectable } from '@angular/core';
import {
  OAuth2AuthenticateOptions,
  GenericOAuth2,
} from '@capacitor-community/generic-oauth2';
import { first, from, Observable, of, switchMap, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { ENV_MOBILE, ENV_WEB } from '@azz-life-streamer/environments';
import { COMMON, DeviceService, UserService } from '@azz-life-streamer/shared';


@Injectable({
  providedIn: 'root',
})
export class OAuthService {
  constructor(@Inject('source') private source: string,
              private deviceService: DeviceService,
              private userService: UserService) { }

  logout(): void {
    const G_AUTH: string | null = localStorage.getItem(COMMON.storageKeys.googleAuth);
    const M_AUTH: string | null = localStorage.getItem(COMMON.storageKeys.microsoftAuth);
    const A_AUTH: string | null = localStorage.getItem(COMMON.storageKeys.appleAuth);

    if (G_AUTH !== null) {
      // SSO Google logout if necessary
      this.logoutGoogle(G_AUTH).subscribe();
    } else if (M_AUTH !== null) {
      // SSO Microsoft logout if necessary
      this.logoutMicrosoft(M_AUTH).subscribe();
    } else if (A_AUTH !== null) {
      // TODO: To be finished when iOS SSO use this plugin
      // SSO Apple logout if necessary
      // this.logoutApple(A_AUTH);
      this.userService.logout();
    } else {
      this.userService.logout();
    }
  }

  /******MICROSOFT******/

  loginMicrosoft(): Observable<boolean> {
    const OPTIONS: OAuth2AuthenticateOptions | undefined = this.getMicrosoftOptions();

    if (!OPTIONS) {
      return of(false);
    }

    //Just for testing app mobile in navigator
    if(this.source === 'mobile' && this.deviceService.device?.platform === 'web') {
      OPTIONS.appId = ENV_WEB.oAuthMicrosoft.appId;
    }
    return from(GenericOAuth2.authenticate(OPTIONS))
      .pipe(
        first(),
        tap((response: any) => {
          console.warn('MS response: ', response);
          if (response) {
            localStorage.setItem(COMMON.storageKeys.microsoftAuth, response['access_token']);
          }
        }),
      );
  }

  logoutMicrosoft(token: string): Observable<boolean> {
    const OPTIONS: OAuth2AuthenticateOptions | undefined = this.getMicrosoftOptions();

    if (!OPTIONS) {
      return of(false);
    }
    return from(GenericOAuth2.logout(OPTIONS, token)).pipe(
      first(),
      map(() => {
        localStorage.removeItem(COMMON.storageKeys.microsoftAuth);
        this.userService.logout();
        return true;
      }),
      catchError((reason) => {
        console.error('OAuth rejected', reason);
        return throwError(() => false);
      }),
    );
  }

  /******GOOGLE******/

  loginGoogle(versionCode: number): Observable<boolean> {
    const OPTIONS: OAuth2AuthenticateOptions | undefined = this.getGoogleOptions();
    const CLIENT_ID: string | undefined = this.source === 'web' ? OPTIONS?.web?.appId :
      this.deviceService.device?.platform === 'android' ? OPTIONS?.android?.appId : OPTIONS?.ios?.appId;

    if (!OPTIONS) {
      return of(false);
    }

    return from(GenericOAuth2.authenticate(OPTIONS)).pipe(
      first(),
      map((response: any) => {
        console.warn('Google response: ', response);
        if (response) {
          localStorage.setItem(COMMON.storageKeys.googleAuth, response['access_token']);
          return {
            id: response.id,
            name: response.name,
            email: response.email,
            clientId: CLIENT_ID,
            version: versionCode,
          };
        } else {
          return throwError(() => 'Unable to login');
        }
      }),
      switchMap((profile: any) => this.userService.loginGoogle(profile)),
    );
  }

  logoutGoogle(token: string): Observable<boolean> {
    const OPTIONS: OAuth2AuthenticateOptions | undefined =
      this.getGoogleOptions();

    if (!OPTIONS) {
      return of(false);
    }

    return from(GenericOAuth2.logout(OPTIONS, token)).pipe(
      first(),
      map((response: boolean) => {
        console.warn('Google logout response: ' + response);
        localStorage.removeItem(COMMON.storageKeys.googleAuth);
        this.userService.logout();
        return true;
      }),
      catchError((reason) => {
        console.error('OAuth rejected', reason);
        return throwError(() => false);
      }),
    );
  }

  /*****APPLE****/
  // TODO: To be finished, meanwhile community-capacitor/apple-sign-in is being used
  /*loginApple(): Observable<boolean> {
    const OPTIONS: OAuth2AuthenticateOptions | undefined = this.getAppleOptions();

    if (!OPTIONS) {
      return of(false);
    }

    return from(GenericOAuth2.authenticate(OPTIONS))
      .pipe(
        first(),
        tap((response: any) => {
          console.warn('Apple response: ', response);
          if (response) {
            // only if you include a resourceUrl protected user values are included in the response!
            localStorage.setItem(COMMON.storageKeys.appleAuth, response['access_token']);
          }
        })
      );
  }*/

  /* logoutApple(token: string): Observable<boolean> {
    const OPTIONS: OAuth2AuthenticateOptions | undefined = this.getAppleOptions();

    if (!OPTIONS) {
      return of(false);
    }
    return from(GenericOAuth2.logout(OPTIONS, token))
      .pipe(
        first(),
        map(() => {
          console.warn('Apple logout response');
          localStorage.removeItem(COMMON.storageKeys.appleAuth);
          this.userService.logout();
          return true;
        }),
        catchError(reason => {
          console.error('OAuth rejected', reason);
          return throwError(() => false);
        }),
      );
  }*/

  private getMicrosoftOptions(): OAuth2AuthenticateOptions | undefined {
    return this.source === 'mobile' ? ENV_MOBILE.oAuthMicrosoft : this.source === 'web' ? ENV_WEB.oAuthMicrosoft : undefined;
  }

  private getGoogleOptions(): OAuth2AuthenticateOptions | undefined {
    return this.source === 'mobile' ? ENV_MOBILE.oAuthGoogle : this.source === 'web' ? ENV_WEB.oAuthGoogle : undefined;
  }

  /*private getAppleOptions(): OAuth2AuthenticateOptions | undefined {
    return (this.source === 'mobile') ? ENV_MOBILE.oAuthApple : (this.source === 'web') ?  ENV_WEB.oAuthApple : undefined;
  }*/
}
