import { FormControl, FormGroup } from '@angular/forms';
import { timer } from 'rxjs';
import {
  FileItem,
  FileUploader,
  FileUploaderOptions,
  ParsedResponseHeaders,
} from 'ng2-file-upload';

import { ENV_COMMON } from '@azz-life-streamer/environments';
import {
  AV_PROD_OPTIONS_INPUT_RESOURCE_TYPES,
  AuthService,
  AvProdInputTypeNumber,
  AvProdSettingsType,
  EventsService,
  IAvSettingsItemConfig,
  IToastConfig,
  ToastPlacement,
  ToastStatus,
  UserService,
  MAX_UPLOAD_FILE_SIZE_BYTES,
  IOrganizationInfo,
  AV_PROD_OPTIONS_INPUT_RESOURCE_TYPES_PROFILE,
} from '@azz-life-streamer/shared';

export class InputAddResourceUploadClass {
  protected _resourceType: AvProdInputTypeNumber = AvProdInputTypeNumber.none;
  protected _resourceSection: 'assets' |'profile' = 'assets';
  protected _baseUrl: string = '';
  protected _tokenProducer: string = '';
  protected _target: 'cloud' | 'event' = 'event';
  protected _allowTypeChange: boolean = false;
  protected onlyForThisEvent: boolean = false;
  protected preload: boolean = true;
  protected fileAccept: string = '';
  protected userStorageLeft: number | undefined;

  protected uploader: FileUploader = new FileUploader({
    url: this._baseUrl,
  });
  protected settingsResource: FormGroup = new FormGroup([]);
  protected itemResourceType: IAvSettingsItemConfig = {
    id: 'resourceType',
    type: AvProdSettingsType.selectComboNumber,
    name: 'inputAdd.resourceType',
    min: 0,
    max: 0,
    step: 1,
    options: AV_PROD_OPTIONS_INPUT_RESOURCE_TYPES,
    placeholder: 'inputAdd.selectResourceType',
    value: undefined,
    config: {
      mode: 'native'
    }
  };

  protected itemResourceTypeProfile: IAvSettingsItemConfig = {
    id: 'resourceTypeProfile',
    type: AvProdSettingsType.selectComboNumber,
    name: 'inputAdd.resourceType',
    min: 0,
    max: 0,
    step: 1,
    options: AV_PROD_OPTIONS_INPUT_RESOURCE_TYPES_PROFILE,
    placeholder: 'inputAdd.selectResourceType',
    value: undefined,
    config: {
      mode: 'native'
    }
  };

  protected itemOnlyForThisEvent: IAvSettingsItemConfig = {
    id: 'onlyForThisEvent',
    type: AvProdSettingsType.switchBoolean,
    name: 'inputAdd.onlyForThisEvent',
    min: 0,
    max: 0,
    step: 1,
    options: [],
    placeholder: '',
    info: 'inputAdd.onlyForThisEventInfo',
    value: this.onlyForThisEvent,
  };

  protected itemPreload: IAvSettingsItemConfig = {
    id: 'preload',
    type: AvProdSettingsType.switchBoolean,
    name: 'inputAdd.preload',
    min: 0,
    max: 0,
    step: 1,
    options: [],
    placeholder: '',
    info: 'inputAdd.preloadInfo',
    value: this.preload,
  };

  constructor(protected userService: UserService,
              protected authService: AuthService,
              protected events: EventsService) {
    this.settingsResource.addControl(this.itemResourceType.id, new FormControl());
    this.settingsResource.addControl(this.itemResourceTypeProfile.id, new FormControl());
    this.settingsResource.addControl(this.itemOnlyForThisEvent.id, new FormControl());
    this.settingsResource.addControl(this.itemPreload.id, new FormControl());
    this.settingsResource.controls['onlyForThisEvent']?.setValue(this.onlyForThisEvent);
    this.settingsResource.controls['preload']?.setValue(this.preload);

    this.uploader.onWhenAddingFileFailed = (item, filter, options) => this.onFileError(item, filter, options);
    this.uploader.onCompleteItem = (item, response, status, headers) =>
      this.onUploadCompleted(item, response, status, headers);
  }

  protected setResourceSection(value: 'assets' | 'profile'): void {
    this._resourceSection = value;
    this.setResourceType(this._resourceType);
  }

  protected setResourceType(value: AvProdInputTypeNumber): void {
    console.log('[InputAddResource] setResourceType: ' + value);
    if (value !== undefined) {
      this._resourceType = value;
      if (this._resourceSection === 'profile'){
        this.settingsResource.controls['resourceTypeProfile']?.setValue(
          this._resourceType
        );
      }
      else {
        this.settingsResource.controls['resourceType']?.setValue(
          this._resourceType
        );
      }
      if (this._resourceType === AvProdInputTypeNumber.imageTile ||
        this._resourceType === AvProdInputTypeNumber.imageOverlay) {
        this.uploader.setOptions({
          url: this._baseUrl,
          allowedFileType: ['image'],
        });
      }
      this.setUploaderOptions();
      this.updateTypeChangeEnable();
    }
  }

  protected setAllowTypeChange(value: boolean): void {
    console.log('[InputAddResourceUploadClass] setAllowTypeChange: ' + value);
    this._allowTypeChange = value;
    this.updateTypeChangeEnable();
  }

  protected updateTypeChangeEnable(): void {
    if (!this._allowTypeChange) {
      this.settingsResource.controls['resourceType']?.disable();
      this.settingsResource.controls['resourceTypeProfile']?.disable();
    } else {
      this.settingsResource.controls['resourceType']?.enable();
      this.settingsResource.controls['resourceTypeProfile']?.enable();
    }
  }

  protected setBaseUrl(value: string): void {
    console.log('[InputAddResourceUploadClass] setBaseUrl: ' + value);
    if (value !== undefined) {
      this._baseUrl = value;
      this.setUploaderOptions();
    }
  }

  protected setTokenProducer(value: string): void {
    if (value !== this._tokenProducer) {
      this._tokenProducer = value;
      this.setUploaderOptions();
    }
  }

  protected setTarget(value: 'cloud' | 'event'): void {
    if (value !== this._target) {
      this._target = value;
      if (this._target === 'cloud') {
        this._baseUrl = ENV_COMMON.uploadUrl;
      }
      this.setUploaderOptions();
    }
  }

  protected init(): void {
    //this.setResourceType(this._resourceType);
    this.uploader.onAfterAddingFile = (item: FileItem) => {
      this.onAfterAddingFile(item);
    };
  }

  protected destroy(): void {}

  protected displayToast(config: IToastConfig): void {
    // To be overridden
  }

  protected formatSize(size: number): string {
    let scaledSize: number = size;
    let units: string[] = [' B', ' KB', ' MB', ' GB'];
    let iterations: number = 0;

    if (scaledSize < 0) {
      scaledSize = 0;
    }

    while (scaledSize > 1024 && iterations < units.length - 1) {
      scaledSize = scaledSize / 1024;
      iterations++;
    }

    return Number(scaledSize.toFixed(1)).toString() + units[iterations];
  }

  protected setUploaderOptions(): void {
    let options: FileUploaderOptions;
    let org: IOrganizationInfo | undefined = this.userService.organization;

    if ((this.userService.orgId !== 0)&&(org !== undefined)){
      if ((org !== undefined) &&
          (org.storageUsed !== undefined) &&
          (org.storageTotal !== undefined)){
        this.userStorageLeft = (org.storageTotal - org.storageUsed) * 1000; // User storage data units is KB
      }
      else{
        this.userStorageLeft = undefined;
      }
    }
    else{
      if ((this.userService.user.storageTotal !== undefined) &&
          (this.userService.user.storageUsed !== undefined)){
        this.userStorageLeft = (this.userService.user.storageTotal - this.userService.user.storageUsed) * 1000; // User storage data units is KB
      }
      else{
        this.userStorageLeft = undefined;
      }
    }
    console.log('[InputAddResourceUploadClass] setUploaderOptions, Storage left: ' + this.userStorageLeft);

    if (this._target === 'cloud') {
      options = {
        url: this._baseUrl,
        disableMultipart: false,
        autoUpload: true,
        method: 'post',
        itemAlias: 'file',
        additionalParameter: {
          user: this.userService.user.id,
          organizationId: this.userService.orgId,
          token: this.authService.accessToken,
          resourceType: this._resourceType,
          preload: this._resourceSection !== 'profile'? this.preload : false,
        },
      };
    } else {
      options = {
        url: this._baseUrl,
        disableMultipart: false,
        autoUpload: true,
        method: 'post',
        itemAlias: 'fileToUpload',
        additionalParameter: {
          token: this._tokenProducer,
          resourceType: this._resourceType,
          moveToArchive: !this.onlyForThisEvent,
        },
      };
    }

    if (this._resourceType === AvProdInputTypeNumber.imageTile ||
        this._resourceType === AvProdInputTypeNumber.imageOverlay ||
        this._resourceType === AvProdInputTypeNumber.profilePicture ||
        this._resourceType === AvProdInputTypeNumber.profileBanner) {
      options.allowedFileType = ['image'];
      options.maxFileSize = MAX_UPLOAD_FILE_SIZE_BYTES.img * 1024 * 1024; // 5 MB
      this.fileAccept = 'image/*';
    } else if (this._resourceType === AvProdInputTypeNumber.document) {
      options.allowedFileType = ['pdf'];
      options.maxFileSize = MAX_UPLOAD_FILE_SIZE_BYTES.doc * 1024 * 1024; // 10 MB
      this.fileAccept = 'application/pdf';
    } else if (this._resourceType === AvProdInputTypeNumber.videoAudioClip) {
      options.allowedFileType = ['video'];
      options.maxFileSize = MAX_UPLOAD_FILE_SIZE_BYTES.video * 1024 * 1024; // 100 MB
      this.fileAccept = 'video/*';
    } else if (this._resourceType === AvProdInputTypeNumber.audioClip) {
      options.allowedFileType = ['audio'];
      options.maxFileSize = MAX_UPLOAD_FILE_SIZE_BYTES.audio * 1024 * 1024; // 10 MB
      this.fileAccept = 'audio/*';
    }
    this.uploader.setOptions(options);
  }

  protected onSettingChanged(setting: IAvSettingsItemConfig): void  {
    if (setting.id === 'resourceType') {
      this.setResourceType(this.settingsResource.controls['resourceType']?.value);
      this.uploader.clearQueue();
    }
    if (setting.id === 'onlyForThisEvent') {
      this.onlyForThisEvent = this.settingsResource.controls['onlyForThisEvent']?.value;
      this.setUploaderOptions();
    }
    if (setting.id === 'preload') {
      this.preload = this.settingsResource.controls['preload']?.value;
      this.setUploaderOptions();
    }
  }

  protected onSelectFiles(event: any): void {
    console.log('[InputAddResourceUpload] onSelectFiles: ' + JSON.stringify(event));
  }

  protected onFileError(item: any, filter: any, options: any): void  {
    let reasonText: string = 'inputAdd.errorFileGeneric';
    if (filter?.name === 'fileType') {
      reasonText = 'inputAdd.errorFileType';
    } else if (filter?.name === 'fileSize') {
      reasonText = 'inputAdd.errorFileSize';
    }
    this.displayToast({
      options: {
        placement: ToastPlacement.middleCenter,
        autohide: true,
      },
      data: {
        status: ToastStatus.error,
        text: reasonText,
        closeButtonBody: true,
      },
    });
    console.log('[InputAddResourceUpload] onUploadError: ' + JSON.stringify(filter) , {item, options} );
  }

  protected onAfterAddingFile(item:FileItem): any {
    item.withCredentials = false;
    console.log('[InputAddResourceUpload] onAfterAddingFile: ', {item});
    let org: IOrganizationInfo | undefined = this.userService.organization;

    if ((this.userService.orgId !== 0)&&(org !== undefined)){
      if ((org !== undefined) &&
          (org.storageUsed !== undefined) &&
          (org.storageTotal !== undefined)){
        this.userStorageLeft = (org.storageTotal - org.storageUsed) * 1000; // User storage data units is KB
      }
      else{
        this.userStorageLeft = undefined;
      }
    }
    else{
      if ((this.userService.user.storageTotal !== undefined) &&
          (this.userService.user.storageUsed !== undefined)){
        this.userStorageLeft = (this.userService.user.storageTotal - this.userService.user.storageUsed) * 1000; // User storage data units is KB
      }
      else{
        this.userStorageLeft = undefined;
      }
    }
    console.log('[InputAddResourceUploadClass] setUploaderOptions, Storage left: ' + this.userStorageLeft);

    if (this._target === 'cloud') {
      if ((this.userStorageLeft !== undefined)&&
          (item.file.size > this.userStorageLeft)){
        item.cancel();
        item.remove();
        this.displayToast({
          options: {
            placement: ToastPlacement.middleCenter,
            autohide: true,
            delay: 3000,
          },
          data: {
            status: ToastStatus.error,
            text: 'inputAdd.errorFileSizeCloud',
            closeButtonBody: true,
          },
        });
      }
    }
    else {
      if ((this.userStorageLeft !== undefined)&&
          (item.file.size > this.userStorageLeft)){
        this.displayToast({
          options: {
            placement: ToastPlacement.middleCenter,
            autohide: true,
            delay: 3000,
          },
          data: {
            status: ToastStatus.warning,
            text: 'inputAdd.errorFileSizeCloud',
            closeButtonBody: true,
          },
        });
      }
    }
  }

  protected onUploadCompleted(item: FileItem,
                              response: string,
                              status: number,
                              headers: ParsedResponseHeaders): void  {
    console.log('[InputAddResourceUpload] onUploadCompleted: ', {item, response, status, headers});
    timer(1000)
      .subscribe(() => this.events.forcePollingChanges());
    if (item.isUploaded && item.isSuccess){
      this.displayToast({
        options: {
          placement: ToastPlacement.middleCenter,
          autohide: true,
          delay: 3000,
        },
        data: {
          status: ToastStatus.success,
          text: 'inputAdd.assetUploadedInfo',
          closeButtonBody: true,
        },
      });
    }
    else{
      this.displayToast({
        options: {
          placement: ToastPlacement.middleCenter,
          autohide: true,
          delay: 3000,
        },
        data: {
          status: ToastStatus.error,
          text: 'inputAdd.assetUploadedError',
          closeButtonBody: true,
        },
      });
    }
  }
}
