import { FormControl, FormGroup } from '@angular/forms';
import { AvProdInputType, AvProdSettingsType } from '../const/av-producer.const';
import { IEventAsset } from '../interfaces/events/event.interface';
import { IAlertData, IToastConfig } from '../interfaces/utils/utils.interface';
import { AuthService } from '../services/auth/auth.service';
import { UserService } from '../services/user/user.service';
import { IAvSettingsItemConfig } from '../interfaces/av-producer/event-av-producer.interface';
import { EventsService } from '../services/events/events.service';
import { ENV_COMMON } from '@azz-life-streamer/environments';
import { ToastPlacement, ToastStatus } from '../enums/common.enum';


export class AssetItemClass {

  protected _asset: IEventAsset | undefined;
  protected imgUrl: string = '';
  protected iconClass: string = 'bi bi-image';
  protected nameText: string = '';

  protected assetForm: FormGroup = new FormGroup([]);

  protected itemsAll: IAvSettingsItemConfig[] = [
    {
      id: 'name',
      type: AvProdSettingsType.text,
      name: 'manager.storage.assetName',
      min: 0,
      max: 0,
      step: 1,
      options: [],
      placeholder: '',
      value: ''
    },
    {
      id: 'preload',
      type: AvProdSettingsType.switchBoolean,
      name: 'manager.storage.assetPreload',
      min: 0,
      max: 0,
      step: 1,
      options: [],
      placeholder: '',
      value: 0
    }
  ]

  constructor(protected userService: UserService,
              protected authService: AuthService,
              protected events: EventsService){
    this.itemsAll.forEach((element: IAvSettingsItemConfig) => {
      this.assetForm.addControl(element.id, new FormControl());
    });
  }

  protected displayAlert(data: IAlertData){
    // Must override
  }

  protected displayToast(config: IToastConfig): void {
    // Must override
  }

  protected setAssetId(value: number) {
    console.log('[AssetItemClass] setAssetId id:' + value);
    this.events.getUserAsset(value)
      .pipe()
      .subscribe(answer => {
        if (answer !== null){
          this.setAsset(answer);
        }
      }
    );
  }

  protected setAsset(value: IEventAsset) {
    console.log('[AssetItemClass] setAssetId:' + JSON.stringify(value));
    this._asset = value;
    this.nameText = this._asset.name + ' (' + this.formatSize(this._asset.size) + ')';
    switch(this._asset.type){
      case AvProdInputType.imageTile:
        this.iconClass = 'bi bi-image';
        break;
      case AvProdInputType.videoAudioClip:
        this.iconClass = 'bi bi-play-btn';
        break;
      case AvProdInputType.imageOverlay:
        this.iconClass = 'bi bi-image-alt';
        break;
      case AvProdInputType.audioClip:
        this.iconClass = 'bi bi-music-note-list';
        break;
      case AvProdInputType.document:
        this.iconClass = 'bi bi-filetype-pdf';
        break;
    }

    this.assetForm.get('name')?.setValue(this._asset.name);
    this.assetForm.get('preload')?.setValue(this._asset.preload);

    if ((this.userService.anonymousUser === false)&&
        (this.userService.user.id !== undefined)&&
        (this._asset.id !== undefined)){
      switch(this._asset.type){
        case AvProdInputType.audioClip:
          this.imgUrl = '/assets/shared/img/asset-sound-thumbnail.png';
          break;
        case AvProdInputType.document:
          this.imgUrl = '/assets/shared/img/asset-document-thumbnail.png';
          break;
        default:
          if (ENV_COMMON.production === true){
            this.imgUrl = 'https://thumbnails.azzulei.tv/' + this.userService.user.id + '/' + this._asset.id + '?token=' + this.authService.accessToken;
          }
          else{
            this.imgUrl = 'https://dev-thumbnails.azzulei.tv/' + this.userService.user.id + '/' + this._asset.id + '?token=' + this.authService.accessToken;
          }
          break;
      }

      console.log('[AssetItemClass] setAsset imgUrl:' + this.imgUrl);
    }
  }

  protected init() {
  }

  protected formatSize(size: number): string {
    let scaledSize: number = size;
    let units: string[] = [' B',' KB',' MB',' GB'];
    let iterations: number = 0;

    while ((scaledSize > 1024)&&(iterations < units.length-1))
    {
      scaledSize = scaledSize / 1024;
      iterations++;
    }

    return (Number(scaledSize.toFixed(1)).toString() + units[iterations]);
  }

  protected onSettingChanged(event: any){
    console.log('[AssetItemClass] onSettingChanged:' + JSON.stringify(event));
    this.SaveAssetSettings();
  }

  protected SaveAssetSettings(){
    if (this._asset?.id !== undefined){
      let id: number = this._asset?.id;
      let name: string | undefined = this.assetForm.get('name')?.value;
      let preload: boolean | undefined = this.assetForm.get('preload')?.value;

      this.events.updateUserAsset(this._asset?.id, name, preload)
        .pipe()
        .subscribe(answer => {
          this.setAssetId(id);
        }
      );
    }
  }

  protected toggleAssetPreload(event: any){
    if (this._asset?.id !== undefined){
      let id: number = this._asset?.id;
      let name: string | undefined = this._asset?.name;
      let preload: boolean | undefined = this._asset?.preload;

      this.events.updateUserAsset(this._asset?.id, name, preload)
        .pipe()
        .subscribe(answer => {
          this.setAssetId(id);
        }
      );
      if (preload === true){
        this.displayToast({
          options: {
            autohide: true,
            placement: ToastPlacement.middleCenter
          },
          data: {
            closeButtonHeader: true,
            status: ToastStatus.success,
            title: 'general.done',
            text: 'manager.storage.preloadOnMessage',
            alignText: 'text-center',
            buttons: []
          },
        });
      } else{
        this.displayToast({
          options: {
            autohide: true,
            placement: ToastPlacement.middleCenter
          },
          data: {
            closeButtonHeader: true,
            status: ToastStatus.info,
            title: 'general.done',
            text: 'manager.storage.preloadOffMessage',
            alignText: 'text-center',
            buttons: []
          },
        });
      }
    }
  }
}
