import { Subscription, interval } from 'rxjs';

import { IAzzInfoScreen } from '../interfaces/utils/utils.interface';
import { InfoScreenMediaType } from '../enums/utils.enum';


export class InfoCarouselClass {

  protected index: number = 0;
  protected playing: boolean = true;
  protected intervalTime: number = 5000;
  protected intervalSubscription: Subscription | undefined;

  protected items: IAzzInfoScreen[] = [
    {
      sectionIcon: 'bi bi-record',
      mediaType: InfoScreenMediaType.image,
      mediaUrl: '/assets/shared/img/carousel/collaborators.jpg',
      styleClass: '',
      title: 'infoScreen.shareCollabTitle',
      textItems: [
        {
          text: 'infoScreen.shareCollabDescription',
          textBullets: []
        }
      ],
      buttons: [
        {
          text: '',
          url: ''
        }
      ]
    },
    {
      sectionIcon: 'bi bi-record',
      mediaType: InfoScreenMediaType.image,
      mediaUrl: '/assets/shared/img/carousel/cameras.jpg',
      styleClass: '',
      title: 'infoScreen.camerasTitle',
      textItems: [
        {
          text: 'infoScreen.camerasDescription',
          textBullets: []
        }
      ],
      buttons: [
        {
          text: '',
          url: ''
        }
      ]
    },
    {
      sectionIcon: 'bi bi-record',
      mediaType: InfoScreenMediaType.image,
      mediaUrl: '/assets/shared/img/carousel/viewers.jpg',
      styleClass: '',
      title: 'infoScreen.shareViewersTitle',
      textItems: [
        {
          text: 'infoScreen.shareViewersDescription',
          textBullets: []
        }
      ],
      buttons: [
        {
          text: '',
          url: ''
        }
      ]
    },
    {
      sectionIcon: 'bi bi-record',
      mediaType: InfoScreenMediaType.image,
      mediaUrl: '/assets/shared/img/carousel/producer.jpg',
      styleClass: '',
      title: 'infoScreen.produceTitle',
      textItems: [
        {
          text: 'infoScreen.produceDescription',
          textBullets: []
        }
      ],
      buttons: [
        {
          text: '',
          url: ''
        }
      ]
    },
  ]

  constructor() {
    // do nothing
  }

  protected init(): void {
    if (this.intervalSubscription !== undefined) this.intervalSubscription.unsubscribe();
    this.intervalSubscription = interval(this.intervalTime).subscribe(() => this.moveNext());
    this.playing = true;
  }

  protected destroy(): void {
    if (this.intervalSubscription !== undefined) this.intervalSubscription.unsubscribe();
  }

  protected moveNext(): void {
    this.index = (this.index + 1) % this.items.length;
  }

  protected movePrev(): void {
    if (this.index > 0) this.index--;
  }

  protected moveGoTo(newIndex: number): void {
    if ((newIndex >= 0) && (newIndex < this.items.length)) {
      this.index = newIndex;
    }
  }

  protected start(): void {
    if (!this.playing) {
      if (this.intervalSubscription !== undefined) this.intervalSubscription.unsubscribe();
      this.intervalSubscription = interval(this.intervalTime).subscribe(() => this.moveNext());
      this.playing = true;
    }
  }

  protected pause(): void {
    if (this.playing) {
      if (this.intervalSubscription !== undefined) this.intervalSubscription.unsubscribe();
      this.playing = false;
    }
  }
}
