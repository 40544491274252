import { AfterViewChecked, AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, Subscription, switchMap, tap, throwError } from 'rxjs';
import { Modal } from 'bootstrap';

import { AppleSsoService, COMMON, OAuthService, UserService } from '@azz-life-streamer/shared';
import { VERSION_CODE } from '../../../core/const/app.const';
import { LayoutService } from '../../../core/services/layout/layout.service';


@Component({
  selector: 'web-users-modal',
  templateUrl: './modal.component.html',
  styleUrls: [ './modal.component.scss' ],
})
export class ModalComponent implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {
  protected readonly Window = Window;

  @ViewChild('modal') modalEl!: ElementRef;
  @ViewChild('modalClose') modalCloseButton!: ElementRef;

  protected page: string = '';
  protected modal: Modal | undefined = undefined;
  protected modalOptions: Modal.Options = {
    backdrop: true,
    keyboard: true,
    focus: true
  }
  protected checkingLoginGo: boolean = false;
  protected checkingLoginMs: boolean = false;
  protected checkingLoginAp: boolean = false;

  private modalSubscription: Subscription | undefined = undefined;
  private gSsoSubscription: Subscription | undefined = undefined;
  private showDelayed: boolean = false;

  constructor(protected layoutService: LayoutService,
              private router: Router,
              private userService: UserService,
              private oAuthService: OAuthService,
              private appleSsoService: AppleSsoService) { }

  ngOnInit(): void {
    this.modalSubscription?.unsubscribe();
    this.modalSubscription = this.layoutService.userModal
      .subscribe((page: string) => {
        this.page = page;
        if ( page !== '' ) {
          this.show();
        } else {
          this.hide();
        }
      });
  }

  ngAfterViewInit(): void {
    this.modal = new Modal(this.modalEl.nativeElement, this.modalOptions);
  }

  ngAfterViewChecked(): void {
    if ( this.showDelayed ) {
      this.showDelayed = false;
      this.show();
    }
  }

  ngOnDestroy(): void {
    if ( this.modalSubscription ) {
      this.modalSubscription.unsubscribe();
    }

    if ( this.gSsoSubscription ) {
      this.gSsoSubscription.unsubscribe();
    }
  }

  protected changeModalContent(page: string): void {
    this.layoutService.showUserModal(page);
  }

  protected closeRequested(): void {
    this.layoutService.hideUserModal();
  }

  protected async gSsoSignIn(): Promise<void> {
    this.checkingLoginGo = true;
    this.oAuthService.loginGoogle(VERSION_CODE)
      .pipe(
        tap((result: boolean) => {
          if (result) {
            this.checkingLoginGo = false;
            if (result) {
              this.hide();
              this.continueNavigation();
            } else {
              throwError(() => false);
            }
          }
        }),
        catchError((err) => {
          this.checkingLoginGo = false;
          return throwError(() => err);
        })
      ).subscribe();
  }

  protected async mSsoSignIn(): Promise<void> {
    this.checkingLoginMs = true;
    this.oAuthService.loginMicrosoft()
      .pipe(
        tap((result: boolean) => {
          if (!result) {
            throwError(() => false);
          }
        }),
        switchMap((profile: any) => this.userService.loginMicrosoft(profile)),
        tap((result: boolean) => {
          if (result) {
            this.checkingLoginMs = false;
            if (result) {
              this.hide();
              this.continueNavigation();
            } else {
              throwError(() => false);
            }
          }
        }),
        catchError((err) => {
          this.checkingLoginMs = false;
          return throwError(() => err);
        })
      )
      .subscribe();
  }

  protected async aSsoSignIn(): Promise<void> {
    this.checkingLoginAp = true;
    this.appleSsoService.signIn(VERSION_CODE)
      .pipe(
        tap((result: boolean) => {
          this.checkingLoginAp = false;
          if ( result ) {
            this.hide();
            this.continueNavigation();
          }
        })
      ).subscribe();
  }

  private show(): void {
    if ( !this.modal ) {
      this.showDelayed = true;
    } else {
      this.modal.show();
    }
  }

  private hide(): void {
    this.modal?.hide();
  }

  private continueNavigation(): void {
    const REDIRECT: string | null = localStorage.getItem(COMMON.storageKeys.redirectTo);
    if(REDIRECT) {
      localStorage.removeItem(COMMON.storageKeys.redirectTo);
      this.router.navigateByUrl(REDIRECT)
        .catch(console.error);
    } else {
      this.router.navigateByUrl(COMMON.urls.manager)
        .catch(console.error);
    }
  }

}
