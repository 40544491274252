import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';

import { COMMON, IKeyName } from "@azz-life-streamer/shared";
import { APP, COOKIE_EXP_DAYS } from '../../core/const/app.const';


@Component({
  selector: 'web-cookie-consent',
  standalone: true,
  imports: [CommonModule, TranslateModule, FormsModule],
  templateUrl: './cookie-consent.component.html',
  styleUrls: ['./cookie-consent.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CookieConsentComponent implements OnInit {

  showCustomizeCookies: boolean = false;
  customizeText: string = '';
  showCookieConsent: boolean = true;
  cookiesSelected: string[] = ['mandatory'];
  categories: IKeyName[] = [
    { name: 'cookies.required', key: 'mandatory' },
    { name: 'cookies.analytics', key: 'statistics' },
    { name: 'cookies.advertisements', key: 'behavioral_advertising' },
    { name: 'cookies.personalization', key: 'customized' },
  ];

  constructor(private cookieService: CookieService,
              private router: Router) { }

  ngOnInit(): void {
    this.customizeText = 'cookies.customize';
    const COOKIE_CONSENT: string = this.cookieService.get(APP.storageKeys.cookieConsent);
    if (COOKIE_CONSENT) {
      this.showCookieConsent = false;
    }
  }

  customize(): void {
    this.showCustomizeCookies = !this.showCustomizeCookies;
    if (this.showCustomizeCookies) {
      this.customizeText = 'cookies.save';
    } else {
      const COOKIES: string = JSON.stringify(this.cookiesSelected);
      this.cookieService.set(APP.storageKeys.cookieConsent, COOKIES, COOKIE_EXP_DAYS);
      window.location.reload();

      this.customizeText = 'cookies.customize';
    }
  }

  acceptAll(): void {
    this.categories.forEach((element: IKeyName) => {
      this.cookiesSelected.push(element.key);
    });

    const COOKIES: string = JSON.stringify(this.cookiesSelected);
    this.cookieService.set(APP.storageKeys.cookieConsent, COOKIES, COOKIE_EXP_DAYS);
    window.location.reload();
  }

  deleteAll(): void {
    this.cookieService.set(APP.storageKeys.cookieConsent, JSON.stringify('[mandatory]'), COOKIE_EXP_DAYS);
    window.location.reload();
  }

  goToCookies(): void {
    this.router.navigateByUrl(COMMON.urls.cookies)
      .catch(console.error);
  }

  changeCookiesPreferences(cookie: string): void {
    if(this.cookiesSelected.find((selected: string) => selected === cookie) === undefined) {
      this.cookiesSelected.push(cookie);
    }
  }

  cookieChecked(cookie: string): boolean {
    return !!this.cookiesSelected.find((selected: string) => selected === cookie);
  }
}
