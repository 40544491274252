import { AfterViewInit, Component, ElementRef, EventEmitter, Inject, OnInit, Input, Output, ViewChild, HostListener, OnDestroy } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import { Modal } from 'bootstrap';

import { AlertService, FormatHoursTimePipe, IAlertData, IPlayerData, PlayerClass } from '@azz-life-streamer/shared';


@Component({
  selector: 'web-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.scss'],
})
export class PlayerComponent extends PlayerClass implements OnInit, OnDestroy, AfterViewInit {
  protected modalPlayer: Modal | undefined;
  @ViewChild('modalPlayer') modalElement: ElementRef | undefined;
  @ViewChild('container', {read: ElementRef}) containerElement!: ElementRef<HTMLDivElement>;
  @ViewChild('videoAzz', {read: ElementRef}) videoElement!: ElementRef;
  @Input() set mode(value: 'vod' | 'live'){
    this._mode = value;
  }

  @Input() set data(value: IPlayerData){
    this._data = value;
  }
  @Input() set ready(ready: boolean) {
    this.setReady(ready);
  }
  @Output() exitPage: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(protected alertService: AlertService,
              protected override router: Router,
              protected override translate: TranslateService,
              protected override formatTime: FormatHoursTimePipe,
              @Inject(DOCUMENT) protected override document: any,
              protected override http: HttpClient) {
      super(formatTime, router, translate, document, http);
  }

  // Added HosListeners to capture escape key
  @HostListener('document:fullscreenchange', ['$event'])
  @HostListener('document:webkitfullscreenchange', ['$event'])
  @HostListener('document:onfullscreenchange', ['$event'])
  @HostListener('document:MSFullscreenChange', ['$event'])
    fullscreenMode(){
      this.checkFullscreen(document);
    }

/*
  @HostListener('document:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
    console.log(event.key);
    if (event.key === ('ArrowLeft' || 'ArrowRight') && this.isOnOverlayBottom) {

    }
    if ((event.key === 'ArrowDown' || 'ArrowUp') && this.isVolumeControl) {
      let volume;
      const ev: any = undefined;
      if (event.key === 'ArrowDown'){
        volume = (this.playerControls.volume > 0) ? this.playerControls.volume - 10 : 0;
      }
      else{
        volume = (this.playerControls.volume < 100) ? this.playerControls.volume + 10 : 100;
      }
      this.volumePressed(ev,volume);
    }
  }
*/

  ngOnInit(): void {
    console.log('[PlayerComponent] -> On init');
  }

  ngOnDestroy() {
    this.destroy();
  }

  ngAfterViewInit(): void {
    this.modalPlayer = new Modal(this.modalElement?.nativeElement);
    this.init(this.containerElement, this.videoElement);
  }

  protected override displayAlert(config: IAlertData){
    this.alertService.showAlert(config);
  }

  protected override hideModal(){
    this.modalPlayer?.hide();
  }
  protected override showModal(){
    this.modalPlayer?.show();
  }
  protected override emitExitPage(value: boolean){
    this.exitPage.emit(value);
  }
  
  protected onClickEnterPassword() {
    this.setPassword(this.settingsForm.get('password')?.value);
  }
}
