import { ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { IMediaDevice, IMediaDeviceRemote } from './devices.interface';
import { AvProdPublishCommsStatus, AvProdPublishSourceType } from '../../const/publish-stream';

export interface IVideoResolution {
  name: string;
  size: string;
  width: number;
  height: number;
  selectable?: boolean;
}

export interface IPublisherSettings {
  streamName: string;
  allowRemote: boolean;
  audioEnabled: boolean;
  audioInputDevice: IMediaDevice | undefined;
  audioOutputDevice: IMediaDevice | undefined;
  audioBitRate: number;
  audioEchoCancellation: boolean;
  audioNoiseSuppression: boolean;
  audioAutoGainControl: boolean;
  videoEnabled: boolean;
  videoDevice: IMediaDevice | undefined;
  videoResolution: IVideoResolution;
  videoBitRate: number;
  videoMirror: boolean;
  videoUseCanvas: boolean;
  videoZoom: number;
  facingMode: 'environment' | 'user' | undefined;
  savingModeSeconds: number | undefined;
}

export interface IPublisherRemoteData {
  clientId: number;
  clientRequester: number;
  settings: IPublisherRemoteSettings;
}

export interface IPublisherRemoteSettings {
  state: number;
  audio: {
    enabled: boolean;
    deviceId: string;
    bitRate: number;
    options: {
      devices: IMediaDeviceRemote[];
      bitRates: number[];
    };
  };
  video: {
    enabled: boolean;
    deviceId: string;
    resolution: string;
    bitRate: number;
    frameRate: number;
    options: {
      devices: IMediaDeviceRemote[];
      resolutions: string[];
      bitRates: number[];
    };
    zoom: number;
  };
}

export interface IPublishStreamSlot {
  id: string;
  sourceType: AvProdPublishSourceType;
  commsStatus?: AvProdPublishCommsStatus;
  active: boolean;
  streamId: number;
  streamIdPrev: number;
  busy: boolean;
  initialized: boolean;
  settings: IPublisherSettings;
  settingsVideoActual?: MediaTrackSettings;
  settingsAudioActual?: MediaTrackSettings;
  mediaOpen: boolean;
  publishing: boolean;
  publishingCommanded: boolean;
  lastPublicationTimestamp: number;
  publishCheckSubscription?: Subscription;
  videoStream?: MediaStream;
  videoElement?: ElementRef<HTMLVideoElement>;
  videoCapabilities?: MediaTrackCapabilities;
  canvasOrigElement?: ElementRef<HTMLCanvasElement>;
  canvasRefreshSubscription?: Subscription;
  videoCanvasStream?: MediaStream;
  audioStream?: MediaStream;
  publishStream?: MediaStream;
  muted: boolean;
  camZoomOffsetX: number;
  camZoomOffsetY: number;
  camZoom: number;
}

export interface IDisplayOptions {
  outputInput: boolean;
  inputOutput: boolean;
  input: boolean;
  output: boolean;
}
