import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription, tap } from 'rxjs';

import { DisplayOptions } from '../../enums/publish-stream.enum';
import { IPublisherSettings, IVideoResolution } from '../../interfaces/publish-stream/settings.interface';
import {
  IAvSettingSelectOption,
  IAvSettingsItemConfig
} from '../../interfaces/av-producer/event-av-producer.interface';
import {
  IMediaDevice,
  IMediaInputDevices,
  IMediaOutputDevices
} from '../../interfaces/publish-stream/devices.interface';
import { PUBLISH_STREAM_RESOLUTIONS } from '../../const/publish-stream';
import { AvProdSettingsType } from '../../const/av-producer.const';
import { AV_PROD_OPTIONS_PUBLISH_VIDEO_RESOLUTION } from '../../const/av-producer-options';
import { PublishStreamService } from '../../services/publish-stream/publish-stream.service';
import { IToastConfig } from '../../interfaces/utils/utils.interface';
import { ToastPlacement, ToastStatus } from '../../enums/common.enum';


export class BroadcasterSettingsClass {
  protected _slotId: string | undefined;
  protected _displayOption: string | undefined = DisplayOptions.outputInput;
  protected _container: string = 'publisher';     // Publisher or Producer container
  protected curSection: string = 'video';

  protected settingsVideoForm: FormGroup = new FormGroup([]);
  protected settingsAudioForm: FormGroup = new FormGroup([]);
  protected settingsGeneralForm: FormGroup = new FormGroup([]);
  protected updateNeeded: boolean = false;
  //protected mirroredImage: boolean = true;

  protected _settings: IPublisherSettings | undefined = undefined;

  protected itemsSettingsVideo: IAvSettingsItemConfig[] = [
    {
      id: 'videoResolution',
      type: AvProdSettingsType.selectComboString,
      name: 'publishSettings.videoResolution',
      min: 0,
      max: 0,
      step: 1,
      options: AV_PROD_OPTIONS_PUBLISH_VIDEO_RESOLUTION,
      placeholder: '',
      value: this._settings?.videoResolution.name,
      config: {
        mode: 'native'
      }
    },
    {
      id: 'videoDevice',
      type: AvProdSettingsType.selectComboString,
      name: 'publishSettings.videoDevice',
      min: 0,
      max: 0,
      step: 1,
      options: [],
      placeholder: '',
      value: this._settings?.videoDevice,
      config: {
        mode: 'native'
      }
    },
    {
      id: 'mirrorImage',
      type: AvProdSettingsType.switchBoolean,
      title: 'publishSettings.mirrorImage',
      name: 'publishSettings.mirrorImage',
      min: 0,
      max: 0,
      step: 1,
      options: [],
      placeholder: '',
      value: this._settings?.videoMirror
    },
    {
      id: 'videoZoom',
      type: AvProdSettingsType.numberSlider,
      name: 'publishSettings.videoZoom',
      min: 1,
      max: 8,
      step: 0.1,
      options: [],
      placeholder: '',
      value: 1
    }
  ];
  protected itemsSettingsAudio: IAvSettingsItemConfig[] = [
    {
      id: 'audioInputDevice',
      type: AvProdSettingsType.selectComboString,
      name: 'publishSettings.audioInputDevice',
      min: 0,
      max: 0,
      step: 1,
      options: [],
      placeholder: '',
      value: this._settings?.audioInputDevice?.deviceId,
      config: {
        mode: 'native'
      }
    },
    // {
    //   id: 'audioOutputDevice',
    //   type: AvProdSettingsType.selectComboString,
    //   name: 'publishSettings.audioOutputDevice',
    //   min: 0,
    //   max: 0,
    //   step: 1,
    //   options: [],
    //   placeholder: '',
    //   value: this._settings?.audioOutputDevice?.deviceId,
    //   config: {
    //     mode: 'native'
    //   }
    // },
    {
      id: 'audioEchoCancellation',
      type: AvProdSettingsType.switchBoolean,
      title: 'publishSettings.audioEchoCancellation',
      name: 'publishSettings.audioEchoCancellation',
      min: 0,
      max: 0,
      step: 1,
      options: [],
      placeholder: '',
      value: this._settings?.audioEchoCancellation
    },
    {
      id: 'audioAutoGainControl',
      type: AvProdSettingsType.switchBoolean,
      title: 'publishSettings.audioAutoGainControl',
      name: 'publishSettings.audioAutoGainControl',
      min: 0,
      max: 0,
      step: 1,
      options: [],
      placeholder: '',
      value: this._settings?.audioAutoGainControl
    },
    {
      id: 'audioNoiseSuppression',
      type: AvProdSettingsType.switchBoolean,
      title: 'publishSettings.audioNoiseSuppression',
      name: 'publishSettings.audioNoiseSuppression',
      min: 0,
      max: 0,
      step: 1,
      options: [],
      placeholder: '',
      value: this._settings?.audioNoiseSuppression
    }
  ];
  protected itemsSettingsGeneral: IAvSettingsItemConfig[] = [
    {
      id: 'displayOption',
      type: AvProdSettingsType.radio,
      title: 'publishSettings.displayOptions.label',
      name: '',
      min: 0,
      max: 0,
      step: 1,
      options: [
        {
          label: 'outputInput',
          labelTranslate: 'publishSettings.displayOptions.outputInput',
          valueNumber: 0,
          valueStr: DisplayOptions.outputInput
        },
        {
          label: 'inputOutput',
          labelTranslate: 'publishSettings.displayOptions.inputOutput',
          valueNumber: 1,
          valueStr: DisplayOptions.inputOutput
        },
        // {
        //   label: 'output',
        //   labelTranslate: 'publishSettings.displayOptions.output',
        //   valueNumber: 2,
        //   valueStr: DisplayOptions.output
        // },
        {
          label: 'input',
          labelTranslate: 'publishSettings.displayOptions.input',
          valueNumber: 3,
          valueStr: DisplayOptions.input
        }
      ],
      placeholder: '',
      value: this._displayOption
    },
    {
      id: 'streamName',
      type: AvProdSettingsType.text,
      name: 'publishSettings.streamName',
      min: 0,
      max: 0,
      step: 1,
      options: [],
      placeholder: '',
      value: this._settings?.streamName ?? '',
      validators: [Validators.required]
    }/*,
    {
      id: 'useCanvas',
      type: AvProdSettingsType.switchBoolean,
      name: 'publishSettings.useCanvas',
      min: 0,
      max: 0,
      step: 1,
      options: [],
      placeholder: '',
      value: this._settings?.videoUseCanvas
    }*/
  ];

  private inputDevicesSubscription: Subscription | undefined;
  private outputDevicesSubscription: Subscription | undefined;
  private inputMediaDevices: IMediaInputDevices | undefined;
  private outputMediaDevices: IMediaOutputDevices | undefined;

  constructor(protected publishService: PublishStreamService) {
    // do nothing
  }

  protected displayToast(config: IToastConfig): void {
    // must be overridden
  }

  protected setSlotId(slotId: string | undefined): void {
    this._slotId = slotId;
    if (this._slotId !== undefined) {
      const SETTINGS: IPublisherSettings | null = this.publishService.getStreamSlotSettings(this._slotId);
      if (SETTINGS !== null) {
        if(SETTINGS.videoDevice === undefined &&
          (this.publishService.userSettings?.videoDevice !== undefined
            || this.publishService.defaultVideoInput !== undefined)) {
          SETTINGS.videoDevice = this.publishService.userSettings?.videoDevice ?? this.publishService.defaultVideoInput;
        }
        this._settings = SETTINGS;
        this.updateFormFields();
      }
    }
  }

  protected setDisplayOption(displayOption: string): void {
    this._displayOption = displayOption;
  }

  protected setContainer(container: string): void {
    this._container = container;
    this.updateItemVisibility(this.itemsSettingsGeneral, 'displayOption', (this._container === 'publisher'));
  }

  protected emitStreamNameChanged(value: string): void {
    // must be overridden
  }

  protected emitMirrorImageChanged(value: boolean): void {
    // must be overridden
  }

  protected emitDisplayOptionChanged(value: string): void {
    // must be overridden
  }

  protected emitZoomChanged(value: number): void {
    // must be overridden
  }

//  protected setSettings(settings: IPublisherSettings): void {
//    this._settings = settings;
//  }

//  protected getSettings(): IPublisherSettings {
//    if (this._settings === undefined) {
//      return PUBLISH_STREAM_DEFAULT_SETTINGS;
//    }
//    return this._settings;
//  }

  protected init(): void {
    console.log('[BroadcasterSettingsClass] init');

    if (this.inputDevicesSubscription !== undefined) {
      this.inputDevicesSubscription.unsubscribe();
    }
    this.inputDevicesSubscription = this.publishService.mediaInputDevices
      .pipe(
        tap((devices: IMediaInputDevices) => this.inputMediaDevices = devices),
        tap(() => this.updateInputDevicesList())
      )
      .subscribe();

    if (this.outputDevicesSubscription !== undefined) {
      this.outputDevicesSubscription.unsubscribe();
    }
    this.outputDevicesSubscription = this.publishService.mediaOutputDevices
      .pipe(
        tap((devices: IMediaOutputDevices) => this.outputMediaDevices = devices),
        tap(() => this.updateOutputDevicesList())
      )
      .subscribe();

    this.initMediaInfo();
    this.updateFormFields();
  }

  protected destroy(): void {
    this.curSection = 'video';
    if (this.updateNeeded) {
      this.updateSlotSettings();
    }
    if (this.inputDevicesSubscription !== undefined) {
      this.inputDevicesSubscription.unsubscribe();
    }
    if (this.outputDevicesSubscription !== undefined) {
      this.outputDevicesSubscription.unsubscribe();
    }
  }

  protected initMediaInfo(): void {
    if (this._slotId !== undefined) {
      const SETTINGS: IPublisherSettings | null = this.publishService.getStreamSlotSettings(this._slotId);
      if (SETTINGS !== null) {
        this._settings = SETTINGS;
      }
    }

    if (this._settings?.streamName !== this.settingsGeneralForm.get('streamName')?.value) {
      this.settingsGeneralForm.get('streamName')?.setValue(this._settings?.streamName);
    }

    if (this.publishService.userMediaEnabled.getValue() === true) {

      this.publishService.refreshMediaDevices()
        .then(() => {
        })
        .catch(console.error);
    }
  }

  protected onSettingChanged(setting: IAvSettingsItemConfig): void {
    console.log('[BroadcasterSettingsClass] onSettingChanged');

    if (this._settings !== undefined){
      switch (setting.id) {
        case 'videoDevice':
          if (this.settingsVideoForm.get('videoDevice')?.value !== this._settings?.videoDevice?.deviceId) {
            this._settings.videoDevice = this.inputMediaDevices?.videoInputs.find(
              (element: IMediaDevice) => element.deviceId === this.settingsVideoForm.get('videoDevice')?.value);
            this.updateNeeded = true;
          }
          break;
        case 'videoResolution':
          if (this.settingsVideoForm.get('videoResolution')?.value !== this._settings.videoResolution.name) {
            const NEW_RES: IVideoResolution | undefined = PUBLISH_STREAM_RESOLUTIONS.find(
              (element: IVideoResolution) => element.name === this.settingsVideoForm.get('videoResolution')?.value);
            if (NEW_RES !== undefined) {
              const CAP: any = this.publishService.getDeviceVideoCapabilities(this._settings?.videoDevice?.deviceId);
              if ((typeof(CAP.height.max) === 'number')&&
                  (NEW_RES.height > CAP.height.max)){
                console.log('[BroadcasterSettingsClass] onSettingChanged - Resolution out of range');
                const MAX_RES: IVideoResolution | undefined = PUBLISH_STREAM_RESOLUTIONS.find(
                  (element: IVideoResolution) => element.height === CAP.height.max);
                if (MAX_RES !== undefined){
                  this.settingsVideoForm.get('videoResolution')?.setValue(MAX_RES.name);
                  this.displayToast({
                    options: {
                      placement: ToastPlacement.middleCenter,
                      autohide: true
                    },
                    data: {
                      status: ToastStatus.error,
                      text: 'publishSettings.errorResolutionNotSupported',
                      closeButtonBody: true,

                    },
                  });
                }
              }
              else{
                this._settings.videoResolution = NEW_RES;
                this.updateNeeded = true;
              }
            }
          }
          break;
        case 'mirrorImage':
          if (this.settingsVideoForm.get('mirrorImage')?.value !== this._settings?.videoMirror) {
            this._settings.videoMirror = this.settingsVideoForm.get('mirrorImage')?.value;
            this.emitMirrorImageChanged(this.settingsVideoForm.get('mirrorImage')?.value);
            this.updateNeeded = true;
          }
          break;
        case 'videoZoom':
          if (this.settingsVideoForm.get('videoZoom')?.value !== this._settings?.videoMirror) {
            this._settings.videoZoom = this.settingsVideoForm.get('videoZoom')?.value;
            this.emitZoomChanged(this.settingsVideoForm.get('videoZoom')?.value);
            this.updateNeeded = true;
          }
          break;
        case 'audioInputDevice':
          if (this.settingsAudioForm.get('audioInputDevice')?.value !== this._settings.audioInputDevice?.deviceId) {
            this._settings.audioInputDevice = this.inputMediaDevices?.audioInputs.find(
              (element: IMediaDevice) => element.deviceId === this.settingsAudioForm.get('audioInputDevice')?.value);
            this.updateNeeded = true;
          }
          break;
        case 'audioOutputDevice':
          if (this.settingsAudioForm.get('audioOutputDevice')?.value !== this._settings.audioOutputDevice?.deviceId) {
            this._settings.audioOutputDevice = this.outputMediaDevices?.audioOutputs.find(
              (element: IMediaDevice) => element.deviceId === this.settingsAudioForm.get('audioOutputDevice')?.value);
            this.updateNeeded = true;
          }
          break;
        case 'audioEchoCancellation':
          if (this.settingsAudioForm.get('audioEchoCancellation')?.value !== this._settings.audioEchoCancellation) {
            this._settings.audioEchoCancellation = this.settingsAudioForm.get('audioEchoCancellation')?.value;
            this.updateNeeded = true;
          }
          break;
        case 'audioAutoGainControl':
          if (this.settingsAudioForm.get('audioAutoGainControl')?.value !== this._settings.audioAutoGainControl) {
            this._settings.audioAutoGainControl = this.settingsAudioForm.get('audioAutoGainControl')?.value;
            this.updateNeeded = true;
          }
          break;
        case 'audioNoiseSuppression':
          if (this.settingsAudioForm.get('audioNoiseSuppression')?.value !== this._settings.audioNoiseSuppression) {
            this._settings.audioNoiseSuppression = this.settingsAudioForm.get('audioNoiseSuppression')?.value;
            this.updateNeeded = true;
          }
          break;
        case 'displayOption':
          if (this.settingsGeneralForm.get('displayOption')?.value !== this._displayOption) {
            this.emitDisplayOptionChanged(this.settingsGeneralForm.get('displayOption')?.value);
          }
          break;
        case 'streamName':
          if (this.settingsGeneralForm.get('streamName')?.value !== this._settings?.streamName) {
            this._settings.streamName = this.settingsGeneralForm.get('streamName')?.value;
            this.emitStreamNameChanged(this.settingsGeneralForm.get('streamName')?.value);
            this.updateNeeded = true;
          }
          break;
        case 'useCanvas':
          if (this.settingsGeneralForm.get('useCanvas')?.value !== this._settings?.videoUseCanvas) {
            this._settings.videoUseCanvas = this.settingsGeneralForm.get('useCanvas')?.value;
            //this.emitUseCanvasChanged(this.settingsGeneralForm.get('useCanvas')?.value);
            this.updateNeeded = true;
          }
          break;
      }

      if (this.updateNeeded) {
        this.updateSlotSettings();
      }

    }
  }

  protected updateSlotSettings(): void {
    if ((this._slotId !== undefined)&&(this._settings !== undefined)) {
      console.log('[BroadcasterSettingsClass] updateSlotSettings ' + this._slotId);
      this.publishService.changeStreamSlotSettings(this._slotId, this._settings);
      this.updateNeeded = false;
    }
  }

  protected updateFormFields(): void {
    if (this._settings !== undefined){
      this.settingsVideoForm.get('videoDevice')?.setValue(this._settings.videoDevice?.deviceId);
      this.settingsVideoForm.get('videoResolution')?.setValue(this._settings.videoResolution.name);
      this.settingsVideoForm.get('mirrorImage')?.setValue(this._settings.videoMirror);

      this.settingsVideoForm.get('videoZoom')?.setValue(this._settings.videoZoom);
      this.settingsAudioForm.get('audioInputDevice')?.setValue(this._settings.audioInputDevice?.deviceId);

      this.settingsAudioForm.get('audioOutputDevice')?.setValue(this._settings.audioOutputDevice?.deviceId);
      this.settingsAudioForm.get('audioEchoCancellation')?.setValue(this._settings.audioEchoCancellation);
      this.settingsAudioForm.get('audioAutoGainControl')?.setValue(this._settings.audioAutoGainControl);
      this.settingsAudioForm.get('audioNoiseSuppression')?.setValue(this._settings.audioNoiseSuppression);
      this.settingsGeneralForm.get('displayOption')?.setValue(this._displayOption);
      this.settingsGeneralForm.get('streamName')?.setValue(this._settings.streamName);
      this.settingsGeneralForm.get('useCanvas')?.setValue(this._settings.videoUseCanvas);
    }
  }

  protected initForms(): void {
    // Video
    this.itemsSettingsVideo.forEach((element: IAvSettingsItemConfig) => {
      if (element.id === 'videoResolution' && element.value === undefined) {
        element.value = AV_PROD_OPTIONS_PUBLISH_VIDEO_RESOLUTION.find(
          (resolution: IAvSettingSelectOption) => resolution.valueStr === this._settings?.videoResolution.name)
      }
      this.settingsVideoForm.addControl(element.id, new FormControl(element.value, element.validators ?? []));
    });

    // Audio
    this.itemsSettingsAudio.forEach((element: IAvSettingsItemConfig) => {
      this.settingsAudioForm.addControl(element.id, new FormControl(element.value, element.validators ?? []));
    });

    // General
    this.itemsSettingsGeneral.forEach((element: IAvSettingsItemConfig) => {
      if (element.id === 'displayOption' && element.value === undefined) {
        element.value = this._displayOption;
      }
      this.settingsGeneralForm.addControl(element.id, new FormControl(element.value, element.validators ?? []));
    });
  }

  protected updateInputDevicesList() {
    const OPTIONS_VIDEO_DEV: IAvSettingSelectOption[] = [];
    const OPTIONS_AUDIO_DEV: IAvSettingSelectOption[] = [];

    // Video
    if ((this._settings != undefined)&&(this._settings?.videoDevice === undefined)) {
      const DEF_VIDEO_FOUND: IMediaDevice | undefined = this.inputMediaDevices?.videoInputs.find((device: IMediaDevice) => device.deviceId === 'default');
      this._settings.videoDevice = DEF_VIDEO_FOUND ?? this.inputMediaDevices?.videoInputs[0];
    }
    this.inputMediaDevices?.videoInputs.forEach(element => {
      OPTIONS_VIDEO_DEV.push({
        label: element.label,
        labelTranslate: element.label,
        valueNumber: element.id,
        valueStr: element.deviceId
      })
    });

    this.itemsSettingsVideo.forEach(element => {
      if (element.id == 'videoDevice') {
        element.options = OPTIONS_VIDEO_DEV;
      }
    });

    const OPTION_FOUND: IAvSettingSelectOption | undefined =
      OPTIONS_VIDEO_DEV.find((option: IAvSettingSelectOption) => option.valueStr === this._settings?.videoDevice?.deviceId);
    if (OPTION_FOUND !== undefined) {
      this.settingsVideoForm.get('videoDevice')?.setValue(OPTION_FOUND.valueStr);
      const CAP: any = this.publishService.getDeviceVideoCapabilities(OPTION_FOUND.valueStr);
      console.log('[BroadcasterSettingsClass] updateInputDevicesList ' + OPTION_FOUND.valueStr + ' / ' + JSON.stringify(CAP));
      if ((CAP !== undefined)&&
          (CAP.zoom !== undefined)&&
          (CAP.zoom.min !== undefined)&&
          (CAP.zoom.max !== undefined)){

        this.updateItemVisibility(this.itemsSettingsVideo, 'videoZoom', true);
        if (CAP.zoom.step !== undefined) {
          this.updateItemRange(this.itemsSettingsVideo, 'videoZoom', CAP.zoom.min, CAP.zoom.max, CAP.zoom.step);
        }
        else{
          this.updateItemRange(this.itemsSettingsVideo, 'videoZoom', CAP.zoom.min, CAP.zoom.max, 0.1);
        }
      }
      else{
        this.updateItemVisibility(this.itemsSettingsVideo, 'videoZoom', false);
      }
    }
    else{
      this.updateItemVisibility(this.itemsSettingsVideo, 'videoZoom', false);
    }

    //Audio
    if ((this._settings !== undefined)&&(this._settings.audioInputDevice === undefined)) {
      const DEF_AUDIO_FOUND: IMediaDevice | undefined = this.inputMediaDevices?.audioInputs.find((device: IMediaDevice) => device.deviceId === 'default');
      this._settings.audioInputDevice = DEF_AUDIO_FOUND ?? this.inputMediaDevices?.audioInputs[0];
    }
    this.inputMediaDevices?.audioInputs.forEach(element => {
      OPTIONS_AUDIO_DEV.push({
        label: element.label,
        labelTranslate: element.label,
        valueNumber: element.id,
        valueStr: element.deviceId
      })
    });

    this.itemsSettingsAudio.forEach((element:IAvSettingsItemConfig) => {
      if (element.id == 'audioInputDevice') {
        element.options = OPTIONS_AUDIO_DEV;
      }
    });

    const OPTION_AUDIO_FOUND: IAvSettingSelectOption | undefined =
      OPTIONS_AUDIO_DEV.find((option: IAvSettingSelectOption) => option.valueStr === this._settings?.audioInputDevice?.deviceId);
    if (OPTION_AUDIO_FOUND !== undefined) {
      this.settingsAudioForm.get('audioInputDevice')?.setValue(OPTION_AUDIO_FOUND.valueStr);
    }
  }

  protected updateItemVisibility(list: IAvSettingsItemConfig[], id: string, visible: boolean): void {
    const ITEM: IAvSettingsItemConfig | undefined = list.find(element => (element.id === id));
    if (ITEM !== undefined) {
      ITEM.visible = visible;
    }
    //console.log('[BroadcasterSettingsClass] updateItemVisibility: ' + JSON.stringify(ITEM));
  }

  protected updateItemRange(list: IAvSettingsItemConfig[], id: string, min: number, max: number, step: number): void {
    const ITEM: IAvSettingsItemConfig | undefined = list.find(element => (element.id === id));
    if (ITEM !== undefined) {
      ITEM.min = min;
      ITEM.max = max;
      ITEM.step = step;
    }
    //console.log('[BroadcasterSettingsClass] updateItemVisibility: ' + JSON.stringify(ITEM));
  }

  protected updateOutputDevicesList(): void {
    const OPTIONS_AUDIO_DEV: IAvSettingSelectOption[] = [];

    //Audio
    if ((this._settings !== undefined)&&(this._settings.audioOutputDevice === undefined)) {
      const DEF_AUDIO_FOUND: IMediaDevice | undefined = this.outputMediaDevices?.audioOutputs.find((device: IMediaDevice) => device.deviceId === 'default');
      this._settings.audioOutputDevice = DEF_AUDIO_FOUND ?? this.outputMediaDevices?.audioOutputs[0];
    }
    this.outputMediaDevices?.audioOutputs.forEach((element: IMediaDevice) => {
      OPTIONS_AUDIO_DEV.push({
        label: element.label,
        labelTranslate: element.label,
        valueNumber: element.id,
        valueStr: element.deviceId
      })
    });

    this.itemsSettingsAudio.forEach((element: IAvSettingsItemConfig) => {
      if (element.id == 'audioOutputDevice') {
        element.options = OPTIONS_AUDIO_DEV;
      }
    });

    const OPTION_AUDIO_FOUND: IAvSettingSelectOption | undefined =
      OPTIONS_AUDIO_DEV.find((option: IAvSettingSelectOption) => option.valueStr === this._settings?.audioInputDevice?.deviceId);
    if (OPTION_AUDIO_FOUND !== undefined) {
      this.settingsAudioForm.get('audioOutputDevice')?.setValue(OPTION_AUDIO_FOUND.valueStr);
    }
  }
}
