import { Inject, Injectable } from '@angular/core';

import { COMMON, ITheme } from '@azz-life-streamer/shared';
import { BehaviorSubject, Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  public selectedTheme: string;
  public themes: ITheme[] = [
    {
      key: 'light',
      name: 'layout.themeLight',
      icon: 'bi bi-sun',
    },
    {
      key: 'dark',
      name: 'layout.themeDark',
      icon: 'bi bi-moon-stars',
    }
  ];

  public curTheme$: Observable<string>;

  private _curTheme: BehaviorSubject<string> = new BehaviorSubject<string>('undefined');

  constructor(@Inject('source') private source: string) {
    const STORAGE_THEME: string | null = localStorage.getItem(COMMON.storageKeys.theme);
    const FOUND: ITheme | undefined = this.themes.find((theme: ITheme) => theme.key === STORAGE_THEME ?? 'light');

    this.selectedTheme = FOUND ? FOUND?.key : this.themes[0].key;
    this.curTheme$ = this._curTheme.asObservable();
    this.setTheme();
  }

  public toggleTheme(): void {
    this.selectedTheme = (this.selectedTheme === this.themes[0].key) ? this.themes[1].key: this.themes[0].key;
    this.setTheme();
  }

  private setTheme(): void {
    this._curTheme.next(this.selectedTheme);
    localStorage.setItem(COMMON.storageKeys.theme, this.selectedTheme);
    if (this.source === 'web') {
      // Theme to apply in web
      document.body.setAttribute('data-bs-theme', this.selectedTheme);
    } else if(this.source === 'mobile') {
      // Theme to apply in mobile
      if(this.selectedTheme === 'dark') {
        document.body.classList.add('dark');
      } else if(this.selectedTheme === 'light') {
        document.body.classList.remove('dark');
      }
    }
  }


}
