import { Component, Input } from '@angular/core';


@Component({
  selector: 'web-legal',
  templateUrl: './legal.component.html',
  styleUrls: ['../styles/users-form.scss'],
})
export class LegalComponent {

  @Input() page: string;

  constructor() {
    this.page = 'privacy';
  }
}
