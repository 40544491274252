import { Component, EventEmitter, OnDestroy, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { AlertStatus, ForgotPasswordClass, IAlertData, IAlertDataButton, UserService, AlertService } from '@azz-life-streamer/shared';
import { LayoutService } from '../../../core/services/layout/layout.service';


@Component({
  selector: 'web-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['../styles/users-form.scss'],
})
export class ForgotPasswordComponent extends ForgotPasswordClass implements OnDestroy {

  @ViewChild('fEmail') fEmail!: NgForm;
  @ViewChild('fCode') fCode!: NgForm;
  @ViewChild('fPass') fPass!: NgForm;

  @Output() alert: EventEmitter<IAlertData> = new EventEmitter<IAlertData>();

  constructor(protected override userService: UserService,
              protected layoutService: LayoutService,
              private alertService: AlertService) {
    super(userService);
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  protected override sendEmail(): void {
    super.sendEmail(this.fEmail);
  }

  protected override sendCode(): void {
    super.sendCode(this.fCode);
  }

  protected override sendPassword(): void {
    super.sendPassword(this.fPass);
  }

  protected override modalClose(): void {
    this.layoutService.hideUserModal();
  }

  protected override showErrorMessage(): void {
    const BUTTONS: IAlertDataButton[] = [];

    if(this.activateAccountPend) {
      BUTTONS.push( {
        text: 'users.activateAccount',
        color: 'secondary',
        closeButton: true,
        handler: () => {
          super.sendActivationCode();
          this.layoutService.showUserModal('activateAccount');
        }
      });
    }

    if(!this.error) {
      BUTTONS.push( {
        text: 'users.accountAccess',
        color: 'secondary',
        closeButton: true,
        handler: () => {
          this.layoutService.showUserModal('login');
        }
      });

      BUTTONS.push( {
        text: 'general.cancel',
        color: 'dark',
        closeButton: true,
      });
    } else {
      BUTTONS.push({
        text: 'general.close',
        color: 'primary',
        closeButton: true,
      });
    }
    this.alertService.showAlert({
      show: true,
      status: this.error? AlertStatus.error : AlertStatus.success,
      title: this.errorTitle ?? '',
      text: this.errorMessage ?? '',
      buttons: BUTTONS,
      closeButton: false
    });
  }
}
