import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, share } from 'rxjs';

import { UserService, COMMON, ThemeService } from '@azz-life-streamer/shared';
import { ITopBar } from '../../../core/interfaces/web.interface';
import { APP, DEFAULT_TOP_BAR } from '../../../core/const/app.const';
import { LayoutService } from '../../../core/services/layout/layout.service';
import { UrlService } from '../../../core/services/url/url.service';


@Component({
  selector: 'web-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],
})
export class TopBarComponent implements OnInit {
  @Output() modalUserRequested: EventEmitter<string> =
    new EventEmitter<string>();

  protected topBar: ITopBar = DEFAULT_TOP_BAR;
  protected scrolled: boolean = false;
  protected scrolling: boolean = false;
  protected collapsed: boolean = true;
  protected activeFragment: Observable<string | null> =
    this.route.fragment.pipe(share());

  protected readonly COMMON: any = COMMON;
  protected readonly APP: any = APP;

  constructor(protected userService: UserService,
              protected layoutService: LayoutService,
              protected route: ActivatedRoute,
              protected urlService: UrlService,
              protected themeService: ThemeService,
              protected translate: TranslateService) {}

  ngOnInit(): void {
    this.layoutService.topBarUpdate.subscribe((topBar: ITopBar) => {
      this.topBar = topBar;
    });
    //console.log('[TOP BAR ngOnInit] - Top Bar init. Scrolling: ' + this.scrolling + ' Scrolled: ' + this.scrolled + ' ScrollY: ' + window.scrollY);
  }

  @HostListener('document:scroll', ['$event'])
  private onWindowScroll(): void {
    if (this.topBar.scrollValue !== 0) {
      this.scrolled = window.scrollY > this.topBar.scrollValue;
      this.scrolling =
        window.scrollY >= 1 && window.scrollY < this.topBar.scrollValue;
      //console.log('[TOP BAR] - Top Bar update. Scrolling: ' + this.scrolling + ' Scrolled: ' + this.scrolled + ' ScrollY: ' + window.scrollY);
    }
  }

  protected toggleMenu(): void {
    this.collapsed = !this.collapsed;
  }
}
