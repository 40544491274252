import { IDevice } from '../device/device.interface';
import { IApi } from '../utils/utils.interface';

export interface IUserPlanConfig {
  channel?: number;
  concurrentEvents?: number;
  customInputs?: number;
  customRtmp?: number;
  customSrt?: number;
  dvrTime?: number;
  extraAudioInputs?: number;
  maxDestinations?: number;
  maxDuration?: number;
  maxHighlights?: number;
  maxHtmlGraphics?: number;
  maxLiveInputs?: number;
  maxProducers?: number;
  maxResolution?: string;
  maxSetup?: number;
  maxViewers?: number;
  storage?: number;
  maxStreamingTime?: number;
  watermark?: boolean;
}

export interface IUserSubscriptionConfig {
  status?: string;
  startDate?: number;
  cancelAt?: number;
  currentPeriodStart?: number;
  currentPeriodEnd?: number;
  trialEnd?: number;
}

export interface IUserOrganizationInfo {
  id: number;
  name: string;
  alias?: string;
  status?: string;
}

export interface IUserOrganizationMember {
  userId?: number;
  email?: string;
  role?: string;
  status?: string;
}

export interface IUser {
  id?: number;
  name?: string;
  phone?: string;
  email: string;
  password?: string;
  device?: IDevice;
  language?: string;
  image?: string;
  lastLogin?: number;
  code?: number;
  push: IPushNotification;
  productKey?: string;
  storageUsed?: number;
  storageTotal?: number;
  streamingTime?: number;
  plan?: IUserPlanConfig;
  subscription?: IUserSubscriptionConfig;
  defaultPassword?: boolean;
  organizations?: IUserOrganizationInfo[];
  profilePhoto?: string;
  backgroundPhoto?: string;
}

export interface IUserAuthKey {
  email: string;
}

export interface ILoginUser {
  email: string;
  password: string;
  device?: IApi<IDevice>;
  version?: number;
  push?: IApi<IPushNotification>;
}

export interface ILoginGoogleUser {
  clientId: string | string[];
  id?: string;
  name: string;
  email: string;
  device?: IApi<IDevice>;
  language: string;
  version?: number;
  push?: IApi<IPushNotification>;
}

export interface ILoginAppleUser {
  identityToken: string;
  authorizationCode: string;
  name: string;
  language: string;
  user: string;
  device?: IApi<IDevice>;
  version?: number;
  push?: IApi<IPushNotification>;
}

export interface ILoginMicrosoftUser {
  displayName?: string, //full name
  givenName?: string, // name
  surname?: string,// surname
  userPrincipalName?: string, // email
  id?: string
  mail?: string, // email
  language: string;
  device?: IApi<IDevice>;
  version?: number;
  push?: IApi<IPushNotification>;
}

export interface ISignUpUser {
  id?: number;
  name: string;
  email: string;
  password: string;
  language?: string;
  legalTerms?: boolean;
}

export interface IActivateUser {
  email: string;
  code: number | undefined;
  device?: IApi<IDevice>;
  version?: number;
  push?: IApi<IPushNotification>;
}

export interface IDeleteUser {
  email: string;
  code: number | undefined;
}

export interface IResetPasswordUser {
  email: string;
  code: number | undefined;
  authKey?: string;
  password: string;
  confirm?: string;
}

export interface IRefreshToken {
  email: string;
  device: IApi<IDevice> | undefined;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  refresh_token: string;
  version: number;
  push?: IApi<IPushNotification>;
}

export interface IUpdateUser {
  email?: string;
  name?: string;
  language?: string;
  curPassword?: string;
  newPassword?: string;
  confirmPassword?: string;
  push?: IApi<IPushNotification>;
}

export interface IPushNotification {
  id?: string;
  timestamp?: number;
  error?: string;
  errorTimestamp?: number;
  userId?: number;
  allowed: boolean;
}

export interface IOrganizationInfo {
  id?: number;
  name?: string;
  alias?: string;
  description?: string;
  plan?: IUserPlanConfig;
  storageUsed?: number;
  storageTotal?: number;
  productKey?: string;
  streamingTime?: number;
  subscription?: IUserSubscriptionConfig,
  members?: IUserOrganizationMember[];
  profilePhoto?: string;
  backgroundPhoto?: string;
}
