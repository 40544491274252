import { IAvSettingSelectOption } from '../interfaces/av-producer/event-av-producer.interface';
import { AvProdInputType, AvProdInputTypeNumber, AvProdLowBandwidthConfig, AvProdOverlayHtmlProviderType, AvProdOverlayScoreSport } from './av-producer.const';

export const AV_PROD_OPTIONS_AUDIO_MODE_MASTER: IAvSettingSelectOption[] = [
    {
        label: 'avOptions.off',
        labelTranslate: 'avOptions.off',
        valueNumber: 0,
        valueStr: ''
    },
    {
        label: 'avOptions.on',
        labelTranslate: 'avOptions.on',
        valueNumber: 1,
        valueStr: ''
    }
]

export const AV_PROD_OPTIONS_AUDIO_MODE_LIVE: IAvSettingSelectOption[] = [
    {
        label: 'avOptions.off',
        labelTranslate: 'avOptions.off',
        valueNumber: 0,
        valueStr: ''
    },
    {
        label: 'avOptions.on',
        labelTranslate: 'avOptions.on',
        valueNumber: 1,
        valueStr: ''
    },
    {
        label: 'avOptions.onWhenSelected',
        labelTranslate: 'avOptions.onWhenSelected',
        valueNumber: 2,
        valueStr: ''
    }
]

export const AV_PROD_OPTIONS_AUDIO_MODE_CLIP: IAvSettingSelectOption[] = [
    {
        label: 'avOptions.off',
        labelTranslate: 'avOptions.off',
        valueNumber: 0,
        valueStr: ''
    },
    {
        label: 'avOptions.on',
        labelTranslate: 'avOptions.on',
        valueNumber: 1,
        valueStr: ''
    },
    {
        label: 'avOptions.soloWhenSelected',
        labelTranslate: 'avOptions.soloWhenSelected',
        valueNumber: 3,
        valueStr: ''
    }
]

export const AV_PROD_OPTIONS_BACKGROUND_TYPE: IAvSettingSelectOption[] = [
    {
        label: 'avOptions.solid',
        labelTranslate: 'avOptions.solid',
        valueNumber: 0,
        valueStr: ''
    },
    {
        label: 'avOptions.inputImage',
        labelTranslate: 'avOptions.inputImage',
        valueNumber: 1,
        valueStr: ''
    }
]

export const AV_PROD_OPTIONS_TILE_TRANSITION: IAvSettingSelectOption[] = [
    {
        label: 'avOptions.cut',
        labelTranslate: 'avOptions.cut',
        valueNumber: 0,
        valueStr: ''
    },
    {
        label: 'avOptions.dissolve',
        labelTranslate: 'avOptions.dissolve',
        valueNumber: 1,
        valueStr: ''
    },
    {
        label: 'avOptions.wipe',
        labelTranslate: 'avOptions.wipe',
        valueNumber: 2,
        valueStr: ''
    },
    {
        label: 'avOptions.flyIn',
        labelTranslate: 'avOptions.flyIn',
        valueNumber: 3,
        valueStr: ''
    },
    {
        label: 'avOptions.fadeBlack',
        labelTranslate: 'avOptions.fadeBlack',
        valueNumber: 4,
        valueStr: ''
    },
    {
        label: 'avOptions.fadeWhite',
        labelTranslate: 'avOptions.fadeWhite',
        valueNumber: 5,
        valueStr: ''
    },
    {
        label: 'avOptions.camera',
        labelTranslate: 'avOptions.camera',
        valueNumber: 6,
        valueStr: ''
    },
    {
        label: 'avOptions.explosion',
        labelTranslate: 'avOptions.explosion',
        valueNumber: 7,
        valueStr: ''
    },
    {
        label: 'avOptions.splash',
        labelTranslate: 'avOptions.splash',
        valueNumber: 8,
        valueStr: ''
    },
    {
        label: 'avOptions.azzulei',
        labelTranslate: 'avOptions.azzulei',
        valueNumber: 9,
        valueStr: ''
    }
]

export const AV_PROD_OPTIONS_TILE_TRANSITION_DIRECTION: IAvSettingSelectOption[] = [
    {
        label: 'avOptions.none',
        labelTranslate: 'avOptions.none',
        valueNumber: 0,
        valueStr: ''
    },
    {
        label: 'avOptions.left',
        labelTranslate: 'avOptions.left',
        valueNumber: 1,
        valueStr: ''
    },
    {
        label: 'avOptions.right',
        labelTranslate: 'avOptions.right',
        valueNumber: 2,
        valueStr: ''
    },
    {
        label: 'avOptions.up',
        labelTranslate: 'avOptions.up',
        valueNumber: 3,
        valueStr: ''
    },
    {
        label: 'avOptions.down',
        labelTranslate: 'avOptions.down',
        valueNumber: 4,
        valueStr: ''
    }
]

export const AV_PROD_OPTIONS_COLOR: IAvSettingSelectOption[] = [
    {
        label: 'avOptions.white',
        labelTranslate: 'avOptions.white',
        valueNumber: 0,
        valueStr: 'white'
    },
    {
        label: 'avOptions.black',
        labelTranslate: 'avOptions.black',
        valueNumber: 1,
        valueStr: 'black'
    },
    {
        label: 'avOptions.red',
        labelTranslate: 'avOptions.red',
        valueNumber: 2,
        valueStr: 'red'
    },
    {
        label: 'avOptions.blue',
        labelTranslate: 'avOptions.blue',
        valueNumber: 3,
        valueStr: 'blue'
    },
    {
        label: 'avOptions.darkblue',
        labelTranslate: 'avOptions.darkblue',
        valueNumber: 4,
        valueStr: 'darkblue'
    },
    {
        label: 'avOptions.yellow',
        labelTranslate: 'avOptions.yellow',
        valueNumber: 5,
        valueStr: 'yellow'
    },
    {
        label: 'avOptions.lightblue',
        labelTranslate: 'avOptions.lightblue',
        valueNumber: 6,
        valueStr: 'lightblue'
    },
    {
        label: 'avOptions.green',
        labelTranslate: 'avOptions.green',
        valueNumber: 7,
        valueStr: 'green'
    },
    {
        label: 'avOptions.orange',
        labelTranslate: 'avOptions.orange',
        valueNumber: 8,
        valueStr: 'orange'
    },
    {
        label: 'avOptions.gray',
        labelTranslate: 'avOptions.gray',
        valueNumber: 9,
        valueStr: 'gray'
    },
    {
        label: 'avOptions.darkgray',
        labelTranslate: 'avOptions.darkgray',
        valueNumber: 10,
        valueStr: 'darkgray'
    },
    {
        label: 'avOptions.custom',
        labelTranslate: 'avOptions.custom',
        valueNumber: 11,
        valueStr: 'custom'
    }
]

export const AV_PROD_OPTIONS_OVERLAY_POSITION_VERTICAL: IAvSettingSelectOption[] = [
    {
        label: 'avOptions.top',
        labelTranslate: 'avOptions.top',
        valueNumber: 0,
        valueStr: ''
    },
    {
        label: 'avOptions.middle',
        labelTranslate: 'avOptions.middle',
        valueNumber: 1,
        valueStr: ''
    },
    {
        label: 'avOptions.bottom',
        labelTranslate: 'avOptions.bottom',
        valueNumber: 2,
        valueStr: ''
    }
]

export const AV_PROD_OPTIONS_OVERLAY_ALIGNMENT: IAvSettingSelectOption[] = [
    {
        label: 'avOptions.left',
        labelTranslate: 'avOptions.left',
        valueNumber: 0,
        valueStr: ''
    },
    {
        label: 'avOptions.center',
        labelTranslate: 'avOptions.center',
        valueNumber: 1,
        valueStr: ''
    },
    {
        label: 'avOptions.right',
        labelTranslate: 'avOptions.right',
        valueNumber: 2,
        valueStr: ''
    }
]

export const AV_PROD_OPTIONS_OUTPUT_VIDEO_RESOLUTION: IAvSettingSelectOption[] = [
    {
        label: '1920x1080',
        labelTranslate: '1920x1080',
        valueNumber: 0,
        valueStr: '1920x1080'
    },
    {
        label: '1280x720',
        labelTranslate: '1280x720',
        valueNumber: 1,
        valueStr: '1280x720'
    },
    {
        label: '960x540',
        labelTranslate: '960x540',
        valueNumber: 2,
        valueStr: '960x540'
    },
    // {
    //     label: '640x360',
    //     labelTranslate: '640x360',
    //     valueNumber: 3,
    //     valueStr: '640x360'
    // }
]

export const AV_PROD_OPTIONS_PUBLISH_VIDEO_RESOLUTION: IAvSettingSelectOption[] = [
    {
        label: '1080p',
        labelTranslate: '1080p',
        valueNumber: 0,
        valueStr: '1080p'
    },
    {
        label: '720p',
        labelTranslate: '720p',
        valueNumber: 1,
        valueStr: '720p'
    },
    {
        label: '540p',
        labelTranslate: '540p',
        valueNumber: 2,
        valueStr: '540p'
    },
    {
        label: '360p',
        labelTranslate: '360p',
        valueNumber: 3,
        valueStr: '360p'
    }
]

export const AV_PROD_OPTIONS_OUTPUT_VIDEO_BIT_RATE: IAvSettingSelectOption[] = [
    {
        label: '800',
        labelTranslate: '800',
        valueNumber: 800,
        valueStr: ''
    },
    {
        label: '1000',
        labelTranslate: '1000',
        valueNumber: 1000,
        valueStr: ''
    },
    {
        label: '1200',
        labelTranslate: '1200',
        valueNumber: 1200,
        valueStr: ''
    },
    {
        label: '1500',
        labelTranslate: '1500',
        valueNumber: 1500,
        valueStr: ''
    },
    {
        label: '2000',
        labelTranslate: '2000',
        valueNumber: 2000,
        valueStr: ''
    },
    {
        label: '2500',
        labelTranslate: '2500',
        valueNumber: 2500,
        valueStr: ''
    },
    {
        label: '3000',
        labelTranslate: '3000',
        valueNumber: 3000,
        valueStr: ''
    },
    {
        label: '4000',
        labelTranslate: '4000',
        valueNumber: 4000,
        valueStr: ''
    },
    {
        label: '5000',
        labelTranslate: '5000',
        valueNumber: 5000,
        valueStr: ''
    }
]

export const AV_PROD_OPTIONS_PUBLISH_VIDEO_BIT_RATE: IAvSettingSelectOption[] = [
    {
        label: '700',
        labelTranslate: '700',
        valueNumber: 700,
        valueStr: ''
    },
    {
        label: '1000',
        labelTranslate: '1000',
        valueNumber: 1000,
        valueStr: ''
    },
    {
        label: '1500',
        labelTranslate: '1500',
        valueNumber: 1500,
        valueStr: ''
    },
    {
        label: '2000',
        labelTranslate: '2000',
        valueNumber: 2000,
        valueStr: ''
    },
    {
        label: '2500',
        labelTranslate: '2500',
        valueNumber: 2500,
        valueStr: ''
    }
]

export const AV_PROD_OPTIONS_OUTPUT_AUDIO_BIT_RATE: IAvSettingSelectOption[] = [
    {
        label: '48',
        labelTranslate: '48',
        valueNumber: 48,
        valueStr: ''
    },
    {
        label: '96',
        labelTranslate: '96',
        valueNumber: 96,
        valueStr: ''
    },
    {
        label: '128',
        labelTranslate: '128',
        valueNumber: 128,
        valueStr: ''
    },
    {
        label: '192',
        labelTranslate: '192',
        valueNumber: 192,
        valueStr: ''
    },
    {
        label: '256',
        labelTranslate: '256',
        valueNumber: 256,
        valueStr: ''
    },
    {
        label: '320',
        labelTranslate: '320',
        valueNumber: 320,
        valueStr: ''
    }
]

export const AV_PROD_OPTIONS_PUBLISH_AUDIO_BIT_RATE: IAvSettingSelectOption[] = [
    {
        label: '48',
        labelTranslate: '48',
        valueNumber: 48,
        valueStr: ''
    },
    {
        label: '96',
        labelTranslate: '96',
        valueNumber: 96,
        valueStr: ''
    },
    {
        label: '128',
        labelTranslate: '128',
        valueNumber: 128,
        valueStr: ''
    },
    {
        label: '192',
        labelTranslate: '192',
        valueNumber: 192,
        valueStr: ''
    },
    {
        label: '256',
        labelTranslate: '256',
        valueNumber: 256,
        valueStr: ''
    }
]

export const AV_PROD_OPTIONS_OUTPUT_AUDIO_SAMPLE_RATE: IAvSettingSelectOption[] = [
    {
        label: '44100',
        labelTranslate: '44100',
        valueNumber: 44100,
        valueStr: ''
    },
    {
        label: '48000',
        labelTranslate: '48000',
        valueNumber: 48000,
        valueStr: ''
    }
]

export const AV_PROD_OPTIONS_INPUT_SOURCE_TYPE: IAvSettingSelectOption[] = [
    {
        label: 'avOptions.native',
        labelTranslate: 'avOptions.native',
        valueNumber: 1,
        valueStr: ''
    },
    {
        label: 'avOptions.pull',
        labelTranslate: 'avOptions.pull',
        valueNumber: 2,
        valueStr: ''
    },
    {
        label: 'avOptions.push',
        labelTranslate: 'avOptions.push',
        valueNumber: 3,
        valueStr: ''
    }
]

export const AV_PROD_OPTIONS_INPUT_SOURCE_TYPE_EXT: IAvSettingSelectOption[] = [
    {
        label: 'avOptions.pull',
        labelTranslate: 'avOptions.pull',
        valueNumber: 2,
        valueStr: ''
    },
    {
        label: 'avOptions.push',
        labelTranslate: 'avOptions.push',
        valueNumber: 3,
        valueStr: ''
    }
]

export const AV_PROD_OPTIONS_INPUT_PLAYING_MODE: IAvSettingSelectOption[] = [
    {
        label: 'avOptions.playingNormal',
        labelTranslate: 'avOptions.playingNormal',
        valueNumber: 0,
        valueStr: ''
    },
    {
        label: 'avOptions.playingLoop',
        labelTranslate: 'avOptions.playingLoop',
        valueNumber: 1,
        valueStr: ''
    }
]

export const AV_PROD_OPTIONS_INPUT_PLAYING_SPEED: IAvSettingSelectOption[] = [
    {
        label: 'x1',
        labelTranslate: 'x1',
        valueNumber: 1,
        valueStr: ''
    },
    {
        label: 'x2',
        labelTranslate: 'x2',
        valueNumber: 2,
        valueStr: ''
    },
    {
        label: 'x4',
        labelTranslate: 'x4',
        valueNumber: 4,
        valueStr: ''
    },
    {
        label: 'x0.5',
        labelTranslate: 'x0.5',
        valueNumber: 0.5,
        valueStr: ''
    },
    {
        label: 'x0.25',
        labelTranslate: 'x0.25',
        valueNumber: 0.25,
        valueStr: ''
    }
]

export const AV_PROD_OPTIONS_SCORE_COLOR_FORMAT: IAvSettingSelectOption[] = [
    {
        label: 'avOptions.scoreColorFormatNone',
        labelTranslate: 'avOptions.scoreColorFormatNone',
        valueNumber: 0,
        valueStr: ''
    },
    {
        label: 'avOptions.scoreColorFormatOne',
        labelTranslate: 'avOptions.scoreColorFormatOne',
        valueNumber: 1,
        valueStr: ''
    },
    {
        label: 'avOptions.scoreColorFormatTwo',
        labelTranslate: 'avOptions.scoreColorFormatTwo',
        valueNumber: 2,
        valueStr: ''
    },
    {
        label: 'avOptions.scoreColorFormatStripes',
        labelTranslate: 'avOptions.scoreColorFormatStripes',
        valueNumber: 3,
        valueStr: ''
    }
]

export const AV_PROD_OPTIONS_CHRONO_PERIOD: IAvSettingSelectOption[] = [
    {
        label: 'avOptions.none',
        labelTranslate: 'avOptions.none',
        valueNumber: 0,
        valueStr: '0'
    },
    {
        label: '1',
        labelTranslate: '1',
        valueNumber: 1,
        valueStr: '1'
    },
    {
        label: '2',
        labelTranslate: '2',
        valueNumber: 2,
        valueStr: '2'
    },
    {
        label: '3',
        labelTranslate: '3',
        valueNumber: 3,
        valueStr: '3'
    },
    {
        label: '4',
        labelTranslate: '4',
        valueNumber: 4,
        valueStr: '4'
    }
]

export const AV_PROD_OPTIONS_FONTS: IAvSettingSelectOption[] = [
    {
        label: 'avOptions.none',
        labelTranslate: 'avOptions.none',
        valueNumber: 0,
        valueStr: ''
    }
]

export const AV_PROD_OPTIONS_EVENT_CATEGORIES: IAvSettingSelectOption[] = [
]

export const AV_PROD_OPTIONS_EVENT_TIME_ZONE: IAvSettingSelectOption[] = [
]

export const AV_PROD_OPTIONS_PRODUCER_MODES: IAvSettingSelectOption[] = [
    {
        label: 'avOptions.modeFull',
        labelTranslate: 'avOptions.modeFull',
        valueNumber: 0,
        valueStr: ''
    },
    {
        label: 'avOptions.modeOverlay',
        labelTranslate: 'avOptions.modeOverlay',
        valueNumber: 1,
        valueStr: ''
    },
    {
        label: 'avOptions.modeAudio',
        labelTranslate: 'avOptions.modeAudio',
        valueNumber: 2,
        valueStr: ''
    },
    {
        label: 'avOptions.modeHighlights',
        labelTranslate: 'avOptions.modeHighlights',
        valueNumber: 3,
        valueStr: ''
    }
]

export const AV_PROD_OPTIONS_BAND_WIDTH_MODES: IAvSettingSelectOption[] = [
    {
        label: 'avOptions.bwHigh',
        labelTranslate: 'avOptions.bwHigh',
        valueNumber: AvProdLowBandwidthConfig.high,
        valueStr: ''
    },
    {
        label: 'avOptions.bwMedium',
        labelTranslate: 'avOptions.bwMedium',
        valueNumber: AvProdLowBandwidthConfig.medium,
        valueStr: ''
    },
    {
        label: 'avOptions.bwLow',
        labelTranslate: 'avOptions.bwLow',
        valueNumber: AvProdLowBandwidthConfig.low,
        valueStr: ''
    },
    {
        label: 'avOptions.bwVeryLow',
        labelTranslate: 'avOptions.bwVeryLow',
        valueNumber: AvProdLowBandwidthConfig.verylow,
        valueStr: ''
    }
]

export const AV_PROD_OPTIONS_OVERLAY_HTML_TYPE: IAvSettingSelectOption[] = [
    {
        label: 'avOptions.htmlCustom',
        labelTranslate: 'avOptions.htmlCustom',
        valueNumber: AvProdOverlayHtmlProviderType.custom,
        valueStr: ''
    },
    {
        label: 'avOptions.htmlSingular',
        labelTranslate: 'avOptions.htmlSingular',
        valueNumber: AvProdOverlayHtmlProviderType.singular,
        valueStr: ''
    },
    {
        label: 'avOptions.htmlFlexyscore',
        labelTranslate: 'avOptions.htmlFlexyscore',
        valueNumber: AvProdOverlayHtmlProviderType.flexyscore,
        valueStr: ''
    }
]

export const AV_PROD_OPTIONS_OVERLAY_STYLE_MODES: IAvSettingSelectOption[] = [
    {
        label: 'avOptions.basic',
        labelTranslate: 'avOptions.basic',
        valueNumber: 0,
        valueStr: ''
    },
    {
        label: 'avOptions.transparent',
        labelTranslate: 'avOptions.transparent',
        valueNumber: 1,
        valueStr: ''
    },
    {
        label: 'avOptions.rounded',
        labelTranslate: 'avOptions.rounded',
        valueNumber: 2,
        valueStr: ''
    },
    {
        label: 'avOptions.roundedBorder',
        labelTranslate: 'avOptions.roundedBorder',
        valueNumber: 3,
        valueStr: ''
    },
    {
        label: 'avOptions.square',
        labelTranslate: 'avOptions.square',
        valueNumber: 4,
        valueStr: ''
    },
    {
        label: 'avOptions.squareBorder',
        labelTranslate: 'avOptions.squareBorder',
        valueNumber: 5,
        valueStr: ''
    }
]

export const AV_PROD_OPTIONS_INPUT_RESOURCE_TYPES: IAvSettingSelectOption[] = [
    {
        label: 'avOptions.videoClip',
        labelTranslate: 'avOptions.videoClip',
        valueNumber: AvProdInputTypeNumber.videoAudioClip,
        valueStr: AvProdInputType.videoAudioClip
    },
    {
        label: 'avOptions.image',
        labelTranslate: 'avOptions.image',
        valueNumber: AvProdInputTypeNumber.imageTile,
        valueStr: AvProdInputType.imageTile
    },
    {
        label: 'avOptions.imageOverlay',
        labelTranslate: 'avOptions.imageOverlay',
        valueNumber: AvProdInputTypeNumber.imageOverlay,
        valueStr: AvProdInputType.imageOverlay
    },
    {
        label: 'avOptions.document',
        labelTranslate: 'avOptions.document',
        valueNumber: AvProdInputTypeNumber.document,
        valueStr: AvProdInputType.document
    },
    {
        label: 'avOptions.sound',
        labelTranslate: 'avOptions.sound',
        valueNumber: AvProdInputTypeNumber.audioClip,
        valueStr: AvProdInputType.audioClip
    }
]

export const AV_PROD_OPTIONS_INPUT_RESOURCE_TYPES_PROFILE: IAvSettingSelectOption[] = [
    {
        label: 'avOptions.profilePicture',
        labelTranslate: 'avOptions.profilePicture',
        valueNumber: AvProdInputTypeNumber.profilePicture,
        valueStr: AvProdInputType.profilePicture
    },
    {
        label: 'avOptions.profileBanner',
        labelTranslate: 'avOptions.profileBanner',
        valueNumber: AvProdInputTypeNumber.profileBanner,
        valueStr: AvProdInputType.profileBanner
    },
]

export const AV_PROD_OPTIONS_SCORE_SPORT: IAvSettingSelectOption[] = [
    {
        label: 'avOptions.generic',
        labelTranslate: 'avOptions.generic',
        valueNumber: AvProdOverlayScoreSport.generic,
        valueStr: 'avOptions.generic'
    },
    {
        label: 'avOptions.soccer',
        labelTranslate: 'avOptions.soccer',
        valueNumber: AvProdOverlayScoreSport.soccer,
        valueStr: 'avOptions.soccer'
    },
    {
        label: 'avOptions.basketball',
        labelTranslate: 'avOptions.basketball',
        valueNumber: AvProdOverlayScoreSport.basketball,
        valueStr: 'avOptions.basketball'
    },
    {
        label: 'avOptions.tennis',
        labelTranslate: 'avOptions.tennis',
        valueNumber: AvProdOverlayScoreSport.tennis,
        valueStr: 'avOptions.tennis'
    },
    {
        label: 'avOptions.volleyball',
        labelTranslate: 'avOptions.volleyball',
        valueNumber: AvProdOverlayScoreSport.volleyball,
        valueStr: 'avOptions.volleyball'
    },
    {
        label: 'avOptions.football',
        labelTranslate: 'avOptions.football',
        valueNumber: AvProdOverlayScoreSport.football,
        valueStr: 'avOptions.football'
    },
    {
        label: 'avOptions.baseball',
        labelTranslate: 'avOptions.baseball',
        valueNumber: AvProdOverlayScoreSport.baseball,
        valueStr: 'avOptions.baseball'
    },
    {
        label: 'avOptions.softball',
        labelTranslate: 'avOptions.softball',
        valueNumber: AvProdOverlayScoreSport.softball,
        valueStr: 'avOptions.softball'
    },
    // {
    //     label: 'avOptions.hockey',
    //     labelTranslate: 'avOptions.hockey',
    //     valueNumber: AvProdOverlayScoreSport.hockey,
    //     valueStr: 'avOptions.hockey'
    // },
    {
        label: 'avOptions.paddle',
        labelTranslate: 'avOptions.paddle',
        valueNumber: AvProdOverlayScoreSport.paddle,
        valueStr: 'avOptions.paddle'
    },
    {
        label: 'avOptions.badminton',
        labelTranslate: 'avOptions.badminton',
        valueNumber: AvProdOverlayScoreSport.badminton,
        valueStr: 'avOptions.badminton'
    },
]

export const AV_PROD_OPTIONS_INPUT_SOURCE_PUSH_PROTOCOL: IAvSettingSelectOption[] = [
    {
        label: 'avOptions.rtmp',
        labelTranslate: 'avOptions.rtmp',
        valueNumber: 1,
        valueStr: ''
    },
    {
        label: 'avOptions.srt',
        labelTranslate: 'avOptions.srt',
        valueNumber: 2,
        valueStr: ''
    }
]

export const AV_PROD_OPTIONS_INPUT_TILE_FILL_MODE: IAvSettingSelectOption[] = [
    {
        label: 'avOptions.fill',
        labelTranslate: 'avOptions.fill',
        valueNumber: 0,
        valueStr: ''
    },
    {
        label: 'avOptions.fit',
        labelTranslate: 'avOptions.fit',
        valueNumber: 1,
        valueStr: ''
    }
]

export const AV_PROD_OPTIONS_CURRENCY: IAvSettingSelectOption[] = [
    {
        label: 'avOptions.currencyEuro',
        labelTranslate: 'EUR (€)',
        valueNumber: 0,
        valueStr: 'EUR'
    },
    {
        label: 'avOptions.currencyDollar',
        labelTranslate: 'USD ($)',
        valueNumber: 1,
        valueStr: 'USD'
    },
    {
        label: 'avOptions.currencyGbp',
        labelTranslate: 'GBP (£)',
        valueNumber: 2,
        valueStr: 'GBP'
    },
]

export const AV_PROD_OPTIONS_EVENT_START_TIME_MODE: IAvSettingSelectOption[] = [
    {
        label: 'avOptions.eventStartTimeNow',
        labelTranslate: 'avOptions.eventStartTimeNow',
        valueNumber: 0,
        valueStr: ''
    },
    {
        label: 'avOptions.eventStartTimeSchedule',
        labelTranslate: 'avOptions.eventStartTimeSchedule',
        valueNumber: 1,
        valueStr: ''
    },
]

export const AV_PROD_OPTIONS_EVENT_START_CONFIG_MODE: IAvSettingSelectOption[] = [
    {
        label: 'avOptions.eventStartConfigPrevious',
        labelTranslate: 'avOptions.eventStartConfigPrevious',
        valueNumber: 0,
        valueStr: 'previous'
    },
    {
        label: 'avOptions.eventStartConfigDefault',
        labelTranslate: 'avOptions.eventStartConfigDefault',
        valueNumber: 1,
        valueStr: 'default'
    },
    {
        label: 'avOptions.eventStartConfigCustom',
        labelTranslate: 'avOptions.eventStartConfigCustom',
        valueNumber: 1,
        valueStr: 'custom'
    },
]

export const AV_PROD_OPTIONS_EVENT_AUTO_START_MODE: IAvSettingSelectOption[] = [
    {
        label: 'avOptions.eventAutoStartModeManual',
        labelTranslate: 'avOptions.eventAutoStartModeManual',
        valueNumber: 0,
        valueStr: ''
    },
    {
        label: 'avOptions.eventAutoStartModeAuto',
        labelTranslate: 'avOptions.eventAutoStartModeAuto',
        valueNumber: 1,
        valueStr: ''
    },
]

export const AV_PROD_OPTIONS_SCORE_FOOTBALL_DOWN: IAvSettingSelectOption[] = [
    {
        label: 'avOptions.none',
        labelTranslate: 'avOptions.none',
        valueNumber: 0,
        valueStr: ''
    },
]
// Add down numbers
for (let i: number=1; i<=4; i++){
    AV_PROD_OPTIONS_SCORE_FOOTBALL_DOWN.push(
      {
        label: i.toString(),
        labelTranslate: i.toString(),
        valueNumber: i,
        valueStr: ''
      }
    )
}

export const AV_PROD_OPTIONS_SCORE_FOOTBALL_DOWN_YARDS: IAvSettingSelectOption[] = [
    {
        label: 'avOptions.goal',
        labelTranslate: 'avOptions.goal',
        valueNumber: 0,
        valueStr: ''
    },
]
// Add yard numbers
for (let i: number=1; i<100; i++){
    AV_PROD_OPTIONS_SCORE_FOOTBALL_DOWN_YARDS.push(
      {
        label: i.toString(),
        labelTranslate: i.toString(),
        valueNumber: i,
        valueStr: ''
      }
    )
}

// Array of option arrays to manage language update
export const AV_PROD_OPTIONS_LIST: IAvSettingSelectOption[][] = [
    AV_PROD_OPTIONS_AUDIO_MODE_MASTER,
    AV_PROD_OPTIONS_AUDIO_MODE_LIVE,
    AV_PROD_OPTIONS_AUDIO_MODE_CLIP,
    AV_PROD_OPTIONS_BACKGROUND_TYPE,
    AV_PROD_OPTIONS_COLOR,
    AV_PROD_OPTIONS_TILE_TRANSITION,
    AV_PROD_OPTIONS_TILE_TRANSITION_DIRECTION,
    AV_PROD_OPTIONS_OVERLAY_POSITION_VERTICAL,
    AV_PROD_OPTIONS_OVERLAY_ALIGNMENT,
    AV_PROD_OPTIONS_INPUT_SOURCE_TYPE,
    AV_PROD_OPTIONS_INPUT_SOURCE_TYPE_EXT,
    AV_PROD_OPTIONS_INPUT_PLAYING_MODE,
    AV_PROD_OPTIONS_INPUT_PLAYING_SPEED,
    AV_PROD_OPTIONS_SCORE_COLOR_FORMAT,
    AV_PROD_OPTIONS_CHRONO_PERIOD,
    AV_PROD_OPTIONS_PRODUCER_MODES,
    AV_PROD_OPTIONS_BAND_WIDTH_MODES,
    AV_PROD_OPTIONS_OVERLAY_HTML_TYPE,
    AV_PROD_OPTIONS_OVERLAY_STYLE_MODES,
    AV_PROD_OPTIONS_INPUT_RESOURCE_TYPES,
    AV_PROD_OPTIONS_INPUT_RESOURCE_TYPES_PROFILE,
    AV_PROD_OPTIONS_SCORE_SPORT,
    AV_PROD_OPTIONS_INPUT_SOURCE_PUSH_PROTOCOL,
    AV_PROD_OPTIONS_INPUT_TILE_FILL_MODE,
    AV_PROD_OPTIONS_CURRENCY,
    AV_PROD_OPTIONS_EVENT_START_TIME_MODE,
    AV_PROD_OPTIONS_EVENT_START_CONFIG_MODE,
    AV_PROD_OPTIONS_EVENT_AUTO_START_MODE,
    AV_PROD_OPTIONS_SCORE_FOOTBALL_DOWN,
    AV_PROD_OPTIONS_SCORE_FOOTBALL_DOWN_YARDS
]
