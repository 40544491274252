import { NgForm } from '@angular/forms';
import { tap } from 'rxjs';
import { inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { AlertStatus, ToastPlacement, ToastStatus } from '../../enums/common.enum';
import { IResetPasswordUser, IUpdateUser } from '../../interfaces/user/user.interface';
import { IAlertDataButton, IHttpResponse, IToastConfig } from '../../interfaces/utils/utils.interface';
import { PasswordClass } from './password.class';
import { UserService } from '../../services/user/user.service';
import { AlertService } from '../../services/alert/alert.service';
import { ModalDialogId } from '../../enums/utils.enum';
import { AvProdInputTypeNumber } from '../../const/av-producer.const';
import { ModalManagerService } from '../../services/modal/modal-manager.service';


export class EditProfileClass extends PasswordClass {

  protected updNameButtonDisabled: boolean | null = null;
  protected updPassButtonDisabled: boolean | null = null;
  protected setPassButtonDisabled: boolean | null = null;

  protected error: boolean = false;
  protected errorTitle: string = '';
  protected errorMessage: string = '';
  protected _layoutMode: string = 'panel'; //'full', 'panel'
  protected userData: IUpdateUser = {
    name: '',
    curPassword: '',
    newPassword: '',
    confirmPassword: '',
  };
  protected isDefaultPassword: boolean = false;
  protected validSetPasswordCode: boolean = false;
  protected userSetPass: IResetPasswordUser = {
    code: undefined,
    email: this.userService.user.email,
    password: '',
    confirm: ''
  };

  private translate: TranslateService = inject(TranslateService);

  constructor(protected userService: UserService,
              protected alertService: AlertService,
              protected modalService: ModalManagerService) {
    super(3);
    this.isDefaultPassword = this.userService.user?.defaultPassword ?? false;
  }

  protected destroy(): void {
    this.validSetPasswordCode = false
  }

  protected updateName(form: NgForm): void {
    this.updNameButtonDisabled = true;
    this.userData.curPassword = '';
    this.userData.newPassword = '';
    this.userData.confirmPassword = '';
    if (form.valid) {
      this.userService.update(this.userData)
        .pipe(
          tap(() => {
            this.updNameButtonDisabled = false;
          })
        )
        .subscribe({
          next: this.handleUpdNameResponse.bind(this),
          error: this.handleUpdNameErrorResponse.bind(this),
        });
    }
  }

  protected updatePassword(form: NgForm): void {
    this.updPassButtonDisabled = true;

    if (form.valid) {
      if (this.isDefaultPassword && this.validSetPasswordCode) {
        this.userSetPass.password = this.userData.newPassword ?? '';
        this.userSetPass.confirm = this.userData.confirmPassword;
        this.userService.resetPassword(this.userSetPass)
          .pipe(
            tap(() => {
              form.control.markAsUntouched();
            })
          )
          .subscribe({
            next: this.handleResetPasswordResponse.bind(this),
            error: this.handleResetPasswordErrorResponse.bind(this),
          });
      } else {
        this.userService.update(this.userData)
          .pipe(tap(() => (this.updPassButtonDisabled = false)))
          .subscribe({
            next: this.handleUpdPassResponse.bind(this),
            error: this.handleUpdPassErrorResponse.bind(this),
          });
      }
    }
  }

  protected checkPassword(form: NgForm): void {
    if (form && this.userData.newPassword !== this.userData.confirmPassword) {
      form.control.get('confirmPassword')?.setErrors({ incorrect: true });
    }
  }

  protected showErrorMessage(): void {
    // Must override
  }

  protected showToastMessage(param: IToastConfig): void {
    // Must override
  }

  protected setLayoutMode(layout: string): void {
    this._layoutMode = layout;
  }

  /**
   * After calling the api to send a deletion code to the user,
   * calls the function that displays the prompt to enter that code
   *
   * @private
   */
  protected sendPasswordCode(): void {
    this.setPassButtonDisabled = true;
    this.userService.sendSetPasswordCode(this.userSetPass)
      .subscribe((resp: boolean) => {
        this.setPassButtonDisabled = false;
        if (resp) {
          this.showSetPasswordPrompt();
        } else {
          this.alertService.showAlert({
            show: true,
            status: AlertStatus.error,
            text: this.translate.instant('general.error'),
            closeButton: true,
            buttons: [
              {
                text: 'general.close',
                color: 'primary',
                closeButton: true,
              },
            ],
          });
        }
      });
  }

  /**
   * displays the alert component with a prompt to enter previously sent code to user
   *
   * @private
   */
  private showSetPasswordPrompt(text?: string): void {
    const BUTTONS: IAlertDataButton[] = [];
    BUTTONS.push({
      text: 'general.cancel',
      color: 'primary',
      closeButton: true,
    });
    BUTTONS.push({
      text: 'general.send',
      color: 'danger',
      fill: 'outline',
      onClickLoading: true,
      onDismiss: 'code',
      handler: (code: number) => {
        this.userSetPass.code = code;
        this.userService.checkForgotPasswordCode(this.userSetPass)
          .subscribe({
            next: this.handleSetPasswordConfirmResponse.bind(this),
            error: this.handleSetPasswordConfirmErrorResponse.bind(this),
          });
      },
    });

    this.alertService.showAlert({
      show: true,
      status: AlertStatus.error,
      title: this.translate.instant('users.setPassword'),
      text: text ? text : this.translate.instant('users.confirmSetPasswordCode'),
      component: 'code',
      componentInputs: { model: this.userSetPass },
      buttons: BUTTONS,
      closeButton: true,
    });
  }

  protected uploadProfilePicture(){
    console.log('[EditProfileClass] uploadProfilePicture');
    this.modalService.show({
        id: ModalDialogId.managerResourceProfileUpload,
        subId: AvProdInputTypeNumber.profilePicture,
        title: 'inputAdd.resourceUploadProfilePicture',
      },true);
  }

  protected uploadProfileBanner(){
    console.log('[EditProfileClass] uploadProfileBanner');
    this.modalService.show({
        id: ModalDialogId.managerResourceProfileUpload,
        subId: AvProdInputTypeNumber.profileBanner,
        title: 'inputAdd.resourceUploadProfileBanner',
      },true);
  }

  private handleUpdNameResponse(result: boolean): void {
    if (result) {
      this.error = false;
      this.errorTitle = 'users.dataSuccessfullyUpdatedTitle';
      this.errorMessage = 'users.dataSuccessfullyUpdated';
    } else {
      this.error = true;
      this.errorTitle = '';
      this.errorMessage = 'users.dataErrorUpdated';
    }
    this.showErrorMessage();
  }

  private handleUpdNameErrorResponse(): void {
    this.updNameButtonDisabled = false;
    this.error = true;
    this.errorTitle = '';
    this.errorMessage = 'users.dataErrorUpdated';
    this.showErrorMessage();
  }

  private handleUpdPassResponse(result: boolean): void {
    this.updPassButtonDisabled = false;
    if (result) {
      this.error = false;
      this.errorTitle = 'users.passwordUpdated';
      this.errorMessage = 'users.passwordSuccessfullyUpdated';
    } else {
      this.error = true;
      this.errorTitle = '';
      this.errorMessage = 'users.passwordErrorUpdated';
    }
    this.showErrorMessage();
  }

  private handleUpdPassErrorResponse(errorCode: any): void {
    this.updPassButtonDisabled = false;
    let errorText = 'users.passwordErrorUpdated';
    switch (errorCode) {
      case 605:
        errorText = 'users.wrongCredentials';
        break;
    }
    this.error = true;
    this.errorTitle = '';
    this.errorMessage = errorText;
    this.showErrorMessage();
  }

  private handleSetPasswordConfirmResponse(response: IHttpResponse): void {
    console.log('response data', response);
    this.alertService.close();
    this.userSetPass.authKey = response.data;
    this.validSetPasswordCode = true;
    this.showToastMessage({
      options: {
        placement: ToastPlacement.middleCenter
      },
      data: {
        status: ToastStatus.info,
        text: 'users.setPasswordAcceptedToast'
      }
    });
  }

  private handleSetPasswordConfirmErrorResponse(): void {
    this.alertService.close();

    let errorText: string = 'users.passwordErrorUpdated';

    if(!this.validSetPasswordCode) {
      errorText = 'users.errorCodeInfo';
    }
    this.error = true;
    this.errorTitle = '';
    this.errorMessage = errorText;
    this.showErrorMessage();
  }

  private handleResetPasswordResponse(result: boolean): void {
    console.log(this.userService.user);
    this.updPassButtonDisabled = false;
    this.validSetPasswordCode = false;
    this.isDefaultPassword = false;
    this.userData = {
      newPassword: '',
      confirmPassword: ''
    }
    if (result) {
      this.error = false;
      this.errorTitle = 'users.setPassword';
      this.errorMessage = 'users.passwordSuccessfullySet';
    } else {
      this.error = true;
      this.errorTitle = '';
      this.errorMessage = 'users.passwordErrorSet';
    }
    this.showErrorMessage();
  }

  private handleResetPasswordErrorResponse(errorCode: any): void {
    this.updPassButtonDisabled = false;
    let errorText = 'users.passwordErrorUpdated';
    switch (errorCode) {
      case 605:
        errorText = 'users.wrongCredentials';
        break;
    }
    this.error = true;
    this.errorTitle = '';
    this.errorMessage = errorText;
    this.showErrorMessage();
  }

}
