export const AvProdServerStatusDefault: IAvProdServerStatus = {
    ts: 0,
    viewers: 0,
    viewersTotal: 0,
    cpuIdle: 0,
    memFree: 0,
    transferIn: 0,
    transferOut: 0,
    diskFree: 0,
    lastActivityWarning: false,
    event: {
        status: '',
        statusAssets: '',
        producerToken: '',
        publisherToken: '',
        viewerToken: '',
        streamStarted: 0
    }
}

export interface IAvProdServerEventStatus {
    status: string;
    producerToken: string;
    publisherToken: string;
    viewerToken: string;
    streamStarted: number;
    statusAssets: string;
}

export interface IAvProdServerStatus {
    ts: number;
    viewers: number;
    viewersTotal: number;
    cpuIdle: number;
    memFree: number;
    transferIn: number;
    transferOut: number;
    diskFree: number;
    lastActivityWarning: boolean;
    event: IAvProdServerEventStatus;
}
