import { FormControl, FormGroup } from '@angular/forms';

import { IAvSettingsItemConfig } from '../../interfaces/av-producer/event-av-producer.interface';
import { AvProdSettingsType } from '../../const/av-producer.const';
import { EventsService } from '../../services/events/events.service';
import { UserService } from '../../services/user/user.service';
import { IToastConfig } from '../../interfaces/utils/utils.interface';
import { IEventContentReport } from '../../interfaces/events/event.interface';
import { API_OPTIONS_REPORT_CONTENT_REASONS } from '../../const/api.const';


export class EventReportContentClass {
  protected reportSettingsForm: FormGroup = new FormGroup([]);
  protected isReady: boolean = false;
  protected _eventId: number | undefined;

  protected items: IAvSettingsItemConfig[] = [
    {
      id: 'reportReason',
      type: AvProdSettingsType.selectComboNumber,
      name: 'player.reportContentReason',
      min: 0,
      max: 0,
      step: 1,
      options: API_OPTIONS_REPORT_CONTENT_REASONS,
      placeholder: 'player.selectReportReason',
      value: '',
      config: {
        mode: 'native'
      }
    },
    {
      id: 'reportComments',
      type: AvProdSettingsType.textArea,
      name: 'player.reportContentComments',
      min: 0,
      max: 0,
      step: 1,
      options: [],
      placeholder: '',
      value: ''
    }
  ];

  constructor(protected eventsService: EventsService,
              protected users: UserService) {
    // Do nothing
  }

  protected init(): void {
    console.log('[EventReportContentClass] init');
    this.items.forEach((element: IAvSettingsItemConfig) => {
      this.reportSettingsForm.addControl(element.id, new FormControl());
    });
  }

  protected setEventId(id: number): void {
    this._eventId = id;
  }

  protected emitReportSent(value: boolean): void {
    // must be overridden
  }

  protected displayToast(config: IToastConfig): void {
    // must be overridden
  }

  protected onSettingChanged(event: IAvSettingsItemConfig): void {
    if (this.reportSettingsForm.get('reportReason')?.value !== null){
      this.isReady = true;
    }
    else{
      this.isReady = false;
    }
  }

  protected onSend(): void {

    if (this._eventId !== undefined) {
      let report: IEventContentReport = {
        eventId: this._eventId,
        reasonId: this.reportSettingsForm.get('reportReason')?.value,
        comments: this.reportSettingsForm.get('reportComments')?.value
      }
      this.eventsService.sendContentReport(report).pipe()
          .subscribe(data => {console.log("[ReportContent] Command response: " + JSON.stringify(data))});
      this.emitReportSent(true);
    }

  }

}
