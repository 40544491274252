import { Subscription, timer } from 'rxjs';

import { AlertStatus, ToastPlacement, ToastStatus } from '../../enums/common.enum';
import { IAlertData, IToastConfig } from '../../interfaces/utils/utils.interface';
import { IAvProdInput } from '../../interfaces/av-producer/event-av-producer.interface';
import { IAvProdTimeTag } from '../../interfaces/av-producer/composer-settings.interface';
import { AvProdDeviceType, AvProdInputPlayingState, AvProdInputTypeNumber } from '../../const/av-producer.const';
import { AvProducerService } from '../../services/av-producer/av-producer.service';
import { ModalDialogId } from '../../enums/utils.enum';
import { ModalManagerService } from '../../services/modal/modal-manager.service';


export class ToolboxHighlightsClass {

  protected listHighlightInputs: IAvProdInput[] = [];
  protected listLiveActiveInputs: IAvProdInput[] = [];
  protected listHighlightTags: IAvProdTimeTag[] = [];
  protected highlightBusy: boolean = false;

  protected tagIdEditionActive: number | undefined;
  protected tagNameEdition: string = '';

  protected inputListSubscription: Subscription | undefined;
  protected inputStatusSubscription: Subscription | undefined;
  protected composerSettingsSubscription: Subscription | undefined;
  protected highlightBusySubscription: Subscription | undefined;

  constructor(protected avProd: AvProducerService, protected modalService: ModalManagerService) {
    // do nothing
  }

  init(): void {
    this.inputListSubscription = this.avProd.components$.subscribe(() => this.updateList());
    this.inputStatusSubscription = this.avProd.onNewInputStatus$.subscribe(() => this.updateList());
    this.composerSettingsSubscription = this.avProd.onNewComposerSettings$.subscribe(() => this.updateList());
    this.updateList();
  }

  close(): void {
    if (this.inputListSubscription !== undefined) this.inputListSubscription.unsubscribe();
    if (this.inputStatusSubscription !== undefined) this.inputStatusSubscription.unsubscribe();
    if (this.composerSettingsSubscription !== undefined) this.composerSettingsSubscription.unsubscribe();
    this.highlightBusySubscription?.unsubscribe();
  }

  protected emitInputSelect(id: number): void {
    // must be overridden
  }

  protected displayAlert(data: IAlertData): void {
    // must be overridden
  }

  protected displayToast(config: IToastConfig): void {
    // must be overridden
  }

  protected updateList(): void {
    //console.log('[ToolboxHighlightsClass] update input list');
    this.listHighlightInputs = this.avProd.inputs.filter((input: IAvProdInput) => input.info.inputTypeNumber === AvProdInputTypeNumber.highlight);
    this.listLiveActiveInputs = this.avProd.inputs.filter(
      (input => (input.info.inputTypeNumber === AvProdInputTypeNumber.videoAudioStream) &&
        (input.status?.playingState === AvProdInputPlayingState.playing))
    );
    if (this.avProd.composerSettings.timeTags !== undefined) {
      const HLS: IAvProdTimeTag[] = this.avProd.composerSettings.timeTags;
      // Update or add new
      for (let i = 0; i < HLS.length; i++) {
        const FOUND: IAvProdTimeTag | undefined = this.listHighlightTags.find((element: IAvProdTimeTag) => element.id === HLS[i].id);
        if (FOUND === undefined) {
          this.listHighlightTags.unshift(HLS[i]);
        } else {
          Object.assign(FOUND, HLS[i]);
        }
      }
      // Remove
      for (let i = this.listHighlightTags.length - 1; i >= 0; i--) {
        const FOUND: IAvProdTimeTag | undefined = HLS.find((element: IAvProdTimeTag) => element.id === this.listHighlightTags[i].id);
        if (FOUND === undefined) {
          this.listHighlightTags.splice(i, 1);
        }
      }
      //this.listHighlightTags = this.avProd.composerSettings.timeTags.slice().reverse();
    } else {
      this.listHighlightTags = [];
    }
  }

  protected onInputSelect(id: number): void {
    this.emitInputSelect(id)
  }

  protected onDragStart(event: any, inputId: number): void {
    //console.log('[ToolboxHighlightsClass] onDragStart ' + JSON.stringify(event));
    event.dataTransfer.setData('itemId', inputId);
    event.dataTransfer.setData('itemType', AvProdDeviceType.input);
  }

  protected onCreateHighlight(): void {
    let allow: boolean = true;
    const MAX_TAGS: number | undefined = this.avProd.serverSettings?.production.maxHighlights;
    if (this.highlightBusy === false){
      if ((MAX_TAGS !== undefined)&&(this.listHighlightTags.length >= MAX_TAGS)){
        allow = false;
        this.displayToast({
          options: {
            placement: ToastPlacement.topCenter,
          },
          data: {
            status: ToastStatus.error,
            text: 'toolboxHighlights.errorMaxHighlights',
            closeButtonBody: true,
          },
        });
      }
      if (allow){
        this.avProd.azzCmdComposerTimeTagCreateCurrent();
        this.highlightBusy = true;
        this.highlightBusySubscription?.unsubscribe();
        this.highlightBusySubscription = timer(5000).subscribe(() => this.highlightBusy = false);
        this.displayToast({
          options: {
            placement: ToastPlacement.middleCenter,
          },
          data: {
            status: ToastStatus.success,
            text: 'toolboxHighlights.highlightCreateSuccess',
            closeButtonBody: true,          }
        });  
      }
    }
  }

  protected onRemoveHighlightTag(id: number | undefined): void {
    if (id !== undefined) {
      console.log('[ToolboxHighlightsClass] onRemoveHighlightTag');

      this.displayAlert({
        closeButton: true,
        show: true,
        status: AlertStatus.question,
        title: 'general.confirmation',
        text: 'toolboxHighlights.removeConfirmation',
        buttons: [{
          text: 'general.cancel',
          color: 'primary',
          fill: 'outline',
          closeButton: true
        },
          {
            text: 'general.confirm',
            color: 'danger',
            closeButton: true,
            handler: (): void => {
              this.avProd.azzCmdComposerTimeTagRemove(id);
              // For now, also remove all associated highlight clips
              this.listHighlightInputs.forEach((element: IAvProdInput) => {
                if ((element.info.inputTypeNumber === AvProdInputTypeNumber.highlight) &&
                  (element.info.highlightTagId === id)) {
                  this.avProd.azzCmdInputDelete(element.info.id);
                }
              })
            }
          }]
      });
    }
  }

  protected onEditHighlightTag(id: number | undefined, event: MouseEvent): void {
    const TAG: IAvProdTimeTag | undefined = this.listHighlightTags.find((element: IAvProdTimeTag) => element.id === id);
    if (TAG?.name !== undefined) {
      if (this.tagIdEditionActive !== id) {
        this.tagNameEdition = TAG.name;
        this.tagIdEditionActive = id;
        timer(50).subscribe(() => {
          const MY_INPUT: HTMLElement | null = <HTMLElement>document?.getElementById('inputTagName') as HTMLElement;
          if (MY_INPUT !== null) {
            MY_INPUT.focus();
          }
        });
      }
      console.log('[ToolboxHighlightsClass] onEditHighlightTag: ' + JSON.stringify(event));
    }
  }

  protected onConfigHighlights(){
    this.modalService.show({id: ModalDialogId.producerSettings, title: 'producer.producerSettings', subId: 5}, false);
  }

  protected onTagNameChange(): void {
    console.log('[ToolboxHighlightsClass] onTagNameChange ' + this.tagNameEdition);
    if ((this.tagIdEditionActive !== undefined) &&
      (this.tagNameEdition !== '')) {

      const TAG: IAvProdTimeTag | undefined = this.listHighlightTags.find((element: IAvProdTimeTag) => element.id === this.tagIdEditionActive);
      if ((TAG !== undefined) && (TAG?.name !== this.tagNameEdition)) {
        this.avProd.azzCmdComposerTimeTagRename(this.tagIdEditionActive, this.tagNameEdition);
        TAG.name = this.tagNameEdition;
      }
    }
    this.tagNameEdition = '';
    this.tagIdEditionActive = undefined;
  }
}
