import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';

import {
  ActivateAccountClass,
  AlertStatus,
  IAlertData,
  UserService,
  AlertService,
  IAlertDataButton, COMMON
} from '@azz-life-streamer/shared';
import { LayoutService } from '../../../core/services/layout/layout.service';


@Component({
  selector: 'web-activate-account',
  templateUrl: './activate-account.component.html',
  styleUrls: ['../styles/users-form.scss'],
})
export class ActivateAccountComponent extends ActivateAccountClass {

  @ViewChild('fCode') fCodeForm!: NgForm;

  @Output() alert: EventEmitter<IAlertData> = new EventEmitter<IAlertData>();

  constructor(protected override userService: UserService,
              private layoutService: LayoutService,
              private alertService: AlertService,
              private router: Router) {
    super(userService);
  }

  override activateAccount(): void {
    super.activateAccount(this.fCodeForm);
  }

  override modalClose(): void {
    this.layoutService.hideUserModal();
    const REDIRECT: string | null = localStorage.getItem(COMMON.storageKeys.redirectTo);
    if(REDIRECT) {
      localStorage.removeItem(COMMON.storageKeys.redirectTo);
      this.router.navigateByUrl(REDIRECT)
        .catch(console.error);
    } else {
      this.router.navigateByUrl(COMMON.urls.manager)
        .catch(console.error);
    }
  }

  override showErrorMessage(): void {
    const BUTTONS: IAlertDataButton[] = [];
    BUTTONS.push({
      text: 'general.close',
      color: 'primary',
      handler: () => {
        this.alertService.close();
      },
    });
    this.alertService.showAlert({
      show: true,
      status: this.error? AlertStatus.error : AlertStatus.success,
      title: this.errorTitle ?? '',
      text: this.errorMessage ?? '',
      closeButton: true,
      buttons: BUTTONS
    });
  }
}
