import { Component, OnInit } from '@angular/core';
import { MetaCheckerService } from '../../../core/services/meta-checker/meta-checker.service';
import { ActivatedRoute } from '@angular/router';
import { EventsService, IPlayerData } from '@azz-life-streamer/shared';
import { LayoutService } from '../../../core/services/layout/layout.service';


@Component({
  selector: 'web-embed',
  templateUrl: './embed.component.html',
  styleUrls: ['./embed.component.scss'],
})
export class EmbedComponent implements OnInit {
  protected token: string = '';
  protected playerDataReady: boolean = false;
  protected playerData: IPlayerData = {
    id: 0,
    name: 'Name',
    token: '',
    start: new Date(),
    protected: false,
    password: '',
    status: ''
  }

  constructor(private activatedRoute: ActivatedRoute,
              private eventsService: EventsService,
              private metaService: MetaCheckerService){

    this.metaService.addNoIndex();    
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(params => {
      this.token = params.get('token')!;
      this.playerData.token = this.token;
      console.log("[EmbedPageComponent] Token: " + this.token);
      this.requestEventInfo();
   });
  }

  requestEventInfo() {
    this.eventsService.getEventInfo('viewer', this.token)
      .pipe()
      .subscribe(event => {
        console.log('[EmbedPageComponent] getEvent: ' + JSON.stringify(event));
        if (event !== null){
          // Assign player data info
          if (event.id) this.playerData.id = event.id;
          else event.id = 0;
          this.playerData.name = event.name;
          this.playerData.status = event.status? event.status : 'live';
          if (event.started) this.playerData.start = new Date(event.started * 1000);
          if (event.protected === true){
            this.playerData.protected = true;
          }
          else if (event.protected === false){
            this.playerData.protected = false;
          }
          else if (event.protected === 0){
            this.playerData.protected = false;
          }
          else if (event.protected === 1){
            this.playerData.protected = true;
          }

          this.playerDataReady = true;
        }
    });
  }
}
