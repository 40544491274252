import { HttpErrorResponse } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { Subscription, takeWhile, timer } from 'rxjs';

import { PasswordClass } from './password.class';
import { IResetPasswordUser } from '../../interfaces/user/user.interface';
import { IHttpResponse } from '../../interfaces/utils/utils.interface';
import { COMMON } from '../../const/common.const';
import { UserService } from '../../services/user/user.service';


export class ForgotPasswordClass extends PasswordClass {

  protected sendButtonDisabled: boolean = false;
  protected resendButtonDisabled: boolean = true;
  protected step: string = 'emailForm';
  protected confirmInvalid: boolean = false;
  protected error: boolean = false;
  protected errorTitle: string = '';
  protected errorMessage: string = '';
  protected activateAccountPend: boolean = false;
  protected showMessage: boolean = false;
  protected resendingCounter: number | undefined = COMMON.resendingCodeCounter

  protected forgotPasswordUser: IResetPasswordUser = {
    code: undefined,
    email: '',
    password: '',
    confirm: ''
  };

  private resentCodeSubscription: Subscription | undefined = undefined;

  constructor(protected userService: UserService) {
    super(2);
  }

  protected destroy(): void {
    this.resentCodeSubscription?.unsubscribe();
  }

  protected sendEmail(fEmail: NgForm): void {
    if (fEmail.valid) {
      this.sendButtonDisabled = true;
      this.userService.sendForgotPassword(this.forgotPasswordUser)
        .subscribe({
          next: this.handleSendEmailResponse.bind(this),
          error: this.handleErrorResponse.bind(this),
        });
    }
  }

  protected sendCode(fCode: NgForm): void {
    if (fCode.valid) {
      this.sendButtonDisabled = true;
      this.userService.checkForgotPasswordCode(this.forgotPasswordUser)
        .subscribe({
          next: this.handleSendCodeResponse.bind(this),
          error: this.handleErrorResponse.bind(this),
        });
    }
  }

  protected sendPassword(fPass: NgForm): void {
    if (fPass.valid) {
      this.sendButtonDisabled = true;
      this.userService.resetPassword(this.forgotPasswordUser)
        .subscribe({
          next: this.handleResetPasswordResponse.bind(this),
          error: this.handleErrorResponse.bind(this),
        });
    }
  }

  protected sendActivationCode(): void {
    this.userService.userActivation = this.forgotPasswordUser.email;
    this.userService.sendActivation({email: this.forgotPasswordUser.email, code: 0})
      .subscribe();
  }

  protected sendRestorationPasswordCode(): void {
    this.resendingCounter = undefined;
    this.resendButtonDisabled = true;
    this.resentCodeSubscription?.unsubscribe();
    this.resentCodeSubscription = this.resentTimer();
    this.userService.sendForgotPassword(this.forgotPasswordUser).subscribe();
  }

  protected checkPassword(fPass: NgForm): void {
    if (this.forgotPasswordUser.password !== this.forgotPasswordUser.confirm) {
      fPass.control.get('confirm')?.setErrors({'incorrect': true});
    }
  }

  protected modalClose(): void {
    // must be overridden
  }

  protected resetVars(): void {
    this.error = false;
    this.showMessage = false;
    this.errorTitle = '';
    this.errorMessage = '';
    this.step = 'emailForm';
    this.sendButtonDisabled = false;
    this.resendButtonDisabled = false;
    this.activateAccountPend = false;
    this.forgotPasswordUser = {
      code: undefined,
      email: '',
      password: '',
      confirm: ''
    };
  }

  protected showErrorMessage(): void {
    //must be overridden
  }

  private handleSendEmailResponse(response: IHttpResponse): void {
    this.sendButtonDisabled = false;
    if (response.code === 0) {
      this.step = 'codeForm';
      this.resendingCounter = COMMON.resendingCodeCounter;
      this.resentCodeSubscription?.unsubscribe();
      this.resentCodeSubscription = this.resentTimer();
      this.error = false;
    } else {
      this.error = true;
      this.showMessage = true;
      switch (response.code) {
        case 601:
          this.errorTitle = 'users.inactiveAccount';
          this.errorMessage = 'users.userInactiveInfo';
          this.activateAccountPend = true;
          this.showErrorMessage();
          break;
      }
    }
  }

  private handleSendCodeResponse(response: IHttpResponse): void {
    this.sendButtonDisabled = false;
    this.forgotPasswordUser.authKey = response.data;
    this.error = false;
    this.step = 'passForm';
  }

  private handleResetPasswordResponse(response: IHttpResponse): void {
    this.showMessage = true;
    this.sendButtonDisabled = false;
    if (response.code === 0) {
      this.errorTitle = 'users.passwordUpdated';
      this.errorMessage = 'users.passwordSuccessfullyUpdated';
      setTimeout(() => {
        this.resetVars();
      }, 6000);
    } else {
      this.error = true;
      switch (response.code) {
        case 600:
          this.errorTitle = 'general.error';
          this.errorMessage = 'users.passwordErrorUpdated';
          break;
      }
    }
    this.showErrorMessage()
  }

  private handleErrorResponse(err: HttpErrorResponse): void {
    this.sendButtonDisabled = false;
    this.showMessage = true;
    this.error = true;
    switch (err.error.code) {
      case 604:
        this.errorTitle = 'users.userNotFound';
        this.errorMessage = 'users.emailDoesntMatch';
        break;
      case 608:
        this.errorTitle = 'users.invalidCode';
        this.errorMessage = 'users.errorCodeInfo';
        break;
    }
    this.showErrorMessage();
  }

  private resentTimer(): Subscription {
    this.resendingCounter = COMMON.resendingCodeCounter;
    return timer(0, 1000)
      .pipe(takeWhile((value: number) => value < COMMON.resendingCodeCounter))
      .subscribe({
        next: () => {
          if (this.resendingCounter) {
            this.resendingCounter--;
          }
        },
        complete: () => {
          this.resendButtonDisabled = false;
        },
      });
  }
}
