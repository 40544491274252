<form (ngSubmit)="activateAccount()"
      #fCode="ngForm"
      class="needs-validation"
      [ngClass]="{'was-validated': fCode.touched}"
      novalidate>
  <h1>{{ "users.activateAccount" | translate }}</h1>
  <p>{{ "users.activationCodeSentCheckEmail" | translate }}</p>

  <div class="form-floating my-3">
    <input type="tel"
           maxlength="6"
           minlength="6"
           class="form-control"
           id="code"
           name="code"
           inputmode="number"
           pattern="[0-9]{6}"
           [(ngModel)]="activateUser.code"
           placeholder="{{ 'users.enterCode' | translate }}"
           required>
    <label for="code">{{ "users.activationCode" | translate }}</label>
  </div>
  <div class="d-grid gap-2">


    <button type="submit"
            class="btn btn-info mt-2"
            [disabled]="sendButtonDisabled || fCode.invalid">
      <span class="spinner-border spinner-border-sm"
            [ngClass]="{'d-none': !sendButtonDisabled}"
            role="status"
            aria-hidden="true"></span>
      <span [ngClass]="{'d-none': sendButtonDisabled}">{{ "general.continue" | translate }}</span>
    </button>
    <br><br>
    <button type="button"
            class="btn btn-secondary mt-2"
            [disabled]="resendButtonDisabled"
            (click)="sendActivationCode()">
      <span class="spinner-border spinner-border-sm"
            [ngClass]="{'d-none': !resendButtonDisabled}"
            role="status"
            aria-hidden="true"></span>
      <span [ngClass]="{'d-none': resendButtonDisabled}">{{ "users.resendActivationCode" | translate }}</span>
    </button>
  </div>

</form>
