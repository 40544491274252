export interface IMediaDevice {
  id: number;
  type: string;  // audioIn | audioOut | videoIn | other
  deviceId: string;
  label: string;
  videoCapabilities?: any;
}

export interface IMediaDeviceRemote {
  deviceId: string;
  name: string;  // deviceLabel
}

export interface IMediaInputDevices {
  videoInputs: IMediaDevice[];
  audioInputs: IMediaDevice[];
}

export interface IMediaOutputDevices {
  audioOutputs: IMediaDevice[];
}
