import { AfterViewInit, Directive, ElementRef, OnDestroy } from '@angular/core';
import { Tooltip } from 'bootstrap';

@Directive({
  selector: '[webTooltip]',
  standalone: true
})
export class TooltipDirective implements AfterViewInit, OnDestroy {
  private tooltip: any;

  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit(): void {
    const domElement: HTMLElement = this.elementRef.nativeElement;
    this.tooltip = new Tooltip(domElement);
  }

  ngOnDestroy(): void {
    this.tooltip.dispose();
  }
}
