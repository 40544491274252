<form *ngIf="step === 'emailForm'"
      (ngSubmit)="sendEmail()"
      #fEmail="ngForm"
      class="needs-validation"
      [ngClass]="{'was-validated': fEmail.touched}"
      novalidate>
  <h1>{{ "users.resetPassword" | translate }}</h1>

  <div class="form-floating mb-3">
    <input type="email"
           class="form-control"
           id="email"
           name="email"
           inputmode="email"
           maxlength="64"
           pattern="[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,4}"
           [(ngModel)]="forgotPasswordUser.email"
           placeholder="{{ 'users.enterEmail' | translate }}"
           required>
    <label for="email">{{ "users.emailAddress" | translate }}</label>
  </div>

  <div class="d-grid gap-2 mb-3">
    <button type="submit" class="btn btn-info mt-2" [disabled]="sendButtonDisabled || fEmail.invalid">
      <span class="spinner-border spinner-border-sm"
            [ngClass]="{'d-none': !sendButtonDisabled}"
            role="status"
            aria-hidden="true"></span>
      <span [ngClass]="{'d-none': sendButtonDisabled}">{{ "general.send" | translate }}</span>
    </button>
  </div>

  <div class="d-flex align-items-baseline change-tab">
    <p><b>{{ "users.alreadyHaveAnAccount" | translate }}</b></p>
    <button type="button"
            class="btn btn-link"
            (click)="layoutService.showUserModal('login')">{{ "users.accountAccess" | translate }}</button>
  </div>

</form>

<form *ngIf="step === 'codeForm'"
      (ngSubmit)="sendCode()"
      #fCode="ngForm"
      class="needs-validation"
      [ngClass]="{'was-validated': fCode.touched}"
      novalidate>
  <h1>{{ "users.resetPassword" | translate }}</h1>

  <p>{{ "users.codeSentCheckEmail" | translate }}</p>

  <div class="form-floating my-3">
    <input type="tel"
           maxlength="6"
           minlength="6"
           class="form-control"
           id="code"
           name="code"
           inputmode="number"
           pattern="[0-9]{6}"
           [(ngModel)]="forgotPasswordUser.code"
           placeholder="{{ 'users.enterCode' | translate }}"
           required>
    <label for="code">{{ "users.resetPasswordCode" | translate }}</label>
  </div>
  <div class="d-grid gap-2">
    <button type="submit" class="btn btn-info mt-2" [disabled]="sendButtonDisabled || fCode.invalid">
      <span class="spinner-border spinner-border-sm"
            [ngClass]="{'d-none': !sendButtonDisabled}"
            role="status"
            aria-hidden="true"></span>
      <span [ngClass]="{'d-none': sendButtonDisabled}">{{ "general.continue" | translate }}</span>
    </button>
  </div>

  <div class="d-grid gap-2 mt-2">
    <button type="button"
            class="btn btn-light mt-2"
            (click)="sendRestorationPasswordCode()"
            [disabled]="resendButtonDisabled">
      <span *ngIf="!resendButtonDisabled else disabledTpl">
        {{ "users.resendActivationCode" | translate }}
      </span>
      <ng-template #disabledTpl>
        <span *ngIf="resendingCounter === undefined || resendingCounter === 0"
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"></span>


        <span *ngIf="resendingCounter !== undefined && resendingCounter > 0">
          {{ "users.canBeResendInXSeconds" | translate: {seconds: resendingCounter} }}
        </span>
      </ng-template>
    </button>
  </div>


</form>

<form *ngIf="step === 'passForm'"
      (ngSubmit)="sendPassword()"
      #fPass="ngForm"
      class="needs-validation"
      [ngClass]="{'was-validated': fPass.touched}"
      novalidate>
  <h1>{{ "users.resetPassword" | translate }}</h1>
  <div class="form-floating mb-3">
    <input [type]="passType[0]"
           name="password"
           id="password"
           class="form-control pass-control"
           minlength="8"
           [(ngModel)]="forgotPasswordUser.password"
           (ngModelChange)="checkPassword(fPass)"
           required>
    <label for="password">{{ "users.newPassword" | translate }}</label>
    <span class="hide-show-pass" (click)="togglePasswordInputType(0)">
             <i class="bi" [ngClass]="passIcon[0]"></i>
            </span>
  </div>
  <div class="form-floating mb-3">
    <input [type]="passType[1]"
           name="confirm"
           id="confirm"
           class="form-control pass-control"
           minlength="8"
           [pattern]="forgotPasswordUser.password"
           [(ngModel)]="forgotPasswordUser.confirm"
           (ngModelChange)="checkPassword(fPass)"
           required>
    <label for="confirm">{{ "users.confirmPassword" | translate }}</label>
    <span class="hide-show-pass" (click)="togglePasswordInputType(1)">
             <i class="bi" [ngClass]="passIcon[1]"></i>
            </span>
  </div>
  <div class="d-grid gap-2">
    <button type="submit" class="btn btn-info mt-2" [disabled]="sendButtonDisabled || fPass.invalid">
      <span class="spinner-border spinner-border-sm"
            [ngClass]="{'d-none': !sendButtonDisabled}"
            role="status"
            aria-hidden="true"></span>
      <span [ngClass]="{'d-none': sendButtonDisabled}">{{ "general.confirm" | translate }}</span>
    </button>
  </div>
</form>
