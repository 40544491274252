import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { AlertStatus } from '../../enums/common.enum';
import { IAlertData } from '../../interfaces/utils/utils.interface';


@Injectable({
  providedIn: 'root'
})
export class AlertService {
  alertEvents$: Observable<IAlertData>

  private alertReset: IAlertData = {
      show: false,
      status: AlertStatus.success,
      closeButton: true,
      text: undefined,
      title: undefined,
      buttons: undefined,
      component: undefined,
      componentInputs: undefined
  }
  private _alertEvents: BehaviorSubject<IAlertData> = new BehaviorSubject<IAlertData>(this.alertReset);

  constructor() {
    this.alertEvents$ = this._alertEvents.asObservable();
  }

  showAlert(data: IAlertData): void {
    this._alertEvents.next(data);
  }

  close(): void {
    this._alertEvents.next(this.alertReset);
  }

  resetAlert(msg: string): void {
    console.log('[AlertService] resetAlert ' + msg);
    if (this._alertEvents.getValue().text === msg){
      console.log('[AlertService] resetAlert OK');
      this.close();
    }
  }

  getCurrent(): IAlertData {
    return this._alertEvents.getValue();
  }
}
