<div class="container-fluid">
  <div class="container">
    <img src="assets/web/img/azz-not-found.png" class="img-fluid" alt="URL Not found" title="URL Not found">
    <br><br>
    <button class="btn btn-secondary"
            [routerLink]="COMMON.urls.home"
            [title]="'general.goHome' | translate">
      {{ "general.goHome" | translate }}
    </button>
  </div>
</div>
