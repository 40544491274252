import { inject } from "@angular/core";
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import {
  AvProdClientType,
  AvProdInputPlayingState,
  AvProdInputTypeNumber
} from '../../const/av-producer.const';
import { IEvent, IEventShareInfoSection } from "../../interfaces/events/event.interface";
import { EventsService } from '../../services/events/events.service';
import { AvProducerService } from '../../services/av-producer/av-producer.service';
import { ShareInfoService } from "../../services/share-info/share-info.service";
import { IToastConfig } from "../../interfaces/utils/utils.interface";


export class ToolbarStatusClass {

  protected countClientViewersCurrent: number = 0;
  protected countClientViewersTotal: number = 0;
  protected countClientContributors: number = 0;
  protected countClientProducers: number = 0;
  protected _event: IEvent | undefined;
  protected _layoutMode: string = 'default'; //'default' | 'portrait'

  protected avProdSubscription: Subscription | undefined;
  protected inputStatusSubscription: Subscription | undefined;

  private shareSrv: ShareInfoService = inject(ShareInfoService);

  constructor(protected avProd: AvProducerService, protected events: EventsService, protected translate: TranslateService) { }

  protected init(): void {
    this.updateAvProdInterfaceStatus();
    this.avProdSubscription = this.avProd.onNewInterfaceStatus$.subscribe(() => this.updateAvProdInterfaceStatus());
    this.inputStatusSubscription = this.avProd.onNewInputStatus$.subscribe(() => this.updateAvProdInterfaceStatus());
  }

  protected close(): void {
    if (this.avProdSubscription !== undefined){
      this.avProdSubscription.unsubscribe();
    }
    if (this.inputStatusSubscription !== undefined){
      this.inputStatusSubscription.unsubscribe();
    }
  }

  protected updateAvProdInterfaceStatus(){
    let countProducers: number = 0;
    let countContributors: number = 0;
    let countLiveStreams: number = 0;
    console.log('[ToolbarStatusClass] updateAvProdInterfaceStatus Clients: ' + this.avProd.interfaceStatus.clients.length);
    for (let i: number = 0; i < this.avProd.interfaceStatus.clients.length; i++){
      //console.log('[ToolbarStatusClass] updateAvProdInterfaceStatus: ' + i + ' ' + JSON.stringify(this.avProd.interfaceStatus.clients[i]));
      if (this.avProd.interfaceStatus.clients[i].clientType === AvProdClientType.publisher){
        countContributors++;
      }
      else if (this.avProd.interfaceStatus.clients[i].clientType === AvProdClientType.producer){
        countProducers++;
        for (let j:number=0; j<this.avProd.interfaceStatus.clients[i].publish.length; j++){
          if (this.avProd.interfaceStatus.clients[i].publish[j].state === 2){
            countContributors++;
          }
        }
      }
    }

    for (let i:number = 0; i < this.avProd.inputs.length; i++){
      if ((this.avProd.inputs[i].info.inputTypeNumber === AvProdInputTypeNumber.videoAudioStream)&&
          (this.avProd.inputs[i].status?.playingState === AvProdInputPlayingState.playing)){
          countLiveStreams++;
      }
    }
    this.countClientProducers = countProducers;
    this.countClientContributors = countLiveStreams;
    this.countClientViewersCurrent = this.avProd.serverStatus.viewers;
    this._event = this.events.currentEvent;

    this.updateStatusTooltip();
  }

  protected updateStatusTooltip(){
    //TO BE OVERRIDDEN
  }

  protected onClickShare(section: string, event: MouseEvent): void {
    if(this._event) {
      const SHARE_DATA: IEventShareInfoSection = this.shareSrv.getSharingData(this._event, section);
      SHARE_DATA.dialogTitle = this.translate.instant('eventStatus.accessCodes');
      console.log('SHARE_DATA', SHARE_DATA)
      this.shareSrv.shareToken(SHARE_DATA);
    }
    event.stopPropagation();
  }

  protected onClickCopy(section: string, event: MouseEvent): void {
    if(this._event){
      const SHARE_DATA: IEventShareInfoSection = this.shareSrv.getSharingData(this._event, section);
      let url: string = SHARE_DATA.url;
      this.copyToClipboard(url);
    }
    event.stopPropagation();
  }
/*
  protected shareToken(title: string, body: string, url: string): void {
    //must be overridden
  }*/

  protected displayToast(config: IToastConfig): void {
    //must be overridden
  }

  protected copyToClipboard(text: string): void {
    //must be overridden
  }

  protected onClickPublish(): void {
    if (this._event?.publisherToken !== undefined) {
      this.goToSection('publish', this._event.publisherToken, true);
    }
  }

  protected onClickWatch(): void {
    if (this._event?.viewerToken !== undefined) {
      this.goToSection('watch', this._event.viewerToken, true);
    }
  }

  protected onClickProduce(): void {
    if (this._event?.producerToken !== undefined) {
      this.goToSection('produce', this._event.producerToken, true);
    }
  }

  protected goToSection (section: 'produce' | 'publish' | 'watch', token: string, newWindow: boolean): void{
    //must be overridden
  }

  protected setLayoutMode (mode: string) {
    this._layoutMode = mode;
  }

}
