import { OperatingSystem } from '@capacitor/device';

export interface IDevice {
  id?: number;
  uuid?: string;
  identifier?: string;
  pushId?: string;
  pushIdTimestamp?: number;
  pushIdError?: string;
  pushIdErrorTimestamp?: number;
  name?: string;
  platform?: 'ios' | 'android' | 'web';
  model?: string;
  manufacturer?: string;
  operatingSystem?: OperatingSystem;
  osVersion?: string;
  isVirtual?: number | boolean;
  memoryUsed?: number;
  webviewVersion?: string;
  appVersion?: string;
  appVersionCode?: number;
  type?: number;
}

export interface IDeviceInterfaceData {
  scrollHeight: number;
  clientHeight: number;
  scrollBar: boolean;
}
