
import { ElementRef } from '@angular/core';
import { AvProducerService, IAvProdVideoLayout } from '@azz-life-streamer/shared';
import { Subscription, interval } from 'rxjs';


export class TileSelectionEmbeddedClass {
  private _layoutId: number = -1;
  private _tileContainerElement: ElementRef<HTMLDivElement> | undefined;
  protected layout: IAvProdVideoLayout | undefined;
  protected imgUrl: string = '';
  protected selectCountdown: number = 0;

  protected timerSubscription: Subscription | undefined;

  constructor(
    protected avProd: AvProducerService){
    // do nothing
  }

  protected setShow(value: boolean) {
    this.update();
    if (value === true){
      this.selectCountdown = 10;
      this.timerSubscription = interval(1000).subscribe(() => {
        this.tickCountdownCheck()
      });
    }
  }

  protected setLayoutId(id: number) {
    this._layoutId = id;
    this.update();
  }

  protected emitTileSelect(id: number){

  }
  protected emitCancel(){

  }

  protected initialize(tileContainerElement: ElementRef<HTMLDivElement> | undefined) {
    if (tileContainerElement !== undefined){
      this._tileContainerElement = tileContainerElement;
    }
    this.update();
  }

  protected destroy(): void {
    if (this.timerSubscription !== undefined){
      this.timerSubscription.unsubscribe();
    }
  }

  protected tickCountdownCheck(){
    this.selectCountdown--;
    if ((this.selectCountdown <= 0)&&
        (this.timerSubscription !== undefined)){

      this.timerSubscription.unsubscribe();
      this.timerSubscription = undefined;
      this.onCancel();
    }
  }

  private update(){
    //console.log('[TileSelectionEmbeddedClass] update');
    const NEW_LAYOUT: IAvProdVideoLayout | undefined = this.avProd.layoutManager.videoLayouts.find(element => (this._layoutId === element.id));
    this.layout = NEW_LAYOUT;
    const HOST: string = this.avProd.getHostUrl();
    this.imgUrl = 'https://' + HOST + '/';

    if ((this._tileContainerElement !== undefined)&&
        (this.layout !== undefined)){
        //let count = 101;
        this._tileContainerElement.nativeElement.innerHTML = '';
        this.layout.tiles.forEach(tile => {
          let div: HTMLDivElement = <HTMLDivElement>(document.createElement('div'));
          div.classList.add('tile-item');
          div.style.position = 'absolute';
          div.style.cursor = 'pointer';
          div.style.backgroundColor = 'rgba(0,0,0,0.4)';
          div.style.borderStyle = 'solid';
          div.style.borderWidth = '2px';
          div.style.borderColor = 'cyan';
          div.style.left = tile.x + '%';
          div.style.top = tile.y + '%';
          div.style.width = tile.width + '%';
          div.style.height = tile.height + '%';
          div.style.zIndex = tile.id.toFixed();
          div.addEventListener('click', () => {
            this.onTileClick(tile.id);
          });
          //count++;
          if (this._tileContainerElement !== undefined){
            this._tileContainerElement.nativeElement.appendChild(div)
          }
        });
    }
  }

  protected onTileClick(id: number) {
    this.emitTileSelect(id);
  }

  protected onCancel() {
    this.emitCancel();
  }
}
