
import { AvProdTileSelectionState, AvProducerService, IAlertData, IAvLayoutTileSelectionProgress, IAvProdVideoLayout, IToastConfig, PublishStreamService, ToastPlacement, ToastStatus } from '@azz-life-streamer/shared';
import { Subscription } from 'rxjs';


export class LayoutSelectionClass {
  protected layoutSelected: number = 0;
  protected layoutSelectedServer: number = 0;

  protected maxFavLayouts: number = 5;
  protected imgUrl: string = '';
  protected layoutFavNames: string[] = [];
  protected activeSlots: number = 0;

  protected layoutSubscription: Subscription | undefined;
  protected avCommsSubscription: Subscription | undefined;
  protected inputStatusSubscription: Subscription | undefined;

  constructor(protected avProd: AvProducerService, protected publishService: PublishStreamService) {
    // do nothing
  }

  protected init() {
    this.updateLayoutInfo();
    this.layoutSubscription = this.avProd.onNewLayoutManagerSettings$.subscribe(() => this.updateLayoutInfo());
    this.avCommsSubscription = this.avProd.onCommsStatusChange$.subscribe(() => this.updateLayoutInfo());
    this.inputStatusSubscription = this.avProd.onNewInputStatus$.subscribe(() => this.updateActiveSlotsCount());
  }

  protected close() {
    this.layoutSubscription?.unsubscribe();
    this.avCommsSubscription?.unsubscribe();
  }

  protected updateActiveSlotsCount(){
    let SLOTS: number = 0;
    for (let item of this.publishService.streamSlots){
      if (item.active === true){
        SLOTS += 1;
      }
    }
    this.activeSlots = SLOTS;
    //console.log('LAYOUT SELECTION COMPONENT - Active Slots: ' + this.activeSlots);
  }

  protected updateLayoutInfo(){
    const NAMES: string[] = [];
    this.imgUrl = 'https://' + this.avProd.getHostUrl() + '/';
    for (let i: number=0; i<this.avProd.layoutManager.favorites.length; i++){
      const LAYOUT: IAvProdVideoLayout | undefined = this.avProd.layoutManager.videoLayouts.find(element => element.id === this.avProd.layoutManager.favorites[i]);
      if (LAYOUT?.name !== undefined){
        NAMES.push(LAYOUT?.name);
      }
      else{
        NAMES.push('');
      }
    }
    this.layoutFavNames = NAMES;
    console.log('[LayoutConfigurationClass] updateLayoutInfo ' + JSON.stringify(this.layoutFavNames));
  }

  protected setServerHost(host: string | undefined) {
    console.log('[LayoutSelectionClass] Host: ' + host);
    if (host !== undefined) {
      this.imgUrl = 'https://' + host + '/';
    }
    console.log('[LayoutSelectionClass] Host end');
  }

  protected setLayoutTileSelProgress (progress: IAvLayoutTileSelectionProgress) {
    console.log('[LayoutSelection] Progress: ' + JSON.stringify(progress));
    if ((progress.state === AvProdTileSelectionState.cancelled)||
        (progress.state === AvProdTileSelectionState.finished)) {
      this.layoutSelected = this.layoutSelectedServer;
    }
  }
  protected setLayoutCurrent (id: number) {
    this.layoutSelected = id;
    this.layoutSelectedServer = id;
    console.log('[LayoutSelectionClass] LayoutServer: ' + id);
  }

  protected onLayoutClick(id: number) {
    this.emitLayoutSelect(id);  
  }

  protected emitLayoutSelect(id: number) {
    // Must override
  }

  protected displayToast(config: IToastConfig){
    // Must override
  }
}
