import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import {
  AlertStatus,
  IAlertData,
  SignUpClass,
  UserService,
  AlertService,
  IAlertDataButton, COMMON
} from '@azz-life-streamer/shared';
import { LayoutService } from '../../../core/services/layout/layout.service';
import { MetaCheckerService } from '../../../core/services/meta-checker/meta-checker.service';


@Component({
  selector: 'web-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['../styles/users-form.scss'],
})
export class SignUpComponent extends SignUpClass {

  @ViewChild('fSignUp') fSignUp!: NgForm;
  @ViewChild('fCode') fCode!: NgForm;
  @ViewChild('gContainer') gContainer!: ElementRef;

  @Output() alert: EventEmitter<IAlertData> = new EventEmitter<IAlertData>();

  protected gSsoSubscription: Subscription | undefined;

  constructor(protected override userService: UserService,
              protected layoutService: LayoutService,
              private alertService: AlertService,
              private router: Router,
              private metaService: MetaCheckerService) {
    super(userService);
    this.metaService.removeNoIndex();
  }

  override signUp(): void {
   super.signUp(this.fSignUp);
  }

  override activateAccount(): void {
    super.activateAccount(this.fCode);
  }

  override modalClose(): void {
    this.layoutService.hideUserModal();
    const REDIRECT: string | null = localStorage.getItem(COMMON.storageKeys.redirectTo);
    if(REDIRECT) {
      localStorage.removeItem(COMMON.storageKeys.redirectTo);
      this.router.navigateByUrl(REDIRECT)
        .catch(console.error);
    } else {
      this.router.navigateByUrl(COMMON.urls.manager)
        .catch(console.error);
    }
  }

  override showErrorMessage(): void {
    const BUTTONS: IAlertDataButton[] = [];
    BUTTONS.push({
      text: 'general.close',
      color: 'primary',
      handler: () => {
        this.alertService.close();
      },
    });
    this.alertService.showAlert({
      show: true,
      status: this.error? AlertStatus.error : AlertStatus.success,
      title: (this.errorTitle)? this.errorTitle : '',
      text: (this.errorMessage)? this.errorMessage : '',
      closeButton: true,
      buttons: BUTTONS
    })
  }

  protected async showModals(event: any): Promise<void> {
    if (event.target.classList.contains('show-privacy')) {
      this.layoutService.showUserModal('privacy')
    }
    if (event.target.classList.contains('show-terms')) {
      this.layoutService.showUserModal('terms');
    }
  }

}
