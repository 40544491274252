<form *ngIf="step === 'signUpForm' || !step"
      (ngSubmit)="signUp()"
      #fSignUp="ngForm"
      class="needs-validation"
      [ngClass]="{'was-validated': fSignUp.touched}"
      novalidate>
  <h1>{{ "users.createAccount" | translate }}</h1>
  <div class="form-floating mb-3">
    <input type="text"
           class="form-control"
           id="nameSignUp"
           name="name"
           inputmode="text"
           [(ngModel)]="signUpUser.name"
           placeholder="{{ 'users.enterName' | translate }}"
           required>
    <label for="nameSignUp">{{ "users.name" | translate }}</label>
  </div>
  <div class="form-floating mb-3">
    <input type="email"
           class="form-control"
           id="emailSignUp"
           name="email"
           inputmode="email"
           maxlength="64"
           pattern="[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,4}"
           [(ngModel)]="signUpUser.email"
           placeholder="{{ 'users.enterEmail' | translate }}"
           required>
    <label for="emailSignUp">{{ "users.emailAddress" | translate }}</label>
  </div>
  <div class="form-floating mb-3">
    <input [type]="passType[0]"
           id="passSignUp"
           name="password"
           class="form-control pass-control"
           minlength="8"
           placeholder="Password"
           [(ngModel)]="signUpUser.password"
           required>
    <label for="passSignUp">{{ "users.password" | translate }}</label>
    <span class="hide-show-pass" (click)="togglePasswordInputType(0)">
              <i class="bi" [ngClass]="passIcon[0]"></i>
            </span>
  </div>

  <div class="form-check-inner">
    <input type="checkbox"
           [(ngModel)]="signUpUser.legalTerms"
           [checked]="signUpUser.legalTerms"
           value="legalTerms"
           name="legalTerms"
           id="legalTerms"
            required="true">
    <label class="form-check-label"
           for="legalTerms"
           (click)="showModals($event)"
           [innerHTML]="'legal.acceptUserPrivacy' | translate">
    </label>
  </div>

  <div class="d-grid gap-3">
    <button type="submit" class="btn btn-info" [disabled]="sendButtonDisabled || fSignUp.invalid">
      <span class="spinner-border spinner-border-sm"
            [ngClass]="{'d-none': !sendButtonDisabled}"
            role="status"
            aria-hidden="true"></span>
      <span [ngClass]="{'d-none': sendButtonDisabled}">{{ "general.signup" | translate }}</span>
    </button>

    <div class="d-flex align-items-baseline change-tab">
      <p><b>{{ "users.alreadyHaveAnAccount" | translate }}</b></p>
      <button type="button"
              class="btn btn-link"
              (click)="layoutService.showUserModal('login')">{{ "users.accountAccess" | translate }}</button>
    </div>
  </div>

</form>

<form *ngIf="step === 'codeForm'"
      (ngSubmit)="activateAccount()"
      #fCode="ngForm"
      class="needs-validation"
      [ngClass]="{'was-validated': fCode.touched}"
      novalidate>
  <h1>{{ "users.createAccount" | translate }}</h1>
  <p>{{ "users.activationCodeSentCheckEmail" | translate }}</p>

  <div class="form-floating my-3">
    <input type="tel"
           maxlength="6"
           minlength="6"
           class="form-control"
           id="code"
           name="code"
           inputmode="number"
           pattern="[0-9]{6}"
           [(ngModel)]="activateUser.code"
           placeholder="{{ 'users.enterCode' | translate }}"
           required>
    <label for="code">{{ "users.activationCode" | translate }}</label>
  </div>

  <div class="d-grid gap-2">
    <button type="submit"
            class="btn btn-info"
            [disabled]="sendButtonDisabled || fCode.invalid">
      <span class="spinner-border spinner-border-sm"
            [ngClass]="{'d-none': !sendButtonDisabled}"
            role="status"
            aria-hidden="true"></span>
      <span [ngClass]="{'d-none': sendButtonDisabled}">{{ "general.signup" | translate }}</span>
    </button>

    <br><br>

    <button type="button"
            class="btn btn-secondary mt-2"
            [disabled]="resendButtonDisabled"
            (click)="sendActivationCode()">
      <span *ngIf="!resendButtonDisabled else disabledTpl">
        {{ "users.resendActivationCode" | translate }}
      </span>
      <ng-template #disabledTpl>
        <span *ngIf="resendingCounter === undefined || resendingCounter === 0"
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"></span>


        <span *ngIf="resendingCounter !== undefined && resendingCounter > 0">
          {{ "users.canBeResendInXSeconds" | translate: {seconds: resendingCounter} }}
        </span>
      </ng-template>
    </button>
  </div>
</form>
