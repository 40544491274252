import { FormControl, FormGroup } from '@angular/forms';
import { SafeUrl } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { AvProdInputSourceType, AvProdInputTileFillMode, AvProdInputTypeNumber, AvProdSettingsType } from '../../const/av-producer.const';
import { IAvProdInput, IAvSettingsItemConfig } from '../../interfaces/av-producer/event-av-producer.interface';
import {
  AV_PROD_OPTIONS_AUDIO_MODE_CLIP, AV_PROD_OPTIONS_AUDIO_MODE_LIVE,
  AV_PROD_OPTIONS_INPUT_PLAYING_MODE, AV_PROD_OPTIONS_INPUT_PLAYING_SPEED,
  AV_PROD_OPTIONS_INPUT_SOURCE_PUSH_PROTOCOL,
  AV_PROD_OPTIONS_INPUT_SOURCE_TYPE,
  AV_PROD_OPTIONS_INPUT_TILE_FILL_MODE
} from '../../const/av-producer-options';
import { AvProducerService } from '../../services/av-producer/av-producer.service';
import { IAlertData, IToastConfig } from '../../interfaces/utils/utils.interface';
import { IAvProdInputSettings } from '../../interfaces/av-producer/input-settings.interface';
import { AlertStatus, ToastPlacement, ToastStatus } from '../../enums/common.enum';


export class InputSettingsClass {

  protected useSaveButton: boolean = false;
  protected inputType: AvProdInputTypeNumber = AvProdInputTypeNumber.none;
  protected baseUrl: string = '';
  protected input: IAvProdInput | undefined;
  protected isVisibleImage: boolean = false;
  protected isResetNeeded: boolean = false;
  protected isDirty: boolean = false;
  protected videoFrameUrl: SafeUrl = '';
  protected inputForm: FormGroup = new FormGroup([]);
  protected inputSettingsSubscription: Subscription | undefined;
  protected inputStatusSubscription: Subscription | undefined;
  protected availableDelete: boolean = false;

  protected _inputId: number = -1;

  protected itemsAll: IAvSettingsItemConfig[] = [
    {
      id: 'name',
      type: AvProdSettingsType.text,
      name: 'inputSettings.name',
      min: 0,
      max: 0,
      step: 1,
      options: [],
      placeholder: '',
      value: ''
    },
    {
      id: 'overlayName',
      type: AvProdSettingsType.switchBoolean,
      name: 'inputSettings.overlayName',
      min: 0,
      max: 0,
      step: 1,
      options: [],
      placeholder: '',
      value: false
    },
    {
      id: 'tileFillMode',
      type: AvProdSettingsType.selectComboNumber,
      name: 'inputSettings.tileFillMode',
      min: 0,
      max: 0,
      step: 1,
      options: AV_PROD_OPTIONS_INPUT_TILE_FILL_MODE,
      placeholder: '',
      value: 0,
      config: {
        mode: 'native'
      }
    },
    {
      id: 'tileFillColor',
      type: AvProdSettingsType.color,
      name: 'inputSettings.tileFillColor',
      min: 0,
      max: 0,
      step: 1,
      options: [],
      placeholder: '',
      value: 'black'
    },
    {
      id: 'liveSourceType',
      type: AvProdSettingsType.selectComboNumber,
      name: 'inputSettings.liveSourceType',
      min: 0,
      max: 0,
      step: 1,
      options: AV_PROD_OPTIONS_INPUT_SOURCE_TYPE,
      placeholder: '',
      value: 0,
      config: {
        mode: 'native'
      }
    },
    {
      id: 'liveSourcePushProtocol',
      type: AvProdSettingsType.selectComboNumber,
      name: 'inputSettings.liveSourcePushProtocol',
      min: 0,
      max: 0,
      step: 1,
      options: AV_PROD_OPTIONS_INPUT_SOURCE_PUSH_PROTOCOL,
      placeholder: '',
      value: 0,
      config: {
        mode: 'native'
      }
    },
    {
      id: 'liveSourceUrlPush',
      type: AvProdSettingsType.text,
      name: 'inputSettings.liveSourceUrlPush',
      min: 0,
      max: 0,
      step: 1,
      options: [],
      placeholder: '',
      value: ''
    },
    {
      id: 'liveSourceUrlPull',
      type: AvProdSettingsType.text,
      name: 'inputSettings.liveSourceUrlPull',
      min: 0,
      max: 500,
      step: 1,
      options: [],
      placeholder: '',
      value: ''
    },
    {
      id: 'playingMode',
      type: AvProdSettingsType.selectComboNumber,
      name: 'inputSettings.playingMode',
      min: 0,
      max: 0,
      step: 1,
      options: AV_PROD_OPTIONS_INPUT_PLAYING_MODE,
      placeholder: '',
      value: 0,
      config: {
        mode: 'native'
      }
    },
    {
      id: 'playingSpeed',
      type: AvProdSettingsType.selectComboNumber,
      name: 'inputSettings.playingSpeed',
      min: 0,
      max: 0,
      step: 1,
      options: AV_PROD_OPTIONS_INPUT_PLAYING_SPEED,
      placeholder: '',
      value: 0,
      config: {
        mode: 'native'
      }
    },
    {
      id: 'audioModeClip',
      type: AvProdSettingsType.selectComboNumber,
      name: 'inputSettings.audioModeClip',
      min: 0,
      max: 0,
      step: 1,
      options: AV_PROD_OPTIONS_AUDIO_MODE_CLIP,
      placeholder: '',
      value: 0,
      config: {
        mode: 'native'
      }
    },
    {
      id: 'audioModeLive',
      type: AvProdSettingsType.selectComboNumber,
      name: 'inputSettings.audioModeLive',
      min: 0,
      max: 0,
      step: 1,
      options: AV_PROD_OPTIONS_AUDIO_MODE_LIVE,
      placeholder: '',
      value: 0,
      config: {
        mode: 'native'
      }
    },
    {
      id: 'audioVolume',
      type: AvProdSettingsType.numberSlider,
      name: 'inputSettings.audioVolume',
      min: -24,
      max: 12,
      step: 1,
      options: [],
      placeholder: '',
      value: ''
    }
  ];

  protected itemsFiltered: IAvSettingsItemConfig[] = [];

  constructor(protected avProd: AvProducerService) {
    this.itemsAll.forEach((element: IAvSettingsItemConfig) => {
      this.inputForm.addControl(element.id, new FormControl());
    });
  }

  protected setInputId(id: number): void {
    this._inputId = id;
    this.init();
  }

  protected init(): void {
    if (this.baseUrl === '') {
      this.baseUrl = this.avProd.getHostUrl();
    }
    if (this.inputSettingsSubscription !== undefined) {
      this.inputSettingsSubscription.unsubscribe();
    }
    this.inputSettingsSubscription = this.avProd.onNewInputSettings$.subscribe((id: number) => {
      if (id === this._inputId) this.readServerSettings(true, true);
    })
    if (this.inputStatusSubscription !== undefined) {
      this.inputStatusSubscription.unsubscribe();
    }
    this.inputStatusSubscription = this.avProd.onNewInputStatus$.subscribe((id: number) => {
      if (id === this._inputId) this.readServerSettings(true, true);
    })

    this.avProd.azzRequestInputSettings(this._inputId);
    if (this._inputId !== -1) {
      this.input = this.avProd.inputs.find((element: IAvProdInput) => (element.info.id === this._inputId));
      this.readServerSettings(true, true);
    } else {
      this.input = undefined;
    }
    this.videoFrameUrl = this.getImageSrc();
  }

  protected destroy() {
    if (this.inputSettingsSubscription !== undefined) {
      this.inputSettingsSubscription.unsubscribe();
    }
    if (this.inputStatusSubscription !== undefined) {
      this.inputStatusSubscription.unsubscribe();
    }
  }

  protected emitRequestClose(): void {
    // To be overridden
  }

  protected displayToast(config: IToastConfig): void {
    // To be overridden
  }

  protected displayAlert(data: IAlertData): void {
    // To be overridden
  }

  protected getImageSrc(): SafeUrl {
    let ret: SafeUrl = '';
    if (this.input !== undefined) {
      if ((this.input.info.inputTypeNumber !== AvProdInputTypeNumber.videoAudioStream) &&
        (this.baseUrl !== undefined)) {
        ret = 'https://' + this.baseUrl + '/' + this.input.info.url;
      } else ret = 'assets/images/events/azzulei.png';
    }
    return ret;
  }

  protected readServerSettings(newSettings: boolean, newStatus: boolean): void {
    if (newSettings) {

      this.input = this.avProd.inputs.find((input: IAvProdInput) => (input.info.id === this._inputId));
      console.log('[InputSettingsClass] readServerSettings ' + this.input?.info.id);

      this.inputForm.get('name')?.setValue(this.input?.info.name);

      if ((this.input?.info.inputTypeNumber === AvProdInputTypeNumber.audioClip) ||
        (this.input?.info.inputTypeNumber === AvProdInputTypeNumber.highlight) ||
        (this.input?.info.inputTypeNumber === AvProdInputTypeNumber.videoAudioClip) ||
        (this.input?.info.inputTypeNumber === AvProdInputTypeNumber.videoAudioStream)) {

        this.inputForm.get('audioVolume')?.setValue(this.input?.settings?.audio?.volume);
      }

      if ((this.input?.info.inputTypeNumber === AvProdInputTypeNumber.imageTile) ||
        (this.input?.info.inputTypeNumber === AvProdInputTypeNumber.highlight) ||
        (this.input?.info.inputTypeNumber === AvProdInputTypeNumber.videoAudioClip) ||
        (this.input?.info.inputTypeNumber === AvProdInputTypeNumber.videoAudioStream) ||
        (this.input?.info.inputTypeNumber === AvProdInputTypeNumber.document)) {

        this.inputForm.get('tileFillMode')?.setValue(this.input?.settings?.tileFillMode);
        this.inputForm.get('tileFillColor')?.setValue(this.input?.settings?.tileFillColor);
        this.inputForm.get('overlayName')?.setValue(this.input?.settings?.overlayName);
      }

      if ((this.input?.info.inputTypeNumber === AvProdInputTypeNumber.highlight) ||
        (this.input?.info.inputTypeNumber === AvProdInputTypeNumber.videoAudioClip)) {

        this.inputForm.get('audioModeClip')?.setValue(this.input?.settings?.audio?.mode);
      }

      if ((this.input?.info.inputTypeNumber === AvProdInputTypeNumber.audioClip) ||
        (this.input?.info.inputTypeNumber === AvProdInputTypeNumber.highlight) ||
        (this.input?.info.inputTypeNumber === AvProdInputTypeNumber.videoAudioClip)) {

        this.inputForm.get('playingMode')?.setValue(this.input?.settings?.playingMode);
        this.inputForm.get('playingSpeed')?.setValue(this.input?.settings?.playingSpeed);
      }

      if (this.input?.info.inputTypeNumber === AvProdInputTypeNumber.videoAudioStream) {

        this.inputForm.get('liveSourceType')?.setValue(this.input?.settings?.liveSourceType);
        this.inputForm.get('liveSourcePushProtocol')?.setValue(this.input?.settings?.liveSourcePushProtocol);
        this.inputForm.get('liveSourceUrlPush')?.setValue(this.input?.settings?.liveSourcePushURL);
        this.inputForm.get('liveSourceUrlPush')?.disable();
        this.inputForm.get('liveSourceUrlPull')?.setValue(this.input?.settings?.liveSourcePullURL);
        this.inputForm.get('audioModeLive')?.setValue(this.input?.settings?.audio?.mode);
        this.availableDelete = this.input?.settings?.liveSourceType !== AvProdInputSourceType.native;
      }
      else {
        this.availableDelete = true;
      }

      this.updateItemsFiltered();

      this.isResetNeeded = false;
      this.isDirty = false;
      this.inputForm.markAsPristine();
      this.refreshDirtyFlags();
      this.updateVisibleControls();
    }
  }

  protected updateItemsFiltered(): void {
    const NEW_LIST: IAvSettingsItemConfig[] = [];
    Object.assign(NEW_LIST, this.itemsAll);
    this.itemsFiltered = NEW_LIST;

    this.updateItemVisibility('name', true);

    this.updateItemVisibility('audioVolume', false);
    this.updateItemVisibility('audioModeClip', false);
    this.updateItemVisibility('playingMode', false);
    this.updateItemVisibility('playingSpeed', false);
    this.updateItemVisibility('liveSourceType', false);
    this.updateItemVisibility('liveSourceUrlPush', false);
    this.updateItemVisibility('liveSourceUrlPull', false);
    this.updateItemVisibility('liveSourcePushProtocol', false);
    this.updateItemVisibility('audioModeLive', false);
    this.updateItemVisibility('tileFillMode', false);
    this.updateItemVisibility('tileFillColor', false);
    this.updateItemVisibility('overlayName', false);

    if ((this.input?.info.inputTypeNumber === AvProdInputTypeNumber.audioClip) ||
      (this.input?.info.inputTypeNumber === AvProdInputTypeNumber.highlight) ||
      (this.input?.info.inputTypeNumber === AvProdInputTypeNumber.videoAudioClip) ||
      (this.input?.info.inputTypeNumber === AvProdInputTypeNumber.videoAudioStream)) {
      this.updateItemVisibility('audioVolume', true);
    }

    if ((this.input?.info.inputTypeNumber === AvProdInputTypeNumber.imageTile) ||
      (this.input?.info.inputTypeNumber === AvProdInputTypeNumber.highlight) ||
      (this.input?.info.inputTypeNumber === AvProdInputTypeNumber.videoAudioClip) ||
      (this.input?.info.inputTypeNumber === AvProdInputTypeNumber.videoAudioStream) ||
      (this.input?.info.inputTypeNumber === AvProdInputTypeNumber.document)) {
      this.updateItemVisibility('tileFillMode', true);
      if (this.inputForm.get('tileFillMode')?.value !== AvProdInputTileFillMode.fill) {
        this.updateItemVisibility('tileFillColor', true);
      }
      this.updateItemVisibility('overlayName', true);
    }

    if ((this.input?.info.inputTypeNumber === AvProdInputTypeNumber.highlight) ||
      (this.input?.info.inputTypeNumber === AvProdInputTypeNumber.videoAudioClip)) {
      this.updateItemVisibility('audioModeClip', true);
      this.updateItemVisibility('playingSpeed', true);
    }

    if ((this.input?.info.inputTypeNumber === AvProdInputTypeNumber.audioClip) ||
      (this.input?.info.inputTypeNumber === AvProdInputTypeNumber.highlight) ||
      (this.input?.info.inputTypeNumber === AvProdInputTypeNumber.videoAudioClip)) {
      this.updateItemVisibility('playingMode', true);
    }

    if (this.input?.info.inputTypeNumber === AvProdInputTypeNumber.videoAudioStream) {
      //this.updateItemVisibility('liveSourceType', true);
      if (this.inputForm.get('liveSourceType')?.value === AvProdInputSourceType.push) {
        this.updateItemVisibility('liveSourceUrlPush', true);
        this.updateItemVisibility('liveSourcePushProtocol', true);
      }
      if (this.inputForm.get('liveSourceType')?.value === AvProdInputSourceType.pull) {
        this.updateItemVisibility('liveSourceUrlPull', true);
      }
      this.updateItemVisibility('audioModeLive', true);
    }

  }

  protected updateItemVisibility(id: string, visible: boolean): void {
    const ITEM: IAvSettingsItemConfig | undefined = this.itemsFiltered.find((element: IAvSettingsItemConfig) => (element.id === id));
    if (ITEM !== undefined) {
      ITEM.visible = visible;
    }
  }

  protected onNewServerData(id: number, newSettings: boolean, newStatus: boolean): void {
    if (id === this.input?.info.id) {
      if (newSettings) {
        this.input = this.avProd.inputs.find(input => (input.info.id === this.input?.info.id));
        console.log('[InputSettingsClass] onNewServerData New settings');
      }

      if (this.isDirty) {
        this.isResetNeeded = true;
      } else {
        this.readServerSettings(newSettings, newStatus);
      }
    }
  }

  protected onSettingChanged(event: IAvSettingsItemConfig): void {
    this.refreshDirtyFlags();
    this.updateItemsFiltered();
    if(!this.useSaveButton && this.isDirty) {
      this.onSave();
    }
  }

  protected onSettingTouched(event: IAvSettingsItemConfig): void {
    this.refreshDirtyFlags();
    this.updateItemsFiltered();
  }

  protected refreshDirtyFlags(): void {
    this.isDirty = this.inputForm.dirty;
  }

  protected onSave(): void {

    const SETTINGS: IAvProdInputSettings = {};
    if (this.inputForm.get('name')?.dirty) {
      SETTINGS.name = this.inputForm.get('name')?.value;
    }
    SETTINGS.audio = {};
    if (this.inputForm.get('audioVolume')?.dirty) {
      SETTINGS.audio.volume = this.inputForm.get('audioVolume')?.value;
    }
    if (this.inputForm.get('audioModeClip')?.dirty) {
      SETTINGS.audio.mode = this.inputForm.get('audioModeClip')?.value;
    }
    if (this.inputForm.get('audioModeLive')?.dirty) {
      SETTINGS.audio.mode = this.inputForm.get('audioModeLive')?.value;
    }

    if (this.inputForm.get('playingMode')?.dirty) {
      SETTINGS.playingMode = this.inputForm.get('playingMode')?.value;
    }
    if (this.inputForm.get('playingSpeed')?.dirty) {
      SETTINGS.playingSpeed = this.inputForm.get('playingSpeed')?.value;
    }
    if (this.inputForm.get('liveSourceType')?.dirty) {
      SETTINGS.liveSourceType = this.inputForm.get('liveSourceType')?.value;
    }
    if (this.inputForm.get('liveSourcePushProtocol')?.dirty) {
      SETTINGS.liveSourcePushProtocol = this.inputForm.get('liveSourcePushProtocol')?.value;
    }
    if (this.inputForm.get('tileFillMode')?.dirty) {
      SETTINGS.tileFillMode = this.inputForm.get('tileFillMode')?.value;
    }
    if (this.inputForm.get('tileFillColor')?.dirty) {
      SETTINGS.tileFillMode = this.inputForm.get('tileFillColor')?.value;
    }
    if (this.inputForm.get('overlayName')?.dirty) {
      SETTINGS.overlayName = this.inputForm.get('overlayName')?.value;
    }

    // Push URL is read-only
    if (this.inputForm.get('liveSourceUrlPush')?.dirty) {
      SETTINGS.liveSourcePushURL = this.inputForm.get('liveSourceUrlPush')?.value;
    }
    if (this.inputForm.get('liveSourceUrlPull')?.dirty) {
      SETTINGS.liveSourcePullURL = this.inputForm.get('liveSourceUrlPull')?.value;
    }

    //if (this.videoCropDirty === true) {
    //  settings.video = {
    //    frameCropStart: this.videoCropRange[0],
    //    frameCropEnd: this.videoCropRange[1]
    //  }
    //}

    this.inputForm.markAsPristine();

    if (this.input !== undefined) {
      this.avProd.azzChangeInputSettings(this.input?.info.id, SETTINGS);
      this.displayToast({
        options: {
          autohide: true,
          placement: ToastPlacement.bottomRight
        },
        data: {
          closeButtonHeader: true,
          status: ToastStatus.success,
          title: 'general.done',
          text: 'producer.settingsSavedSuccess',
          alignText: 'text-center',
          buttons: []
        },
      });
    }

    this.refreshDirtyFlags();
  }

  protected onDelete(): void {

    this.displayAlert({
      closeButton: true,
      show: true,
      status: AlertStatus.question,
      title: 'general.confirmation',
      text: 'inputSettings.removeExternalStreamConfirmation',
      buttons: [{
        text: 'general.cancel',
        color: 'primary',
        fill: 'outline',
        closeButton: true
      },
        {
          text: 'general.confirm',
          color: 'danger',
          closeButton: true,
          handler: (): void => {
            if ((this.input?.info.inputTypeNumber === AvProdInputTypeNumber.videoAudioStream)&&
                (this.input?.settings?.liveSourceType !== AvProdInputSourceType.native)){
              const SETTINGS: IAvProdInputSettings = {
                liveSourceType: AvProdInputSourceType.native
              }
              this.avProd.azzChangeInputSettings(this.input?.info.id, SETTINGS);
            }
            else{
              this.avProd.azzCmdInputDelete(this._inputId);
            }
            console.log('[InputSettingsClass] onDelete ' + this._inputId);
            this.emitRequestClose();
            // Display success message
            this.displayToast({
              options: {
                autohide: true,
                placement: ToastPlacement.bottomRight
              },
              data: {
                closeButtonHeader: true,
                status: ToastStatus.success,
                title: 'general.done',
                text: 'inputSettings.removeSuccess',
                alignText: 'text-center',
                buttons: []
              },
            });
          }
        }]
    });
  }

  protected onReset(): void {
    this.readServerSettings(true, true);
  }

  protected updateVisibleControls(): void {
    let ret: boolean = false;
    if (this.input !== undefined) {
      switch (this.input.info.inputTypeNumber) {
        case AvProdInputTypeNumber.highlight:
        case AvProdInputTypeNumber.imageTile:
        case AvProdInputTypeNumber.imageOverlay:
        case AvProdInputTypeNumber.document:
        case AvProdInputTypeNumber.videoAudioClip:
          ret = true;
          break;
      }
    }
    this.isVisibleImage = ret;
  }
}
