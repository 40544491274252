export const CLIENT_BATTERY_UPDATE_INTERVAL: number = 60000;

export const MAX_UPLOAD_FILE_SIZE_BYTES: any = {
  img: 5,
  audio: 10,
  doc: 10,
  video: 100
}

export const COMMON = {

  defaultLanguage: {
    iso: 'en',
    name: 'English',
    code: 'en-US'
  },

  languages: [
    {
      iso: 'en',
      name: 'English',
      code: 'en-US'
    },
    {
      iso: 'es',
      name: 'Español',
      code: 'es-ES'
    }
  ],

  storageKeys: {
    accessToken: '_accessToken',
    appleAuth: '_aauth',
    cookieConsent: '_cookieConsentStatus',
    device: '_device',
    eventTokenList: '_eventTokenList',
    googleAuth: '_gauth',
    language: '_language',
    microsoftAuth: '_mauth',
    newUser: '_newUser', // for url language redirections in web
    publishSettings: '_publishSettings',
    publishSettingsEvent: '_publishSettingsEvent',
    redirectTo: '_redirect',
    refreshToken: '_refreshToken',
    source: '_source',
    theme: '_theme',
    userOrgId: '_userOrgId',
    version: '_version',
  },

  defaultDeviceParams : {
    identifier: '',
    platform: undefined,
    operatingSystem: undefined,
    manufacturer: '',
    type: 0,
    appVersion: '',
    appVersionCode: 0
  },

  interceptors: {
    skip: [
      'play.azzulei.tv',
      'dev-play.azzulei.tv',
//      'assets',
      'upload',
      'i18n',
      'events-categories',
      'v3/events/validate-token'
    ]
  },

  urlsAbs: {
    publish: '/publish',
  },

  urlsExt: {
    facebook: 'https://www.facebook.com/azzuleit',
    instagram: 'https://instagram.com/azzuleitv',
    linkedin: 'https://linkedin.com/company/azzulei-technologies',
    twitterX: 'https://twitter.com/azzuleitv',
    youtube: 'https://www.youtube.com/@azzuleitv'
  },

  urls: {
    aboutUs: 'about-us',
    aboutUsLang: ':lang/about-us',
    account: 'account',
    blog: 'blog',
    blogLang: ':lang/blog',
    blogPost: 'blog/:post',
    blogPostLang: ':lang/blog/:post',
    contact: 'contact',
    contactLang: ':lang/contact',
    cookies: 'cookies-policy',
    cookiesLang: ':lang/cookies-policy',
    dashboard: '',
    destinations: 'destinations',
    developers: 'developers',
    embed: 'embed/:token',
    events: 'events',
    eventsManage: 'events/manage',
    features: 'features',
    featuresLang: ':lang/features',
    helpCenter: 'help-center',
    home: '', //
    homeLang: ':lang', //
    homeMobile: 'home',   //app only
    homeLogin: 'home/login',
    homeSignUp: 'home/sign-up',
    howItWorks: 'how-it-works',
    howItWorksLang: ':lang/how-it-works',
    legal: 'legal',
    legalLang: ':lang/legal',
    legalCookies: 'legal/cookies-policy',
    legalCookiesLang: ':lang/legal/cookies-policy',
    legalPrivacy: 'legal/privacy-policy',
    legalPrivacyLang: ':lang/legal/privacy-policy',
    legalTerms: 'legal/terms-and-conditions',
    legalTermsLang: ':lang/legal/terms-and-conditions',
    loading: '', // Only app
    login: 'login',
    loginLang: ':lang/login',
    manager: 'manager',
    managerAccount: '/manager/account',
    managerContact: '/manager/contact',
    managerDashboard: '/manager',
    managerDestinations: '/manager/destinations',
    managerEvents: '/manager/events',
    managerOrganizationAccount: '/manager/organization-account',
    managerPayCheckout: '/manager/pay-checkout',
    managerPayInfo: '/manager/pay-info',
    managerPayResult: '/manager/pay-result',
    managerStorage: '/manager/storage',
    notFound: 'not-found',
    organizationAccount: 'organization-account',
    payCancel: 'pay-cancel',
    payCheckout: 'pay-checkout',
    payInfo: 'pay-info',
    payResult: 'pay-result',
    paySuccess: 'pay-success',
    post: 'blog',
    prices: 'pricing',
    pricesLang: ':lang/pricing',
    privacy: 'privacy-policy',
    privacyLang: ':lang/privacy-policy',
    produce: 'studio',
    produceFull: 'studio/full',
    produceScore: 'studio/score',
    produceSingleCam: 'studio/singlecam',
    publish: 'liveinput',
    signUp: 'sign-up',
    signUpLang: ':lang/sign-up',
    storage: 'storage',
    support: 'support',
    supportLang: ':lang/support',
    supportDevelopers: 'support/developers',
    supportDevelopersLang: ':lang/support/developers',
    supportHelpCenter: 'support/help-center',
    supportHelpCenterLang: ':lang/support/help-center',
    terms: 'terms-and-conditions',
    termsLang: ':lang/terms-and-conditions',
    useCase: 'use-case/:case',
    useCaseLang: ':lang/use-case/:case',
    useCases: 'use-cases',
    useCasesLang: ':lang/use-cases',
    watch: 'watch',
    org: 'org',
  },

  resendingCodeCounter: 20, // seconds

  microsoftGraphEndPoint: 'https://graph.microsoft.com/v1.0/me'
}
