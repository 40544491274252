import { AvProdInputType } from '../../const/av-producer.const';
import { IAvProdInputSettings } from '../av-producer/input-settings.interface';

export interface IEvent {
  id?: number;
  name: string;
  userId?: number;
  userName?: string;
  description: string;
  categoryId: number;
  category?: string;
  status?: string;
  started?: number | null;
  finished?: number;
  ended?: number;
  organizationId?: number;
  private?: boolean;
  protected?: boolean;
  protectedPassword?: string;
  viewerToken?: string;
  publisherToken?: string;
  producerToken?: string;
  scheduledFinish?: number;
  host?: string;
  dynamicPoster?: string;
  staticPoster?: string;
  currentViewers?: number;
  totalViewers?: number;
  totalSize?: number;
  expirationTime?: number;
  timezone?: string;
  autoStartMode?: number;
  configMode?: string;
  configCustom?: number;
  scheduledStart?: number;
  scheduledEventStart?: number;
  maxResolution?: string;
}

export interface IEventCategories {
  id: number;
  name: string;
}

export interface IEventContentReportReason {
  id: number;
  reason: string;
}

export interface IEventPublicationTool {
  id: number;
  name: string;
}

export interface IEventRequest {
  userId: number;
  categories: number[];
  publicationTools: number[];
  monthlyFrequency: string;
  averageDuration: string;
  averageAudience: string;
  comments: string;
}

export interface IEventTokenCheckResponse {
  eventId: number;
  tokenType: string;
}

export interface IEventContentReport {
  eventId: number,
  reasonId: number,
  comments: string
}

export interface IEventListItemData {
  id: number;
  token: string;
  name: string;
  author: string;
  start?: Date;
  end?: Date;
  duration?: number;
  durationStr?: string;
  status: string;
  host: string;
  description: string;
  categoryId: number;
  viewersCurrent: number;
  viewersTotal: number;
  snapshotUrl: string;
  private: boolean;
  protected: boolean;
}

export interface IEventAsset {
  id: number;
  name: string;
  type: AvProdInputType;
  status: string;
  preload: boolean;
  size: number;
  userId: number;
  organizationId?: number;
  config: IAvProdInputSettings;
}

export interface IEventUserLastChanges {
  timestamp: number;
  account?: boolean;
  storage?: boolean;
  eventList?: boolean;
  destinations?: boolean;
  organization?: boolean;
  organizations?: boolean;
  events?: number[];
}

export interface IEventDestination {
  id: number;
  platform: string;
  name: string;
  active: boolean;
  settings: any;
  status: string;
  organizationId?: number;
  avatarUrl?: string;
  customUrl?: string;
}

export interface IEventShareInfoSection {
  title: string;
  text: string;
  url: string;
  dialogTitle?: any;
}

export interface IEventTokenSaved {
  added: number;
  name: string;
  token: string;
  tokenType: string;
}
