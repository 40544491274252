import { Subject, Subscription, debounceTime, distinctUntilChanged } from 'rxjs';
import { AV_PROD_OPTIONS_AUDIO_MODE_LIVE, AV_PROD_OPTIONS_AUDIO_MODE_MASTER } from '../../const/av-producer-options';
import { IAvProdInput, IAvSettingSelectOption, IAvSettingsAudioInput } from '../../interfaces/av-producer/event-av-producer.interface';
import { AvProducerService } from '../../services/av-producer/av-producer.service';
import { AvProdInputType } from '../../const/av-producer.const';
import { IAvProdInputSettings } from '../../interfaces/av-producer/input-settings.interface';
import { IAvProdComposerSettings } from '../../interfaces/av-producer/composer-settings.interface';
import { IToastConfig } from '../../interfaces/utils/utils.interface';
import { ToastPlacement, ToastStatus } from '../../enums/common.enum';

export class ToolboxAudioClass {
  protected audioInputsServer: IAvProdInput[] = [];
  protected audioInputs: IAvSettingsAudioInput[] = [];
  protected audioModeOptions: IAvSettingSelectOption[] = AV_PROD_OPTIONS_AUDIO_MODE_LIVE;
  protected audioModeMasterOptions: IAvSettingSelectOption[] = AV_PROD_OPTIONS_AUDIO_MODE_MASTER;
  protected audioMasterVolume: number = 0;
  protected audioMasterMode: number = 1;
  protected liveInputDefault: number = 2;
  protected isDirty: boolean = false;
  protected isResetNeeded: boolean = false;
  protected isApplyImmediate: boolean = true;

  protected volumeLimitMin: number = -24;
  protected volumeLimitMax: number = 12;

  protected changeValueInputSubject: Subject<IAvSettingsAudioInput> = new Subject<IAvSettingsAudioInput>();
  protected changeValueInputSubjectSubscription: Subscription = new Subscription();
  protected changeValueMasterSubject: Subject<number> = new Subject<number>();
  protected changeValueMasterSubjectSubscription: Subscription = new Subscription();

  protected serverInputSettingsSubscription: Subscription = new Subscription();
  protected serverInputStatusSubscription: Subscription = new Subscription();
  protected serverComposerSettingsSubscription: Subscription = new Subscription();

  constructor(protected avProd: AvProducerService) { }

  protected init(): void {

    this.changeValueInputSubjectSubscription = this.changeValueInputSubject
      .pipe(
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe(newValue => {
        this.onSettingChangedFiltered(newValue);
    });

    this.changeValueMasterSubjectSubscription = this.changeValueMasterSubject
      .pipe(
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe(newValue => {
        this.onSettingMasterChangedFiltered(newValue);
    });

    this.updateAudioSettings();
    this.serverInputSettingsSubscription = this.avProd.onNewInputSettings$.subscribe(id => this.onNewInputSettings(id));
    this.serverInputStatusSubscription = this.avProd.onNewInputStatus$.subscribe(id => this.onNewInputSettings(id));
    this.serverComposerSettingsSubscription = this.avProd.onNewComposerSettings$.subscribe(() => this.onNewComposerSettings());
  }

  protected close(): void {
  }

  protected destroy(): void {
    if (this.serverComposerSettingsSubscription !== undefined) this.serverComposerSettingsSubscription.unsubscribe();
    if (this.serverInputSettingsSubscription !== undefined) this.serverInputSettingsSubscription.unsubscribe();
    if (this.serverInputStatusSubscription !== undefined) this.serverInputStatusSubscription.unsubscribe();
    if (this.changeValueInputSubjectSubscription !== undefined) this.changeValueInputSubjectSubscription.unsubscribe();
    if (this.changeValueMasterSubjectSubscription !== undefined) this.changeValueMasterSubjectSubscription.unsubscribe();
  }

  protected displayToast(config: IToastConfig){
    // Must override
  }

  protected onNewInputSettings(id: number) {
    let auxInput: IAvProdInput | undefined = this.avProd.inputs.find(input => (input.info.id === id));
    if (auxInput?.info.inputType === AvProdInputType.videoAudioStream) {
      if (this.isDirty) {
        this.isResetNeeded = true;
      }
      else {
        this.updateAudioSettings();
      }
    }
  }

  protected onNewComposerSettings() {
    if ((this.avProd.composerSettings.audioMasterVolume !== this.audioMasterVolume)||
        (this.avProd.composerSettings.audioMasterMode !== this.audioMasterMode)){
      if (this.isDirty) {
        this.isResetNeeded = true;
      }
      else {
        this.updateAudioSettings();
      }
    }
  }

  protected updateAudioSettings() {
    this.audioInputsServer = this.avProd.inputs.filter(input => (input.info.inputType === AvProdInputType.videoAudioStream));
    this.audioInputs = [];
    this.audioInputsServer.forEach(element => {
      if ((element.settings !== undefined)&&(element.settings.audio !== undefined)) {
        let audioInput: IAvSettingsAudioInput = {
          id: element.info.id,
          name: element.info.name,
          playingState: element.status?.playingState,
          liveSourceType: element.settings.liveSourceType,
          settings: {
            mode: element.settings.audio.mode,
            volume: element.settings.audio.volume
          }
        }
        this.audioInputs.push(audioInput);
      }
    });

    if (this.avProd.composerSettings.audioMasterVolume != undefined){
      this.audioMasterVolume = this.avProd.composerSettings.audioMasterVolume;
    }
    else {
      this.audioMasterVolume = 0;
    }
    if (this.avProd.composerSettings.audioMasterMode != undefined){
      this.audioMasterMode = this.avProd.composerSettings.audioMasterMode;
    }
    else {
      this.audioMasterMode = 1;
    }
    if (this.avProd.composerSettings.liveInputAudioDefaultMode != undefined){
      this.liveInputDefault = this.avProd.composerSettings.liveInputAudioDefaultMode;
    }
    else {
      this.liveInputDefault = 2;
    }

    this.isResetNeeded = false;
    this.isDirty = false;
  }

  protected onSettingInputChanged(event: any, input: IAvSettingsAudioInput) {
    
    if (this.isApplyImmediate === true) {
      this.changeValueInputSubject.next(input);
    }
    else {
      this.isDirty = true;
    }
  }

  protected onSettingChangedFiltered(input: IAvSettingsAudioInput) {
    let newSettings: IAvProdInputSettings = {
      audio: {
        mode: input.settings.mode,
        volume: input.settings.volume
      }
    }
    this.avProd.azzChangeInputSettings(input.id, newSettings);
    console.log("Apply Value: " + input.id + " " + JSON.stringify(newSettings));
  }

  protected onSettingMasterChanged(event: any) {
    console.log("Apply Master Volume: " + JSON.stringify(event));    
    if (this.isApplyImmediate === true) {
      this.changeValueMasterSubject.next(this.audioMasterVolume);
    }
    else {
      this.isDirty = true;
    }
  }

  protected onSettingMasterChangedFiltered(volume: number) {
    let newSettings: IAvProdComposerSettings = {
      audioMasterVolume: volume,
      audioMasterMode: this.audioMasterMode
    }
    this.avProd.azzChangeComposerSettings(newSettings);
    console.log("Apply Master Volume: " + JSON.stringify(newSettings));
  }

  protected onSettingMasterModeChanged(event: any) {
    
    if (this.isApplyImmediate === true) {
      this.changeValueMasterSubject.next(this.audioMasterVolume);
      let newSettings: IAvProdComposerSettings = {
        audioMasterVolume: this.audioMasterVolume,
        audioMasterMode: this.audioMasterMode,
        liveInputAudioDefaultMode: this.liveInputDefault
      }
      this.avProd.azzChangeComposerSettings(newSettings);
      console.log("Apply Master Mode: " + JSON.stringify(newSettings));  
    }
    else {
      this.isDirty = true;
    }
  }

  /**
   * Function called when the user clicks Save button
   */
  protected onSave() {
    this.audioInputs.forEach(element => {
      let newSettings: IAvProdInputSettings = {
        audio: {
          mode: element.settings.mode,
          volume: element.settings.volume
        }
      }
      this.avProd.azzChangeInputSettings(element.id, newSettings);
    });

    let newSettings: IAvProdComposerSettings = {
      audioMasterVolume: this.audioMasterVolume,
      audioMasterMode: this.audioMasterMode,
      liveInputAudioDefaultMode: this.liveInputDefault
    }
    this.avProd.azzChangeComposerSettings(newSettings);

    this.displayToast({
      options: {
        autohide: true,
        placement: ToastPlacement.bottomRight
      },
      data: {
        closeButtonHeader: true,
        status: ToastStatus.success,
        title: 'general.done',
        text: 'producer.settingsSavedSuccess',
        alignText: 'text-center',
        buttons: []
      },
    });
    
    this.isDirty = false;
    this.isResetNeeded = false;
  }

  protected onReset() {
    this.updateAudioSettings();
  }

  protected onSliderMasterDecrease(){
    if (this.audioMasterVolume > this.volumeLimitMin){
      this.audioMasterVolume--;
      this.onSettingMasterChanged(this.audioMasterVolume);
    }
  }
  protected onSliderMasterIncrease(){    
    if (this.audioMasterVolume < this.volumeLimitMax){
      this.audioMasterVolume++;
      this.onSettingMasterChanged(this.audioMasterVolume);
    }
  }
  protected onMasterModeToggle(){
    this.audioMasterMode = (this.audioMasterMode + 1) % 2;
    this.onSettingMasterModeChanged(null);
  }

  protected onSliderVolumeDecrease(input: IAvSettingsAudioInput){
    if (input.settings.volume !== undefined){
      if (input.settings.volume > this.volumeLimitMin){
        input.settings.volume--;
        this.onSettingChangedFiltered(input);
      }
    }
  }
  protected onSliderVolumeIncrease(input: IAvSettingsAudioInput){    
    if (input.settings.volume !== undefined){
      if (input.settings.volume < this.volumeLimitMax){
        input.settings.volume++;
        this.onSettingChangedFiltered(input);
      }
    }
  }
  protected onInputModeToggle(input: IAvSettingsAudioInput){
    if (input.settings.mode !== undefined){
      input.settings.mode = (input.settings.mode + 1) % 3;
      if (this.isApplyImmediate === true) {
        let newSettings: IAvProdInputSettings = {
          audio: {
            mode: input.settings.mode,
          }
        }
        this.avProd.azzChangeInputSettings(input.id, newSettings);
      }
      else {
        this.isDirty = true;
      }
    }
  }

  protected onInputModeDefaultToggle(){
    this.liveInputDefault = (this.liveInputDefault + 1) % 3;
    this.onSettingMasterModeChanged(null);
  }

  protected getAudioModeString(value: number | undefined) : string{
    let mode = '';
    switch(value){
      case (0):{
        mode = 'avOptions.off';
        break;
      }
      case (1):{
        mode = 'avOptions.on';
        break;
      }
      case (2):{
        mode = 'avOptions.onWhenSelected';
        break;
      }
      default: {}
    }
    return mode;
  }
}
