<ng-container *ngIf="showCookieConsent"></ng-container>
<div class="background" [ngClass]="{'open': showCookieConsent}">
  <div class="popup" [ngClass]="{'open': showCookieConsent}">
    <div class="top-wrapper">
        <p>{{ 'cookies.message' | translate }}</p>
        <br>
        <p (click)="goToCookies()" [innerHTML]="'cookies.moreInfo' | translate"></p>
      <br>

      <div class="controls" [ngClass]="{ 'open': showCustomizeCookies }">
        <div *ngFor="let category of categories" class="form-check">
          <input class="form-check-input"
                 type="checkbox"
                 [value]="category.key"
                 [id]="category.key"
                 [checked]="cookieChecked(category.key)"
                 (click)="changeCookiesPreferences(category.key)"
                 [disabled]="category.key === 'mandatory'">
          <!-- <p-checkbox
             name="group2"
             value="category"
             [value]="category"
             [(ngModel)]="selectedCategories"
             [inputId]="category.key"
             [disabled]="category.key === 'mandatory'"></p-checkbox>-->
          <label class="form-check-label" [for]="category.key">{{ category.name | translate }}</label>
        </div>
      </div>

      <div class="d-flex buttons justify-content-start gap-2 align-items-center flex-column flex-sm-row">
        <button
        type="button"
        class="btn btn-primary btn-cookie"
        (click)="acceptAll()">{{ "cookies.acceptAll" | translate }}
      </button>
      <button
        type="button"
        class="btn btn-cookie"
        [ngClass]="showCustomizeCookies? 'btn-primary':'btn-outline-primary'"
        (click)="customize()">{{ customizeText | translate }}
      </button>
      <button
        type="button"
        class="btn btn-outline-danger"
        (click)="deleteAll()">{{ "cookies.refuse" | translate }}
      </button>
      </div>

    </div>
  </div>
</div>
