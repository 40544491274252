import { AzzBillingMode, AzzCustomerType, AzzSubscriptionLevel } from '../../const/subscription-plans.const';

export interface IAzzSubscriptionPlan {
  customerType: AzzCustomerType;
  billingMode: AzzBillingMode;
  level: AzzSubscriptionLevel;
  name: string;
  price: number;
  key: string;
}

export interface IAzzSubscriptionPlanDisplay {
  price: {
    EUR: number,
    USD: number,
    GBP: number
  };
  key: string;
  level: AzzSubscriptionLevel;
  nameCustomerType: string;
  nameLevel: string;
  nameBillingMode: string;
}
