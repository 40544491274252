import { AvProdOverlayItemType, AvProdOverlaySettingsSizeMode } from '../../const/av-producer.const';

export interface IAvProdOverlaySettingsPosition {
    x?: number;
    y?: number;
}

export interface IAvProdOverlaySettingsGeneral {
    enabled?: boolean;
    itemType?: AvProdOverlayItemType;
    name?: string;
    layer?: number;
    opacity?: number;
    position?: IAvProdOverlaySettingsPosition;
}

export interface IAvProdOverlaySettingsBrowser {
    webUrl?: string;
    width?: number;
    height?: number;
    providerType?: number;
}

export interface IAvProdOverlayItemSettingsImage {
    inputId?: number;
    width?: number;
    height?: number;
    scale?: number;
    sizeMode?: AvProdOverlaySettingsSizeMode;
}

export interface IAvProdOverlayItemSettingsLabel {
    text?: string;
    fontSize?: number;
    fontFace?: string;
    colorText?: string;
    colorBack?: string;
    opacityBack?: number;
    alignHorizontal?: number;
    alignVertical?: number;
    width?: number;
    height?: number;
}

export interface IAvProdOverlayItemSettings {
    general?: IAvProdOverlaySettingsGeneral;
    imageSettings?: IAvProdOverlayItemSettingsImage;
    labelSettings?: IAvProdOverlayItemSettingsLabel;
    groupSettings?: IAvProdOverlaySettingsGroup;
}

export interface IAvProdOverlaySettingsGroup {
    images?: IAvProdOverlayItemSettings[];
    labels?: IAvProdOverlayItemSettings[];
    groups?: IAvProdOverlayItemSettings[];
}

export interface IAvProdOverlaySettings {
    id?: number;
    rootType?: number;
    favorite?: boolean;
    general?: IAvProdOverlaySettingsGeneral;
    browserSettings?: IAvProdOverlaySettingsBrowser;
    groupSettings?: IAvProdOverlaySettingsGroup;
}
