import { IPublisherSettings } from '../../interfaces/publish-stream/settings.interface';
import { PublishStreamService } from '../../services/publish-stream/publish-stream.service';
import { DisplayOptions } from '../../enums/publish-stream.enum';
import { Subscription } from 'rxjs';


export class BroadcasterButtonsSettingsClass {

  protected _settings: IPublisherSettings | undefined;
  protected _slotId: string | undefined;
  protected _displayOption: string = DisplayOptions.outputInput;

  protected publishStreamSubscription: Subscription | undefined;

  constructor(protected publishService: PublishStreamService) {
  }

  protected init(){
    this.publishStreamSubscription?.unsubscribe();
    this.publishStreamSubscription = this.publishService.slotChangeSource.subscribe((slotId: string)=> {
      if ((this._slotId !== undefined)&&(slotId === this._slotId)){
        this.setSlotId(this._slotId);
      }
    });
  }

  protected destroy(){
    this.publishStreamSubscription?.unsubscribe();
  }

  protected setSlotId(slotId: string | undefined): void{
    this._slotId = slotId;
    if (this._slotId !== undefined) {
      const SETTINGS: IPublisherSettings | null = this.publishService.getStreamSlotSettings(this._slotId);
      if (SETTINGS !== null) {
        this._settings = SETTINGS;
      }
    }
  }

  protected getSlotId(): string {
    return this._slotId ?? '';
  }

  protected setSettings(settings: IPublisherSettings): void{
    console.log('[BroadcasterButtonsSettingsClass] setSettings');
    this._settings = settings;
  }

  protected setDisplayOption(value: string): void {
    this._displayOption = value;
  }

  protected getDisplayOption(): string {
    return this._displayOption;
  }

  protected onOpenConfig(){
    // must override
  }

  protected onSettingChanged(setting: string): void {
    let update: boolean = false;
    switch (setting) {
      case 'video':
        if (this._settings) {
          this._settings.videoEnabled = !this._settings.videoEnabled;
          update = true;
        }
        break;
      case 'audio':
        if (this._settings) {
          this._settings.audioEnabled = !this._settings.audioEnabled;
          update = true;
        }
        break;
    }

    if(update && this._slotId !== undefined) {
      if (this._settings) {
        this.publishService.changeStreamSlotSettings(this._slotId, this._settings);
      }
    }
  }

}
