import { IOrganizationInfo, IUser, IUserPlanConfig, IUserSubscriptionConfig } from "../../interfaces/user/user.interface";
import { UserService } from "../../services/user/user.service";

export class UserPlanClass {

    protected _user: IUser | undefined;
    protected _organization: IOrganizationInfo | undefined;
    protected _productKey: string | undefined;
    protected _subscriptionMessage: string | undefined;
    protected _isOrganizationPlan: boolean | undefined;

    protected featuresList: any = [];

    constructor(protected userService: UserService){
    }

    protected initializeUser(){
      if(this._isOrganizationPlan === false){
        if (this.userService.user !== null){
          this._user = this.userService.user;
        }
        this._productKey = this._user?.productKey;
        console.log('[USER PLAN CLASS] Initialize User: Individual');
      }
      else if(this._isOrganizationPlan === true){
        if (this.userService.organization !== null){
          this._organization = this.userService.organization;
        }
        this._productKey = this._organization?.productKey;
        console.log('[USER PLAN CLASS] Initialize User: Organization');
      }

    }

    protected initializeFeatures(){
          if(this._isOrganizationPlan === false){
            if (this._user?.plan !== undefined){
              const PLAN: IUserPlanConfig = this._user.plan;
              this.featuresList = [
                  {
                    key: (PLAN.maxStreamingTime !== undefined && PLAN.maxStreamingTime > 30 ? 'unlimitedTransmission' : 'limitedTransmission'),
                    params: { hours: PLAN.maxStreamingTime },
                  },
                  {
                    key: 'maxResolution',
                    params: { resolution: PLAN.maxResolution },
                  },
                  {
                    key: (PLAN.watermark === true? 'watermarkLogo' : 'noLogo'),
                  },
                  {
                    key: 'storageTime',
                    params: { days: (PLAN.dvrTime !== undefined? (PLAN.dvrTime / 24) : PLAN.dvrTime) },
                  },
                  {
                    key: 'numberOfInputs',
                    params: { inputs: PLAN.maxLiveInputs },
                  },
                  {
                    key: ((PLAN.extraAudioInputs !== undefined && PLAN.extraAudioInputs >= 1)? 'additionalAudioInputs' : 'noAdditionalAudioInputs'),
                    params: { inputs: PLAN.extraAudioInputs },
                    check: ((PLAN.extraAudioInputs !== undefined && PLAN.extraAudioInputs >= 1)? true : false),
                  },
                  {
                    key: 'numberOfControlPosition',
                    params: { number: PLAN.maxProducers },
                  },
                  {
                    key: 'numberOfDestinations',
                    params: { destinations: PLAN.maxDestinations },
                  },
                  {
                    key: (PLAN.customRtmp === 1? 'RTMPCustomDestination' : 'noRTMPCustomDestination'),
                    check: (PLAN.customRtmp === 1? true : false),
                  },
                  {
                    key: (PLAN.customSrt === 1? 'SRTDestination' : 'noSRTDestination'),
                    check: (PLAN.customSrt === 1? true : false),
                  },
                  {
                    key: 'numberOfPlatformViewers',
                    params: { viewers: PLAN.maxViewers },
                  },
                  {
                    key: 'maxStorage',
                    params: { storage: (PLAN.storage !== undefined? this.formatSizeMB(PLAN.storage) : PLAN.storage) },
                  },
                  {
                    key: (PLAN.maxHighlights !== undefined && PLAN.maxHighlights > 20 ? 'unlimitedHighlights' : 'numberOfHighlights'),
                    params: { highlights: PLAN.maxHighlights },
                  },
                  {
                    key: (PLAN.customInputs === 1? 'customFormatInput' : 'noCustomFormatInput'),
                    check: (PLAN.customInputs === 1? true : false),
                  }
              ]
              console.log('[USER PLAN CLASS] Initialize Features: Individual');
            }
          }
          else if(this._isOrganizationPlan === true){
            //Organizations
            if(this._organization?.plan !== undefined){
              const PLAN: IUserPlanConfig = this._organization.plan;
              this.featuresList = [
                  {
                    key: 'simultaneousEvents',
                    params: { events: PLAN.concurrentEvents },
                  },
                  {
                    key: 'organizationMicroSite',
                  },
                  {
                    key: (PLAN.maxStreamingTime !== undefined && PLAN.maxStreamingTime > 30 ? 'unlimitedTransmission' : 'limitedTransmission'),
                    params: { hours: PLAN.maxStreamingTime },
                  },
                  {
                    key: 'maxResolution',
                    params: { resolution: PLAN.maxResolution },
                  },
                  {
                    key: (PLAN.watermark === true? 'watermarkLogo' : 'noLogo'),
                  },
                  {
                    key: 'storageTime',
                    params: { days: (PLAN.dvrTime !== undefined? (PLAN.dvrTime / 24) : PLAN.dvrTime) },
                  },
                  {
                    key: 'numberOfInputs',
                    params: { inputs: PLAN.maxLiveInputs },
                  },
                  {
                    key: ((PLAN.extraAudioInputs !== undefined && PLAN.extraAudioInputs >= 1)? 'additionalAudioInputs' : 'noAdditionalAudioInputs'),
                    params: { inputs: PLAN.extraAudioInputs },
                    check: ((PLAN.extraAudioInputs !== undefined && PLAN.extraAudioInputs >= 1)? true : false),
                  },
                  {
                    key: 'numberOfControlPosition',
                    params: { number: PLAN.maxProducers },
                  },
                  {
                    key: 'numberOfDestinations',
                    params: { destinations: PLAN.maxDestinations },
                  },
                  {
                    key: (PLAN.customRtmp === 1? 'RTMPCustomDestination' : 'noRTMPCustomDestination'),
                    check: (PLAN.customRtmp === 1? true : false),
                  },
                  {
                    key: (PLAN.customSrt === 1? 'SRTDestination' : 'noSRTDestination'),
                    check: (PLAN.customSrt === 1? true : false),
                  },
                  {
                    key: 'numberOfPlatformViewers',
                    params: { viewers: PLAN.maxViewers },
                  },
                  {
                    key: 'maxStorage',
                    params: { storage: (PLAN.storage !== undefined? this.formatSizeMB(PLAN.storage) : PLAN.storage) },
                  },
                  {
                    key: (PLAN.maxHighlights !== undefined && PLAN.maxHighlights > 20 ? 'unlimitedHighlights' : 'numberOfHighlights'),
                    params: { highlights: PLAN.maxHighlights },
                  },
                  {
                    key: (PLAN.customInputs === 1? 'customFormatInput' : 'noCustomFormatInput'),
                    check: (PLAN.customInputs === 1? true : false),
                  }
              ]
              console.log('[USER PLAN CLASS] Initialize Features: Organization');

            }         
          }
    }

    protected formatSizeMB(size: number): string {
      let scaledSize: number = size;
      let units: string[] = ['B','KB','MB','GB'];
      let iterations: number = 2; // Input is MBytes
  
      if (scaledSize < 0){
        scaledSize = 0;
      }
  
      while ((scaledSize > 1024)&&(iterations < units.length-1))
      {
        scaledSize = scaledSize / 1024;
        iterations++;
      }
  
      return (Number(scaledSize.toFixed(1)).toString() + units[iterations]);
    }
}