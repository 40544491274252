import { inject } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, tap } from 'rxjs';
import { FileUploader } from 'ng2-file-upload';

import { ENV_COMMON } from '@azz-life-streamer/environments';
import {
  AlertStatus,
  ToastPlacement,
  ToastStatus,
} from '../../enums/common.enum';
import { IToastConfig } from '../../interfaces/utils/utils.interface';
import {
  IOrganizationInfo,
  IUserOrganizationMember,
  IUserSubscriptionConfig,
} from '../../interfaces/user/user.interface';
import { IAvSettingsItemConfig } from '../../interfaces/av-producer/event-av-producer.interface';
import { IEventUserLastChanges } from '../../interfaces/events/event.interface';
import { AvProdInputTypeNumber, AvProdSettingsType } from '../../const/av-producer.const';
import { EventsService } from '../../services/events/events.service';
import { UserService } from '../../services/user/user.service';
import { AlertService } from '../../services/alert/alert.service';
import { ModalDialogId } from '../../enums/utils.enum';
import { ModalManagerService } from '../../services/modal/modal-manager.service';

export class ManagerOrganizationAccountClass {
  protected isBusy: boolean = false;
  protected isProduction: boolean = false;
  protected productKey: string | undefined;
  protected productCustomer: string = '';
  protected productLevel: string = '';
  protected productBilling: string = '';
  protected isProductPlanAvailable: boolean = false;
  protected subscriptionMessage: string | undefined;
  protected datePipe: DatePipe = new DatePipe('en-US');

  private eventsService: EventsService = inject(EventsService);
  private alertService: AlertService = inject(AlertService);
  private modalService: ModalManagerService = inject(ModalManagerService);

  protected fileAccept: string = '';
  protected uploader: FileUploader = new FileUploader({
    url: '',
  });

  protected userChangesSubscription: Subscription | undefined;

  protected orgSettingsForm: FormGroup = new FormGroup([]);
  protected itemsOrgSettings: IAvSettingsItemConfig[] = [
    {
      id: 'name',
      type: AvProdSettingsType.text,
      name: 'organizations.organizationSettings.name',
      min: 0,
      max: 0,
      step: 1,
      options: [],
      placeholder: '',
      value: '',
    },
    {
      id: 'alias',
      type: AvProdSettingsType.text,
      name: 'organizations.organizationSettings.alias',
      min: 0,
      max: 0,
      step: 1,
      options: [],
      placeholder: '',
      value: '',
    },
    {
      id: 'description',
      type: AvProdSettingsType.textArea,
      name: 'organizations.organizationSettings.description',
      min: 0,
      max: 0,
      step: 1,
      options: [],
      placeholder: '',
      value: '',
    },
  ];

  /*protected memberEmailForm: FormGroup = new FormGroup([]);
  protected itemMemberEmail: IAvSettingsItemConfig = {
    id: 'memberEmail',
    type: AvProdSettingsType.text,
    name: 'organizations.email',
    min: 0,
    max: 0,
    step: 1,
    options: [],
    placeholder: 'organizations.addMemberPlaceholder',
    value: ''
  };*/

  protected _addMemberEmail: string | undefined;

  constructor(protected userService: UserService,
              protected translate: TranslateService,
              protected events: EventsService) {
    if (ENV_COMMON.production) {
      this.isProduction = true;
    }
    this.itemsOrgSettings.forEach((element: IAvSettingsItemConfig) => {
      this.orgSettingsForm.addControl(element.id, new FormControl());
    });
    //this.memberEmailForm.addControl(this.itemMemberEmail.id, new FormControl());
  }

  protected displayToast(config: IToastConfig): void {
    // Must override
  }

  protected navigateToLogin(): void {
    // Must override
  }

  protected navigateToExitPage(): void {
    // Must override
  }

  protected init(): void {
    this.userChangesSubscription?.unsubscribe();
    this.userChangesSubscription = this.events.userChanges$.subscribe((data: IEventUserLastChanges) =>
      this.checkUserChanges(data)
    );
    this.events.addPollingClient('ManagerOrganizationAccountClass');

    this.readOrganizationData();
  }

  protected close(): void {
    this.userChangesSubscription?.unsubscribe();
    this.events.removePollingClient('ManagerOrganizationAccountClass');
  }

  protected readOrganizationData(): void {
    this.userService.getOrganizationData().subscribe((response: boolean) => {
      if (response) {
        this.updateCurrentPlan();
        this.updateSubscriptionMessage();
        const ORG: IOrganizationInfo | undefined = this.userService.organization;
        this.orgSettingsForm.get('name')?.setValue(ORG.name ?? '');
        this.orgSettingsForm.get('alias')?.setValue(ORG.alias ?? '');
        this.orgSettingsForm.get('description')?.setValue(ORG.description ?? '');
      } else {
        // Organization is not available, exitSection
        this.navigateToExitPage();
      }
    });
  }

  protected checkUserChanges(changes: IEventUserLastChanges): void {
    if (changes.organization === true || changes.organizations === true) {
      this.readOrganizationData();
    }
  }

  protected saveOrganizationData(): void {
    const ORG: IOrganizationInfo | undefined = this.userService.organization;
    if (ORG.id !== undefined) {
      const NEW_ORG: IOrganizationInfo = {
        id: ORG.id,
        name: this.orgSettingsForm.get('name')?.value ?? ORG.name,
        alias: this.orgSettingsForm.get('alias')?.value ?? ORG.alias,
        description:
          this.orgSettingsForm.get('description')?.value ?? ORG.description,
      };
      this.userService.updateOrganization(NEW_ORG)
        .pipe(
          tap((org: IOrganizationInfo) => {
            if(org !== null) {
              this.displayToast({
                options: {
                  autohide: true,
                  placement: ToastPlacement.bottomRight,
                },
                data: {
                  closeButtonHeader: true,
                  status: ToastStatus.success,
                  title: 'general.done',
                  text: 'users.successUpdateOrganization',
                  alignText: 'text-center',
                  buttons: [],
                },
              });
            }
          })
        )
        .subscribe((org: IOrganizationInfo) => {
        if (org !== null) {
          // Success
          this.init();
        }
      });
    }
  }

  protected deleteOrganization(): void {
    // Delete organization
    this.alertService.showAlert({
      closeButton: true,
      show: true,
      status: AlertStatus.question,
      title: 'general.confirmation',
      text: 'users.confirmDeleteOrganization',
      buttons: [
        {
          text: 'general.cancel',
          color: 'primary',
          fill: 'outline',
          closeButton: true,
        },
        {
          text: 'general.confirm',
          color: 'danger',
          closeButton: true,
          handler: (): void => {
            this.userService.deleteOrganization(this.userService.orgId)
              .subscribe((response: boolean) => {
                if (response) {
                  // Success
                  // Display success message
                  this.displayToast({
                    options: {
                      autohide: true,
                      placement: ToastPlacement.bottomRight,
                    },
                    data: {
                      closeButtonHeader: true,
                      status: ToastStatus.success,
                      title: 'general.done',
                      text: 'users.successDeleteOrganization',
                      alignText: 'text-center',
                      buttons: [],
                    },
                  });
                } else {
                  // Error
                }
              });
          },
        },
      ],
    });
  }

  protected gotoSelectSubscription(): void {
    // Must override
  }

  protected gotoStripeCustomerPortal(): void {
    this.isBusy = true;
    try {
      this.eventsService
        .getStripeCustomerPortal(this.userService.orgId)
        .subscribe((answer) => {
          this.isBusy = false;
          console.log(
            '[PayCheckout] Return CheckSession: ' + JSON.stringify(answer)
          );
          let err: boolean = true;
          if (answer !== null && answer !== undefined) {
            if (answer.status_code === 200 && answer.data.url !== undefined) {
              err = false;
              window.open(answer.data.url, '_self');
            }
          }
          if (err) {
            this.showStripeError();
          }
        });
    } catch (error) {
      this.isBusy = false;
      this.showStripeError();
    }
  }

  /**
   * Display Stripe error warning to user
   *
   * @private
   */
  protected showStripeError(): void {
    this.alertService.showAlert({
      show: true,
      status: AlertStatus.error,
      text: 'users.stripeError',
      closeButton: true,
      buttons: [
        {
          text: 'general.close',
          color: 'primary',
          closeButton: true,
        },
      ],
    });
  }

  private updateCurrentPlan(): void {
    let org: IOrganizationInfo | undefined = this.userService.organization;
    this.productKey = org.productKey;
    console.log(
      '[ManagerOrganizationAccount] updateCurrentPlan ' + JSON.stringify(org)
    );

    if (this.productKey !== undefined) {
      this.isProductPlanAvailable = true;
      if (this.productKey[0] === 'i') {
        this.productCustomer = '';
      } else if (this.productKey[0] === 'o') {
        this.productCustomer = 'manager.customerTypeNameOrganization';
      }

      if (this.productKey[2] === 'm') {
        this.productBilling = 'manager.billingModeNameMonthly';
      } else if (this.productKey[2] === 'y') {
        this.productBilling = 'manager.billingModeNameYearly';
      }

      if (this.productKey[1] === 'b') {
        this.productLevel = 'manager.subscriptionLevelNameBronze';
      } else if (this.productKey[1] === 's') {
        this.productLevel = 'manager.subscriptionLevelNameSilver';
      } else if (this.productKey[1] === 'g') {
        this.productLevel = 'manager.subscriptionLevelNameGold';
      } else {
        this.isProductPlanAvailable = false;
        this.productLevel = 'manager.subscriptionLevelNameFree';
        this.productBilling = '';
      }
    } else {
      this.productLevel = 'manager.subscriptionLevelNameFree';
      this.productBilling = '';
      this.productCustomer = 'manager.customerTypeNameIndividual';
      this.isProductPlanAvailable = false;
    }
  }

  private updateSubscriptionMessage(): void {
    if (this.userService.organization.subscription !== undefined) {
      const SUB: IUserSubscriptionConfig = this.userService.organization?.subscription;
      console.log(SUB);

      if (SUB.trialEnd !== undefined && SUB.trialEnd !== null) {
        this.subscriptionMessage = this.translate.instant('manager.account.subscriptionMessage.freeTrialEnds') +
          this.datePipe.transform(SUB.trialEnd * 1000, 'dd/MM/yyyy');
      } else if (SUB.cancelAt !== undefined && SUB.cancelAt !== null) {
        this.subscriptionMessage = this.translate.instant('manager.account.subscriptionMessage.cancelAt') +
          this.datePipe.transform(SUB.cancelAt * 1000, 'dd/MM/yyyy');
      } else if (SUB.currentPeriodEnd !== undefined && SUB.currentPeriodEnd !== null) {
        this.subscriptionMessage =
          this.translate.instant('manager.account.subscriptionMessage.nextPayment') +
          this.datePipe.transform(SUB.currentPeriodEnd * 1000, 'dd/MM/yyyy');
      }
    }
  }

  protected onClickAddMember(): void {
    if (this._addMemberEmail !== undefined && this._addMemberEmail !== '') {
      this.inviteMember(this._addMemberEmail);
      this._addMemberEmail = '';
    }
  }

  protected inviteMember(email: string): void {
    this.userService
      .inviteOrganizationMember(this.userService.orgId, email)
      .subscribe((response: boolean) => {
        if (response !== null) {
          console.log(
            '[Organization Account Class] - Invite Member: invitation sent ' +
              JSON.stringify(response)
          );
          this.displayToast({
            options: {
              placement: ToastPlacement.topCenter,
              autohide: true,
            },
            data: {
              status: ToastStatus.success,
              text: 'organizations.toastInviteSent',
              closeButtonBody: true,
              alignText: 'text-center',
            },
          });
          this.userService.getOrganizationData().subscribe((response: boolean) => {
            if (response !== null) {
              //
            }
          });
        }
      });
  }

  protected onClickDelete(member: IUserOrganizationMember): void {
    this.alertService.showAlert({
      closeButton: true,
      show: true,
      status: AlertStatus.question,
      title: 'general.confirmation',
      text: 'users.confirmDeleteOrganizationMember',
      buttons: [
        {
          text: 'general.cancel',
          color: 'primary',
          fill: 'outline',
          closeButton: true,
        },
        {
          text: 'general.confirm',
          color: 'danger',
          closeButton: true,
          handler: (): void => {
            this.onConfirmDelete(member);
          },
        },
      ],
    });
  }

  protected onConfirmDelete(member: IUserOrganizationMember): void {
    this.userService.removeOrganizationMember(this.userService.orgId,
      member.email ?? undefined,
      member.userId ?? undefined
      )
      .subscribe((response: boolean) => {
        if (response !== null) {
          console.log('[Organization Account Component] onClickDelete: ' +  JSON.stringify(response));
          this.displayToast({
            options: {
              placement: ToastPlacement.topCenter,
              autohide: true,
            },
            data: {
              status: ToastStatus.success,
              text: 'organizations.toastDeleteMember',
              closeButtonBody: true,
              alignText: 'text-center',
            },
          });
          this.userService.getOrganizationData().subscribe((response: boolean) => {
            if (response !== null) {
              //
            }
          });
        }
      });
  }

  protected onClickPlanFeatures(): void {
    this.modalService.show({
        id: ModalDialogId.userPlan,
        subId: 1,
        title: 'manager.account.userPlanFeatures',
      },
      false
    );
  }

  protected uploadProfilePicture(){
    console.log('[ManagerOrganizationAccountClass] uploadProfilePicture');
    this.modalService.show({
        id: ModalDialogId.managerResourceProfileUpload,
        subId: AvProdInputTypeNumber.profilePicture,
        title: 'inputAdd.resourceUploadProfilePicture',
      },true);
  }

  protected uploadProfileBanner(){
    console.log('[ManagerOrganizationAccountClass] uploadProfileBanner');
    this.modalService.show({
        id: ModalDialogId.managerResourceProfileUpload,
        subId: AvProdInputTypeNumber.profileBanner,
        title: 'inputAdd.resourceUploadProfileBanner',
      },true);
  }
}
