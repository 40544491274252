import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { IToastConfig, ToastPlacement } from '@azz-life-streamer/shared';


@Injectable({
  providedIn: 'root'
})
export class ToastService {

  toastEvents$: Observable<IToastConfig | null>
  private _toastEvents: Subject<IToastConfig | null> = new Subject<IToastConfig| null>();

  constructor() {
    this.toastEvents$ = this._toastEvents.asObservable();
  }

  showToast(config: IToastConfig): void {
    // Placement by default bottomRight
    if(!config.options.placement) {
      config.options.placement = ToastPlacement.bottomRight;
    }
    this._toastEvents.next(config);
  }

  hideToast(): void {
    this._toastEvents.next(null);
  }
}
