<div #toast
     [class]="'toast ' + backgroundColor"
     role="alert"
     aria-live="assertive"
     aria-atomic="true">

  <div class="toast-header" *ngIf="data.title || data.time || data.closeButtonHeader">
    <strong class="me-auto" *ngIf="data.title">{{ data.title | translate }}</strong>
    <small *ngIf="data.time">{{ data.time | translate }}</small>
    <button *ngIf="data.closeButtonHeader" type="button" class="btn-close" data-bs-dismiss="toast"
            aria-label="Close"></button>
  </div>

  <div [ngClass]="{'d-flex': data.closeButtonBody}">
    <div class="toast-body">
      <div [class]="data.alignText" *ngIf="data.text">{{ data.text | translate }}</div>
      <div class="d-flex justify-content-evenly mt-3" *ngIf="data.buttons && data.buttons.length > 0">
        <ng-container *ngFor="let button of data.buttons!">
          <button *ngIf="!button.closeButton"
                  type="button"
                  class="btn btn-sm"
                  [class.btn-link]="(button.fill === 'clear')"
                  [ngClass]=" [
                   (button.fill === 'outline') ? ('btn-' + button.fill +'-' + button.color) : '',
                   (button.fill === 'solid') ?  'btn-' + button.color : '',
                   (!button.fill) ?  'btn-' + button.color : '',
                   ]"
                  (click)="(button.handler)? button.handler() : ''">
            {{ button.text | translate }}
          </button>
          <button *ngIf="button.closeButton"
                  type="button"
                  [class]="'btn btn-sm btn-' + button.color"
                  [ngClass]=" [
                   (button.fill === 'outline') ? ('btn-' + button.fill +'-' + button.color) : '',
                   (button.fill === 'solid') ?  'btn-' + button.color : '',
                   (!button.fill) ?  'btn-' + button.color : '',
                   ]"
                  data-bs-dismiss="toast"
                  aria-label="Close"
                  (click)="(button.handler)? button.handler() : ''">
            {{ button.text | translate }}
          </button>

        </ng-container>
      </div>
    </div>
    <button *ngIf="data.closeButtonBody"
            type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close">
    </button>
  </div>
</div>



