import { Injectable } from '@angular/core';
import { IAvClientInfo, IAvMsgAscii, IAvMsgAsciiCommand, IAvMsgDataHello, IAvMsgDataHelloAns, IAvMsgDataHelloSync, IAvMsgDataHelloSyncAns, IAvMsgDataPing, IAvMsgItem, IAvOnNewData, IAvProdCommsStatus, IAvProdOverlay } from '../../interfaces/av-producer/event-av-producer.interface';
import { AvProdClientDeviceType, AvProdClientType, AvProdDeviceType, AvProdItemSection, AvProdLowBandwidthConfig, AvProdMsgAsciiRequest, AvProdMsgAsciiType, AvProdMsgBinType, AvProdRequests, AvProdStatus } from '../../const/av-producer.const';
import { IAvProdLayoutMngrSettings } from '../../interfaces/av-producer/layoutmanager-settings.interface';
import { IAvProdComposerSettings } from '../../interfaces/av-producer/composer-settings.interface';
import { Observable, Subject, Subscription, catchError, interval, map, partition } from 'rxjs';
import { IAvProdInterfaceClientStatus } from '../../interfaces/av-producer/interface-client-status.interface';
import { IEvent } from '../../interfaces/events/event.interface';
import { WebSocketService } from '../web-socket/web-socket.service';
import { WS, WSStatus } from '../../const/web-socket.const';

@Injectable({
  providedIn: 'root'
})
export class AvProducerLiteService {

  public commsStatus: IAvProdCommsStatus = {
    ok: false,
    status: AvProdStatus.notinitialized,
    stats: {
      rxBytes: 0,
      rxRate: 0,
      rxRateStr: '0',
      txBytes: 0,
      txRate: 0,
      txRateStr: '0',
      lastTimestamp: 0
    },
    videoReceptionStatus: {
      lastDelays: [],
      lastRxTimestamp: 0,
      lastRxTimestampMsg: 0
    },
    lowBandwidthConfig: AvProdLowBandwidthConfig.medium,
    lowBandwidthStatus: AvProdLowBandwidthConfig.medium,
    lowBandwidthStatusTS: 0,
    lowBandwidthServerRestarting: false
  }
  public overlays: IAvProdOverlay[] = [];
  public layoutManager: IAvProdLayoutMngrSettings = {videoLayouts: [], favorites: []};
  public composerSettings: IAvProdComposerSettings = {}

  public onNewData$: Observable<IAvOnNewData> = new Observable();
  public onNewComposerSettings$: Observable<IAvProdComposerSettings> = new Observable();
  public onNewLayoutManagerSettings$: Observable<IAvProdLayoutMngrSettings> = new Observable();

  public clientInfo: IAvClientInfo = {
    clientId: 0,
    clientType: AvProdClientType.internal,
    clientDeviceType: AvProdClientDeviceType.internal,
    name: 'OverlayDisplay',
    token: 'azz-sec-ret',
    user: 'AzzuleiInternal',
    deviceId: 'Rnd' + (Math.round(Math.random() * 1000000)).toString(),
    tsDiff: 0
  };
  public clientStatus: IAvProdInterfaceClientStatus = {
    clientId: 0,
    registration: '',
    tsDiff: 0,
    publish: undefined
  }

  private onNewDataSource: Subject<IAvOnNewData> = new Subject();
  private onNewComposerSettingsSource: Subject<IAvProdComposerSettings> = new Subject();
  private onNewLayoutManagerSettingsSource: Subject<IAvProdLayoutMngrSettings> = new Subject();
  public onCommsStatusChange$: Subject<IAvProdCommsStatus> = new Subject();

  private clientStatusTimestamp: number = 0;

  private wsMessages$: Observable<any> = new Observable();
  private binaryData$: Observable<any> = new Observable();
  private asciiData$: Observable<any> = new Observable();
  private wsStatusSubscription: Subscription | undefined;
  private wsMsgAsciiSubscription: Subscription | undefined;
  private wsMsgBinarySubscription: Subscription | undefined;
  private timerStatusSubscription: Subscription | undefined;

  private event: IEvent = {
    categoryId: 0,
    name: 'local',
    description: '',
    host: 'dev-azz0394-1.azzulei.tv',
    //host: '127.0.0.1'
  };

  private subscriptions: string[] = [
      'composer/#/settings',
      'server/#/status',
      'server/#/components',
      'interface/#/status',
      'layoutmanager/1/settings',
      'msg/1/status'
  ]
  
  private initialRequests: string[] = [
    'server/1/components',
    'server/1/settings',
    'server/1/status',
    'layoutmanager/1/settings',
    'composer/1/settings',
    'output/1/settings',
    'interface/1/status'
  ]

  constructor(private wsService: WebSocketService) {
    this.onNewData$ = this.onNewDataSource.asObservable();
    this.onNewComposerSettings$ = this.onNewComposerSettingsSource.asObservable();
    this.onNewLayoutManagerSettings$ = this.onNewLayoutManagerSettingsSource.asObservable();
  }

  public init(){
    this.closeComms();

    this.commsStatus.status = AvProdStatus.closed;
    this.wsMessages$ = this.wsService.messages$.pipe(
      map((data: any) => new Uint8Array(data)),
      catchError(err => {
        throw err;
      }));

    // Trigger status change event
    this.onCommsStatusChange$.next(this.commsStatus);

    // Separating binary from ascii data messages
    [this.binaryData$, this.asciiData$] = partition(this.wsMessages$, (data) => (data[0] >= 0x61 && data[0] <= 0x7A));

    this.wsMsgAsciiSubscription = this.asciiData$.subscribe(data => this.receiveWsMsgAscii(data));
    this.wsMsgBinarySubscription = this.binaryData$.subscribe(data => this.receiveWsMsgBinary(data));

    this.wsStatusSubscription = this.wsService.socketStatus$.subscribe(status => this.receiveWsStatus(status));
  }

  public destroy() {

    this.closeComms();

    if (this.wsStatusSubscription !== undefined) {
      this.wsStatusSubscription.unsubscribe();
    }
    if (this.wsMsgAsciiSubscription !== undefined) {
      this.wsMsgAsciiSubscription.unsubscribe();
    }
    if (this.wsMsgBinarySubscription !== undefined) {
      this.wsMsgBinarySubscription.unsubscribe();
    }
    if (this.timerStatusSubscription !== undefined) {
      this.timerStatusSubscription.unsubscribe();
    }
    this.commsStatus.status = AvProdStatus.destroyed;
    // Trigger status change event
    this.onCommsStatusChange$.next(this.commsStatus);
  }

  public openComms(): boolean{
    let ret = false;

    if (this.commsStatus.status !== AvProdStatus.closed) {
      this.closeComms();
    }

    if (this.event.host !== undefined) {
      this.wsService.connect(WS.protocol + this.event.host + WS.path, {reconnect: true});
      ret = true;
      console.log('[AvProducerLiteService] Open Comms');
    }
    return ret;
  }

  public closeComms(): boolean {
    const RET = true;
    this.wsService.close();

    // Initialize component lists
    this.overlays = [];
    this.layoutManager = {videoLayouts: [], favorites: []};
    this.composerSettings = {}
    this.clientInfo.clientId = 0;
    this.clientInfo.tsDiff = 0;
    this.commsStatus.status = AvProdStatus.closed;
    this.commsStatus.ok = false;
    this.onCommsStatusChange$.next(this.commsStatus);
    return RET;
  }

  /**
   * Function called periodically by an interval to check communications
   */
  private tickStatusCheck() {
    const TIME_NOW: number = (new Date()).getTime();
    if ((this.commsStatus.status === AvProdStatus.receiving) &&
      (this.clientStatusTimestamp != 0)) {
      const DIFF: number = (TIME_NOW - this.clientStatusTimestamp);
      if (DIFF > 3000) {
        this.commsStatus.status = AvProdStatus.error;
        this.commsStatus.ok = false;
        console.log('[EventAvProducer] Communications error Timeout');
        // Trigger status change event
        this.onCommsStatusChange$.next(this.commsStatus);
      }
    }
  }

  /**
   *
   * @param deviceType Type of device or component
   * @param deviceId Component identifier
   * @param section Item section
   * @param subSections Item subsections (optional)
   * @returns string describing item
   */
  private item2string(deviceType: AvProdDeviceType, deviceId: number, section: AvProdItemSection, subSections: string[]): string {
    let ret: string = deviceType + '/' + deviceId.toString() + '/' + section;
    for (let i = 0; i < subSections.length; i++) {
      ret += ('/' + subSections[i]);
    }
    return ret;
  }

  private string2item(item: string): IAvMsgItem {
    const RET: IAvMsgItem = {
      deviceType: AvProdDeviceType.none,
      deviceId: 0,
      section: AvProdItemSection.none,
      subSections: []
    }

    const STR_ARRAY: string[] = item.split('/');
    if (STR_ARRAY.length >= 3) {
      if ((<any>AvProdDeviceType)[STR_ARRAY[0]] !== undefined) {
        RET.deviceType = (<any>AvProdDeviceType)[STR_ARRAY[0]];
      }
      RET.deviceId = parseInt(STR_ARRAY[1]);
      if ((<any>AvProdItemSection)[STR_ARRAY[2]] !== undefined) {
        RET.section = (<any>AvProdItemSection)[STR_ARRAY[2]];
      }
    }

    if (STR_ARRAY.length > 3) {
      RET.subSections = STR_ARRAY.splice(3);
    }
    return RET;
  }

  /**
   * Receives new Status change from Web socket service
   *
   * @param wsStatus WebSocket service new status
   */
  private receiveWsStatus(wsStatus: WSStatus) {
    console.log('[AvProducerService] New Ws Status: ' + wsStatus);
    let localStatus: AvProdStatus = AvProdStatus.error;
    if ((wsStatus == WSStatus.closed) ||
      (wsStatus == WSStatus.closedByUser)) localStatus = AvProdStatus.closed;
    else if (wsStatus == WSStatus.closedByServer) localStatus = AvProdStatus.error;
    else if (wsStatus == WSStatus.closing) localStatus = AvProdStatus.closing;
    else if (wsStatus == WSStatus.connecting) localStatus = AvProdStatus.connecting;
    else if (wsStatus == WSStatus.open) {
      if ((this.commsStatus.status !== AvProdStatus.connected) &&
        (this.commsStatus.status !== AvProdStatus.receiving)) {
        this.azzSendHello();
        this.clientStatusTimestamp = (new Date()).getTime();
      }
      localStatus = AvProdStatus.connected;
    }

    if (this.timerStatusSubscription !== undefined) this.timerStatusSubscription.unsubscribe();
    if ((localStatus === AvProdStatus.connected) || (this.commsStatus.lowBandwidthServerRestarting === true)) {
      this.timerStatusSubscription = interval(1000).subscribe(() => {
        this.tickStatusCheck()
      });
    }

    this.commsStatus.ok = false;

    this.commsStatus.status = localStatus;

    if (this.commsStatus.status != AvProdStatus.connected) {
      this.commsStatus.videoReceptionStatus.lastRxTimestamp = 0;
      this.commsStatus.videoReceptionStatus.lastDelays = [];
    }
    console.log('[AvProducerLiteService] New Av Status: ' + this.commsStatus.status);
    // Trigger status change event
    this.onCommsStatusChange$.next(this.commsStatus);
  }

  /**
   * Receives new ASCII message from avProducer Web socket
   *
   * @param data Message data received from the web socket
   */
  private receiveWsMsgAscii(data: any) {
    // Ignore first byte and parse JSON object
    const msgType: string = String.fromCharCode(data[0]);
    const msg = JSON.parse(new TextDecoder().decode(data.slice(1)));
    //console.log('[AvProducerService] New Ws Message Ascii: (' + data.length + ') (' + msgType + ') ' + JSON.stringify(msg));

    if (msg != undefined) {

      // Add received bytes for stats
      //this.commsStatus.stats.rxBytes += data.length

      // Register reception TS for communications check
      this.clientStatusTimestamp = (new Date()).getTime();
      this.commsStatus.status = AvProdStatus.receiving;
      if (this.commsStatus.ok !== true) {
        // Trigger status change event
        this.commsStatus.ok = true;
        this.onCommsStatusChange$.next(this.commsStatus);
      }

      switch (msgType) {

        case AvProdMsgAsciiType.answer: {
          // Answer message received
          this.receiveAvAnswer(msg);
          break;
        }
        case AvProdMsgAsciiType.notification: {
          // Notification message received
          this.receiveAvNotification(msg);
          break;
        }
        case AvProdMsgAsciiType.request: {
          // Request message received
          this.receiveAvRequest(msg);
          break;
        }
      }
    }
  }

  /**
   * Receives AV Answer message
   *
   * @param msg Answer message object
   */
  private receiveAvAnswer(msg: IAvMsgAscii) {

    // Answers to ASCII messages
    switch (msg.request) {

      case AvProdRequests.hello: {
        // Hello answer returns client Id, token validation and time synchronization data
        const ANS_DATA: IAvMsgDataHelloAns | undefined = msg.data;
        if (msg.retCode !== 0) {
          console.log('[AvProducerLiteService] Hello ERROR (' + msg.retStr + ')');
          // By now, close comms if Hello error
          this.closeComms();
          //if (msg.retCode === 14) {
          //  // Too many clients error code
          //  this.onCommsTooManyClientsChange$.next(true);
          //}
        }
        else if ((msg.retCode === 0) && (ANS_DATA !== undefined)) {
          this.clientInfo.clientId = ANS_DATA.clientId;
          if (ANS_DATA.deviceId !== undefined){
            this.clientInfo.deviceId = ANS_DATA.deviceId;
          }
          //this.batteryUpdateTimestamp = 0;
          // Check registration return value
          // ansData.registration
          const HELLO_SYNC_DATA: IAvMsgDataHelloSync = {
            ts1: ANS_DATA.ts1,
            ts2: ANS_DATA.ts2,
            ts3: new Date().getTime()
          }
          this.azzSendHelloSync(HELLO_SYNC_DATA);
        }
        break;
      }

      case AvProdRequests.helloSync: {
        const ANS_DATA: IAvMsgDataHelloSyncAns | undefined = msg.data;
        if ((ANS_DATA !== undefined) && (msg.retCode !== undefined) && (msg.retCode == 0)) {
          this.clientInfo.tsDiff = ANS_DATA.tsDiff;
          console.log('[AvProducerLiteService] Hello Sync Answer received. Id' + this.clientInfo.clientId + ' (' + this.clientInfo.tsDiff + ')');
          this.azzSendInitialRequests();
          this.azzSendSubscriptions();
        }
        break;
      }

      // Answers to get requests are like Notification
      case AvProdRequests.get: {
        this.receiveAvNotification(msg);
        break;
      }

      case AvProdRequests.command: {
        if (msg.item === 'interface/1/commands'){
          console.log('[AvProducerLiteService] receiveAvAnswer Interface CMD ' + JSON.stringify(msg));
          //this.onInterfaceCommandResponse$.next(msg);
        }
        break;
      }
    }

    // Check answers for Binary data messages
    if ((msg.dataType !== undefined) && (msg.dataId !== undefined) &&
      (msg.retCode !== 0)) {
      switch (msg.dataType) {
        case AvProdMsgBinType.audioencoded:
        case AvProdMsgBinType.audioraw:
        case AvProdMsgBinType.frame:
        case AvProdMsgBinType.media:
          // Publication error received
          //this.onAnswerErrorPublishSource.next(msg);
          break;
      }
    }

  }

  /**
   * Receives AV Notification message
   *
   * @param msg Notification message object
   */
  private receiveAvNotification(msg: IAvMsgAscii) {
    const ITEM: IAvMsgItem = this.string2item(msg.item);
    //console.log('[EventAvProducer] Notification Item: ' + JSON.stringify(item));

    // Components data received
    if (ITEM.section === AvProdItemSection.components) {
      if (ITEM.deviceType === AvProdDeviceType.server) {
        if (msg.data !== undefined) {
          //console.log('[AvProducerLiteService] Components: ' + JSON.stringify(msg.data));
          //this.updateInputComponents(msg.data);
          //this.updateOverlayComponents(msg.data);
          //this.componentsSource.next(msg.data);
        }
      }
    }
    // Composer settings data received
    else if ((ITEM.section === AvProdItemSection.settings) && (ITEM.deviceType === AvProdDeviceType.composer)) {
      if (msg.data !== undefined) {
        //console.log('[AvProducerLiteService] Composer settings: ' + JSON.stringify(msg.data));
        this.updateComposerSettings(ITEM.deviceId, msg.data);
        this.onNewComposerSettingsSource.next(this.composerSettings);
      }
    }
    // Layout manager settings data received
    else if ((ITEM.section === AvProdItemSection.settings) && (ITEM.deviceType === AvProdDeviceType.layoutmanager)) {
      if (msg.data !== undefined) {
        console.log('[AvProducerLiteService] Layout Manager settings: (' + ITEM.deviceId + ')' + JSON.stringify(msg.data));
        //this.updateLayoutManager(ITEM.deviceId, msg.data);
        //this.onNewLayoutManagerSettingsSource.next(msg.data);
      }
    }
    // Server settings data received
    else if ((ITEM.section === AvProdItemSection.settings) && (ITEM.deviceType === AvProdDeviceType.server)) {
      if (msg.data !== undefined) {
        console.log('[AvProducerLiteService] Server settings: (' + ITEM.deviceId + ')' + JSON.stringify(msg.data));
        //this.updateServerSettings(ITEM.deviceId, msg.data);
        //this.onNewServerSettingsSource.next(msg.data);
      }
    }
    // Server status data received
    else if ((ITEM.section === AvProdItemSection.status) && (ITEM.deviceType === AvProdDeviceType.server)) {
      if (msg.data !== undefined) {
        //console.log('[AvProducerLiteService] Server status: (' + item.deviceId + ')' + JSON.stringify(msg.data));
        //this.updateServerStatus(ITEM.deviceId, msg.data);
      }
    }
    // Interface status data received
    else if ((ITEM.section === AvProdItemSection.status) && (ITEM.deviceType === AvProdDeviceType.interface)) {
      if (msg.data !== undefined) {
        //console.log('[AvProducerLiteService] Interface status: (' + ITEM.deviceId + ')[' + this.clientInfo.clientId + ']' + JSON.stringify(msg.data));
        //this.updateInterfaceStatus(ITEM.deviceId, msg.data);
      }
    }
    // Interface client status data received
    else if ((ITEM.section === AvProdItemSection.client) && (ITEM.deviceType === AvProdDeviceType.interface)) {
      if (msg.data !== undefined) {
        //console.log('[EventAvProducer] Interface client status: (' + ITEM.deviceId + ')' + JSON.stringify(msg.data));
        this.updateClientStatus(ITEM.deviceId, msg.data);
      }
    }

    // Emit event fo new data
    const NEW_DATA: IAvOnNewData = {
      item: ITEM,
      data: msg.data
    }

    this.onNewDataSource.next(NEW_DATA);
  }

  /**
   * Receives AV Request message
   *
   * @param msg Request message object
   */
  private receiveAvRequest(msg: IAvMsgAscii) {
    const ITEM: IAvMsgItem = this.string2item(msg.item);
    console.log('[AvProducerLiteService] receiveAvRequest Item: ' + JSON.stringify(ITEM));

    // Command
    if (msg.request === AvProdMsgAsciiRequest.command) {
      if ((ITEM.deviceType === AvProdDeviceType.client) &&
        (ITEM.section === AvProdItemSection.commands)) {
        if (ITEM.deviceId === this.clientInfo.clientId) {
          // Command for this interface client
          if (msg.data !== undefined) {
            const CMD: IAvMsgAsciiCommand = msg.data;
            //this.onCmdPublishSettingsChange$.next(CMD);
          }
        }
      }
    }
  }

  /**
   * Receives new Binary message from avProducer Web socket
   *
   * @param data Message data received from the web socket
   */
  private receiveWsMsgBinary(data: any) {
    // Not used for now
  }

  /**
   * Sends an ASCII message to avProducer
   *
   * @param msgType Character defining message type (request, notification, answer)
   * @param request Message action (subscribe, set, get, unsubscribe, ...)
   * @param item avProducer item destination (object type / identifier / item / ...)
   * @param data Optional extra message data information in JSON object format
   *
   * @return boolean (true for OK and false for Error)
   */
  private sendMsgAscii(msgType: string, request: string, item: string, data?: any): boolean {
    const RET = true;
    let msg = '';
    const MSG_OBJECT: any = {};

    MSG_OBJECT.request = request;
    MSG_OBJECT.item = item;
    if (data !== undefined) MSG_OBJECT.data = data;

    // Start by message type character
    msg += msgType;
    // Add stringified JSON object
    msg += JSON.stringify(MSG_OBJECT);
    //console.log('[AvProducerService] sendMsgAscii:' + msg);
    this.wsService.sendMessage(msg);
    // Add transmitted bytes for stats
    this.commsStatus.stats.txBytes += msg.length;
    return RET;
  }

  /**
   * Sends Hello message to avProducer
   *
   * @return boolean (true for OK and false for Error)
   */
  private azzSendHello() {
    const DATA_OBJ: IAvMsgDataHello = {
      clientType: this.clientInfo.clientType,
      clientDeviceType: this.clientInfo.clientDeviceType,
      deviceId: this.clientInfo.deviceId,
      token: this.clientInfo.token,
      name: this.clientInfo.name,
      user: this.clientInfo.user,
      ts1: new Date().getTime()
    }

    return this.sendMsgAscii(AvProdMsgAsciiType.request, AvProdMsgAsciiRequest.hello, 'interface', DATA_OBJ);
  }

  /**
   * Sends Hello Syn message to avProducer
   *
   * @return boolean (true for OK and false for Error)
   */
  private azzSendHelloSync(data: IAvMsgDataHelloSync) {
    return this.sendMsgAscii(AvProdMsgAsciiType.request, AvProdMsgAsciiRequest.helloSync, 'interface', data);
  }

  /**
   * Sends ping message to avProducer
   *
   */
  private azzSendPing(tsNotification: number) {
    const DATA_OBJ: IAvMsgDataPing = {
      tsNotification: tsNotification
    }
    //console.log('[AvProducerService] avProducer Ping');
    this.sendMsgAscii(AvProdMsgAsciiType.request, AvProdMsgAsciiRequest.ping, 'interface', DATA_OBJ);
  }

  /**
   * Sends unsubscribe message to avProducer
   *
   */
  private azzRemoveSubscriptions() {
    console.log('[AvProducerLiteService] Remove subscriptions');
    this.sendMsgAscii(AvProdMsgAsciiType.request, AvProdMsgAsciiRequest.unsubscribe, 'all');
  }

  /**
   * Sends subscription messages to avProducer
   *
   * @return boolean (true for OK and false for Error)
   */
  private azzSendSubscriptions() {
    console.log('[AvProducerLiteService] Send subscriptions');
    if (this.sendMsgAscii(AvProdMsgAsciiType.request, AvProdMsgAsciiRequest.unsubscribe, 'all')) {
      for (let i = 0; i < this.subscriptions.length; i++) {
        this.sendMsgAscii(AvProdMsgAsciiType.request, AvProdMsgAsciiRequest.subscribe, this.subscriptions[i]);
      }
    }
  }

  /**
   * Sends initial request messages to avProducer
   *
   * @return boolean (true for OK and false for Error)
   */
  private azzSendInitialRequests() {
    for (let i = 0; i < this.initialRequests.length; i++) {
      this.sendMsgAscii(AvProdMsgAsciiType.request, AvProdMsgAsciiRequest.get, this.initialRequests[i]);
    }
  }

  /**
   * Function to update composer settings, called when new setting information is received
   *
   * @param id Composer identifier
   * @param settings New composer settings received from avProducer
   */
  private updateComposerSettings(id: number, settings: IAvProdComposerSettings) {
    this.composerSettings = settings;
  }

  /**
   * Function to update interface client status, called when new information is received
   *
   * @param id Interface client identifier (1)
   * @param status New interface client status received from avProducer
   */
  private updateClientStatus(id: number, status: IAvProdInterfaceClientStatus) {
    if (this.clientInfo.clientId === status.clientId) {
      this.clientStatus = status;
      // Send ping to avProducer to check connectivity status
      if (status.ts !== undefined) {
        this.azzSendPing(status.ts);
      }
      //this.onNewInterfaceClientStatusSource.next(status);
    } else {
      console.log('[AvProducerLiteService] Client status different Id:' + this.clientInfo.clientId + '/' + status.clientId);
    }
  }
}
