import { Subject } from 'rxjs';
import { AvProdDeviceType, AvProdItemSection, AvProdLowBandwidthConfig, AvProdStatus } from '../../const/av-producer.const';
import { IAvProdComponentInput, IAvProdComponentOverlay } from './components.interface';
import { IAvProdInputSettings, IAvProdInputSettingsAudio } from './input-settings.interface';
import { IAvProdInputStatus } from './input-status.interface';
import { IAvProdOverlaySettings } from './overlay-settings.interface';
import { ValidatorFn } from '@angular/forms';

export interface IAvProdInput {
    info: IAvProdComponentInput;
    settings?: IAvProdInputSettings;
    status?: IAvProdInputStatus;
}

export interface IAvProdOverlay {
    info: IAvProdComponentOverlay;
    settings?: IAvProdOverlaySettings;
}

export interface IAvMsgAscii {
    request: string;
    item: string;
    data?: any;
    dataTx?: any;
    retCode?: number;
    retStr?: string;
    dataType?: string;
    dataId?: number;
}

export interface IAvOnNewData {
    item: IAvMsgItem;
    data?: any;
}

export interface IAvMsgBin {
    type: string;
    timestamp: number;
    dataId: number;
    dataSubId: number;
    dataSize: number;
    data: any;
}

export interface IAvMsgAsciiCommand {
    command: string;
    params?: any;
}

export interface IAvMsgItem {
    deviceType: AvProdDeviceType;
    deviceId: number;
    section: AvProdItemSection;
    subSections: string[];
}

export interface IAvClientInfo {
    clientId: number;
    clientType: number;
    clientDeviceType: number;
    name: string;
    token: string;
    user: string;
    deviceId: string;
    tsDiff: number;
}

export interface IAvClientStatus {
    clientId: number;
    clientType: number;
    clientDeviceType: number;
    name: string;
    token: string;
    user: string;
    deviceId: string;
    tsDiff: number;
}

export interface IAvMsgDataHello {
    clientType: number;
    clientDeviceType: number;
    name: string;
    token: string;
    user: string;
    deviceId: string;
    ts1: number;
}

export interface IAvMsgDataHelloAns {
    clientId: number;
    deviceId?: string;
    registration: string;
    ts1: number;
    ts2: number;
}

export interface IAvMsgDataHelloSync {
    ts1: number;
    ts2: number;
    ts3: number;
}

export interface IAvMsgDataHelloSyncAns {
    tsDiff1: number;
    tsDiff2: number;
    tsDiff: number;
}

export interface IAvMsgDataPing {
    tsNotification: number;
}

export interface IAvMsgDataSubscription {
    count?: number;
    interval?: number;
}

export interface IAvAudioLevel {
    audiolevels: number[];
}

export interface IAvLayoutTileSelectionProgress {
    state: string;
    layoutId: number;
    tileSelection: number[];
}

export interface IAvLayoutTileSelectionCommand {
    action: string;
    layoutId: number;
}

/**** */

export interface IAvSettingsAudioInput {
    id: number;
    name: string;
    settings: IAvProdInputSettingsAudio;
    playingState?: number;
    liveSourceType?: number;
}

export interface IAvSettingSelectOption {
    label: string;
    labelTranslate: string;
    valueNumber: number;
    valueStr: string;
}

export interface IAvSettingsItemConfig {
    id: string;
    type: string;
    name: string;
    title?: string;
    info?: string;
    value?: any;
    min: number;
    max: number;
    step: number;
    options: IAvSettingSelectOption[];
    placeholder: string;
    visible?: boolean;
    translate?: boolean;
    validators?: ValidatorFn[] | [];
    disabled?: boolean;
    config?: any;
}

export interface IAvProducerSettingsConfig {
    producerMode: string;
    lowBandwidth: AvProdLowBandwidthConfig;
}

export interface IAvProdCommsStats {
    lastTimestamp: number;
    rxBytes: number;
    txBytes: number;
    rxRate: number;
    txRate: number;
    rxRateStr: string;
    txRateStr: string;
}

export interface IAvProdVideoReceptionStatus {
    lastRxTimestamp: number;
    lastRxTimestampMsg: number;
    lastDelays: number[];
}

export interface IAvProdCommsStatus {
    ok: boolean;
    status: AvProdStatus;
    stats: IAvProdCommsStats;
    videoReceptionStatus: IAvProdVideoReceptionStatus;
    lowBandwidthConfig: AvProdLowBandwidthConfig;
    lowBandwidthStatus: AvProdLowBandwidthConfig;
    lowBandwidthStatusTS: number;
    lowBandwidthServerRestarting: boolean;
}

export interface IAvProdWebRtcViewerDataVideo {
    width: number;
    height: number;
    decoder: any;
    channel: RTCDataChannel | null;
    partialVideoData: Uint8Array | null;
    firstTS: number;
    lastTS: number;
    lastLocalTS: number;
    //frame$?: Subject<VideoFrame>;
    frame$?: Subject<any>;
}

export interface IAvProdWebRtcViewerDataAudio {
    active: boolean;
    channel: RTCDataChannel | null;
    firstTS: number;
    lastTS: number;
    lastLocalTS: number;
    data$?: Subject<Uint8Array>;
}

export interface IAvProdWebRtcViewerData {
    video: IAvProdWebRtcViewerDataVideo;
    audio: IAvProdWebRtcViewerDataAudio;
}

export interface IAvProdWebRtcConnection {
    active: boolean;
    deviceId: number;
    id: string;
    serverSdp: string;
    clientSdp: string;
    lastClientPingTS: number;
    lastStartTS: number;
    peerConnection?: RTCPeerConnection;
}

export interface IAvProdWebRtcInputData {
    viewer: IAvProdWebRtcConnection;
    viewerManager: IAvProdWebRtcViewerData;
    broadcaster: IAvProdWebRtcConnection;
    broadcasterStream?: MediaStream;
}

export interface IAvProdWebRtcOutputData {
    viewer: IAvProdWebRtcConnection;
    viewerManager: IAvProdWebRtcViewerData;
}

export interface IAvProdInputDropInfo {
    inputId: number;
    x: number;
    y: number;
}

export interface IAvProdInputTileSwapInfo {
    x1: number;
    y1: number;
    x2: number;
    y2: number;
}

export interface IAvProdMessageItem {
    id: number;
    ts: number;
    originUserId: number;
    originUserName: string;
    text: string;
}

export interface IAvProdMessagingStatus {
    devId?: number;
    msgCountTotal?: number;
    msgList?: IAvProdMessageItem[];
}
