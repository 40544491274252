import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IToastConfig, ToastPlacement, ToastPlacementCss } from '@azz-life-streamer/shared';
import { ToastService } from '../../../core/services/toast/toast.service';
import { ToastComponent } from '../toast/toast.component';


@Component({
  selector: 'web-toaster',
  standalone: true,
  imports: [CommonModule, ToastComponent],
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToasterComponent implements OnInit {

  protected readonly toastPlacement = ToastPlacement;
  protected readonly Object = Object;
  protected currentToasts: {
    topLeft: IToastConfig[];
    topCenter: IToastConfig[];
    topRight: IToastConfig[];
    middleLeft: IToastConfig[];
    middleCenter: IToastConfig[];
    middleRight: IToastConfig[];
    bottomLeft: IToastConfig[];
    bottomCenter: IToastConfig[];
    bottomRight: IToastConfig[];
  };

  constructor(private toastService: ToastService,
              private changeDetectorRef: ChangeDetectorRef) {
    this.currentToasts = this.initToasts();
  }

  ngOnInit(): void {
    this.getToast()
  }

  protected close(index: number, placement: string): void {
    this.getCurrentToasts(placement).splice(index, 1);
  }

  private getToast(): void {
    this.toastService.toastEvents$.subscribe((config: IToastConfig | null) => {
      if (config !== null) {
        this.getCurrentToasts(config.options.placement).push(config);
      } else {
        this.currentToasts = this.initToasts();
      }
      this.changeDetectorRef.detectChanges();
    });
  }

  protected getCssPlacement(placement: string): ToastPlacementCss {
    switch (placement) {
      case ToastPlacement.topLeft:
        return ToastPlacementCss.topLeft;
      case ToastPlacement.topCenter:
        return ToastPlacementCss.topCenter;
      case ToastPlacement.topRight:
        return ToastPlacementCss.topRight;
      case ToastPlacement.middleLeft:
        return ToastPlacementCss.middleLeft;
      case ToastPlacement.middleCenter:
        return ToastPlacementCss.middleCenter;
      case ToastPlacement.middleRight:
        return ToastPlacementCss.middleRight;
      case ToastPlacement.bottomLeft:
        return ToastPlacementCss.bottomLeft;
      case ToastPlacement.bottomCenter:
        return ToastPlacementCss.bottomCenter;
      case ToastPlacement.bottomRight:
        return ToastPlacementCss.bottomRight;
      default:
        return ToastPlacementCss.bottomRight;
    }
  }

  protected getCurrentToasts(placement: string): IToastConfig[] {
    switch (placement) {
      case ToastPlacement.topLeft:
        return this.currentToasts.topLeft;
      case ToastPlacement.topCenter:
        return this.currentToasts.topCenter;
      case ToastPlacement.topRight:
        return this.currentToasts.topRight;
      case ToastPlacement.middleLeft:
        return this.currentToasts.middleLeft;
      case ToastPlacement.middleCenter:
        return this.currentToasts.middleCenter;
      case ToastPlacement.middleRight:
        return this.currentToasts.middleRight;
      case ToastPlacement.bottomLeft:
        return this.currentToasts.bottomLeft;
      case ToastPlacement.bottomCenter:
        return this.currentToasts.bottomCenter;
      case ToastPlacement.bottomRight:
        return this.currentToasts.bottomRight;
      default:
        return this.currentToasts.bottomRight;
    }
  }

  private initToasts(): any {
    return {
      bottomCenter: [],
      bottomLeft: [],
      bottomRight: [],
      middleCenter: [],
      middleLeft: [],
      middleRight: [],
      topCenter: [],
      topLeft: [],
      topRight: [],
    };
  }
}
