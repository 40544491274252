import { Component, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'web-form-input-code',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, TranslateModule, FormsModule],
  templateUrl: './form-input-code.component.html',
  styleUrls: ['./form-input-code.component.scss'],
})
export class FormInputCodeComponent {

  @Output() codeChanged: EventEmitter<number> = new EventEmitter<number>();

  onCodeChange(event: any): void {
    const value = event.target.value;
    if (value !== undefined) {
      this.codeChanged.emit(value);
    }
  }
}
