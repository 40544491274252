import { Component } from '@angular/core';

import { VERSION_NAME } from '../../../core/const/app.const';


@Component({
  selector: 'web-footer-bottom',
  templateUrl: './footer-bottom.component.html',
  styleUrls: ['./footer-bottom.component.scss'],
})
export class FooterBottomComponent {
  protected readonly VERSION_NAME: string = VERSION_NAME;
}
