import { Subscription, timer } from 'rxjs';

import { SafeUrl } from '@angular/platform-browser';
import { FormControl, FormGroup } from '@angular/forms';
import { IAvProdInput, IAvSettingsItemConfig } from '../../interfaces/av-producer/event-av-producer.interface';
import { AvProdInputType, AvProdInputTypeNumber, AvProdSettingsType } from '../../const/av-producer.const';
import { AV_PROD_OPTIONS_INPUT_RESOURCE_TYPES } from '../../const/av-producer-options';
import { AvProducerService } from '../../services/av-producer/av-producer.service';
import { IAlertData, IToastConfig } from '../../interfaces/utils/utils.interface';
import { IAvProdInputSettings } from '../../interfaces/av-producer/input-settings.interface';
import { AlertStatus, ToastPlacement, ToastStatus } from '../../enums/common.enum';
import { IEventAsset, IEventUserLastChanges } from '../../interfaces/events/event.interface';
import { EventsService } from '../../services/events/events.service';
import { ENV_COMMON } from '@azz-life-streamer/environments';
import { UserService } from '../../services/user/user.service';
import { AuthService } from '../../services/auth/auth.service';


export class InputAddResourceClass {
  protected tabSelected: number = 0;
  protected inputList: IAvProdInput[] = [];
  protected assets: IEventAsset[] = [];
  protected assetsFiltered: IEventAsset[] = [];
  protected componentSubscription: Subscription | undefined;
  protected userChangesSubscription: Subscription | undefined;
  protected downloadBusy: boolean = false;
  protected _allowTypeChange: boolean = false;
  protected _resourceType: AvProdInputTypeNumber = AvProdInputTypeNumber.videoAudioClip;
  protected _resourceTypeStr: string = '';
  protected _layoutMode: number = 1; //(ONLY FOR APP) 1: Modal, 2: Toolbox

  protected inputTypeNumber = AvProdInputTypeNumber;
  protected inputType = AvProdInputType;

  protected settingsResource: FormGroup = new FormGroup([]);
  protected itemResourceType: IAvSettingsItemConfig =
    {
      id: 'resourceType',
      type: AvProdSettingsType.selectComboNumber,
      name: 'inputAdd.resourceType',
      min: 0,
      max: 0,
      step: 1,
      options: AV_PROD_OPTIONS_INPUT_RESOURCE_TYPES,
      placeholder: '',
      value: undefined,
      config: {
        mode: 'native'
      }
    }

  constructor(protected avProd: AvProducerService,
              protected events: EventsService,
              protected userService: UserService,
              protected authService: AuthService) {
    this.settingsResource.addControl(this.itemResourceType.id, new FormControl());
  }

  protected setResourceType(value: AvProdInputTypeNumber): void {
    console.log('[InputAddResource] setResourceType: ' + value);
    if (value !== undefined) {
      this._resourceType = value;
      switch (value) {
        case AvProdInputTypeNumber.audioClip:
          this._resourceTypeStr = AvProdInputType.audioClip;
          break;
        case AvProdInputTypeNumber.audioStream:
          this._resourceTypeStr = AvProdInputType.audioStream;
          break;
        case AvProdInputTypeNumber.document:
          this._resourceTypeStr = AvProdInputType.document;
          break;
        case AvProdInputTypeNumber.highlight:
          this._resourceTypeStr = AvProdInputType.highlight;
          break;
        case AvProdInputTypeNumber.imageOverlay:
          this._resourceTypeStr = AvProdInputType.imageOverlay;
          break;
        case AvProdInputTypeNumber.imageTile:
          this._resourceTypeStr = AvProdInputType.imageTile;
          break;
        case AvProdInputTypeNumber.videoAudioClip:
          this._resourceTypeStr = AvProdInputType.videoAudioClip;
          break;
        case AvProdInputTypeNumber.videoAudioStream:
          this._resourceTypeStr = AvProdInputType.videoAudioStream;
          break;
        case AvProdInputTypeNumber.videoClip:
          this._resourceTypeStr = AvProdInputType.videoClip;
          break;
        case AvProdInputTypeNumber.videoStream:
          this._resourceTypeStr = AvProdInputType.videoStream;
          break;
        default:
          this._resourceTypeStr = AvProdInputType.none;
          break;
      }
      this.updateInputs();
      this.settingsResource.controls['resourceType']?.setValue(this._resourceType);
      this.updateTypeChangeEnable();
    }
  }
  protected setAllowTypeChange(value: boolean): void {
    this._allowTypeChange = value;
    this.updateTypeChangeEnable();
  }

  protected updateTypeChangeEnable(){
    if (this._allowTypeChange === false){
      this.settingsResource.controls['resourceType']?.disable();
    }
    else {
      this.settingsResource.controls['resourceType']?.enable();
    }
  }

  protected init(): void {
    if (this.componentSubscription !== undefined) this.componentSubscription.unsubscribe();
    this.componentSubscription = this.avProd.components$.subscribe(() => this.updateInputs());
    this.userChangesSubscription?.unsubscribe();
    this.userChangesSubscription = this.events.userChanges$.subscribe(data => this.checkUserChanges(data));
    this.updateAssetList();
    this.events.addPollingClient('InputAddResourceClass');
  }

  protected destroy(): void {
    if (this.componentSubscription !== undefined) this.componentSubscription.unsubscribe();
    this.userChangesSubscription?.unsubscribe();
    this.events.removePollingClient('InputAddResourceClass');
  }

  protected emitOpenSettings(id: number): void {
    //must be overridden
  }

  protected emitOpenUpload(type: AvProdInputTypeNumber) {
    //must be overridden
  }

  protected displayToast(config: IToastConfig): void {
    //must be overridden
  }

  protected displayAlert(data: IAlertData): void {
    //must be overridden
  }

  protected checkUserChanges(changes: IEventUserLastChanges){
    if ((changes.storage === true)||
        (changes.account === true)){
      this.updateAssetList();
    }
  }

  protected updateAssetList(){
    if (this.events.currentEvent?.producerToken !== undefined){
      this.events.getEventAssetList(this.events.currentEvent?.producerToken)
      .pipe()
      .subscribe(answer => {
        if (answer !== null){
          this.assets = answer;
          this.updateAssetFilter();
        }
      });
    }
  }

  protected updateAssetFilter(){
    this.assetsFiltered = this.assets.filter(element =>
        (element.type === this._resourceTypeStr)
    );
  }

  protected updateInputs(): void {
    this.inputList = this.avProd.inputs.filter((element: IAvProdInput) => (element.info.inputTypeNumber === this._resourceType));
    this.updateAssetFilter();
  }

  protected onClickEventResource(id: number): void {
    const INPUT: IAvProdInput | undefined = this.inputList.find((element: IAvProdInput) => element.info.id === id);
    if (INPUT !== undefined) {
      const SETTINGS: IAvProdInputSettings = {
        favorite: !INPUT.info.favorite
      }
      INPUT.info.favorite = !INPUT.info.favorite;
      this.avProd.azzChangeInputSettings(id, SETTINGS);
    }
  }

  protected getImageSrc(input: IAvProdInput): SafeUrl {
    return 'https://' + this.avProd.getHostUrl() + '/' + input.info.url;
  }

  protected getImageSrcArchiver(input: IEventAsset): SafeUrl {
    let imgUrl: SafeUrl = '/assets/shared/img/asset-document-thumbnail.png';
    if ((this.userService.anonymousUser === false)&&
    (this.userService.user.id !== undefined)&&
    (input.id !== undefined)){
      switch(input.type){
        case AvProdInputType.audioClip:
          imgUrl = '/assets/shared/img/asset-sound-thumbnail.png';
          break;
        case AvProdInputType.document:
          imgUrl = '/assets/shared/img/asset-document-thumbnail.png';
          break;
        default:
          if (ENV_COMMON.production === true){
            imgUrl = 'https://thumbnails.azzulei.tv/' + this.userService.user.id + '/' + input.id + '?token=' + this.authService.accessToken;
          }
          else{
            imgUrl = 'https://dev-thumbnails.azzulei.tv/' + this.userService.user.id + '/' + input.id + '?token=' + this.authService.accessToken;
          }
          break;
      }
    }
    return imgUrl;
  }

  protected onSettingChanged(setting: IAvSettingsItemConfig): void {
    if (setting.id === 'resourceType') {
      this.setResourceType(this.settingsResource.controls['resourceType']?.value);
    }
  }

  protected onDeleteInput(id: number): void {
    this.displayAlert({
      closeButton: true,
      show: true,
      status: AlertStatus.question,
      title: 'general.confirmation',
      text: 'inputSettings.removeConfirmation',
      buttons: [{
        text: 'general.cancel',
        color: 'primary',
        fill: 'outline',
        closeButton: true
      },
        {
          text: 'general.confirm',
          color: 'danger',
          closeButton: true,
          handler: (): void => {
            this.avProd.azzCmdInputDelete(id);
            // Display success message
            this.displayToast({
              options: {
                autohide: true,
                placement: ToastPlacement.bottomRight
              },
              data: {
                closeButtonHeader: true,
                status: ToastStatus.success,
                title: 'general.done',
                text: 'inputSettings.removeSuccess',
                alignText: 'text-center',
                buttons: []
              },
            });
          }
        }]
    });
  }

  protected onUpload(): void {
    this.emitOpenUpload(this._resourceType);
  }

  protected downloadAsset(asset: IEventAsset){
    this.downloadBusy = true;
    timer(4000).subscribe(() => this.downloadBusy = false);
    this.avProd.azzCmdServerAssetDownload(asset.id, asset.type);
    // Display success message
    this.displayToast({
      options: {
        autohide: true,
        placement: ToastPlacement.bottomRight
      },
      data: {
        closeButtonHeader: true,
        status: ToastStatus.success,
        title: 'general.done',
        text: 'inputAdd.downloadInProgress',
        alignText: 'text-center',
        buttons: []
      },
    });
  }

  protected getAssetInEvent(asset: IEventAsset): boolean{
    let ret: boolean = false;
    const INPUT: IAvProdInput | undefined = this.inputList.find(element => element.info.uuid === asset.id);
    if (INPUT !== undefined){
      ret = true;
    }
    return ret;
  }

  protected getAssetIcon(asset: IEventAsset): string{
    let ret: string = 'image'
    switch (asset.type) {
      case AvProdInputType.audioClip:
        ret = 'soundwave';
        break;
      case AvProdInputType.videoAudioClip:
      case AvProdInputType.videoClip:
        ret = 'play-btn';
        break;
      case AvProdInputType.imageTile:
        ret = 'image';
        break;
      case AvProdInputType.imageOverlay:
        ret = 'image-alt';
        break;
    }
    return ret;
  }
}
