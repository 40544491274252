import { ENV_COMMON } from '@azz-life-streamer/environments';
import { IEvent } from "../interfaces/events/event.interface";
import { IToastConfig } from "../interfaces/utils/utils.interface";


export class EventEmbedClass {
  protected _event: IEvent = {
    name: 'Name',
    userName: '',
    description: '',
    categoryId: 1,
    protected: false,
    private: false,
  };

  protected watchEmbeddedCode: string = '';

  constructor() { }

  protected onClickCopy(): void {
    this.copyToClipboard(this.watchEmbeddedCode);
  }

  protected displayToast(config: IToastConfig): void {
    // To be overridden
  }

  protected copyToClipboard(text: string): void {
    // To be overridden
  }

  protected setEventData(event: IEvent): void {
    if (event !== null) {
      this._event = event;
      const URL: string = ENV_COMMON.scheme + ENV_COMMON.domain;
      this.watchEmbeddedCode = this.watchEmbeddedCode =
        '<iframe width="560" height="315"\n' +
        '  src="' + URL + '/embed/' + event.viewerToken +
        '" title="Azzulei TV player"\n' +
        '  allow="accelerometer; autoplay; clipboard-write; encrypted-media;\n' +
        '   gyroscope; picture-in-picture; web-share"\n' +
        '  allowfullscreen>\n' +
        '</iframe>';
    }
  }
}
