import { Injectable } from '@angular/core';
import { App, AppInfo, URLOpenListenerEvent } from '@capacitor/app';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { DeviceService } from '@azz-life-streamer/shared';


@Injectable({
  providedIn: 'root'
})
export class AppService {

  stateChange$: Observable<boolean>;
  backButtonPress$: Observable<boolean>;
  urlOpen$: Observable<string>;
  appInfo: AppInfo | undefined = undefined;


  wasPublishing: boolean = false;
  cameraAccess: boolean = false;

  private _stateChange: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private _backButtonPress: Subject<boolean> = new Subject<boolean>();
  private _urlOpen: Subject<string> = new Subject<string>();

  constructor(private deviceService: DeviceService) {
    this.urlOpen$ = this._urlOpen.asObservable();
    this.stateChange$ = this._stateChange.asObservable();
    this.backButtonPress$ = this._backButtonPress.asObservable();
    this.initListeners();



    if(this.deviceService.device?.platform !== 'web') {
      App.getInfo()
        .then((info: AppInfo) => {
          this.appInfo = info;
        })
        .catch(console.error);
    }
  }

  ngOnDestroy(): void {
    App.removeAllListeners()
      .catch(console.error)
  }

  private initListeners(): void {

    // Control going to and coming from background
    App.addListener('appStateChange', ({ isActive }) => {
      // console.warn('[AppService] appStateChange - ' + isActive);
      this._stateChange.next(isActive);
    });

    // Deep links
    if(this.deviceService.device?.platform !== 'web') {
      App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
        // console.warn('[AppService] appUrlOpen - ' + JSON.stringify(event.url));
        this._urlOpen.next(event.url);
      });

    }

    // android hardware back button
    if(this.deviceService.device?.platform === 'android') {
      App.addListener('backButton', () => {
        // console.warn('[AppService] backButton');
        this._backButtonPress.next(true);
      });
    }
  }
}
