export interface ISegment {
  first: number;
  sequence: number;
  duration: number;
  start: number;
  id: string;
  end?: number;
  show_ad?: boolean;
  now: number;
  intervals: IIntervals[];
}

export interface IIntervals {
  start: number;
  first: number;
  end: number;
  duration: number;
  sequence: number;
}
