import { SafeUrl } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

import {
  AvProdDeviceType,
  AvProdInputTypeNumber,
  AvProducerService, IAvProdComposerSettings,
  IAvProdInput,
  IAvProdInputSettings,
  IAvProdInterfaceStatusClient,
  IAvProdVideoLayout,
  ModalDialogId,
  ModalManagerService,
  PublishStreamService
} from '@azz-life-streamer/shared';
import { ILiveInputBatteryUI } from '../../interfaces/input/input-item.interface';

export class InputItemClass {
  protected typeInput: AvProdDeviceType = AvProdDeviceType.input;
  protected baseUrl: string = '';
  protected input: IAvProdInput | undefined;
  protected inputSelected: boolean = false;
  protected audioIcon: string = '';
  protected batteryInfo: ILiveInputBatteryUI = {
    available: false,
    icon: '',
    color: '',
    level: 0,
    charging: false,
  };
  protected availableRemove: boolean = false;
  protected availableConfigure: boolean = false;
  protected highlightName: string = '';

  protected inputSettingsSubscription: Subscription | undefined;
  protected inputStatusSubscription: Subscription | undefined;
  protected composerSubscription: Subscription | undefined;
  protected liveInputStatusSubscription: Subscription | undefined;

  protected avProdInputTypeNumber = AvProdInputTypeNumber;

  protected _inputId: number = -1;
  protected _broadcastSlotId: string = '';

  constructor(protected avProd: AvProducerService,
              protected publishService: PublishStreamService,
              protected modalService: ModalManagerService) { }

  protected setInputId(id: number): void {
    this._inputId = id;
    this.init();
  }

  protected setBroadcastSlotId(id: string): void {
    this._broadcastSlotId = id;
    this.init();
  }

  protected emitInputSelect(): void {
    // must override
  }

  protected updateBatteryIcon(): void {
    // must override
  }

  protected updateBatteryColor(): void {
    // must override
  }

  protected init(): void {
    if (this.baseUrl === '') {
      this.baseUrl = this.avProd.getHostUrl();
    }

    if (this._broadcastSlotId !== '') {
    }
    if (this._inputId !== -1) {
      this.avProd.azzRequestInputSettings(this._inputId);
    }

    this.updateInput();
    this.updateSelection();

    if (this.composerSubscription !== undefined) {
      this.composerSubscription.unsubscribe();
    }
    this.composerSubscription = this.avProd.onNewComposerSettings$.subscribe(
      (settings: IAvProdComposerSettings) => {
        this.updateSelection();
      }
    );
    if (this.inputSettingsSubscription !== undefined) {
      this.inputSettingsSubscription.unsubscribe();
    }
    this.inputSettingsSubscription = this.avProd.onNewInputSettings$.subscribe(
      (id) => {
        if (id === this._inputId) this.updateInput();
      }
    );
    if (this.inputStatusSubscription !== undefined) {
      this.inputStatusSubscription.unsubscribe();
    }
    this.inputStatusSubscription = this.avProd.onNewInputStatus$.subscribe(
      (id) => {
        if (id === this._inputId) this.updateInput();
      }
    );
  }

  protected destroy(): void {
    if (this.composerSubscription !== undefined) {
      this.composerSubscription.unsubscribe();
    }
    if (this.inputSettingsSubscription !== undefined) {
      this.inputSettingsSubscription.unsubscribe();
    }
    if (this.inputStatusSubscription !== undefined) {
      this.inputStatusSubscription.unsubscribe();
    }
    if (this.liveInputStatusSubscription !== undefined) {
      this.liveInputStatusSubscription.unsubscribe();
    }
  }

  protected updateSelection(): void {
    let selected: boolean = false;
    let tileCount: number = 0;
    let currentLayout: IAvProdVideoLayout | undefined =
      this.avProd.layoutManager.videoLayouts.find(
        (element) => element.id === this.avProd.composerSettings.layoutId
      );
    //console.log('[InputItem] updateSelection ' + JSON.stringify(this.input));

    if (currentLayout !== undefined &&
        this.avProd.composerSettings.videoSelection !== undefined &&
        this._inputId !== -1) {
      tileCount = currentLayout.tiles.length;
      for (let i = 0; i < tileCount; i++) {
        if (this.avProd.composerSettings.videoSelection[i] === this._inputId) {
          selected = true;
          break;
        }
      }
    }
    this.inputSelected = selected;
  }

  protected updateInputInterface(): void {
    let clientStatus: IAvProdInterfaceStatusClient | undefined = undefined;
    console.log('[InputItemClass] updateInputInterface');
    for (let i: number = 0; i < this.avProd.interfaceStatus.clients.length; i++) {
      for (let j: number = 0; j < this.avProd.interfaceStatus.clients[i].publish.length; j++ ) {
        if (this.avProd.interfaceStatus.clients[i].publish[j].streamId === this._inputId + 1) {
          clientStatus = this.avProd.interfaceStatus.clients[i];
        }
      }
    }
    if (clientStatus !== undefined) {
      this.batteryInfo.available = true;
      this.batteryInfo.level = clientStatus.status.batteryLevel;
      this.batteryInfo.charging = clientStatus.status.batteryCharging;
      this.updateBatteryIcon();
      this.updateBatteryColor();
    } else {
      this.batteryInfo.available = false;
    }
  }

  protected updateInput(): void {
    if (this._inputId !== -1) {
      this.input = this.avProd.inputs.find(
        (element: IAvProdInput) => element.info.id === this._inputId
      );
      //console.log('[InputItem] updateInput ' + JSON.stringify(this.input));
      // Update Remove available flag
      this.availableRemove = this.input?.info.inputTypeNumber !==
        AvProdInputTypeNumber.videoAudioStream ||
        this._broadcastSlotId !== '';

      this.availableConfigure = this._broadcastSlotId !== '';

      // Update name for Highlights
      if (
        this.input?.info.inputTypeNumber === AvProdInputTypeNumber.highlight
      ) {
        let nameArray: string[] = this.input?.info.name.split('-');
        if (nameArray.length === 2 && nameArray[0].substring(0, 3) === 'Tag') {
          this.highlightName = nameArray[1];
        } else {
          this.highlightName = this.input?.info.name;
        }
      }

      // Update audio icon
      if (this.input?.info.inputTypeNumber === AvProdInputTypeNumber.videoAudioClip ||
          this.input?.info.inputTypeNumber === AvProdInputTypeNumber.audioClip ||
          this.input?.info.inputTypeNumber === AvProdInputTypeNumber.highlight ||
          this.input?.info.inputTypeNumber === AvProdInputTypeNumber.videoAudioStream) {
        if (this.input?.settings?.audio?.mode === 3) {
          // Solo when selected
          this.audioIcon = 'bi bi-volume-up-fill';
        } else if (this.input?.settings?.audio?.mode === 2) {
          // On when selected
          this.audioIcon = 'bi bi-volume-up-fill';
        } else if (this.input?.settings?.audio?.mode === 1) {
          this.audioIcon = 'bi bi-volume-up';
        } else {
          this.audioIcon = 'bi bi-volume-mute';
        }
      } else {
        this.audioIcon = '';
      }

      // Subscribe to interface status
      this.batteryInfo = {
        available: false,
        icon: '',
        color: '',
        level: 0,
        charging: false,
      };
      if (this.liveInputStatusSubscription !== undefined) {
        this.liveInputStatusSubscription.unsubscribe();
      }
      if (this.input?.info.inputTypeNumber === AvProdInputTypeNumber.videoAudioStream) {
        this.liveInputStatusSubscription =
          this.avProd.onNewInterfaceStatus$.subscribe(() => {
            this.updateInputInterface();
          });
      }
    } else {
      this.input = undefined;
    }
  }

  protected getImageSrc(): SafeUrl {
    let ret: SafeUrl = '';
    if (this.input !== undefined) {
      if (this.input.info.inputTypeNumber !== AvProdInputTypeNumber.videoAudioStream && this.baseUrl !== undefined) {
        if (this.input.status?.url !== undefined) {
          ret = 'https://' + this.baseUrl + '/' + this.input.status.url;
        } else {
          ret = 'https://' + this.baseUrl + '/' + this.input.info.url;
        }
      } else ret = 'assets/images/events/azzulei.png';
    }
    return ret;
  }

  protected onInputSelect(): void {
    this.emitInputSelect();
  }

  protected onSettings(): void {
    this.modalService.show(
      {
        id: ModalDialogId.producerInputSettings,
        subId: this._inputId,
        title: 'producer.inputSettings',
      },
      true
    );
  }

  protected onRemove(): void {
    // Toggle favorite flag for resource inputs
    if (this.input !== undefined) {
      if (this.input?.info.inputTypeNumber !== AvProdInputTypeNumber.videoAudioStream) {
        const SETTINGS: IAvProdInputSettings = {
          favorite: !this.input.info.favorite,
        };
        this.input.info.favorite = !this.input.info.favorite;
        this.avProd.azzChangeInputSettings(this.input.info.id, SETTINGS);
      }
    }
    // Remove broadcast slot for local input cameras and screen share
    if (this._broadcastSlotId !== '') {
      this.publishService.setSlotActive(this._broadcastSlotId, false);
    }
  }

  protected onConfigure(): void {
    if (this._broadcastSlotId !== '') {
      if (this._broadcastSlotId === 'ProducerBroadcastScreen1') {
        this.modalService.show(
          {
            id: ModalDialogId.producerInputAddScreen,
            subId: 0,
            size: 'lg',
            title: 'inputAdd.localScreenShare',
          },
          false
        );
      } else {
        this.modalService.show(
          {
            id: ModalDialogId.producerInputAddLocal,
            subId: 0,
            size: 'lg',
            title: 'inputAdd.localLiveInput',
          },
          false
        );
      }
    }
  }

  protected onDocPrev(): void {
    this.avProd.azzCmdInputClipFramePrevious(this._inputId);
  }

  protected onDocNext(): void {
    console.log('[InputItemClass] onDocNext');
    this.avProd.azzCmdInputClipFrameNext(this._inputId);
  }

  protected onTileFull(): void {
    const SELECTION: number[] = [this._inputId];
    // Take for granted layout 1 is always full screen
    this.avProd.azzChangeVideoLayoutSettings(1, SELECTION);
  }

  protected onAudioToggle(event: MouseEvent): void {
    if (this.input !== undefined) {
      const SETTINGS: IAvProdInputSettings = {};
      if (this.input?.settings?.audio?.mode !== undefined) {
        SETTINGS.audio = {};
        if (this.input?.info.inputTypeNumber === AvProdInputTypeNumber.videoAudioStream) {
          if (this.input?.settings?.audio?.mode === 0) {
            SETTINGS.audio.mode = 1;
          } else if (this.input?.settings?.audio?.mode === 1) {
            SETTINGS.audio.mode = 2;
          } else {
            SETTINGS.audio.mode = 0;
          }
          this.avProd.azzChangeInputSettings(this._inputId, SETTINGS);
        } else if (this.input?.info.inputTypeNumber === AvProdInputTypeNumber.videoAudioClip ||
                  this.input?.info.inputTypeNumber === AvProdInputTypeNumber.audioClip ||
                  this.input?.info.inputTypeNumber === AvProdInputTypeNumber.highlight) {
          if (this.input?.settings?.audio?.mode === 0) {
            SETTINGS.audio.mode = 1;
          } else if (this.input?.settings?.audio?.mode === 1) {
            SETTINGS.audio.mode = 3;
          } else {
            SETTINGS.audio.mode = 0;
          }
          this.avProd.azzChangeInputSettings(this._inputId, SETTINGS);
        }
      }
    }
    event.stopPropagation();
  }
}
