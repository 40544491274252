import { AvProdInputTypeNumber, AvProdOverlayType } from '../../const/av-producer.const';

export interface IAvProdComponentInput {
    id: number;
    name: string;
    favorite: boolean;
    inputType: string;
    inputTypeNumber: AvProdInputTypeNumber;
    file: string;
    url: string;
    highlightTagId: number;
    uuid?: number;
}

export interface IAvProdComponentComposer {
    id: number;
}

export interface IAvProdComponentOutput {
    id: number;
}

export interface IAvProdComponentInterface {
    id: number;
}

export interface IAvProdComponentOverlay {
    id: number;
    name: string;
    overlayType: AvProdOverlayType;
    favorite: boolean;
}

export interface IAvProdComponents {
    inputs: IAvProdComponentInput[];
    composers: IAvProdComponentComposer[];
    outputs: IAvProdComponentOutput[];
    overlays: IAvProdComponentOverlay[];
    interfaces: IAvProdComponentInterface[];
}
