<form (ngSubmit)="login()"
      #fLogin="ngForm"
      class="needs-validation"
      [ngClass]="{'was-validated': fLogin.touched}"
      novalidate>
  <h1>{{ "users.loginTitle" | translate }}</h1>
  <div class="form-floating mb-3">
    <input type="email"
           class="form-control"
           id="emailLogin"
           name="email"
           inputmode="email"
           maxlength="64"
           pattern="[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,4}"
           [(ngModel)]="loginUser.email"
           placeholder="{{ 'users.enterEmail' | translate }}"
           required>
    <label for="emailLogin">{{ "users.emailAddress" | translate }}</label>
    <div class="invalid-tooltip">
      {{ "users.invalidEmail" | translate }}
    </div>

  </div>
  <div class="form-floating mb-3">
    <input [type]="passType[0]"
           id="passLogin"
           name="password"
           class="form-control pass-control"
           placeholder="Password"
           [(ngModel)]="loginUser.password"
           required>
    <label for="passLogin">{{ "users.password" | translate }}</label>
    <span class="hide-show-pass" (click)="togglePasswordInputType(0)">
             <i class="bi" [ngClass]="passIcon[0]"></i>
            </span>
  </div>

  <div class="d-grid gap-4">
    <button type="submit" class="btn btn-info mt-2" [disabled]="sendButtonDisabled || fLogin.invalid">
      <span class="spinner-border spinner-border-sm"
            [ngClass]="{'d-none': !sendButtonDisabled}"
            role="button"
            aria-hidden="true"></span>
      <span [ngClass]="{'d-none': sendButtonDisabled}">{{ "general.login" | translate }}</span>
    </button>
  </div>

  <div class="d-flex justify-content-end my-1">
    <button type="button"
            class="btn btn-link"
            (click)="layoutService.showUserModal('forgotPassword')">{{ "users.forgotPassword" | translate }}</button>
  </div>


  <div class="d-flex align-items-baseline change-tab" (click)="layoutService.showUserModal('signUp')">
    <p><b>{{ "users.notMemberYet" | translate }}</b></p>
    <button type="button"
            class="btn btn-link">{{ "users.createAccount" | translate }}</button>
  </div>

</form>
