<div #panel
     class="offcanvas offcanvas-end"
     tabindex="-1"
     id="userPanel"
     aria-labelledby="userPanelLabel">

  <div class="offcanvas-header">
    <button *ngIf="!editingProfile"
            type="button"
            class="d-flex gap-2 align-items-center"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            #panelClose>
      <i class="bi bi-arrow-right"></i>
      <span>{{ "general.close" | translate}}</span>
    </button>

    <button *ngIf="editingProfile"
            type="button"
            class="d-flex gap-2 align-items-center"
            (click)="editingProfile=false">
      <i class="bi bi-arrow-left"></i> <span>{{ "general.back" | translate}}</span>
    </button>
  </div>

  <div class="offcanvas-body text-center  d-flex flex-column">
    <ng-container *ngIf="userService.user">
      <a [href]="COMMON.urls.managerAccount" class="title-link">
        <h2 class="mt-5" id="userPanelLabel">{{ userService.user.name }}</h2>
      </a>
      <p>{{ userService.user.email }}</p>
    </ng-container>

    <ul class="mt-5" *ngIf="!editingProfile">

      <li class="cursor-pointer" (click)="goToAccount()"><!--(click)="editProfile()"-->
        <i class="bi bi-person"></i>
        <h3>{{ 'users.editProfile' | translate }}</h3>
        <!--<i class="bi bi-chevron-right ms-auto"></i>-->
      </li>

      <li>
        <i class="bi bi-globe2"></i>
        <h3>{{ 'general.language' | translate }}</h3>
        <web-change-language [color]="'light'" class="ms-auto"></web-change-language>
      </li>

      <li *ngIf="true">
        <i class="bi bi-circle-half"></i>
        <h3>{{ 'users.theme' | translate }}</h3>
        <web-toggle-theme class="ms-auto"></web-toggle-theme>
      </li>

      <a *ngIf="false" [routerLink]="[urlService.curLangUrl, COMMON.urls.legal, COMMON.urls.privacy]">
        <li data-bs-dismiss="offcanvas"
            aria-label="Close">
          <i class="bi bi-lock"></i>
          <h3>{{ 'legal.privacyTitle' | translate }}</h3>
        </li>
      </a>

      <a *ngIf="false" [routerLink]="[urlService.curLangUrl, COMMON.urls.legal, COMMON.urls.terms]">
        <li class="cursor-pointer"
            data-bs-dismiss="offcanvas"
            aria-label="Close">
          <i class="bi bi-shield-check"></i>
          <h3>{{ 'legal.legalTermsTitle' | translate }}</h3>
        </li>
      </a>

      <li class="cursor-pointer" (click)="onClickLogout()">
        <i class="bi bi-box-arrow-right"></i>
        <h3>{{ 'users.logout' | translate }}</h3>
      </li>

      <li class="cursor-pointer" *ngIf="false" (click)="deleteAccount()">
        <i class="bi bi-trash3"></i>
        <h3>{{ 'users.deleteAccount' | translate }}</h3>
      </li>

    </ul>

    <!--<web-edit-profile *ngIf="editingProfile" [layoutMode]="'panel'"></web-edit-profile>-->

    <small class="mt-auto"> &copy; Azzulei Technologies - v.{{ VERSION_NAME }}</small>

  </div>
</div>
