import { Subscription } from 'rxjs';
import { IOrganizationInfo, IUserOrganizationInfo } from '../interfaces/user/user.interface';
import { UserService } from '../services/user/user.service';
import { EventsService } from '../services/events/events.service';

export class OrganizationBannerClass {

    //protected orgList: IUserOrganizationInfo[] = [];
    protected _orgInfo: IOrganizationInfo | undefined;
    protected _orgId: number = 0;
    protected userSubscription: Subscription | undefined;
    protected userOrgSubscription: Subscription | undefined;
    protected bannerImageSrc: string | undefined;
    protected profileImageSrc: string | undefined;
    protected _layoutMode: string = ''; //'manager' | 'dashboard' | 'player' | 'organization'
    protected _eventOrgId: number = 0;

    constructor (protected userService: UserService,
                 protected events: EventsService
    ) {}

    protected init(): void {
        //this.updateUserOrganizationList();
        if ((this._layoutMode !== 'player')&&(this._layoutMode !== 'organization')){
          if ((this._orgInfo === undefined)&&(this.userService.organization !== undefined)){
            this._orgInfo = this.userService.organization;
          }
        }
        this.updateImages();
        this.userSubscription?.unsubscribe();
        this.userSubscription = this.userService.user$.subscribe(() => this.updateOrganization());
        this.userOrgSubscription?.unsubscribe();
        this.userOrgSubscription = this.userService.orgId$.subscribe(() => this.updateOrganization());
      }

    protected close(): void {
      this.userOrgSubscription?.unsubscribe();
      this.userSubscription?.unsubscribe();
    }

    protected updateOrganization(): void {
      if (this._orgId > 0){

        if (this._orgId == this.userService.orgId){
          console.log('[OrganizationBannerClass] - updateOrganization - Same as user Org:' + this._orgId);
          this._orgInfo = this.userService.organization;
          this.updateImages();
        }
        else{
          console.log('[OrganizationBannerClass] - updateOrganization - Id:' + this._orgId);
          this.events.getOrganizationInfoById(this._orgId)
            .pipe()
            .subscribe((org: IOrganizationInfo | null) => {
              if (org != null) {
                this._orgInfo = org;
                this.updateImages();
              }
              else{
                // Organization is not available
              }
          });
        }
      }
    }

    /*
    protected updateUserOrganizationList(): void {
        console.log('[OrganizationBannerClass] updateUserOrganizationList - orgId: ' + this._orgId);
        console.log('[OrganizationBannerClass] updateUserOrganizationList' + JSON.stringify(this.userService.user));
        if (this.userService.user.organizations !== undefined){
          this.orgList = this.userService.user.organizations;
        }
        else{
          this.orgList = [];
        }
        this.updateImages();
    }*/

    protected updateImages(): void {
      if ((this._orgInfo?.profilePhoto !== undefined) &&
          (this._orgInfo?.profilePhoto !== '')){
        this.profileImageSrc = 'url("' + this._orgInfo.profilePhoto + '")';
      }
      else{
        this.profileImageSrc = 'url("/assets/web/img/organizations/default-logo.png")';
      }

      if ((this._orgInfo?.backgroundPhoto !== undefined) &&
          (this._orgInfo?.backgroundPhoto !== '')){
        this.bannerImageSrc = 'url("' + this._orgInfo.backgroundPhoto + '")';
      }
      else{
        this.bannerImageSrc = 'url("/assets/web/img/organizations/default-banner.png")';
      }
      
      console.log('[OrganizationBannerClass] - updateImages - Banner: ' + this.bannerImageSrc + ', Profile: ' + this.profileImageSrc);
    }

    protected onClickProfile(): void {
      //TO BE OVERRIDE
    }
}
