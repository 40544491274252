import { Component, Input, OnInit } from '@angular/core';

import { COMMON } from '@azz-life-streamer/shared';
import { LayoutService } from '../../../core/services/layout/layout.service';
import { UrlService } from '../../../core/services/url/url.service';
import { MetaCheckerService } from '../../../core/services/meta-checker/meta-checker.service';


@Component({
  selector: 'web-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent implements OnInit {

  @Input('lang') langParam: string = '';

  protected readonly COMMON: any = COMMON;

  constructor(private layoutService: LayoutService,
              private urlService: UrlService,
              private metaService: MetaCheckerService) {
    this.layoutService.setTopBar({
      mode: 'clear',
      fluid: false,
      borderBottom: false,
      scrollValue: 0,
    });
    this.layoutService.showFooter = true;
    this.metaService.addNoIndex();
  }

  ngOnInit(): void {
    this.urlService.checkUrlLangParam(
      this.langParam ?? COMMON.defaultLanguage.iso
    );
  }


}
