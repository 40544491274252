
export enum InfoScreenMediaType {
    none = 0,
    image = 1,
    video = 2
}

export enum EventStatusLayoutMode {
    list = 1,
    creation = 2,
    dashboard = 3
}

export enum ModalDialogId {
    none = 0,
    producerInputAdd = 1,
    producerInputAddLive = 2,
    producerInputAddLocal = 3,
    producerInputAddScreen = 4,
    producerInputAddResource = 5,
    producerInputAddResourceUpload = 6,
    producerInputSettings = 7,
    producerSettings = 8,
    publisherBroadcasterSettings = 9,
    managerResourceUpload = 10,
    managerResourceSettings = 11,
    managerEventCreate = 12,
    destinationCreate = 13,
    destinationEdit = 14,
    playerReportContent = 15,
    producerOutputConfirmation = 16,
    managerEventTutorial = 17,
    assetEdit = 18,
    eventEmbed = 19,
    userPlan = 20,
    payCheckout = 21,
    payConfirmation = 22,
    managerResourceProfileUpload = 23,
}

export enum SubscriptionTypes {
    ibm = 0,
    iby = 1,
    ism = 2,
    isy = 3,
    igm = 4,
    igy = 5,
    obm = 6,
    oby = 7,
    osm = 8,
    osy = 9,
    ogm = 10,
    ogy = 11
}

export enum ErrorSeverity {
  fatal = 0,
  critical = 1,
  error = 2,
  warning = 3
}
