import { AvProdInputTypeNumber } from '../../const/av-producer.const';
import { ModalDialogId } from '../../enums/utils.enum';
import { AvProducerService } from '../../services/av-producer/av-producer.service';
import { ModalManagerService } from '../../services/modal/modal-manager.service';

export class ToolboxResourcesClass {
    protected addInputResourceType: AvProdInputTypeNumber = AvProdInputTypeNumber.imageTile;
    protected modalDialogId = ModalDialogId;

    constructor(protected avProd: AvProducerService,
                protected modalService: ModalManagerService) { }

    init(): void {
    }

    close(): void {
    }

    protected onInputSettings(inputId: number): void {
        console.log('[ProducerPageClass] onInputSettings: ' + inputId);
        this.modalService.show(
          {
            id: ModalDialogId.producerInputSettings,
            subId: inputId,
            title: 'producer.inputSettings'
          },
          true
        )
    }
    protected openUpload(type: AvProdInputTypeNumber): void {
        this.addInputResourceType = type;
        if ((this.addInputResourceType === AvProdInputTypeNumber.videoAudioClip) ||
          (this.addInputResourceType === AvProdInputTypeNumber.imageTile) ||
          (this.addInputResourceType === AvProdInputTypeNumber.imageOverlay) ||
          (this.addInputResourceType === AvProdInputTypeNumber.document)) {
          this.modalService.show(
            {
              id: ModalDialogId.producerInputAddResourceUpload,
              subId: this.addInputResourceType,
              title: 'inputAdd.resourceUpload',
            },
            true
          );
        }
    }
}
