import { Subscription } from 'rxjs';

import { AlertService, AlertStatus, EventsService, IEventUserLastChanges, IModalDialogData, IToastConfig, ModalDialogId, ModalManagerService, ToastPlacement, ToastStatus, UserService } from '@azz-life-streamer/shared';
import { inject } from '@angular/core';


export class ManagerPageClass {

  protected modalDialogId = ModalDialogId;
  protected modalDataCurrent: IModalDialogData = {id: ModalDialogId.none, title: ''};
  protected modalServiceSubscription: Subscription | undefined;

  protected userChangesSubscription: Subscription | undefined;

  private eventsService: EventsService = inject(EventsService);

  constructor(protected modalService: ModalManagerService,
              protected userService: UserService,
              protected alertService: AlertService) { }

  protected init(): void {
    this.modalServiceSubscription?.unsubscribe();
    this.modalServiceSubscription = this.modalService.modalManagerEvent$.subscribe((value: IModalDialogData) => {
      this.onModalNewData(value);
    });
    this.checkUserOrgInvitations();
    this.userChangesSubscription?.unsubscribe();
    this.userChangesSubscription = this.eventsService.userChanges$.subscribe(data => this.checkUserChanges(data));
  }

  protected destroy(): void {
    this.userChangesSubscription?.unsubscribe();
    this.modalServiceSubscription?.unsubscribe();
  }

  protected checkUserChanges(changes: IEventUserLastChanges){
    if (changes.organizations === true){
      this.userService.getUserData().subscribe(() => {
        console.log('[ManagerPageClass] checkUserChanges / checkUserOrgInvitations');
        this.checkUserOrgInvitations();
      });
    }
  }

  protected onModalNewData(data: IModalDialogData): void {
    this.modalDataCurrent = data;
    // console.warn('[ManagerComponent] onModalNewData ' + JSON.stringify(data));
    switch (data.id) {
      case ModalDialogId.none:
        this.hideModal();
        break;
      default:
        this.showModal();
        break;
    }
  }

  protected hideModal(): void {
   // Must override
  }

  protected showModal(): void {
    // Must override
  }

  protected displayToast(config: IToastConfig): void {
    // Must override
  }

  protected closeModalCurrent(): void {
    this.modalService.closeCurrent();
  }

  protected checkUserOrgInvitations(): void {
    if ((this.userService.anonymousUser === false)&&
        (this.userService.user?.organizations !== undefined)){

      for (let i=0; i<this.userService.user.organizations.length; i++){
        if ((this.userService.user.organizations[i].status?.toLowerCase() === 'invited')){
          let orgId: number = this.userService.user.organizations[i].id;
          console.log('[ManagerPageClass] checkUserOrgInvitations: ' + orgId);
          this.alertService.showAlert({
            closeButton: true,
            show: true,
            status: AlertStatus.question,
            title: this.userService.user.organizations[i].name,
            text: 'users.orgInvitationConfirm',
            buttons: [{
              text: 'general.decline',
              color: 'danger',
              fill: 'outline',
              closeButton: true,
              handler: (): void => {
                this.userService.declineOrganizationInvitation(orgId).subscribe((response) => {
                  if (response === true){
                    console.log('[ManagerPageClass] checkUserOrgInvitations: Invitation declined');
                    this.userService.getUserData().subscribe(() => this.checkUserOrgInvitations());
                    this.displayToast({
                      options: {
                        placement: ToastPlacement.topCenter,
                        autohide: true
                      },
                      data: {
                        status: ToastStatus.success,
                        text: 'organizations.toastDecline',
                        closeButtonBody: true,
                        alignText: 'text-center',
                      },
                    });
                  }
                  else{
                    // To do: Mostrar error
                  }
                });
              }
            },
            {
              text: 'general.accept',
              color: 'primary',
              closeButton: true,
              handler: (): void => {
                this.userService.acceptOrganizationInvitation(orgId).subscribe((response) => {
                  if (response === true){
                    console.log('[ManagerPageClass] checkUserOrgInvitations: Invitation accepted');
                    this.displayToast({
                      options: {
                        placement: ToastPlacement.topCenter,
                        autohide: true
                      },
                      data: {
                        status: ToastStatus.success,
                        text: 'organizations.toastAccept',
                        closeButtonBody: true,
                        alignText: 'text-center',
                      },
                    });
                    this.userService.getUserData().subscribe(() => this.checkUserOrgInvitations());
                  }
                  else{
                    // To do: mostrar error
                  }
                });
              }
            }]
          });
        }
      }
    }
  }
}
