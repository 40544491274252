import { Injectable, OnDestroy } from '@angular/core';
import { interval, BehaviorSubject, Observable, Subscription } from 'rxjs';
import { AV_PROD_FRAME_ID_OUPUT_OFFSET } from '../../const/av-producer.const';

@Injectable({
  providedIn: 'root'
})
export class AudioService implements OnDestroy {

  //protected enabled: boolean = false;
  private context: AudioContext | undefined = undefined;
  private gainNode: GainNode | undefined = undefined;
  private stack: AudioBuffer[] = [];
  private nextTime: number = 0;
  private countLatePackages: number = 0;
  private timerSubscription: Subscription | undefined;
  private selectedChannel: number = AV_PROD_FRAME_ID_OUPUT_OFFSET; // Selected audio channel to play local (out, in1, in2, in3, ...)
  private enabledSource: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public enabledChange$: Observable<boolean> = new Observable();

  constructor() {
    this.enabledChange$ = this.enabledSource.asObservable();
  }

  ngOnDestroy(): void {
    console.log('[AudioService] Destroy');
    if (this.timerSubscription !== undefined) this.timerSubscription.unsubscribe();
    this.disableAudio();
  }

  /**
   * Receives an event with raw audio frame message
   * @param msg Received message with header fields and data
   */
   public receiveAudioFrame(dataBytes: ArrayBuffer) {
    const DATA: any = dataBytes;
    const NOW: number = Date.now();
    // Check size is correct
    const AUDIO_BUFFER_L = new Float32Array(dataBytes.byteLength / 4);
    const AUDIO_BUFFER_R = new Float32Array(dataBytes.byteLength / 4);

    for (let i:number = 0; i < dataBytes.byteLength; i += 4)
    {
      const VAL_L = DATA[i] * 0x100 + DATA[i+1];
      AUDIO_BUFFER_L[i / 4] = (VAL_L < 0x7FFF) ? VAL_L :  VAL_L - 0x10000;

      const VAL_R = DATA[i+2] * 0x100 + DATA[i+3];
      AUDIO_BUFFER_R[i / 4] = (VAL_R < 0x7FFF) ? VAL_R :  VAL_R - 0x10000;

      AUDIO_BUFFER_L[i / 4] /= 0x8000;
      AUDIO_BUFFER_R[i / 4] /= 0x8000;
    }

    if ((this.enabledSource.value === true)&&(this.context !== undefined)) {
      const AB2 = this.context.createBuffer(2,(dataBytes.byteLength / 4),48000);
      AB2.getChannelData(0).set(AUDIO_BUFFER_L);
      AB2.getChannelData(1).set(AUDIO_BUFFER_R);
      this.stack.push(AB2);
      if (this.stack.length > 3){
        console.log('[AudioService] receiveAudioFrame - Busy stack: ' + this.stack.length + '  (' + NOW + ')');
      }
    }
  }

  /**
   * Periodical timer function to control local audio playing
   * 
   * @param count 
   */
   protected onAudioTimer() {

    // Debug traces
    if (this.stack.length > 3){
      console.log('[AudioService] onAudioTimer - Busy stack: ' + this.stack.length);
    }
    /////////////////////////////////
    while ((this.stack?.length > 0)&&(this.context !== undefined)&&(this.gainNode !== undefined)) {
      const SOURCE    = this.context.createBufferSource();
      const BUFF: AudioBuffer | undefined = this.stack.shift();
      if (BUFF !== undefined) {
        SOURCE.buffer = BUFF;
        SOURCE.connect(this.gainNode);
        if (this.nextTime === 0) {
          this.countLatePackages = 0;
          //this.nextTime = this.context.currentTime + 0.1;  /// add 100ms latency to work well across systems - tune this if you like
          this.nextTime = this.context.currentTime + 0.05;  /// add 50ms latency to work well across systems - tune this if you like
        }

        if (this.nextTime - this.context.currentTime > 0.205) {   /// Good value here is 0.105
          this.countLatePackages = 0;
          console.log('[AudioService] onAudioTimer - IGNORED Too much buffer: ' + (this.nextTime - this.context.currentTime) + ' ' + Date.now());
          //SOURCE.start(this.nextTime);
        }
        else if (this.nextTime - this.context.currentTime >= 0) {
          SOURCE.start(this.nextTime);
          this.countLatePackages = 0;
          //console.log('[AudioService] onAudioTimer - OK: ' + (this.nextTime - this.context.currentTime) + ' ' + Date.now());
          this.nextTime +=  SOURCE.buffer.duration; // Make the next buffer wait the length of the last buffer before being played
        } else {
          console.log('[AudioService] onAudioTimer - IGNORED Late: ' + (this.nextTime - this.context.currentTime) + ' ' + Date.now());
          this.countLatePackages++;
          //if (this.countLatePackages >= 5) {
            console.log('[AudioService] onAudioTimer - IGNORED Late ADJUST ' + (this.nextTime - this.context.currentTime));
            this.nextTime = this.context.currentTime + 0.00; // this used to be 0.01
            this.countLatePackages = 0;
          //}
          SOURCE.start(this.nextTime);
          this.nextTime +=  SOURCE.buffer.duration; // Make the next buffer wait the length of the last buffer before being played
        }
      }
      else {
        console.log('[AudioService] onAudioTimer - Buffer error');
      }
    }
  }

  protected enableAudio() {
    console.log('[AudioService] Enable');
    this.context = new window.AudioContext();
    this.gainNode = this.context.createGain();
    this.gainNode.gain.value = 1.0; // setting it to 10%
    this.gainNode.connect(this.context.destination);
    this.stack = [];
    this.nextTime = 0;
    if (this.timerSubscription !== undefined) this.timerSubscription.unsubscribe();
    this.timerSubscription = interval(20).subscribe(() => this.onAudioTimer());
    this.onAudioTimer();
    console.log('[AudioService] Enable END ' + Date.now());
  }

  protected disableAudio() {
    console.log('[AudioService] Disable');
    if (this.timerSubscription !== undefined) {
      this.timerSubscription.unsubscribe();
    }
    if (this.gainNode !== undefined) {
      if (this.context !== undefined) {
        this.gainNode.disconnect(this.context.destination);
      }
      this.gainNode = undefined;
    }
    if (this.context !== undefined) {
      delete this.context;
      this.context = undefined;
    }
  }

  public getEnabled(): boolean {
    return this.enabledSource.getValue();
  }

  public changeEnabled(value: boolean) {
    this.enabledSource.next(value);
    console.log('[AudioService] Audio enabled changed: ' + this.enabledSource.getValue());
    if (this.enabledSource.value === true) {
      // Enable audio playing
      this.enableAudio();
    }
    else {
      // Disable audio playing
      this.disableAudio();
    }
  }

  public getSelectedChannel(): number {
    return this.selectedChannel;
  }

  public changeSelectedChannel(channel: number) {
    console.log('[AudioService] changeSelectedChannel: ' + channel);
    if (this.enabledSource.value === true) {
      this.disableAudio();
      this.selectedChannel = channel;
      this.enableAudio();
    }
    else {
      this.selectedChannel = channel;
    }
  }
}
