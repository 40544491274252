import { Subscription } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';

import { ToastPlacement, ToastStatus } from '../../enums/common.enum';
import { IAvSettingsItemConfig } from '../../interfaces/av-producer/event-av-producer.interface';
import { AvProdSettingsType } from '../../const/av-producer.const';
import { AvProducerService } from '../../services/av-producer/av-producer.service';
import { IAvProdComposerSettings } from '../../interfaces/av-producer/composer-settings.interface';
import { IToastConfig } from '../../interfaces/utils/utils.interface';

export class ProducerSettingsHighlightsClass {
  protected useSaveButton: boolean = false;
  protected settingsForm: FormGroup = new FormGroup([]);
  protected isBusy: boolean = false;
  protected isDirty: boolean = false;
  protected isResetNeeded: boolean = false;

  protected outputSettingsSubscription: Subscription = new Subscription();

  protected itemsSettings: IAvSettingsItemConfig[] = [
    {
      id: 'highlightsAuto',
      type: AvProdSettingsType.switchBoolean,
      name: 'producerSettings.highlightAuto',
      min: 0,
      max: 0,
      step: 1,
      options: [],
      placeholder: '',
      visible: false,
    },
    {
      id: 'highlightsPreRoll',
      type: AvProdSettingsType.numberSlider,
      name: 'producerSettings.highlightPreRoll',
      min: 0,
      max: 60,
      step: 1,
      options: [],
      placeholder: '',
    },
    {
      id: 'highlightsPostRoll',
      type: AvProdSettingsType.numberSlider,
      name: 'producerSettings.highlightPostRoll',
      min: 0,
      max: 60,
      step: 1,
      options: [],
      placeholder: '',
    },
  ];

  constructor(protected avProd: AvProducerService) {}

  protected init(): void {
    this.itemsSettings.forEach((element) => {
      this.settingsForm.addControl(element.id, new FormControl());
    });

    this.readServerSettings();
    this.outputSettingsSubscription = this.avProd.onNewOutputSettings$
      .subscribe(() =>
        this.onNewServerSettings()
      );
  }

  protected destroy(): void {
    this.outputSettingsSubscription?.unsubscribe();
  }

  protected emitDirty(value: boolean): void {
    // must override
  }

  protected displayToast(config: IToastConfig): void {
    // must override
  }

  protected readServerSettings(): void {
    if (!this.isDirty) {
      // Global settings
      this.settingsForm.get('highlightsAuto')?.setValue(this.avProd.composerSettings.highlights?.auto);
      this.settingsForm.get('highlightsPreRoll')?.setValue(this.avProd.composerSettings.highlights?.preRoll);
      this.settingsForm.get('highlightsPostRoll')?.setValue(this.avProd.composerSettings.highlights?.postRoll);

      this.settingsForm.markAsPristine();
      this.isResetNeeded = false;
      this.isDirty = false;
      this.emitDirty(this.isDirty);
    } else {
      this.isResetNeeded = true;
    }
  }

  protected onSettingChanged(event: any): void {
    this.refreshDirtyFlags();
    if ((this.useSaveButton === false)&&(this.isDirty)){
      this.onSave();
    }
  }

  protected refreshDirtyFlags(): void {
    this.isDirty = this.settingsForm.dirty;
    if (this.isDirty) {
      this.isResetNeeded = true;
    }
    this.emitDirty(this.isDirty);
  }

  protected onNewServerSettings(): void {
    if (this.isDirty) {
      this.isResetNeeded = true;
    } else {
      this.readServerSettings();
    }
  }

  protected onSave(): void {
    let settings: IAvProdComposerSettings = {};
    this.isBusy = true;

    // Highlight settings
    settings.highlights = {};
    settings.highlights.auto = this.settingsForm.get('highlightsAuto')?.value;
    settings.highlights.preRoll = this.settingsForm.get('highlightsPreRoll')?.value;
    settings.highlights.postRoll = this.settingsForm.get('highlightsPostRoll')?.value;
    this.settingsForm.markAsPristine();
    this.avProd.azzChangeComposerSettings(settings);
    this.refreshDirtyFlags();

    this.displayToast({
      options: {
        autohide: true,
        placement: ToastPlacement.bottomRight,
      },
      data: {
        closeButtonHeader: true,
        status: ToastStatus.success,
        title: 'general.done',
        text: 'producer.settingsSavedSuccess',
        alignText: 'text-center',
        buttons: [],
      },
    });

    this.isBusy = false;
  }

  protected onReset(): void {
    this.isDirty = false;
    this.readServerSettings();
    this.emitDirty(this.isDirty);
  }
}
