import { Route } from '@angular/router';

import { LayoutComponent } from './layout/layout.component';
import { NotFoundComponent } from './layout/pages/not-found/not-found.component';
import { EmbedComponent } from './player/pages/embed/embed.component';


export const APP_ROUTES: Route[] = [
  {
    path: '',
    loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule),
    component: LayoutComponent,
  },
  { path: 'embed/:token', component: EmbedComponent },
  { path: 'not-found', component: NotFoundComponent },
  { path: ':lang/:fbclid', redirectTo: '' }, //redirections from instagram
  { path: '**', redirectTo: 'not-found' },
  /*,
  {
    path: 'demo-eurocop',
    loadChildren: () => import('./demo-eurocop/demo-eurocop.module').then(m => m.DemoEurocopModule),
  },*/
];

