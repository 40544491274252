import { FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

import { ModalDialogId } from '../../enums/utils.enum';
import { ScoreCenterBlock } from '../../enums/common.enum';
import { IAvProdComposerSettings, IAvProdOverlayScore } from '../../interfaces/av-producer/composer-settings.interface';
import { IAvSettingSelectOption, IAvSettingsItemConfig } from '../../interfaces/av-producer/event-av-producer.interface';
import { AvProdOverlayScoreSetsMode, AvProdOverlayScoreSport, AvProdSettingsType } from '../../const/av-producer.const';
import { ModalManagerService } from '../../services/modal/modal-manager.service';
import { AvProducerService } from '../../services/av-producer/av-producer.service';
import { AV_PROD_OPTIONS_SCORE_FOOTBALL_DOWN, AV_PROD_OPTIONS_SCORE_FOOTBALL_DOWN_YARDS } from '../../const/av-producer-options';


export class ToolboxScoreClass {
  protected lastChangeTS: number = 0;
  protected newSettingsSubscription: Subscription | undefined;
  protected scoreSettings: IAvProdOverlayScore | undefined;
  protected settingsScoreForm: FormGroup = new FormGroup([]);
  protected settingsChronoForm: FormGroup = new FormGroup([]);
  protected settingsFootballForm: FormGroup = new FormGroup([]);

  protected readonly ScoreCenterBlock = ScoreCenterBlock;

  protected setsMode: AvProdOverlayScoreSetsMode =
    AvProdOverlayScoreSetsMode.none;
  protected AvProdOverlayScoreSetsMode = AvProdOverlayScoreSetsMode;
  protected AvProdOverlayScoreSport = AvProdOverlayScoreSport;

  protected itemSettingEnable: IAvSettingsItemConfig = {
    id: 'visible',
    type: AvProdSettingsType.switchBoolean,
    name: '',
    min: 0,
    max: 0,
    step: 1,
    options: [],
    placeholder: '',
    value: false,
  };

  protected itemSettingFootballDown: IAvSettingsItemConfig = {
    id: 'down',
    type: AvProdSettingsType.selectComboNumber,
    name: 'toolboxScore.down',
    min: 0,
    max: 0,
    step: 1,
    options: AV_PROD_OPTIONS_SCORE_FOOTBALL_DOWN,
    placeholder: '',
    value: false,
  };

  protected itemSettingFootballDownYards: IAvSettingsItemConfig = {
    id: 'downYards',
    type: AvProdSettingsType.selectComboNumber,
    name: 'toolboxScore.downYards',
    min: 0,
    max: 0,
    step: 1,
    options: AV_PROD_OPTIONS_SCORE_FOOTBALL_DOWN_YARDS,
    placeholder: '',
    value: false,
  };

  protected itemsSettingsChrono: IAvSettingsItemConfig[] = [
    {
      id: 'visible',
      type: AvProdSettingsType.switchBoolean,
      name: '',
      min: 0,
      max: 0,
      step: 1,
      options: [],
      placeholder: '',
      value: false,
    },
    {
      id: 'period',
      type: AvProdSettingsType.selectComboNumber,
      name: 'overlaySettings.chronoPeriod',
      min: 0,
      max: 5,
      step: 1,
      options: [],
      placeholder: '',
      value: false,
    },
    {
      id: 'setMinutes',
      type: AvProdSettingsType.selectComboNumber,
      name: 'overlaySettings.chronoSetMinutes',
      min: 0,
      max: 200,
      step: 1,
      options: [],
      placeholder: '',
      value: false,
    },
    {
      id: 'setSeconds',
      type: AvProdSettingsType.selectComboNumber,
      name: 'overlaySettings.chronoSetSeconds',
      min: 0,
      max: 59,
      step: 1,
      options: [],
      placeholder: '',
      value: false,
    },
  ];

  protected centerBlockSports: AvProdOverlayScoreSport[] = [
    AvProdOverlayScoreSport.paddle,
    AvProdOverlayScoreSport.tennis,
    AvProdOverlayScoreSport.volleyball,
    AvProdOverlayScoreSport.badminton,
  ];
  //protected centerBlock: boolean = false;
  protected contentCenterBlock: ScoreCenterBlock[] = [];

  protected showCurrentSet: boolean = false;

  constructor(protected avProd: AvProducerService,
              protected modalService: ModalManagerService) {
    //this.initializeFootballOptions();
    this.settingsScoreForm.addControl(this.itemSettingEnable.id, new FormControl());
    this.settingsFootballForm.addControl(this.itemSettingFootballDownYards.id, new FormControl());
    this.settingsFootballForm.addControl(this.itemSettingFootballDown.id, new FormControl());
    this.itemsSettingsChrono.forEach((element: IAvSettingsItemConfig) => {
      this.settingsChronoForm.addControl(element.id, new FormControl());
    });
  }

  init(): void {
    this.updateScoreSettings();
    this.settingsChronoForm.get('setMinutes')?.setValue(0);
    this.settingsChronoForm.get('setSeconds')?.setValue(0);
    this.newSettingsSubscription = this.avProd.onNewComposerSettings$.subscribe(
      () => {
        this.updateScoreSettings();
      }
    );
  }

  close(): void {
    if (this.newSettingsSubscription !== undefined)
      this.newSettingsSubscription.unsubscribe();
  }

  protected initializeFootballOptions(){
    let yardsOptions: IAvSettingSelectOption[] = [
      {
        label: 'avOptions.goal',
        labelTranslate: 'avOptions.goal',
        valueNumber: 0,
        valueStr: ''
      },
    ];
    for (let i: number=1; i<100; i++){
      yardsOptions.push(
        {
          label: i.toString(),
          labelTranslate: i.toString(),
          valueNumber: i,
          valueStr: ''
        }
      )
    }
    this.itemSettingFootballDownYards.options = yardsOptions;
  }

  protected onConfigScore(): void {
    this.modalService.show(
      {
        id: ModalDialogId.producerSettings,
        title: 'producer.producerSettings',
        subId: 4,
      },
      false
    );
  }

  protected updateScoreSport(): void {
    switch (this.scoreSettings?.sport) {
      case AvProdOverlayScoreSport.tennis:
      case AvProdOverlayScoreSport.paddle:
        this.setsMode = AvProdOverlayScoreSetsMode.all;
        this.showCurrentSet = true;
        break;
      case AvProdOverlayScoreSport.volleyball:
      case AvProdOverlayScoreSport.badminton:
        this.setsMode = AvProdOverlayScoreSetsMode.global;
        this.showCurrentSet = false;
        break;
      default:
        this.showCurrentSet = false;
        this.setsMode = AvProdOverlayScoreSetsMode.none;
        break;
    }

//    this.centerBlock = this.scoreSettings?.sport
//      ? this.centerBlockSports.includes(this.scoreSettings?.sport)
//      : false;

    this.setContentInCenterBlock();
  }

  protected updateScoreSettings(): void {
    this.scoreSettings = this.avProd.composerSettings.overlayScore;
    this.lastChangeTS = new Date().getTime();
    this.settingsScoreForm.get('visible')?.setValue(this.avProd.composerSettings.overlayScore?.enabled);
    this.settingsChronoForm.get('visible')?.setValue(this.avProd.composerSettings.overlayChrono?.enabled);
    this.settingsChronoForm.get('period')?.setValue(this.avProd.composerSettings.overlayChrono?.period);
    this.settingsFootballForm.get('down')?.setValue(this.avProd.composerSettings.overlayScore?.footballDown);
    this.settingsFootballForm.get('downYards')?.setValue(this.avProd.composerSettings.overlayScore?.footballYardsToGo);
    this.updateScoreSport();

    console.log('[ToolboxScoreClass] updateScoreSettings: ' + JSON.stringify(this.scoreSettings));
  }

  protected toggleFullCompact(): void {
    let settings: IAvProdComposerSettings = {};
    console.log('[ToolboxScoreClass] toggle score full/compact before: ' +  this.scoreSettings?.displayMode);
    settings.overlayScore = {};
    settings.overlayScore.displayMode = (this.scoreSettings?.displayMode === 0) ? 1 : 0;
    if (this.scoreSettings) {
      this.scoreSettings.displayMode = settings.overlayScore.displayMode;
    }
    console.log('[ToolboxScoreClass] toggle score full/compact after: ' +  this.scoreSettings?.displayMode);
    this.avProd.azzChangeComposerSettings(settings);
  }

  protected onSettingChanged(event: any): void {
    let settings: IAvProdComposerSettings = {};
    console.log('[ToolboxScoreClass] onSettingChanged', event);
    settings.overlayScore = {};
    settings.overlayScore.enabled =
      this.settingsScoreForm.get('visible')?.value;
    this.avProd.azzChangeComposerSettings(settings);
  }

  protected onSettingChronoChanged(event: any): void {
    let settings: IAvProdComposerSettings = {};
    console.log(
      '[ToolboxScoreClass] onSettingChronoChanged ' + JSON.stringify(event)
    );
    settings.overlayChrono = {};
    settings.overlayChrono.enabled = this.settingsChronoForm.get('visible')?.value;
    settings.overlayChrono.period = this.settingsChronoForm.get('period')?.value;
    this.avProd.azzChangeComposerSettings(settings);
  }

  protected onSettingFootballChanged(event: any): void {
    let settings: IAvProdComposerSettings = {};
    console.log(
      '[ToolboxScoreClass] onSettingFootballChanged ' + JSON.stringify(event)
    );
    settings.overlayScore = {};
    settings.overlayScore.footballDown = this.settingsFootballForm.get('down')?.value;
    settings.overlayScore.footballYardsToGo = this.settingsFootballForm.get('downYards')?.value;
    this.avProd.azzChangeComposerSettings(settings);
  }

  protected chronoStop(): void {
    this.avProd.azzCmdComposerOverlayChronoStop();
  }

  protected chronoStart(): void {
    this.avProd.azzCmdComposerOverlayChronoStart();
  }

  protected chronoReset(): void {
    let time: number = 0;
    let period: number = this.settingsChronoForm.get('period')?.value;
    console.log('[ToolboxScoreClass] chronoReset: ' + time + ' ' + period);
    this.avProd.azzCmdComposerOverlayChronoReset(period, time);
  }

  protected chronoAdjust(): void {
    let time: number =
      this.settingsChronoForm.get('setMinutes')?.value * 60 +
      this.settingsChronoForm.get('setSeconds')?.value;
    let period: number = this.settingsChronoForm.get('period')?.value;
    console.log('[ToolboxScoreClass] chronoAdjust: ' + time + ' ' + period);
    this.avProd.azzCmdComposerOverlayChronoReset(period, time);
  }

  protected increaseScore(index: number, increment: number): void {
    let settings: IAvProdComposerSettings = {};
    let newValue: number = 0;
    console.log(
      '[ToolboxScoreClass] increaseScore: ' + index + ' ' + increment
    );
    if (this.scoreSettings?.values !== undefined) {
      let values: number[] = [];
      let valuesStr: string[] = [];
      for (let i = 0; i < this.scoreSettings?.values.length; i++) {
        if (i === index) {
          newValue = this.scoreSettings?.values[i] + increment;
          if (newValue >= 0) {
            values.push(newValue);
          } else {
            values.push(0);
          }
        } else {
          values.push(this.scoreSettings?.values[i]);
        }
      }

      // Check sport special features and set change
      if ((this.scoreSettings.sport === AvProdOverlayScoreSport.tennis ||
          this.scoreSettings.sport === AvProdOverlayScoreSport.paddle) &&
        this.scoreSettings.tieBreak === false) {
        if (values[index] >= 4 && values[index] - values[(index + 1) % 2] > 1) {
          // Game won
          // Increase games and update sets
          // ...
          values[index] = 4;
        } else if (values[0] === 4 && values[1] === 4) {
          // Back to Deuce
          values[0] = 3;
          values[1] = 3;
        }

        // Update string values
        for (let i: number = 0; i < 2; i++) {
          if (values[i] == 0) valuesStr[i] = '0';
          else if (values[i] == 1) valuesStr[i] = '15';
          else if (values[i] == 2) valuesStr[i] = '30';
          else if (values[i] == 3) valuesStr[i] = '40';
          else if (values[i] >= 4) {
            if (values[(i + 1) % 2] >= 3 && values[i] > values[(i + 1) % 2]) {
              valuesStr[i] = 'Ad';
            } else {
              valuesStr[i] = '40';
            }
          }
        }
      } else {
        valuesStr[0] = values[0].toFixed();
        valuesStr[1] = values[1].toFixed();
      }
      this.scoreSettings.values = values;
      this.scoreSettings.valuesStr = valuesStr;

      settings.overlayScore = {};
      settings.overlayScore.values = values;
      settings.overlayScore.valuesStr = valuesStr;
      this.avProd.azzChangeComposerSettings(settings);
    }
  }

  protected increaseScoreSet(index: number, increment: number): void {
    let settings: IAvProdComposerSettings = {};
    let newValue: number = 0;
    console.log('[ToolboxScoreClass] increaseScoreSet: ' + index + ' ' + increment);
    if (this.scoreSettings?.valuesSets !== undefined) {
      let values: number[] = [];
      for (let i = 0; i < this.scoreSettings?.valuesSets.length; i++) {
        if (i === index) {
          newValue = this.scoreSettings?.valuesSets[i] + increment;
          if (newValue >= 0) {
            values.push(newValue);
          } else {
            values.push(0);
          }
        } else {
          values.push(this.scoreSettings?.valuesSets[i]);
        }
      }
      this.scoreSettings.valuesSets = values;

      settings.overlayScore = {};
      settings.overlayScore.valuesSets = values;
      this.avProd.azzChangeComposerSettings(settings);
    }
  }

  protected increaseScoreSetGames(team: number, setIndex: number, increment: number): void {
    let settings: IAvProdComposerSettings = {};
    console.log('[ToolboxScoreClass] increaseScoreSetGames: ' + team + ' ' + setIndex + ' ' + increment);
    if (this.scoreSettings?.valuesSetsAll !== undefined &&
      this.scoreSettings?.valuesSetsAll[setIndex] !== undefined &&
      this.scoreSettings?.valuesSetsAll[setIndex][team] !== undefined) {
      let newValue: number =
        this.scoreSettings.valuesSetsAll[setIndex][team] + increment;
      if (newValue < 0) {
        newValue = 0;
      } else if (newValue > 99) {
        newValue = 99;
      }
      this.scoreSettings.valuesSetsAll[setIndex][team] = newValue;

      settings.overlayScore = {};
      settings.overlayScore.valuesSetsAll = this.scoreSettings?.valuesSetsAll;
      this.avProd.azzChangeComposerSettings(settings);
    }
  }

  protected resetScore(): void {
    this.avProd.azzCmdComposerOverlayScoreReset(0);
  }

  protected setIndex(i: number): number {
    return i;
  }

  protected teamsIndex(i: number): number {
    return i;
  }

  protected toggleServe(): void {
    console.log('[ToolboxScoreClass] toggleServe');
    const TEAM: number = (this.scoreSettings?.serve === 1)? 2 : 1;
    let settings: IAvProdComposerSettings = {};
    settings.overlayScore = {};
    settings.overlayScore.serve = TEAM;
    if (this.scoreSettings) {
      this.scoreSettings.serve = TEAM;
    }
    this.avProd.azzChangeComposerSettings(settings);
  }

  protected toggleTieBreak(): void {
    let settings: IAvProdComposerSettings = {};
    console.log('[ToolboxScoreClass] toggleTieBreak');
    settings.overlayScore = {};
    settings.overlayScore.tieBreak = !this.scoreSettings?.tieBreak;
    if (this.scoreSettings) {
      this.scoreSettings.tieBreak = !this.scoreSettings?.tieBreak;
    }
    this.avProd.azzChangeComposerSettings(settings);
  }

  protected setCurrentSet(currentSet: number): void {
    let settings: IAvProdComposerSettings = {};
    console.log('[ToolboxScoreClass] currentSet');
    settings.overlayScore = {};
    settings.overlayScore.currentSet = currentSet;
    if (this.scoreSettings) {
      this.scoreSettings.currentSet = currentSet;
    }
    this.avProd.azzChangeComposerSettings(settings);
  }

  protected bbIncrementStrike(incr: number){
    let settings: IAvProdComposerSettings = {};
    console.log('[ToolboxScoreClass] bbIncrementStrike ' + incr);
    settings.overlayScore = {};
    if (this.scoreSettings?.baseballStrike !== undefined){
      settings.overlayScore.baseballStrike = this.scoreSettings?.baseballStrike + incr;
      if (settings.overlayScore.baseballStrike < 0){
        settings.overlayScore.baseballStrike = 0;
      }
      if (settings.overlayScore.baseballStrike > 2){
        settings.overlayScore.baseballStrike = 2;
      }
    }
    if (this.scoreSettings) {
      this.scoreSettings.baseballStrike = settings.overlayScore.baseballStrike;
    }
    this.avProd.azzChangeComposerSettings(settings);
  }

  protected bbToggleBase(index: number){
    let settings: IAvProdComposerSettings = {};
    console.log('[ToolboxScoreClass] bbToggleBase ' + index);
    if (this.scoreSettings !== undefined){
      if (this.scoreSettings?.baseballBases !== undefined){
        if (index < this.scoreSettings?.baseballBases.length){
          this.scoreSettings.baseballBases[index] = !this.scoreSettings.baseballBases[index];
        }
      }
      else{
        this.scoreSettings.baseballBases = [false, false, false];
      }
      settings.overlayScore = {};
      settings.overlayScore.baseballBases = this.scoreSettings?.baseballBases;
      this.avProd.azzChangeComposerSettings(settings);
    }
  }

  protected bbIncrementBall(incr: number){
    let settings: IAvProdComposerSettings = {};
    console.log('[ToolboxScoreClass] bbIncrementBall ' + incr);
    settings.overlayScore = {};
    if (this.scoreSettings?.baseballBall !== undefined){
      settings.overlayScore.baseballBall = this.scoreSettings?.baseballBall + incr;
      if (settings.overlayScore.baseballBall < 0){
        settings.overlayScore.baseballBall = 0;
      }
      if (settings.overlayScore.baseballBall > 3){
        settings.overlayScore.baseballBall = 3;
      }
    }
    if (this.scoreSettings) {
      this.scoreSettings.baseballBall = settings.overlayScore.baseballBall;
    }
    this.avProd.azzChangeComposerSettings(settings);
  }

  protected bbIncrementOut(incr: number){
    let settings: IAvProdComposerSettings = {};
    console.log('[ToolboxScoreClass] bbIncrementOut ' + incr);
    settings.overlayScore = {};
    if (this.scoreSettings?.baseballOut !== undefined){
      settings.overlayScore.baseballOut = this.scoreSettings?.baseballOut + incr;
      if (settings.overlayScore.baseballOut < 0){
        settings.overlayScore.baseballOut = 0;
      }
      if (settings.overlayScore.baseballOut > 3){
        settings.overlayScore.baseballOut = 3;
      }
    }
    if (this.scoreSettings) {
      this.scoreSettings.baseballOut = settings.overlayScore.baseballOut;
    }
    this.avProd.azzChangeComposerSettings(settings);
  }

  protected bbIncrementInning(incr: number){
    let settings: IAvProdComposerSettings = {};
    console.log('[ToolboxScoreClass] bbIncrementOut ' + incr);
    settings.overlayScore = {};
    let topbottom: number = 1;
    if (this.scoreSettings?.serve !== undefined){
      topbottom = this.scoreSettings?.serve + incr;
    }

    if ((topbottom == 1)||(topbottom == 2)){
      settings.overlayScore.serve = topbottom;
      if (this.scoreSettings) {
        this.scoreSettings.serve = settings.overlayScore.serve;
      }
    }
    else{
      if (topbottom > 2){
        topbottom = 1;
        if (this.scoreSettings?.currentSet !== undefined){
          settings.overlayScore.currentSet = this.scoreSettings?.currentSet + 1;
        }
        else{
          settings.overlayScore.currentSet = 1;
        }
      }
      else if (topbottom < 1){
        topbottom = 2;
        if (this.scoreSettings?.currentSet !== undefined){
          settings.overlayScore.currentSet = this.scoreSettings?.currentSet - 1;
        }
        else{
          settings.overlayScore.currentSet = 1;
        }
      }
      if (settings.overlayScore.currentSet !== undefined){
        if (settings.overlayScore.currentSet < 1){
          settings.overlayScore.currentSet = 1;
          topbottom = 1;
        }
      }
      else{
        settings.overlayScore.currentSet = 1;
      }
      settings.overlayScore.serve = topbottom;
      if (this.scoreSettings) {
        this.scoreSettings.serve = settings.overlayScore.serve;
        this.scoreSettings.currentSet = settings.overlayScore.currentSet;
      }

    }
    this.avProd.azzChangeComposerSettings(settings);
  }

  protected footballIncrementDown(incr: number){
    let settings: IAvProdComposerSettings = {};
    console.log('[ToolboxScoreClass] footballIncrementDown ' + incr);
    settings.overlayScore = {};
    if (this.scoreSettings?.footballDown !== undefined){
      settings.overlayScore.footballDown = this.scoreSettings?.footballDown + incr;
      if (settings.overlayScore.footballDown < 0){
        settings.overlayScore.footballDown = 0;
      }
      if (settings.overlayScore.footballDown > 4){
        settings.overlayScore.footballDown = 4;
      }
    }
    if (this.scoreSettings) {
      this.scoreSettings.footballDown = settings.overlayScore.footballDown;
    }
    this.avProd.azzChangeComposerSettings(settings);
  }

  protected footballIncrementDownYards(incr: number){
    let settings: IAvProdComposerSettings = {};
    console.log('[ToolboxScoreClass] footballIncrementDownYards ' + incr);
    settings.overlayScore = {};
    if (this.scoreSettings?.footballYardsToGo !== undefined){
      settings.overlayScore.footballYardsToGo = this.scoreSettings?.footballYardsToGo + incr;
      if (settings.overlayScore.footballYardsToGo < 0){
        settings.overlayScore.footballYardsToGo = 0;
      }
      if (settings.overlayScore.footballYardsToGo > 99){
        settings.overlayScore.footballYardsToGo = 99;
      }
    }
    if (this.scoreSettings) {
      this.scoreSettings.footballYardsToGo = settings.overlayScore.footballYardsToGo;
    }
    this.avProd.azzChangeComposerSettings(settings);
  }

  private setContentInCenterBlock(): void {
    switch (this.scoreSettings?.sport) {
      case AvProdOverlayScoreSport.tennis:
      case AvProdOverlayScoreSport.paddle:
        this.contentCenterBlock = [
          ScoreCenterBlock.serve,
          ScoreCenterBlock.tieBreak,
          ScoreCenterBlock.resetButton,
        ];
        break;
      case AvProdOverlayScoreSport.volleyball:
      case AvProdOverlayScoreSport.badminton:
        this.contentCenterBlock = [
          ScoreCenterBlock.serve,
          ScoreCenterBlock.resetButton,
        ];
        break;
      case AvProdOverlayScoreSport.baseball:
      case AvProdOverlayScoreSport.softball:
        this.contentCenterBlock = [
          ScoreCenterBlock.resetButton,
          ScoreCenterBlock.bbInningCtrl,
        ];
        break;
      case AvProdOverlayScoreSport.football:
        this.contentCenterBlock = [];
        break;
      default:
        this.contentCenterBlock = [];
        break;
    }
  }
}
