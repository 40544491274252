export enum Sections {
  home = 'Home',
  publisher = 'Publish',
  producer = 'Produce',
  player = 'Watch'
}

export enum SectionIds {
  login = 0,
  home = 1,
  events = 2,
  producer = 3,
  publisher = 4,
  player = 5,
  accountSettings = 6,
  produce = 7,
  publish = 8,
  watch = 9
}

export enum DeviceType {
  none = 0,
  ipad = 1,
  tablet = 2,
  iphone = 3,
  phone = 4,
  web = 5,
  webPhone = 6,
  webTablet = 7,
  webIpad = 8
}

export enum AlertStatus {
  error = 'error',
  success = 'success',
  warning = 'warning',
  question = 'question'
}

export enum ToastPlacement {
  topLeft = 'topLeft',
  topCenter = 'topCenter',
  topRight = 'topRight',
  middleLeft = 'middleLeft',
  middleCenter = 'middleCenter',
  middleRight = 'middleRight',
  bottomLeft = 'bottomLeft',
  bottomCenter = 'bottomCenter',
  bottomRight = 'bottomRight'
}

export enum ToastPlacementCss {
  topLeft = 'top-0 start-0',
  topCenter = 'top-0 start-50 translate-middle-x',
  topRight = 'top-0 end-0',
  middleLeft = 'top-50 start-0 translate-middle-y',
  middleCenter = 'top-50 start-50 translate-middle',
  middleRight = 'top-50 end-0 translate-middle-y',
  bottomLeft = 'bottom-0 start-0',
  bottomCenter = 'bottom-0 start-50 translate-middle-x',
  bottomRight = 'bottom-0 end-0'
}

export enum ToastStatus {
  error = 'error',
  success = 'success',
  warning = 'warning',
  info = 'info',
}

export enum PlayerModals {
  none = 'none',
  passwordPrompt = 'passwordPrompt',
  eventFinished = 'eventFinished'
}

export enum StudioTypes {
  full = 1,
  score = 2,
  singleCam = 3
}

export enum OrganizationStatus {
  active = 'Active',
  declined = 'Declined',
  invited = 'Invited'
}

export enum ScoreCenterBlock {
  resetButton = 'resetButton',
  tieBreak = 'tieBreak',
  serve = 'serve',
  bbInningCtrl = 'bbInningCtrl',
  footballCtrl = 'footballCtrl'
}
