import { Subscription, timer } from 'rxjs';
import { AvProducerService } from '../../services/av-producer/av-producer.service';
import { IAvProdComposerStatus, IAvProdVideoLayoutPreset } from '../../interfaces/av-producer/composer-status.interface';
import { ToastPlacement, ToastStatus } from '../../enums/common.enum';
import { IAlertData, IToastConfig } from '../../interfaces/utils/utils.interface';

export class ToolboxPresetsClass {

  protected imgUrl: string = '';
  protected lastChangeTS: number = 0;
  protected newStatusSubscription: Subscription | undefined;
  protected presetIdEditionActive: number | undefined;
  protected presetNameEdition: string = '';
  protected _presets: IAvProdVideoLayoutPreset[] = [];

  constructor(protected avProd: AvProducerService) { }

  init(): void {
    const HOST: string = this.avProd.getHostUrl();
    this.imgUrl = 'https://' + HOST + '/';
    this.onNewStatusReceived(this.avProd.composerStatus);
    this.newStatusSubscription = this.avProd.onNewComposerStatus$.subscribe(status => this.onNewStatusReceived(status));
  }

  close(): void {
    if (this.newStatusSubscription !== undefined) this.newStatusSubscription.unsubscribe();
  }
  protected displayToast(config: IToastConfig){
  }

  protected onNewStatusReceived(status: IAvProdComposerStatus){
    console.log('[ToolboxPresetsClass] onNewStatusReceived: ' + JSON.stringify(status));
    if (this.avProd.composerStatus.presets !== undefined){
      for (let i:number=0; i<this.avProd.composerStatus.presets?.length; i++){
        if (this._presets.length < i+1){
          this._presets.push(
            {'exists': this.avProd.composerStatus.presets[i].exists,
             'name': this.avProd.composerStatus.presets[i].name});
        }
        else{
          this._presets[i].exists = this.avProd.composerStatus.presets[i].exists;
          this._presets[i].name = this.avProd.composerStatus.presets[i].name;
        }
      }
    }
    else{
      this._presets = [];
    }
    const HOST: string = this.avProd.getHostUrl();
    this.imgUrl = 'https://' + HOST + '/';
    this.lastChangeTS = (new Date()).getTime();
  }

  protected onPresetDelete(id: number){
    this.avProd.azzCmdComposerPresetRemove(id);
    this.displayToast({
      options: {
        autohide: true,
        placement: ToastPlacement.bottomRight
      },
      data: {
        closeButtonHeader: true,
        status: ToastStatus.success,
        title: 'general.done',
        text: 'toolboxPresets.removeSuccess',
        alignText: 'text-center',
        buttons: []
      },
    });
  }

  protected onPresetCreate(id: number){
    this.onPresetSave(id);
    this.onClickEditName(id-1);
  }

  protected onPresetSave(id: number){
    this.avProd.azzCmdComposerPresetSave(id);
    this.displayToast({
      options: {
        autohide: true,
        placement: ToastPlacement.bottomRight
      },
      data: {
        closeButtonHeader: true,
        status: ToastStatus.success,
        title: 'general.done',
        text: 'toolboxPresets.saveSuccess',
        alignText: 'text-center',
        buttons: []
      },
    });
  }

  protected onPresetApply(id: number){
    this.avProd.azzCmdComposerPresetApply(id);
  }
  
  protected onPresetChangeName(id: number, name: string){
    console.log('[ToolboxPresets] onPresetChangeName: ' + id + ' ' + name);
    if ((this.avProd.composerStatus.presets !== undefined)&&
        (this.presetNameEdition !== this.avProd.composerStatus.presets[id].name)&&
        (this.presetNameEdition !== '')){

      this.avProd.azzCmdComposerPresetSaveName(id+1, name);
      this.displayToast({
        options: {
          autohide: true,
          placement: ToastPlacement.bottomRight
        },
        data: {
          closeButtonHeader: true,
          status: ToastStatus.success,
          title: 'general.done',
          text: 'toolboxPresets.changeNameSuccess',
          alignText: 'text-center',
          buttons: []
        },
      });
    }
    this.presetIdEditionActive = undefined;
    this.presetNameEdition = '';
  }
  
  protected onClickEditName(index: number){
    if (this.avProd.composerStatus.presets !== undefined){
      this.presetNameEdition = this.avProd.composerStatus.presets[index].name;
      this.presetIdEditionActive = index;
      timer(50).subscribe(() => {
        let myInput: HTMLElement | null = <HTMLElement>document?.getElementById('presetTagName') as HTMLElement;
        if (myInput !== null){
          myInput.focus();
        }
      });
  }
  }
}
