import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
  HttpErrorResponse
} from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, from, iif, Observable, of, tap, throwError } from 'rxjs';
import { catchError, filter, switchMap, take, timeout } from 'rxjs/operators';

import { ErrorSeverity } from '../../enums/utils.enum';
import { API } from '../../const/api.const';
import { COMMON } from '../../const/common.const';
import { ErrorHandlerService } from '../../services/error-handler/error-handler.service';
import { DeviceService } from '../../services/device/device.service';
import { AuthService } from '../../services/auth/auth.service';
import { UserService } from '../../services/user/user.service';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  refreshingToken: boolean;
  skipIntercept: boolean;
  skipRequestUrls: string[];

  private _token: string | undefined;
  private refreshTokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(private authService: AuthService,
              private userService: UserService,
              private errorService: ErrorHandlerService,
              private router: Router,
              private deviceService: DeviceService) {
    this.skipRequestUrls = COMMON.interceptors.skip;
    this.refreshingToken = false;
    this.skipIntercept = false;
  }

  get token(): string | undefined {
    return this._token;
  }

  set token(token: string | undefined) {
    this._token = 'Bearer ' + token;
  }

  static isServerError(err: any): boolean {
    return (err.status > 499 && err.status < 600);
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> | Observable<any> {
    this.token = this.authService.accessToken;

    this.skipRequestUrls.forEach((curSkipRequest: string) => {
      if (request.url.includes(curSkipRequest)) {
        // console.log('**** Interceptor Skip: ' + request.url);
        this.skipIntercept = true;
      }
    });

    if (this.skipIntercept) {
      // SKIP REQUEST
      this.skipIntercept = false;
      return next.handle(request);

    } else {
      // SEND & TOKENIZE REQUEST
      const TOKENIZED_REQUEST: HttpRequest<any> = this.addAuthHeaders(request);

      return next.handle(TOKENIZED_REQUEST)
        .pipe(
          timeout(API.timeout),
          catchError((err: Error) => {
            if (err instanceof HttpErrorResponse) {
              if (err.status === 401 && err.error.code !== 605) {
                if (err.error.code === 620) {// REFRESH_TOKEN EXPIRED
                  return this.handleAuthError('Refresh token expired');
                } else {
                  return this.handle401Error(TOKENIZED_REQUEST, next);
                }
              } else {
                if (AuthInterceptor.isServerError(err)) {
                  this.handleServerError(err, TOKENIZED_REQUEST);
                } else {
                  if (err.status === 0) {
                    this.handleUnknownError(err, TOKENIZED_REQUEST);
                  }
                }
              }
            } else {
              if (err.name && err.name === 'TimeoutError') {
                this.handleTimeOutError(err, TOKENIZED_REQUEST);
                err.message = 'Request Time-out';
              }
            }
            return throwError(() => err);
          }));
    }
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<boolean | HttpEvent<any>> {
    if (!this.refreshingToken) {
      this.refreshingToken = true;
      this.refreshTokenSubject.next('');
      return this.authService.updateAccessToken()
        .pipe(
          tap(() => this.refreshingToken = false),
          switchMap((result: boolean) =>
            iif(() => result,

              // If result is true (token has been updated with a new one from localstorage) -> resend las request
              // Only happens when more than 1 tab is open
              next.handle(this.addAuthHeaders(request)),

              // If result is false -> unable to update token - Tries to renew it with refresh token
              this.userService.renewToken(this.deviceService.device?.appVersionCode ?? 0)
                .pipe(
                  switchMap((result: boolean) => {
                    if (result) {
                      // If access token could be renewed, resend last request with a renewed header
                      this.token = this.authService.accessToken;
                      this.refreshTokenSubject.next(this.token);
                      return next.handle(this.addAuthHeaders(request));
                    } else {
                      // If access token could NOT be renewed, logout user
                      return from( this.handleAuthError('Unable to renew token'));
                    }
                  }),
                  catchError((err) => {
                    this.handleAuthError('Renew token catch error: ' + JSON.stringify(err));
                    return throwError(() => err);
                  })
                )
          ))
        )
    }
    return this.refreshTokenSubject.pipe(
      filter((token: string) => !!token),
      take(1),
      switchMap(() => next.handle(this.addAuthHeaders(request)))
    );
  }

  private addAuthHeaders(request: HttpRequest<any>): HttpRequest<any> {

    let headers: HttpHeaders = request.headers;
    if (this.deviceService.source) {
      headers = headers.set(API.headers.source, this.deviceService.source);
    }
    if (this.authService.accessToken && this.authService.accessToken !== '') {
      headers = headers.set(API.headers.authToken, `Bearer ${this.authService.accessToken}`);
    }
    return request.clone({headers});
  }


  private handleAuthError(text?: string): Observable<boolean> {
    this.errorService.handle({
      code: 401,
      text: 'Unauthorized request',
      severity: ErrorSeverity.warning,
      location: 'interceptor + redirect to login -> in: '+ text ,
      display: 'none',
      report: true
    });
    this.authService.authDisallowed()
      .catch(console.error);
    this.userService.resetUser();
    this.router.navigateByUrl(COMMON.urls.login)
      .catch(console.error);
    return of(true);
  }


  private handleServerError(error: HttpErrorResponse, request: HttpRequest<any>): void {
    this.errorService.handle({
      code: error.status,
      text: error.message,
      severity: ErrorSeverity.critical,
      location: 'interceptor in: ' + request.url + ' params: ' + JSON.stringify(request.params) + 'headers: ' + JSON.stringify(request.headers),
      display: 'alert',
      report: true
    });
  }

  private handleUnknownError(error: HttpErrorResponse, request: HttpRequest<any>): void {
    this.errorService.handle({
      code: error.status,
      text: error.message,
      severity: ErrorSeverity.critical,
      location: 'interceptor in: ' + request.url + ' params: ' + JSON.stringify(request.params) + 'headers: ' + JSON.stringify(request.headers),
      display: 'alert',
      report: true
    });
  }

  private handleTimeOutError(error: any, request: HttpRequest<any>): void {
    this.errorService.handle({
      code: error.status ?? undefined,
      text: error.message,
      severity: ErrorSeverity.error,
      location: 'interceptor in: ' + request.url + ' params: ' + JSON.stringify(request.params) + 'headers: ' + JSON.stringify(request.headers),
      display: 'alert',
      report: true
    });
  }
}
