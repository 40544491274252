import { HttpErrorResponse } from '@angular/common/http';
import { NgForm } from '@angular/forms';

import { PasswordClass } from './password.class';
import { ILoginGoogleUser, ILoginUser } from '../../interfaces/user/user.interface';
import { UserService } from '../../services/user/user.service';


export class LoginClass extends PasswordClass {

  protected sendButtonDisabled: boolean = false;
  protected error: boolean = false;
  protected errorTitle: string = '';
  protected errorMessage: string = '';
  protected activateAccountPend: boolean = false;

  protected loginUser: ILoginUser = {
    email: '',
    password: ''
  };

  protected loginGoogleUser: ILoginGoogleUser = {
    clientId: '',
    name: '',
    email: '',
    language: '',
  };

  constructor(protected userService: UserService ) {
    super(1);
  }

  protected login(fLogin: NgForm, version: number): void {
    this.error = false;
    this.errorTitle = '';
    this.errorMessage = '';
    if (fLogin.valid) {
      this.sendButtonDisabled = true;
      this.userService.login(this.loginUser, version ?? 0)
        .subscribe({
          next: this.handleResponse.bind(this),
          error: this.handleErrorResponse.bind(this)
        });
    }
  }

  protected sendActivationCode(): void {
    this.userService.userActivation = this.loginUser.email;
    this.userService.sendActivation({email: this.loginUser.email, code: 0})
      .subscribe();
  }

  protected modalClose(): void {
    // must be overridden
  }

  protected resetVars(): void {
    this.error = false;
    this.errorTitle = '';
    this.errorMessage = '';
    this.activateAccountPend = false;
    this.sendButtonDisabled = false;
    this.loginUser = {
        email: '',
        password: ''
      };
    this.loginGoogleUser = {
      clientId: '',
      name: '',
      email: '',
      language: '',
    };
  }

  protected showErrorMessage(): void {
    //must be overridden
  }

  private handleResponse(): void {
    if(this.error) {
      this.showErrorMessage();
    } else {
      setTimeout( () => {
        this.resetVars();
        this.modalClose();
      }, 100);
    }
  }

  private handleErrorResponse(err: HttpErrorResponse): void {
    this.sendButtonDisabled = false;
    this.activateAccountPend = false;
    if(err.status === 401) {
      this.errorTitle = 'users.wrongCredentials';
      this.errorMessage = 'users.userNotFoundInfo';
    } else {
      switch (err.error.code) {
        case 601:
          this.errorTitle = 'users.inactiveAccount';
          this.errorMessage = 'users.userInactiveInfo';
          this.activateAccountPend = true;
          break;
        case 604:
          this.errorTitle = 'users.wrongCredentials';
          this.errorMessage = 'users.userNotFoundInfo';
          break;
      }
    }
    this.error = true;
    this.showErrorMessage();
  }

}
