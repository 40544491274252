<header>
  <web-top-bar *ngIf="layoutService.showTopBar" (modalUserRequested)="modalRequested($event)"></web-top-bar>
</header>
<main>
  <router-outlet></router-outlet>
</main>
<footer>
  <web-footer *ngIf="layoutService.showFooter"></web-footer>
  <web-footer-bottom *ngIf="layoutService.showFooter"></web-footer-bottom>
</footer>

<web-cookie-consent></web-cookie-consent>
<web-toaster></web-toaster>
<web-alert></web-alert>

<web-users-modal></web-users-modal>
<web-users-panel *ngIf="!userService.anonymousUser"></web-users-panel>
