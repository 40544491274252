import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { ThemeService } from '@azz-life-streamer/shared';


@Component({
  selector: 'web-toggle-theme',
  standalone: true,
    imports: [CommonModule, TranslateModule],
  templateUrl: './toggle-theme.component.html',
  styleUrls: ['./toggle-theme.component.scss'],
})
export class ToggleThemeComponent {

  private _color: string = 'default';

  @Input() set color(color: string) {
    if(color !== this._color) this._color = color;
  }

  get color(): string {
    return this._color;
  }

  constructor(protected themeService: ThemeService) { }

}
