
export interface IAvProdClientStatusVideo {
    bitRate: number;
    frameRate: number;
    resolution: string;
    deviceId: string;
    enabled: boolean;
    statLastPackage: number;
    statBitRate: number;
}

export interface IAvProdClientStatusAudio {
    bitRate: number;
    deviceId: string;
    enabled: boolean;
    statLastPackage: number;
    statBitRate: number;
}

export interface IAvProdClientStatusPublish {
    slotIndex: number;
    mode: number;
    streamId: number;
    state: number;
    video: IAvProdClientStatusVideo;
    audio: IAvProdClientStatusAudio;
}

export interface IAvProdInterfaceClientDeviceStatus {
    batteryLevel: number;
    batteryCharging: boolean;
}

export interface IAvProdInterfaceClientStatus {
    clientId: number;
    tsDiff: number;
    ts?: number;
    registration: string;
    status?: IAvProdInterfaceClientDeviceStatus;
    publish?: IAvProdClientStatusPublish[];
}